export const locale = {
	TRANSLATOR: {
		SELECT: "Select your language"
	},
	MENU: {
		DASHBOARD: "Dashboard",
		ASIDE: {
			AGENT: "Agent",
			CUSTOMER_MANAGEMENT: "Customer Management",
			CUSTOMER: "Customer",
			DELIVERY_ADDRESS: "Delivery Address",
			CUSTOMER_DO_HEADER: "Customer Do Header",
			CUSTOMER_SUPPLIER: "Customer Supplier",
			CUSTOMER_UPDATE_LOG: "Request Change A/C Name / BRN",
			CUSTOMER_UPDATE_LOG_REJECT_INFO: "Reject Change A/C Request",

			CHARGES_TYPE: "Charges Type",
			MASTER_DATA: "Master Data",
			TRANSACTION: "Transaction",
			TEMPLATE_SETTING: "Template Setting",
			ANNOUNCEMENT_MANAGEMENT: "Announcement Management",
			NOTICE_MANAGEMENT: "Notice Management",
			SLIDER_MANAGEMENT: "Slider Management",
			BANNER_MANAGEMENT: "Banner Management",
			CMS: "CMS",
			PAGE_MANAGEMENT: "Page Management",
			MENU_MANAGEMENT: "Menu Management",
			MENU_ITEM_MANAGEMENT: "Menu Item Management",
			DASHBOARD: "Dashboard",
			COUNTRIES_REGION: "Countries & Region",
			COUNTRIES: "Countries",
			STATES: "States",
			CITIES: "Cities",
			POSTCODES: "Postcodes",
			CURRENCIES_TAX: "Currencies & Tax",
			DO_MANAGEMENT: "D/O Management",
			DO_SUMMARY: "D/O Summary",
			CREATE_DO_BY_REF_ID: "Create D/O By Ref ID",
			ASSIGN_DO_DATE_TRANSPORTER: "Assign D/O Date & Transporter",
			REQUEST_CHANGE_DELIVERY_ADD: "Request Change Del. Addr.",
			APPROVE_CHANGE_DELIVERY_ADD: "Approve Change Del. Addr.",
			TAX_TYPE: "Tax Type",
			CURRENCIES: "Currencies",
			CURRENCY_GROUP: "Currency Group",
			COMPANY: "Company",
			WAREHOUSE: "Warehouse",
			SALESMAN_MANAGEMENT: "Salesman Management",
			MAINSALESMAN: "Main Salesman",
			COSTING: "Costing Management",
			CUSTOMER_COSTING_GROUP: "Customer Costing Group",
			SHIPMENT: "Shipment",
			SHIPMENT_TYPE: "Shipment Type",
			SHIPMENT_MODE: "Shipment Mode",
			MOVEMENT_TYPE: "Movement Type",
			SHIPMENT_TERMS: "Shipment Terms",
			REFERENCE_TYPE: "Reference Type",
			COURIER_COMPANY: "Courier Company",
			CATEGORY_TYPE: "Category Type",
			SENSITIVE_TYPE: "Sensitive Type",
			SHIPPER: "Shipper",
			SHIPPING_LINE: "Shipping Line",
			TRANSPORTER: "Transporter",
			AREA: "Area",
			CREDIT_TERMS: "Credit Terms",
			SALESMAN: "Salesman",
			SETTINGS: "Settings",
			GENERAL_SETTINGS: "General",
			NOTICE_TYPE_GROUP: "Notice Type Group",
			NOTICE_TYPE: "Notice Type",
			DOC_TYPE: "Doc Type",
			DOC_NUM: "Doc Numbering",
			QUOTATION_MANAGEMENT: "Quotation Management",
			QUOTATION: "Quotation",
			/* Wesly 04/05/2021 End*/
			VALIDITY: "Validity",
			/* Wesly 04/05/2021 */
			INDEMNIFY: "Indemnify",
			/* Wesly 09/05/2021 */
			FREIGHT_CHARGES: "Freight Charges",
			/* Wesly 09/05/2021 */
			QUOTATION_STATUS: "Quotation Status",
			REQUEST_FOR_QUOTE: "Request For Quote",
			PORT: "Port",
			TERRITORY: "Territory",
			STOCK: "Stock",
			UOM: "Stock UOM",
			INSTRUCTION_TYPE: "Instruction Type",
			INDUSTRIES: "Industries",
			PARTNER_TYPE: "Partner Type",
			REMARK_ID: "Delivery Remark",
			FORWARDER: "Forwarder",
			FORWARDER_GROUP: "Forwarder Group",
			CONSIGNEE: "Consignee",
			CONSIGNEE_GROUP: "Consignee Group",
			FORWARDER_COSTING_TYPE: "Forwarder Costing Type",
			GL_ACCOUNT_SETTING: "GL Account Setting ",
			QUOTATION_MANAGEMENT_FCL: "FCL",
			QUOTATION_MANAGEMENT_OTHERS_LCL: "Others LCL",
			QUOTATION_MANAGEMENT_LCL: "LCL",
			WAREHOUSE_LOCATION: "Warehouse Location",
			TAX: "Tax",
			ROUTE: "Route",
			EXCEPTIONAL_COST_MASTER: "Exceptional Cost Type",
			EXCEPTIONAL_COST: "Exceptional Cost",
			WEIGHT_ROUNDING: "Weight Rounding",
			CUSTOMER_MASTER_DATA: "Customer Master Data",
			DAY_CONVERSION: "Day Conversion",
			QUOTATION_SUBJECT: "Quotation Subject",
			CUSTOMER_REJECT_QUOTE_REASON: "Customer Reject Reason",
			ADMIN_REJECT_QUOTE_REASON: "Admin Reject Reason",
			REVERT_QUOTE_REASON: "Revert Quote Reason",
			QUOTE_INVALID_REASON: "Quotation Invalid Reason",
			MAIN_SALESMAN_COST: "Main Salesman Cost",
			CATEGORY_TYPE_COST: "Category Type Cost",
			SALESMAN_COST_GROUP: "Salesman Cost Group",
			SALESMAN_COST_SETUP: "Salesman Cost",
			CUSTOMER_COST: "Customer Cost",
			SPECIAL_PRODUCT_STATUS: "Special Product Status",
			WAREHOUSE_TPYE: "Warehouse Type",
			LANGUAGE: "Language",
			PRODUCT_MANAGEMENT: "Product Management",
			SPECIAL_PRODUCT: "Special Product",
			BASE_COST: "Base Cost",
			SPECIAL_INSTRUCTION_BY_MARKING: "Special Instruction By Marking",
			SPECIAL_INSTRUCTION_BY_EPO: "Special Instruction By Epo",
			TRANSIT_COST: "Transit Cost",
			MARKING_COST: "Marking Cost",
			CUSTOMER_EXCEPTIONAL_COST: "Customer Exceptional Cost",
			EPO_MANAGEMENT: "EPO Management",
			EPO_LISTING: "EPO Listing",
			EPO_STATUS: "EPO Status",
			DISTRICT: "District",
			DELIVERY_ZONE: "Delivery Zone",
			SPECIAL_INSTRUCTION_MANAGEMENT: "Special Instruction",
			EPO_SETTING: "EPO Setting",
			PAYABLE_NOTE_FORMAT_SETTING: "PN Format Setting",
			AREA_GROUP: "Area Group",
			TT_TRANSACTION: "TT Master",
			TT_CHARGES: "TT Charges",
			BENEFICIARY_TYPE: "TT Beneficiary Type",
			TT_CATEGORY: "TT Category",
			TT_GROUP: "TT Group",
			TT_REJECT_REASON: "Reject/Revert Reason",
			TT_DEPOSIT_STATUS: "Deposit/PN Status",
			BANK_CODE: "TT Bank Code",
			TT_CREDITOR: "TT Creditor",
			TT_GL_ACCOUNT: "TT GL Account",
			TT_DEFAULT_GL_ACCOUNT: "TT Default GL Account",
			TT_AGENT: "TT Agent",
			TT_DAILY_RATE: "TT Daily Rate",
			TT_COMPANY: "TT Company",
			TT_CUSTOMER_SPECIAL_RATE: "TT Customer Special Rate",
			TT_SPREAD_RATE: "TT Spread Rate",
			TELEGRAPHIC_TRANSFER: "TT Transaction",
			RECEIPT_MAINTENANCE: "Receipt Maintenance",
			RECEIPT_PAYMENT: "Receipt Payment",
			TT_ASSIGNMENT: "TT Assignment",
			PAY_MODE: "Pay Mode",
			PRODUCT: "Product",
			TT: {
				TITLE: "TT",
				REQUEST: "Requests",
				VERIFY: "Verify",
				APPROVE: "Approve",
				ACKNOWLEDGE: "Acknowledge",
				ASSIGN: "Assignment",
				REJECT: "Reject",
				PAYABLE_PAYMENT_CONFIRM: "Approve PN Payment",
				CLOSE: "Close TT",
				BLOCK: "Block TT",
				CN: "Credit Note",
				DN: "Debit Note",
				BOOKING: "TT Booking",
				PAYMENT_ADVICE: "Payment Advice",
				PAYMENT_ADVICE_APPROVE: "Payment Advice Approve"
			},
			PAYABLE_PAYMENT: "Payable Notes Payment",
			INV_ON_HOLD: "Invoice Status",
			INVOICE_MANAGEMENT: "Invoice Management",
			CREATE_INVOICE_BY_REF_ID: "Create Invoice By Ref Id",
			INVOICE: "Invoice",
			INV_REF: "Inv Ref Id Maintenance",
			SYSTEM_LOG: "System Log",
			WAREHOUSE_SETTINGS: "Warehouse Settings",
			DEPARTMENT_SETTINGS: "Department Settings",
			ROLES_TYPE: "Roles Type",
			LOCATION: "Location",
			IN_RANGE_CHARGES: "In Range Charges",
			OUT_OF_RANGE_CHARGES: "Out Of Range Charges",
			DELIVERY_AGENT: "Delivery Agent",
			AUDIT_LOG: "Audit/Email Log",
			SPECIAL_PRODUCT_REMARK: "Special Product Remark",
			CLOSE_DO_BY_REF_ID: "Close D/O By Ref Id",
			OPEN_DO_BY_REF_ID: "Open D/O By Ref Id",
			SYSTEM_SETTINGS: "System Settings",
			TRANSACTION_SETTINGS: "Transaction Settings",
			REPORT: "Report",

		},
		PROFILE_SIDEBAR: {
			TITLE_REFRESH_PERMISSION: "Refresh Permission"
		}
	},
	AUTH: {
		GENERAL: {
			OR: "Or",
			SUBMIT_BUTTON: "Submit",
			NO_ACCOUNT: "Don't have an account?",
			SIGNUP_BUTTON: "Sign Up",
			FORGOT_BUTTON: "Forgot Password",
			BACK_BUTTON: "Back",
			PRIVACY: "Privacy",
			LEGAL: "Legal",
			CONTACT: "Contact"
		},
		LOGIN: {
			TITLE: "GBIZS SYSTEM LOGIN",
			BUTTON: "Sign In",
			MSG_BAD_CREDENTIALS: "Invalid Username or Password"
		},
		FORGOT: {
			TITLE: "Forgot Password",
			DESC: "Enter your email to reset your password",
			SUCCESS: "Your account has been successfully reset.",
			FAILED: "Forgot email password failed to send out"
		},
		REGISTER: {
			TITLE: "Sign Up",
			DESC: "Enter your details to create your account",
			SUCCESS: "Your account has been successfuly registered."
		},
		INPUT: {
			EMAIL: "Email",
			FULLNAME: "Fullname",
			PASSWORD: "Password",
			CONFIRM_PASSWORD: "Confirm Password",
			USERNAME: "Username"
		},
		VALIDATION: {
			INVALID: "{name} is not valid",
			REQUIRED: "{name} is required",
			MIN_LENGTH: "{name} minimum length is {min}",
			AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
			NOT_FOUND: "The requested {name} is not found",
			INVALID_LOGIN: "The login detail is incorrect",
			REQUIRED_FIELD: "Required field",
			MIN_LENGTH_FIELD: "Minimum field length:",
			MAX_LENGTH_FIELD: "Maximum field length:",
			INVALID_FIELD: "Field is not valid",

			INVALID_WARNING_MSG: "Please, provide correct data!",
			UNIQUE: "{name} must be unique"
		},
		RESPONSE_MSG: {
			LOGIN: {
				200: "Login Successfully",
				2001: "Invalid Username or Password"
			}
		}
	},
	COMMON: {
		DATE: "Date",
		META_TTILE: "{page_title}",
		SELECTION_OTHERS_TITLE: "OTHERS",
		TOTAL_TITLE: "Total",

		LBL_CREATED_BY: "Created By",
		LBL_CREATED_AT: "Creation On",
		LBL_UPDATED_BY: "Modified By",
		LBL_UPDATED_AT: "Modified On",

		LBL_PUBLISH: "Publish",
		LBL_PUBLISH_MENU: "Publish Menu",
		LBL_PUBLISH_MENU_ITEM: "Public Menu Item",
		LBL_RECORD_PER_PAGE: "Records",
		LBL_USE_FOR_RECEIVING: "Use For EPO/Receiving",
		LBL_PRINT_ON_QUOTATION: "Print On Quotation",
		LBL_ALLOW_PURCHASE_PLG: "Allow purchase parcel lost guarantee",
		LBL_COUNTRY: "Country",
		LBL_CUSTOMER_MARKING: "Customer Marking",
		LBL_DELIVERY_ADDRESS: "Delivery Address",
		LBL_WAREHOUSE: "Warehouse",
		LBL_WAREHOUSE_COUNTRY: "Warehouse Country",
		LBL_WAREHOUSE_TYPE: "Warehouse Type",
		LBL_REMARK: "Remark",
		LBL_NOTICE_TYPE: "Notice Type",
		LBL_NOTICE_TYPE_GROUP: "Notice Type Group",
		LBL_DELIVERY_ID: "Del. ID",
		LBL_APPROVE_REMARK: "Approved Remarks",
		LBL_AR_REMARK: "AR Remarks",
		LBL_AP_REMARK: "AP Remarks",
		LBL_UPSTAIRS: "Move Upstairs?",
		LBL_COUNTRIES: "Country",
		LBL_STATES: "State",
		LBL_CITY: "City",
		LBL_POSTCODE: "Postcode",
		LBL_ADDRESS: "Address",
		LBL_DISTRICT: "District",
		LBL_LOCATION: "Location",
		LBL_BUILDING_NAME: "Building Name",
		LBL_WEBSITE: "WebSite",
		LBL_BILL_ADDRESS: "Billing Address",
		LBL_EFFECTIVE_DATE: "Effective Date",
		LBL_EFFECTIVE_DATE_FROM: "Effective Date From",
		LBL_EFFECTIVE_DATE_TO: "Effective Date To",

		LBL_SENSITIVE_TYPE: "Sensitive Type",
		LBL_EXPIRED_DATE: "Expire Date",
		LBL_EMAIL: "Email",
		LBL_SHIPMENT_TYPE: "Shipment Type",
		LBL_SHIPMENT_MODE: "Shipment Mode",
		LBL_ROUTE: "Route",
		LBL_QUOTATION_NO: "Quotation No.",
		LBL_CUSTOMER_NAME: "Customer Name",
		LBL_ENTER_CUSTOMER_NAME: "Manual",
		LBL_MAIN_SALESMAN: "Main Salesman",
		LBL_SALESMAN: "Salesman",
		LBL_COMPANY: "Company",
		LBL_COMPANY_GROUP: "Company Sub Group",
		LBL_EPO_NO: "Epo. No.",
		LBL_ONLY_DRAFT: "Only Draft",
		LBL_TAX_TYPE: "Tax Type",
		LBL_WEBSITE: "Website",
		LBL_REJECT_REASON: "Reject Reason",
		LBL_REVERT_REASON: "Revert Reason",
		LBL_REASON: "Reason Remark",
		LBL_EXCLUDE_SAVE_DRAFT: "Excl. Draft",
		LBL_CURRENCY: "Currency",
		LBL_DEFAULT: "Default?",
		LBL_DATE: "Date",
		LBL_TAX: "Tax",
		LBL_SST_NO: "SST No.",
		LBL_DOC_NO: "Doc No.",
		LBL_DOC_DATE: "Document Date",
		LBL_CURRENCY_RATE: "Curr. Rate",
		LBL_EPO_SO_NO: "EPO/SO No.",
		LBL_AGENT: "Agent",

		PH_ENTER_CREATED_BY: "Enter Created By",
		PH_ENTER_CREATED_AT: "Enter Creation On",
		PH_ENTER_UPDATED_BY: "Enter Modified By",
		PH_ENTER_UPDATED_AT: "Enter Modified On",
		PH_ENTER_APPROVED_BY: "Enter Approved By",
		PH_ENTER_REJECTED_BY: "Enter Rejected By",
		PH_ENTER_FROM: "From",
		PH_ENTER_TO: "To",
		PH_AUTO_DOC_NO: "Auto Generate",

		COL_STATUS: "Status",
		COL_POSTED: "Posted ?",
		COL_POSTED_ON: "Posted On",
		COL_MODIFIEDBY: "Modified By",
		COL_CREATED_BY: "Created By",
		COL_ACTION: "Action",
		COL_PUBLISH: "Publish",
		COL_CREATED_ON: "Created On",
		COL_MODIFIED_ON: "Modified On",
		COL_REQUEST_PLG: "Parcel Lost Guarantee",
		COL_NOTICE_TYPE: "Notice Type",
		COL_WAREHOUSE: "W/H",
		COL_WAREHOUSE_COUNTRY: "W/H Country",
		COL_EFFECTIVE_DATE: "Effective Date",
		COL_CATEGORY_TYPE: "Cat Type",
		COL_SENSITIVE_TYPE: "Sensitive Type",
		COL_SHIPMENT: "Shipment",

		COL_COUNTRY: "Country",
		COL_STATE: "State",
		COL_CITY: "City",
		COL_POSTCODE: "Postcode",
		COL_DISTRICT: "District",
		COL_LOCATION: "Location",
		COL_ADDRESS: "Address",
		COL_REMARKS: "Remarks",
		COL_SHIPMENT_TYPE: "Shipt. Type",
		COL_SHIPMENT_MODE: "Shipt Mode",
		COL_CHARGES: "Charges",

		COL_SALESMAN: "Sm",
		COL_MAINSALESMAN: "Msm",
		COL_COMPANY_GRP: "Comp. Grp",
		COL_COMPANY: "Comp.",
		COL_MARKING: "Marking",
		COL_FULL_MARKING: "Full Marking",
		COL_DELIVERY_ID: "Del. ID",
		COL_CUSTOMER_NAME: "Customer Name",
		COL_CATEGORY_TYPE: "Category Type",
		COL_SENSITIVE_TYPE: "Sensitive Type",
		COL_REF_NO: "Ref No.",
		COL_TOTAL: "Total",
		COL_GRAND_TOTAL: "Grand Total",
		COL_CAT: "Cat",
		COL_AGENT: "Agent",
		COL_EPO_DATE: "Epo Date",
		COL_EPO_AMOUNT: "Epo Amt",
		COL_CURRENCY: "Currency",
		TXT_NEW: "New",
		TXT_EXISTS: "Exists",
		TXT_ERROR: "Error",
		TEXT_WARNING: "Warning",
		TXT_SELECT_ALL: "Select All",
		CALC_TYPE_DESC: "Indicator cost calculate type when create invoice",
		COL_TAX_RATE: "Tax Rate (%)",

		MSG_CONFIRM_DELETE: "Are you sure to delete?",
		DELETE_MODAL_TITLE: "Confirm Delete Record?",
		SELECTION_OPTION_TITLE: "-- Select Options --",
		SELECTION_NONE: " -- None --",
		CATEGORY_ROOT_SELECTION: " --- Category Lvl1 ---",
		CATEGORYT_SELECTION: "--- Category Lvl2 ---",
		TAX_TYPE_SELECTION: "-- Select Tax Type --",
		TAX_SELECTION: "-- Select Tax --",
		SELECTION_OPTION_TITLE_NAME: "Select {name}",

		MSG_FAILED_CONNECT_SERVER: "Failed To Connect Server. Please Contact System Administrator",
		MSG_FAILED_TO_UPDATE: "{mod} has failed to be saved",
		MSG_SUCCESS_TO_UPDATE: "{mod} has been successfully saved",

		MSG_FAILED_CREATE: "New {mod} has failed to be created",
		MSG_SUCCESS_TO_CREATE: "New {mod} has been successfully created",
		MSG_FAILED_DELETE: "{mod} has failed to be deleted",
		MSG_SUPPORT_IMAGE_SIZE_FORMAT: "Supported size should be less than 1 MB and Supported Format .jpg, .jpeg, .png",
		MSG_IMAGE_FORMAT_NOT_SUPPORTED: "Image format not supported. Supported format: .jpg, .jpeg, .png",
		MSG_REMOVE_IMAGE: "This image will be removed",
		MSG_REMOVE_FILE: "This document will be removed",
		MSG_DATA_EXIST: "Reminder: Data exists in this record - ",
		MSG_DATA_EXIST_SECOND_LINE: "The system will not allow you to save the same data record twice.",
		MSG_VERSION_NOT_MATCH: "The data has been modified, please reload.",
		MSG_VERSION_NOT_MATCH_DOC_NO: "Ref No.: {ref_no} has been modified, please reload.",

		MSG_FAIL_TO_RETRIEVE: "Fail to retrieve {mod} records",
		MSG_SUCCESS_TO_DELETE: "{mod} record successful to delete",
		MGS_BLOCK_PREVIEW_IMAGE: "Image Size too small, not allow to preview",
		COST_ATTENTION_NOTE: "Take Notes: The uom field not selected, the system will depend base charges settings.",
		PH_DROP_FILE: "Choose a file or drop it here...",
		DROP_PH_DROP_FILE: "Drop file here...",
		MSG_INVALID_EMAIL: "Invalid Email",
		MSG_NOT_ALLOW_DELETE: "Ooops, You are not allow delete this {mod} record",
		MSG_NOT_ALLOW_DELETE_REASON: "Ooops, You are not allow delete this {mod} record. {reason}",
		MSG_DAILY_RATE_NOT_SET: "{currency} rate not yet set/active. Please contact AP.",
		MSG_DB_ANNOTAION_NOT_SET: "Account database not set properly. Please contact system admin.",
		MSG_DB_ANNOTATION_NOT_VALID: "Account DB Annotation ({db_annotation}) invalid",

		SELECT_ALL_TITLE: "Select All",
		DAY_VAL: "{day} days",

		RATE_TYPE_SELECTION: {
			FIX_AMOUNT: "Fix Amount",
			RATE_BY_AMOUNT: "By Amount",
			RATE_BY_PERCENTAGE: "By Rate (%)"
		},

		FORMULA_SELECTION: {
			BASE_RATE: "Base Rate",
			MULTIPLE_RATE: "Multiple Rate"
		},

		MSG: {
			MSG_ERROR_TITLE: "Oops...",
			NOT_FOUND: "{name} not found",
			API_SERVICE_ERROR: "System Error, Please assist System Admin.",
			SUCCESSFUL_SAVE: "Successful save record",
			SUCCESSFUL_SAVE_AS_DRAFT: "Successful save as draft",
			ARE_YOU_SURE: "Are You Sure?",
			MSG_UNABLE_UPLOAD_IMAGE: "Unable upload image. Please try again",
			MSG_BEFORE_LEAVE_PAGE: "Changes you made may not saved. If you leave page, these changes will be lost.",
			MSG_BEFORE_LEAVE_PAGE_TITLE: "Are you sure you want to leave this page?",
			MSG_CODE_EXIST: "Code exists in the system.",
			MSG_DATA_NOT_FOUND: "No Records Found",
			COMPLETE_SYNC_ALL: "Completed to sync all record",
			COMPLETE_SYNC: "Completed to sync a record",
			REMINDER_ACTION_REQUIRED: "Reminder Action Required",
			NO_RECORD_SELECT: "No record selected.",
			DATA_NOT_AVAILABLE: "No data available",
			API_SERVER_DOWN: "Server Connection Refused. Please assist System Admin.",
			FILE_FORMAT_NOT_SUPPORTED: "File format not supported",
			FAILD_UPLOAD_FILE: "Oops... ..., Failed to upload file to server. Please try again",
			SUPPORT_FILE_SIZE_FORMAT: "File size should be less than 10 MB",
			SERVER_WMS_AGENT_CONNECTION_ERROR: "WMS/Agent Server Connection Refuse. Please Contact System Admin.",
			SERVER_WMS_CONNECTION_ERROR: "WMS Server Connection Refuse. Please Contact System Admin.",
			SERVER_AGENT_CONNECTION_ERROR: "AGENT Server Connection Refuse. Please Contact System Admin.",
			ACCOUNT_API_CONNECTION_REFUSED: "Account Server Connection Refuse, Please Contact System Admin.",
			ONLY_ALLOW_ONE_RECORD: "View/Edit/Reject Only Allow select 1 record",
			DAILY_RATE_NOT_SET: "Today currency rate still not yet to set. Please assist AP.",
			FAILED_SYNC_RECORD_WMS: "Failed to sync a record to WMS. Please Contact System Admin.",
			MSG_POSTED_ACCOUNT_BLOCK_DELETE: "This {ref_no} was posted to account. You are not allow to delete",
			MSG_CUSTOMER_BILL_NOT_COMPLETE: "Customer billing information not complete",
			MSG_CUSTOMER_MARKING_BILL_NOT_COMPLETE: "Customer Marking billing information not complete",
			MSG_DOCUMENT_TYPE_EMPTY: "Missing Document Type Settings",
			MSG_DOCUMENT_NUMBER_EMPTY: "Missing Document Number Settings"
		},

		NEW_TITLE: "New",

		ADDRESS: {
			LBL_ADDRESS: "Address",
			LBL_UPSTAIRS: "Move Upstairs"
		},

		TITLE_PREVIEW_IMAGE: "Preview Image",
		TITLE_UPLOAD_IMAGE: "Upload Image",
		TITLE_PREVIEW_DOC: "Preview Supported Document",
		TITLE_UPLOAD_DOCUMENT: "Upload Supported Document",
		REJECT_TITLE: "Reject {module_name}, Reference No.: {reference_no}",
		REVERT_TITLE: "Revert {module_name}, Reference No.: {reference_no}",
		MSG_CONFIRM_REJECT: "Are you sure to reject this {reference_no} {module_name}?",
		MSG_CONFIRM_REVERT: "Are you sure to revert this {reference_no} {module_name}?",

		TYPE: {
			NORMAL: "Normal",
			SENSITIVE: "Sensitive"
		},

		DOCUMENT_FORMAT_SELECTION: {
			CN_FORMAT: "Chinese Format",
			EN_FORMAT: "English Format"
		},

		POSTING_MSG: {
			MSG_ACCOUNT_PERIOD_NOT_DEFINED: "Account Period Not Define !",
			MSG_ACCOUNT_YEAR_CLOSE: "Account Year already closed!",
			MSG_AR_CLOSED: "AR already closed !",
			MSG_GL_CLOSED: "GL already closed !"
		},

		INV_CHARGES_DATE: {
			INVOICE_DATE: "Invoice Date",
			MANIFEST_DATE: "Manifest Date"
		},

		BTN_ADD_FROM_OUTSTANDING_TT: "Add From OutStanding TT"
	},
	/**Start Master Data */
	MASTER_DATA: {
		CHARGES_TYPE: {
			BTN_CREATE: "Create New Charges Type",
			MODULE_NAME: "Charges Type",
			LISTING_TITLE: "Charges Type Listing",
			CREATE_TITLE: "Create New Charges Type",
			DETAIL_TITLE: "Charges Type Detail",
			EDIT_TITLE: "Edit Charges Type",

			LBL_CHARGES_TYPE_NAME: "Charges Type Name",
			LBL_CHARGES_TYPE_CODE: "Charges Type Code",
			LBL_CHARGES_TYPE_SHORT_FORM: "Charges Type Description",
			LBL_ORDER_SEQUENCE: "Order Sequence",

			PH_ENTER_CHARGES_TYPE_CODE: "Enter Charges Type Code",
			PH_ENTER_CHARGES_TYPE_NAME: "Enter Charges Type Name",
			PH_ENTER_CHARGES_TYPE_SHORTFORM: "Enter Charges Type Description",
			PH_ENTER_ORDER_SEQUENCE: "Enter Order Sequence",
			PH_ENTER_SEQUENCE: "Enter Sequence",

			COL_CHARGES_TYPE_CODE: "Charges Type Code",
			COL_CHARGES_TYPE_NAME: "Charges Type Name",
			COL_ORDER_SEQUENCE: "Order Sequence",

			LBL_BY_UOM: "Charges By UOM",
			LBL_ALLOW_MARKUP: "Allow Markup",
			LBL_BY_AMOUNT: "Charges By Amount",
			LBL_OPTIONAL_CHARGES: "Optional Charges",
			LBL_PRINT_ON_QUOTATION: "Print on Quotation",
			LBL_SUB_CHARGES: "Sub Charges",
			LBL_MIN_CHARGES: "Min Charges",
			LBL_MIN_CHARGES_BY_UOM: "Min Charges By UOM",

			MSG_FAILED_TO_UPDATE: "Charges Type has been failed saved",
			MSG_SUCCESS_TO_UPDATE: "Charges Type has been successfully saved",

			MSG_FAILED_CREATE_CHARGES_TYPE: "New Charges Type has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New Charges Type has been successfully to created",
			MSG_SUCCESS_TO_DELETE: "Charges Type has been deleted",

			SUBCHARGER: {
				COL_CHARGES_TYPE_NAME: "Charges Type Name",
				COL_CHARGES_TYPE_DESCRIPTION: "Charges Type Description",
				COL_CHARGES_TYPE_SEQUENCE: "Sequence",
				COL_CHARGES_TYPE_CHARGE_TYPE_UOM: "Charges By UOM",
				COL_CHARGES_TYPE_OPTIONAL_CHARGES: "Optional Charges",
				COL_CHARGES_TYPE_ALLOW_MARKUP: "Allow Markup",
				COL_CHARGES_TYPE_PRINT_ON_QUOTATION: "Print on Quotation",
				COL_CHARGES_TYPE_MIN_CHARGES: "Min Charges",
				COL_CHARGES_TYPE_MIN_CHARGES_UOM: "Min Charges By UOM"
			}
		},
		COUNTRIES: {
			LISTING_TITLE: "Country Listing",
			CREATE_TITLE: "Create New Country",
			DETAIL_TITLE: "Country Detail",
			EDIT_TITLE: "Edit Country",
			MODULE_NAME: "Country",

			LBL_COUNTRIES_NAME: "Country Name",
			LBL_COUNTRIES_FULL_NAME: "Full Name",
			LBL_COUNTRIES_ISO_CODE3: "ISO Code 3",
			LBL_COUNTRIES_ISO_CODE2: "ISO Code 2",
			LBL_COUNTRIES_PHONE_CODE: "Phone Code",
			LBL_POPULAR_SEQUENCE: "Popular Sequence",

			BTN_CREATE: "Create New Countries",

			COL_NAME: "Country Name",
			COL_ISO_CODE3: "Iso Code 3",
			COL_ISO_CODE2: "Iso Code 2",
			COL_CALIING_CODE: "Phone Code",
			COL_POPULAR_SEQUENCE: "Popular Sequence",

			PH_ENTER_COUNTRIES_NAME: "Enter Country Name",
			PH_ENTER_COUNTRIES_FULL_NAME: "Enter Country Full Name",
			PH_ENTER_COUNTRIES_ISO_CODE3: "Enter Iso Code 3",
			PH_ENTER_COUNTRIES_ISO_CODE2: "Enter Iso Code 2",
			PH_ENTER_COUNTRIES_PHONE_CODE: "Enter Phone Code",

			MSG_FAILED_TO_UPDATE: "Country has been failed saved",
			MSG_SUCCESS_TO_UPDATE: "Country has been successfully saved",

			MSG_FAILED_CREATE_COUNTRIES: "New Country has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New Country has been successfully to created",
			MSG_SUCCESS_TO_DELETE: "Country has been deleted",

			MSG_CALLING_CODE_EMPTY: "Calling Code records have been not found"
		},
		STATES: {
			LISTING_TITLE: "States Listing",
			CREATE_TITLE: "Create New State",
			DETAIL_TITLE: "State Detail",
			EDIT_TITLE: "Edit State",
			MODULE_NAME: "State",

			BTN_CREATE: "Create New States",

			LBL_STATES_NAME: "State Name",
			LBL_COUNTRIES: "Country",
			LBL_STATES_CODE: "State Code",

			PH_ENTER_STATES_NAME: "State Name",
			PH_ENTER_STATES_CODE: "State Code",

			COL_NAME: "State Name",
			COL_COUNTRY: "Country",

			MSG_FAILED_TO_UPDATE: "State has been failed saved",
			MSG_SUCCESS_TO_UPDATE: "State has been successfully saved",

			MSG_FAILED_CREATE_STATES: "New State has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New State has been successfully to created",
			MSG_FAILED_DELETE: "State failed to delete",

			MSG_SUCCESS_TO_DELETE: "State record successful to delete"
		},
		CITIES: {
			LISTING_TITLE: "City Listing",
			CREATE_TITLE: "Create New City",
			DETAIL_TITLE: "City Detail",
			EDIT_TITLE: "Edit City Detail",
			MODULE_NAME: "City",
			BTN_CREATE: "Create New City",

			LBL_CITY_NAME: "City Name",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",

			PH_ENTER_CITY_NAME: "Enter City Name",
			COL_NAME: "Name",
			COL_COUNTRY: "Country",
			COL_STATE: "State",

			MSG_FAILED_TO_UPDATE: "City has been failed saved",
			MSG_SUCCESS_TO_UPDATE: "City has been successfully saved",

			MSG_FAILED_CREATE_CITY: "New City has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New City has been successfully to created",
			MSG_FAILED_DELETE: "City failed to delete",

			MSG_SUCCESS_TO_DELETE: "City record successful to delete"
		},
		PORT: {
			LISTING_TITLE: "Port Listing",
			EDIT_TITLE: "Edit Port Detail",
			DETAIL_TITLE: "Port Detail",
			LBL_COUNTRIES: "Country",
			BTN_CREATE: "Create New Port",
			LBL_PORT_CODE: "Port Code",
			LBL_PORT_NAME: "Port Name",
			COL_PORT_CODE: "Port Code",
			COL_PORT_NAME: "Port Name",
			COL_COUNTRY_NAME: "Country Name",
			MODULE_NAME: "Port",
			CREATE_TITLE: "Create New Port"
		},
		TERRITORY: {
			LISTING_TITLE: "Territory Listing",
			EDIT_TITLE: "Edit Territory Detail",
			DETAIL_TITLE: "Territory Detail",
			LBL_COUNTRIES: "Country",
			BTN_CREATE: "Create New Territory",
			LBL_TERRITORY_CODE: "Territory Code",
			LBL_TERRITORY_NAME: "Territory Name",
			COL_TERRITORY_CODE: "Territory Code",
			COL_TERRITORY_NAME: "Territory Name",
			COL_COUNTRY_NAME: "Country Name",
			MODULE_NAME: "Territory",
			CREATE_TITLE: "Create New Territory"
		},
		UOM: {
			LISTING_TITLE: "Stock UOM Listing",
			EDIT_TITLE: "Edit Stock UOM Detail",
			DETAIL_TITLE: "Stock UOM Detail",
			BTN_CREATE: "Create New Stock UOM",
			LBL_UOM_CODE: "Stock UOM Code",
			LBL_UOM_NAME: "Stock UOM Name",
			COL_UOM_CODE: "Stock UOM Code",
			COL_UOM_NAME: "Stock UOM Name",
			COL_USE_EPO: "Use EPO/Receiving/Inv",
			LCL_CALC_TYPE: "Calc Type",
			LBL_UOM_PERCENTAGE_VALUE: "Percentage",
			LBL_UOM_USE_RECEIVING: "Use EPO/Receive/Inv",
			LBL_UOM_USE_INVOICE: "Use for Invoice",
			LBL_UOM_QUOTATION_DESCRIPTION: "Quotation Description",
			MODULE_NAME: "Stock UOM",
			CREATE_TITLE: "Create New Stock UOM"
		},
		INDUSTRIES: {
			LISTING_TITLE: "Industries Listing",
			EDIT_TITLE: "Edit Industries Detail",
			DETAIL_TITLE: "Industries Detail",
			BTN_CREATE: "Create New Industries",
			LBL_INDUSTRIES_CODE: "Industries Code",
			LBL_INDUSTRIES_NAME: "Industries Name",
			COL_INDUSTRIES_CODE: "Industries Code",
			COL_INDUSTRIES_NAME: "Industries Name",
			MODULE_NAME: "Industries",
			CREATE_TITLE: "Create New Industries"
		},
		PARTNER_TYPE: {
			LISTING_TITLE: "Partner Type Listing",
			EDIT_TITLE: "Edit Partner Type Detail",
			DETAIL_TITLE: "Partner Type Detail",
			BTN_CREATE: "Create New Partner Type",
			LBL_PARTNER_TYPE_CODE: "Partner Type Code",
			LBL_PARTNER_TYPE_NAME: "Partner Type Name",
			COL_PARTNER_TYPE_CODE: "Partner Type Code",
			COL_PARTNER_TYPE_NAME: "Partner Type Name",
			LBL_CUSTOMER_TYPE: "Default",
			MODULE_NAME: "Partner Type",
			CREATE_TITLE: "Create New Partner Type"
		},
		FORWARDER_COSTING_TYPE: {
			LISTING_TITLE: "Forwarder Costing Type Listing",
			EDIT_TITLE: "Edit Forwarder Costing Type Detail",
			DETAIL_TITLE: "Forwarder Costing Type Detail",
			BTN_CREATE: "Create New Forwarder Costing Type",
			LBL_FORWARDER_COSTING_TYPE_CODE: "Forwarder Costing Type Code",
			LBL_FORWARDER_COSTING_TYPE_NAME: "Forwarder Costing Type Name",
			COL_FORWARDER_COSTING_TYPE_CODE: "Forwarder Costing Type Code",
			COL_FORWARDER_COSTING_TYPE_NAME: "Forwarder Costing Type Name",
			MODULE_NAME: "Forwarder Costing Type",
			CREATE_TITLE: "Create New Forwarder Costing Type"
		},
		GL_ACCOUNT_SETTING: {
			LISTING_TITLE: "GL Account Setting Listing",
			EDIT_TITLE: "Edit GL Account Setting Detail",
			DETAIL_TITLE: "GL Account Setting Detail",
			BTN_CREATE: "Create New GL Account Setting",
			LBL_GL_CONTROL_ACCOUNT: "GL Control Account",
			LBL_GL_SALES_ACCOUNT: "GL Sales Account",
			COL_GL_CONTROL_ACCOUNT: "GL Control Account",
			COL_GL_SALES_ACCOUNT: "GL Sales Account",
			MODULE_NAME: "GL Account Setting",
			CREATE_TITLE: "Create New GL Account Setting",
			MSG_COMPABY_GROUP_EXIST: "This Company Sub Group has been created"
		},
		POSTCODES: {
			LISTING_TITLE: "Postcode Listing",
			CREATE_TITLE: "Create New Postcode",
			DETAIL_TITLE: "Postcode Detail",
			EDIT_TITLE: "Edit Postcode Detail",
			MODULE_NAME: "Postcode",
			BTN_CREATE: "Create New Postcode",

			LBL_POSTCODE: "Postcode",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",
			LBL_CITY: "City",
			LBL_DISTIRCT: "District",
			LBL_POSTCODE_OUTSKIRT: "Outskirt",

			PH_ENTER_POSTCODE: "Enter Postcode",

			COL_POSTCODE: "Postcode",
			COL_COUNTRY: "Country",
			COL_STATE: "State",
			COL_CITY: "City",
			COL_DISTRICT: "District",
			MSG_FAILED_TO_UPDATE: "City has been failed saved",
			MSG_SUCCESS_TO_UPDATE: "City has been successfully saved",

			MSG_FAILED_CREATE_POSTCODE: "New City has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New City has been successfully to created",
			MSG_FAILED_DELETE: "City failed to delete",

			MSG_SUCCESS_TO_DELETE: "City record successful to delete"
		},
		TAX_TYPE: {
			CREATE_TITLE: "Create New Tax Type",
			DETAIL_TITLE: "Tax Type Detail",
			EDIT_TITLE: "Edit Tax Type",
			LISTING_TITLE: "Tax Type Listing",
			MODULE_NAME: "Tax Type",
			BTN_CREATE: "Create New Tax Type",

			LBL_TAX_TYPE_ID: "Tax Type Code",
			LBL_TAX_TYPE_NAME: "Tax Type Name",
			LBL_COUNTRIES: "Countries",

			PH_ENTER_TAX_TYPE_ID: "Enter Tax Type Code",
			PH_ENTER_TAX_TYPE_NAME: "Enter Tax Type Name",

			COL_TAX_TYPE_ID: "Tax Type Code",
			COL_TAX_TYPE_NAME: "Tax Type Name",
			COL_COUNTYR_NAME: "Country Name",

			MSG_FAILED_TO_UPDATE: "Tax Type has been failed saved",
			MSG_SUCCESS_TO_UPDATE: "Tax Type has been successfully saved",

			MSG_FAILED_CREATE_TAX_TYPE: "New Tax Type has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New Tax Type has been successfully to created",
			MSG_FAILED_DELETE: "Tax Type failed to delete",

			MSG_SUCCESS_TO_DELETE: "Tax Type record successful to delete"
		},
		CURRENCIES: {
			CREATE_TITLE: "Create New Currency",
			DETAIL_TITLE: "Currency Detail",
			EDIT_TITLE: "Edit Currency",
			LISTING_TITLE: "Currencies Listing",
			MODULE_NAME: "Currencies",
			BTN_NAME: "Currency",

			LBL_CURRENCIES_NAME: "Currency Name",
			LBL_CURRENCIES_CODE: "Currency Code",
			LBL_SYMBOL_LEFT: "Symbol Left",
			LBL_SYMBOL_RIGHT: "Symbol Right",
			LBL_DECIMAL_PLACE: "TT Rate Decimal Place",
			LBL_TT_CHARGE_SETTINGS: "TT Charges Settings",

			COL_CURRENCY_CODE: "Currency Code",
			COL_CURRENCY_NAME: "Currency Name",
			LBL_EXCHANGE_CURRENCY: "TT Currency Rate",
			LBL_RATE_EFFECTIVE_TIME_FROM: "Effective Time From",
			LBL_RATE_EFFECTIVE_TIME_TO: "Effective Time To",
			LBL_BANK_SWIFT_CODE: "Swift Code Required",
			LBL_BANK_IBAN: "IBAN Required",

			COL_EXCHANGE_CURRENCY: "TT Currency",
			COL_CONVERSION_FACTOR: "Conversion Factor",
			COL_RATE: "Daily Rate",
			COL_TT_CHARGES: "TT Charges",
			COL_LIMIT_TT: "Limit TT",
			COL_EFFECTIVE_TIME: "Effective Time",
			BTN_DAILY_CURRENCY_RATE: "Daily Currency Rate",
			SHOW_ONLY_IN_TT: "Show Only In TT Module",
			TT_CATEGORY_REQUIRED: "TT Category Required",
			BANK_ADDRESS_REQUIRED: "Bank Address Required",

			TT_CHARGES_SETTINGS: {
				NONE: "No TT Charges",
				DEPEND_CURRENCY: "Depend Currency",
				DEPEND_LOCATION: "Depend Bank Location"
			}
		},
		CURRENCY_GROUP: {
			MODULE_NAME: "Currency Group",
			LISTING_TITLE: "Currency Group List",
			DETAIL_TITLE: "Currency Group Detail",
			CREATE_TITLE: "Create Currency Group",
			EDIT_TITLE: "Edit Currency Group",
			GROUP_CODE: "Currency Group Code",
			GROUP_DESC: "Currency Group Description",
			FAIL_GET_LIST: "Fail to get currency group"
		},
		COMPANY: {
			CREATE_TITLE: "Create New Company",
			DETAIL_TITLE: "Company Detail",
			EDIT_TITLE: "Edit Company",
			MODULE_NAME: "Company",
			LISTING_TITLE: "Company Listing",

			LBL_COMPANY_NAME: "Company Name",
			LBL_COMPANY_CODE: "Company Code",
			LBL_COMPANY_REGISTER_NUMBER: "Company Business Register Number",
			LBL_COMPANY_EMAIL: "Email",
			LBL_COMPANY_GROUP_NAME: "Group Name",
			LBL_TAX_CODE: "Tax Code",

			LBL_COMPANY_GROUP_CODE: "Sub Group Code",

			LBL_CONTACT_NUMBER: "Contact Number",
			LBL_FAX_NUMBER: "Fax Number",

			LBL_BUSINESS_NATURE: "Business Nature",
			LBL_WEBSITE: "Website",
			LBL_COMPANY_ADDRESS: "Company Address",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",
			LBL_CITY: "City",
			LBL_POSTCODE: "Postcode",
			LBL_PAYMENT_TT_GROUP: "Payment TT Group",
			LBL_COMPANY_LOGO: "Company Logo",
			LBL_TAX_TYPE: "Tax Type",
			LBL_TAX_REGISTERDATE: "Register Date",
			LBL_TAX_NUMBER: "Register Number",
			LBL_TAX_REGISTER_TYPE: "Tax Register Type",
			LBL_TAX_EFFECTIVEDATE: "Effective Date",
			LBL_TAX_VALIDATIONDATE: "Verify Date",
			LBL_TAX_PIC: "Person Incharge",
			LBL_BANK: "Bank(s)",

			LBL_OWNER: "Owner",

			COL_COMPANY_GROUP_CODE: "Code",
			COL_GL_SALES_ACCOUNT: "GL Sales Account",
			NO_TAX: "No Tax",

			MSG_FAILED_TOUPLOAD_IMAGE: "Failed To Upload Company Logo.",

			COL_COMPANY_CODE: "Company Code",
			COL_COMPANY_NAME: "Company Name",

			BANK_TABLE: {
				COL_SHOW: "Show",
				COL_ACCOUNT_NAME: "Account Name",
				COL_ACCOUNT_NO: "Account No",
				COL_BANK_NAME: "Bank Name"
			},

			MSG_IMG_SUPPORTED: "*Support JPG,JPEG,PNG,GIF file, file size not more that 500kb.",
			MSG_IMAGE_SIZE: "*image size(W x L) 590x208",

			MSG_IMAGE_ERROR_TITLE: "File size is too huge",
			MSG_IMAGE_ERROR_SIZE: "The size of file should be less than 1 MB"
		},
		WAREHOUSE: {
			LISTING_TITLE: "Warehouse List",
			CREATE_TITLE: "Create New Warehouse",
			DETAIL_TITLE: "Warehouse Detail",
			EDIT_TITLE: "Edit Warehouse",
			MODULE_NAME: "Warehouse",

			LBL_WAREHOUSE_CODE: "WH Code",
			LBL_WAREHOUSE_NAME: "WH Name",
			LBL_API_URL: "Api Path",
			LBL_API_CLIENT_ID: "Client Id",
			LBL_API_CLIENT_KEY: "Client Key",

			COL_WAREHOUSE_CODE: "Warehouse Code",
			COL_WAREHOUSE_NAME: "Warehouse Name",
			LBL_WAREHOUSE_ADDRESS: "Address",
			LBL_SUPPORTED_SHIPMENT_TYPE: "Supported Shipment Type",
			LBL_SUPPORT_OTHER_SHIPMENT: "Support Other Country Shipment",
			LBL_REQUIREDSTATUSINQUIRY: "Required Status Inquiry",
			LBL_BYPASS_EPO: "Allow Bypass EPO",
			LBL_STATUSINQUIRYREQUIREEPO: "Status Inquiry Require EPO",

			EDIT_WMS_API: "Link WMS System"
		},
		SHIPMENT_TYPE: {
			LISTING_TITLE: "Shipment Type Listing",
			CREATE_TITLE: "Create New Shipment Type",
			DETAIL_TITLE: "Shipment Type Detail",
			EDIT_TITLE: "Edit Shipment Type Detail",

			LBL_SHIPMENT_TYPE_NAME: "Shipment Type Name",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_CATERGORY_TYPE_COST: "Category Type Cost",
			LBL_DEFAULT_SELECT: "Default (Customer Portal)",

			CHECKBOX_CATERGORY_TYPE_COST_TITLE: "Multiple Route & Shipment Mode",

			MODULE_NAME: "Shipment Type",

			COL_NAME: "Shipment Type Name",
			COL_SHIPMENT_TYPE_NAME: "Shipment Type Name",
			MODULE_QUOTATION: "Quotation",
			MODULE_EPO: "EPO"
		},
		SHIPMENT_MODE: {
			LISTING_TITLE: "Shipment Mode Listing",
			CREATE_TITLE: "Create New Shipment Mode",
			DETAIL_TITLE: "Shipment Mode Detail",
			EDIT_TITLE: "Edit Shipment Mode Detail",

			COSTING_STRUCTURE_TITLE: "Base Cost Structure",
			EXCEPTIONAL_COST_STRUCTURE_TITLE: "Exceptional Cost Structure",

			LBL_SHIPMENT_MODE_CODE: "Shipment Mode Code",
			LBL_SHIPMENT_MODE_NAME: "Shipment Mode Name",
			LBL_SHIPMENT_TYPE: "Supported Shipt. Type",
			LBL_QUOTATION_REMARK: "{shipment_type} Quotation Remark",
			LBL_COST_SETUP: "Cost Setup?",
			LBL_SUPPORTED_COUNTRY: "Supported Countries",
			SUPPORTED_COUNTRIES_MSG: "** All Countries supported, field set empty",
			LBL_SHIPMENT_SETTINGS: "Shipt. Settings",

			MODULE_NAME: "Shipment Mode",

			COL_CODE: "Shipment Mode Code",
			COL_NAME: "Shipment Mode Name",
			COL_SHIPMENT_TYPE_NAME: "Shipment Type",

			MODULE_QUOTATION: "Quotation (Default)",
			MODULE_EPO: "EPO (Default)",
			APPLY_COST_WITH_DELIVERY_AGENT: "Apply Cost with Delivery Agent",
			APPLY_INV_CHARGES_DATE: "Apply Inv Chrg Date",
			LBL_DEFAULT_SELECT: "Default (Customer Portal)"
		},
		COURIER_COMPANY: {
			LISTING_TITLE: "Courier Company Listing",
			CREATE_TITLE: "Create New Courier Company",
			DETAIL_TITLE: "Courier Company Detail",
			EDIT_TITLE: "Edit Courier Company Detail",

			LBL_COURIER_COMPANY_CODE: "Code",
			LBL_COURIER_COMPANY_NAME: "Name",
			LBL_COURIER_COMPANY_COUNTRY: "Country",
			LBL_COURIER_COMPANY_DESCRIPTION: "Description",

			MODULE_NAME: "Courier Company",

			COL_CODE: "Code",
			COL_NAME: "Name"
		},
		MOVEMENT_TYPE: {
			LISTING_TITLE: "Movement Type Listing",
			CREATE_TITLE: "Create New Movement Type",
			DETAIL_TITLE: "Movement Type Detail",
			EDIT_TITLE: "Edit Movement Type",

			MODULE_NAME: "Movement Type",

			LBL_MOVEMENT_TYPE_CODE: "Movement Type Code",
			LBL_MOVEMENT_TYPE_NAME: "Movement Type Name",
			LBL_MOVEMENT_TYPE_WAREHOUSE: "Warehouse",
			LBL_MOVEMENT_TYPE_WAREHOUSE_TYPE: "Warehouse Type",
			LBL_REQUIRED: "Required",
			LBL_OPTIONAL: "Optional",
			LBL_NONE: "None",

			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_QUOTATION_SETTING: "Quotation Settings",
			COL_CODE: "Movement Type Code",
			COL_NAME: "Movement Type Name",
			COL_SHIPMENT_TYPE_NAME: "Shipment Type",
			COL_SUPPORT_SHIPMENT: "Support Shipment",
			COL_SUPPORT_WAREHOUSE: "Warehouse",

			LBL_SUPPOPRT_SHIPMENT: "Support Shipment",
			QUOTATION_SETTINGS_STANDARD: "Apply All Warehouse",
			QUOTATION_SETTINGS_OTHER: "Only Apply Other Country",
			QUOTATION_SETTING: {
				FORWARDER: "Forwarder",
				SUPPLIER: "Supplier",
				LPT: "Ldg. Port",
				DPT: "Dest. Port",
				PRD_MEASUREMENT: "Prod. Measure.",
				PRD_AMOUNT: "Prod Amount",
				REFERENCE_TYPE: "Ref Type",
				PRD_BRAND: "Prod Brand",
				INSURANCE: "Parcel Lost Guarantee",
				SINGLE_PRODUCT: "Single Prod",
				SHIPPING_TERMS: "Ship. Terms",
				WAREHOUSE: "Warehouse",
				DELIVERY_ZONE: "Delivery Zone",
				QUOTATION_TYPE: "Quot. Type"
			},
			MODULE_QUOTATION: "Quotation",
			LBL_DEFAULT_SELECT: "Default (Customer Portal)",
			BTN_ALL_WAREHOUSE: "All Warehouse"
		},
		SHIPMENT_TERMS: {
			LISTING_TITLE: "Shipment Terms Listing",
			CREATE_TITLE: "Create New Shipment Terms",
			DETAIL_TITLE: "Shipment Terms Detail",
			EDIT_TITLE: "Edit Shipment Terms",
			MODULE_NAME: "Shipment Terms",

			LBL_SHIPMENT_TERMS_CODE: "Shipment Terms Code",
			LBL_SHIPMENT_TERMS_NAME: "Shipment Terms Name",

			LBL_MOVEMENT_TYPE: "Movement Type",
			LBL_DESCRIPTION: "Description",

			COL_CODE: "Shipment Terms Code",
			COL_NAME: "Shipment Terms Name",
			COL_MOVEMENT_TYPE: "Movement Type"
		},
		REFERENCE_TYPE: {
			LISTING_TITLE: "Reference Type Listing",
			CREATE_TITLE: "Create New Reference Type",
			DETAIL_TITLE: "Reference Type Detail",
			EDIT_TITLE: "Edit Reference Type",

			MODULE_NAME: "Reference Type",

			LBL_REF_TYPE_CODE: "Reference Type Code",
			LBL_REF_TYPE_NAME: "Reference Type Name",
			LBL_QUOTATION: "Quotation",

			LBL_SHIPMENT_TYPE: "Shipment Type",
			COL_CODE: "Reference Type Code",
			COL_NAME: "Reference Type Name",
			COL_SHIPMENT_TYPE_NAME: "Shipment Type",
			COL_QUOTATION: "Quotation"
		},
		AREA: {
			CREATE_TITLE: "Create New Area",
			LISTING_TITLE: "Area Listing",
			DETAIL_TITLE: "Area Detail",
			EDIT_TITLE: "Edit Area",
			MODULE_NAME: "Area",
			SEARCH_POSTCODE_TITLE: "Search Postcode By Range",

			LBL_AREA_CODE: "Area Code",
			LBL_AREA_NAME: "Area Name",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "States",
			LBL_POSTCODE_RANGE: "Postcode Range",
			LBL_OUTSKIRT: "Outskirt",
			LBL_ORIGIN_AREA: "Split From",

			ORIGIN_AREA_DESC: "Split From Other Area. This field will using to revise quotation",
			COL_STATES: "State",
			COL_POSTCODE: "Postcode",
			LBL_CHOOSE_POSTCODE: "Choose Postcode",
			MSG_CONFIRM_POSTCODE: "This will replace postcode selected in previously. Are you sure contiue?",
			COL_AREA_CODE: "Area Code",
			COL_AREA_NAME: "Area Name",
			COL_COUNTRY: "Country Name",
			CONFIRMATION_CHANGE_COUNTRY: "State and Postcode selection will be reset. Are you sure to continue?",

			MSG_STATE_DUPLICATE: "State duplicate.",
			MSG_AREA_CODE_DUPLICATE: "{code} is not available."
		},
		DELIVERY_ZONE: {
			CREATE_TITLE: "Create New Delivery Zone",
			LISTING_TITLE: "Delivery Zone Listing",
			DETAIL_TITLE: "Delivery Zone Detail",
			EDIT_TITLE: "Edit Delivery Zone",
			MODULE_NAME: "Delivery Zone",

			LBL_DELIVERY_ZONE_CODE: "Delivery Zone Code",
			LBL_DELIVERY_ZONE_NAME: "Delivery Zone Name",
			LBL_COUNTRIES: "Country",
			LBL_OUTSKIRT: "Outskirt",
			LBL_ORIGIN_AREA: "Split From",
			LBL_STATES: "State",

			ORIGIN_DESC: "Split From Other Delivery Zone. This field will using to revise quotation",

			COL_DELIVERY_ZONE_CODE: "Delivery Zone Code",
			COL_DELIVERY_ZONE_NAME: "Delivery Zone Name",
			COL_COUNTRY: "Country Name",
			CONFIRMATION_CHANGE_COUNTRY: "State selection will be reset. Are you sure to continue?",

			MSG_STATE_DUPLICATE: "State duplicate.",
			MSG_DELIVERY_ZONE_CODE_DUPLICATE: "{code} is not available."
		},
		CREDIT_TERMS: {
			LISTING_TITLE: "Credit Terms Listing",
			CREATE_TITLE: "Create New Credit Terms",
			DETAIL_TITLE: "Credit Terms Detail",
			EDIT_TITLE: "Edit Credit Terms Detail",

			LBL_CREDIT_TERMS_CODE: "Credit Terms Code",
			LBL_CREDIT_TERMS_DESC: "Credit Terms Desc",
			LBL_CREDIT_TERMS_TYPE: "Credit Terms Type",
			LBL_CREDIT_TERMS_DAY: "Credit Terms Day",
			LBL_CREDIT_TERMS_NOTICE_TYPE: "Notice Type",
			LBL_CREDIT_TERMS_PAYMENT_TERMS_QUOTE_DESC: "Payment Terms Quote Desc",
			LBL_CREDIT_TERMS_CREDIT_TERMS_QUOTE_DESC: "Credit Terms Quote Desc",
			LBL_DO_SETTINGS: "D/O Settings",
			LBL_DO_SUMMARY_PRINT_PARENT_CATEGORY: "D/O Summary Print Parent Category",

			MODULE_NAME: "Credit Terms",

			COL_CREDIT_TERMS_CODE: "Credit Terms Code",
			COL_CREDIT_TERMS_DESC: "Credit Terms Desc",
			COL_CREDIT_TERMS_DAY: "Credit Terms Day",
			COL_NOTICE_TYPE: "Notice Type",

			CREDIT_TERMS_TYPE_CODE: {
				PURCHASE_AMOUNT: "PA",
				SERVICE_CHARGE: "SC",
				TAX: "TA"
			},

			CREDIT_TERMS_TYPE_SELECTION: {
				PURCHASE_AMOUNT: "PA (Purchase Amount)",
				SERVICE_CHARGE: "SC (Service Charge)",
				TAX: "TA (Tax)"
			},
			DO_SETTINGS_SELECTION: {
				DETAIL: "Detail",
				SUMMARY: "Summary"
			}
		},
		/* Wesly 04/05/2021 Start*/
		VALIDITY: {
			CREATE_TITLE: "Create Validity",
			MODULE_NAME: "Validity",
			EDIT_TITLE: "Edit Validity",
			DETAIL_TITLE: "Validity Detail",
			LISTING_TITLE: "Validity Listing",

			LBL_VALIDITY_CODE: "Validity Code",
			LBL_VALIDITY_NAME: "Validity Name",
			LBL_VALIDITY_DAY: "Validity Day",
			LBL_DEFAULT: "Default Selected",
			LBL_QUOTATION: "Quotation",
			LBL_INSTRUCTION: "Instruction",
			LBL_USE_FOR: "Use For",

			COL_VALIDITY_CODE: "Validity Code",
			COL_VALIDITY_NAME: "Validity Name"
		},
		/* Wesly 04/05/2021 End*/
		/* Wesly 09/05/2021 Indemnify End*/
		INDEMNIFY: {
			CREATE_TITLE: "Create Indemnify",
			MODULE_NAME: "Indemnify",
			EDIT_TITLE: "Edit Indemnify",
			DETAIL_TITLE: "Indemnify Detail",
			LISTING_TITLE: "Indemnify Listing",

			LBL_INDEMNIFY_CODE: "Indemnify Code",
			LBL_INDEMNIFY_NAME: "Indemnify Name",

			COL_INDEMNIFY_CODE: "Indemnify Code",
			COL_INDEMNIFY_NAME: "Indemnify Name",

			LBL_INDEMNIFY_REMARK: "Indemnify Remark"
		},
		/* Wesly 04/05/2021 Indemnify End*/
		/* Wesly 09/05/2021 Freight Charges End*/
		FREIGHT_CHARGES: {
			CREATE_TITLE: "Create Freight Charges",
			MODULE_NAME: "Freight Charges",
			EDIT_TITLE: "Edit Freight Charges",
			DETAIL_TITLE: "Freight Charges Detail",
			LISTING_TITLE: "Freight Charges Listing",

			LBL_FREIGHT_CHARGES_CODE: "Freight Charges Code",
			LBL_FREIGHT_CHARGES_NAME: "Freight Charges Name",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_TYPE_DESC: "{name} shipment description",

			COL_FREIGHT_CHARGES_CODE: "Freight Charges Code",
			COL_FREIGHT_CHARGES_NAME: "Freight Charges Name"
		},
		/* Wesly 04/05/2021 Freight Charges End*/
		NOTICE_TYPE_GROUP: {
			LISTING_TITLE: "Notice Type Group Listing",
			CREATE_TITLE: "Create New Notice Type Group",
			DETAIL_TITLE: "Notice Type Group Detail",
			EDIT_TITLE: "Edit Notice Type Group Detail",

			LBL_NOTICE_TYPE_GROUP_CODE: "Notice Type Group Code",
			LBL_NOTICE_TYPE_GROUP_DESC: "Notice Type Group Desc",

			MODULE_NAME: "Notice Type Group",

			COL_NOTICE_TYPE_GROUP_CODE: "Notice Type Group Code",
			COL_NOTICE_TYPE_GROUP_DESC: "Notice Type Group Desc"
		},
		NOTICE_TYPE: {
			LISTING_TITLE: "Notice Type Listing",
			CREATE_TITLE: "Create New Notice Type",
			DETAIL_TITLE: "Notice Type Detail",
			EDIT_TITLE: "Edit Notice Type Detail",

			LBL_NOTICE_TYPE_CODE: "Notice Type Code",
			LBL_NOTICE_TYPE_DESC: "Notice Type Desc",
			LBL_NOTICE_TYPE_GROUP: "Notice Type Group",
			LBL_NOTICE_TYPE_PAYMENT_TERMS_DESCRIPTION: "Quotation Payment Terms Description",
			LBL_NOTICE_TYPE_INSTRUCTION: "Notice Type Instruction",
			LBL_ORDER_RECEIPT: "Order Receipt",
			LBL_CASH_PAYMENT: "Cash Payment",
			LBL_STOP_RECEIVED: "Stop Receive",
			LBL_DUE_DAYS: "Due Days",
			LBL_CAN_TT_PAYMENT: "Can Make TT Payment",
			LBL_CAN_ISSUE_PN: "Can Issue Payable Notes",
			LBL_EPO_REMARK: "EPO Remark",
			LBL_WAREHOUSE: "Warehouse",
			LBL_EPO_IMPORTANT_NOTICE: "Important Notice",
			LBL_EPO_MARKING_REMARK: "Marking Remark",
			LBL_SUPPLIER_BANK: "Supplier Bank Required",
			LBL_EPO_SUPPLIER_BANK_REMARK: "Supplier Bank Remark",
			MODULE_NAME: "Notice Type",

			COL_NOTICE_TYPE_CODE: "Notice Type Code",
			COL_NOTICE_TYPE_DESC: "Notice Type Desc",
			COL_NOTICE_TYPE_GROUP: "Notice Type Group",
			COL_NOTICE_TYPE_INSTRUCTION: "Notice Type Instruction",
			COL_ORDER_RECEIPT: "Order Receipt",
			COL_CASH_PAYMENT: "Cash Payment",
			COL_DUE_DAYS: "Due Days",

			BTN_ADD_NEW_EPO_REMARK: "Add New EPO Remark",
			BTN_ADD_REMOVE_EPO_REMARK: "Remove EPO Remark"
		},
		DOC_TYPE: {
			LISTING_TITLE: "Doc Type Listing",
			CREATE_TITLE: "Create New Doc Type",
			DETAIL_TITLE: "Doc Type Detail",
			EDIT_TITLE: "Edit Doc Type Detail",

			LBL_DOC_TYPE_CODE: "Doc Type Code",
			LBL_DOC_TYPE_DESC: "Doc Type Desc",
			LBL_DOC_TYPE_NOTICE_TYPE: "Notice Type",
			LBL_POSTING_SIDE: "Posting Site",
			LBL_USE_SAME_ID: "Use Same ID",

			MODULE_NAME: "Doc Type",

			COL_DOC_TYPE_CODE: "Doc Type Code",
			COL_DOC_TYPE_DESC: "Doc Type Desc",
			COL_POSTING_SIDE: "Posting Side",
			COL_USE_SAME_ID: "Use Same ID",

			SELECTION: {
				NO_POSTING: "No Posting",
				CREDIT: "Credit",
				DEBIT: "Debit"
			}
		},
		DOC_NUM: {
			LISTING_TITLE: "Doc Numbering Listing",
			CREATE_TITLE: "Create New Doc Numbering",
			DETAIL_TITLE: "Doc Numbering Detail",
			EDIT_TITLE: "Edit Doc Numbering Detail",
			LBL_NUM_DESC: "Doc Numbering Desc",
			LBL_DOC_TYPE: "Doc Type",
			LBL_WAREHOUSE: "Warehouse",
			LBL_NUM_BY_COMPANY_SUBGROUP: "By Company Sub Group?",
			LBL_COMPANY_SUBGROUP: "Company Sub Group",
			LBL_NUM_BY_DEPARTMENT: "By Department?",
			LBL_DEPARTMENT: "Department",
			LBL_NUM_PREFIX: "Numbering Prefix",
			LBL_NUM_PREFIX_WITH_WAREHOUSE_COUNTRY: "By Warehouse Country?",
			LBL_WAREHOUSE_COUNTRY: "Warehouse Country",
			LBL_NUM_POSTFIX: "Numbering Postfix",
			LBL_NUM_RESET: "Reset",
			LBL_NUM_DIGIT_COUNT: "Digit Count",
			LBL_NUM_LAST_NUM_INT: "Last Numbering (In Digit)",
			LBL_NUM_LAST_NUM_FORMAT: "Last Numbering (In Full)",
			LBL_NOTICE_TYPE: "Notice Type",
			LBL_POSTFIX: "Postfix",
			LBL_PREFIX: "Prefix",
			LBL_CUSTOMER_MARKING: "Customer Marking",

			MODULE_NAME: "Doc Numbering",

			COL_NUM_DESC: "Doc Numbering Desc",
			COL_DOC_TYPE: "Doc Type",
			COL_NUM_WITH_WAREHOUSE_COUNTRY: "Num with Warehouse Country ?",
			COL_NUM_BY_COMPANY_SUBGROUP: "By Company Sub Group ?",
			COL_COMPANY_SUBGROUP: "Company Sub Group",
			COL_NUM_PREFIX: "No. Prefix",
			COL_NUM_POSTFIX: "No. Postfix",
			COL_NUM_RESET: "Reset",
			COL_NUM_DIGIT_COUNT: "Digit Count",
			COL_NUM_LAST_NUM_INT: "Last No.",
			COL_NUM_LAST_NUM_FORMAT: "Last No. (In Full)",

			SELECTION: {
				NO_RESET: "None",
				BY_YEAR: "By Year",
				BY_MONTH: "By Month"
			},

			DOC_NUM_FORMAT: "{prefix}{date}{number}{postfix}"
		},
		INSTRUCTION_TYPE: {
			CREATE_TITLE: "Create New Instruction Type",
			DETAIL_TITLE: "Instruction Type Detail",

			LBL_INSTRUCTION_TYPE: "Instruction Type Code",
			LBL_NAME: "Instruction Type Name",
			LBL_STOP_RECEIVED: "Stop Received",
			LBL_STOP_PAYMENT: "Stop Payment",
			LBL_STOP_LOADING: "Stop Loading",
			LBL_COLOR_INDICATOR: "Color Indicator",
			LBL_REMARK: "Remark",
			LBL_REQUEST_PLG: "Request Parcel Lost Guarantee",
			LBL_CANCEL_PLG: "Cancel Parcel Lost Guarantee",
			LBL_CHANGE_NOTICE_TYPE: "Switch EPO Notice Type",

			COL_INSTRUCTION_TYPE: "Instruction Type Code",
			COL_INSTRUCTION_TYPE_NAME: "Instruction Type Name",
			COL_COLOR_INDICATOR: "Color Indicator",

			MODULE_NAME: "Instruction Type",
			LISTING_TITLE: "Intruction Type",
			EDIT_TITLE: "Edit Intruction Type"
		},
		PAY_MODE: {
			CREATE_TITLE: "Create New Pay Mode",
			DETAIL_TITLE: "Pay Mode Detail",

			LBL_CODE: "Pay Mode Code",
			LBL_NAME: "Pay Mode Name",

			COL_PAY_MODE_CODE: "Pay Mode Code",
			COL_PAY_MODE_NAME: "Pay Mode Name",

			MODULE_NAME: "Pay Mode",
			LISTING_TITLE: "Pay Mode",
			EDIT_TITLE: "Edit Pay Mode"
		},

		WAREHOUSE_LOCATION: {
			MODULE_NAME: "Warehouse Location",
			LISTING_TITLE: "Warehouse Location Listing",
			LBL_WAREHOUSE_LOCATION_CODE: "Location Code",
			LBL_WAREHOUSE_LOCATION_NAME: "Location Name",
			LBL_WAREHOUSE: "Warehouse",
			CREATE_TITLE: "Create New Warehouse Location",
			DETAIL_TITLE: "Warehouse Location Detail",
			EDIT_TITLE: "Edit Warehouse Location",

			COL_LOCATION_CODE: "Location Code",
			COL_LOCATION_NAME: "Location Name"
		},
		CURRENCIES_RATE: {
			TITLE: "Currency Rate",
			LABEL_DATE: "Date",
			LABEL_CURRENCY: "Currency",
			LABEL_EXCHANGE_CURRENCY: "Exchange Currency",
			ERROR_MESSAGE_GET_CURRENCY_LIST: "No Currency Record Has Been Found",
			LABEL_EXCHANGE_CURRENCY_RATE: "Exchange Currency Rate",
			MESSAGE_SUCCESS_SAVE: "New Currency Rate successfull to save",
			MESSAGE_FAILED_SAVE: "Failed to Save New Currency Date",
			BTN_CURRENCT_RATE_HISTORY: "Currency Rate History",
			MESSAGE_CONVERSION_FACTOR_NOT_SET: "Conversion Factor Not Set",
			HISTORY_TITLE: "Currency Rate History",
			LBL_DATE_FROM: "Date From",
			LBL_DATE_TO: "To",

			COL_DATE: "Date",
			COL_CURRENCY: "Currency",
			COL_EXCHANGE_CURRENCY: "Exchange Currency",
			COL_CONVERSION_FACTOR: "Conversion Factor",
			COL_RATE: "Rate"
		},
		TAX: {
			LISTING_TITLE: "Tax Listing",
			CREATE_TITLE: "Create New Tax",
			DETAIL_TITLE: "Tax Detail",
			EDIT_TITLE: "Edit Tax Detail",

			MODULE_NAME: "Tax",

			LBL_TAX_CODE: "Tax Code",
			LBL_TAX_NAME: "Tax Name",
			LBL_TAX_TYPE: "Tax Type",
			LBL_CHARGES_TO_COST: "Charges To Cost",
			LBL_FOR_ADJUSTMENT: "For Adjustment",
			LBL_GST03: "GST03",
			LBL_GST03_REMARK: "GST03 Remark",
			LBL_TAX_RATE: "Tax Rate (%)",
			LBL_TAX_QUOTE_DESC: "Tax Quote Remark",

			COL_TAX_CODE: "Tax Code",
			COL_TAX_NAME: "Tax Name",
			COL_TAX_TYPE: "Tax Type",
			COL_TAX_RATE: "Tax Rate (%)"
		},
		DAY_CONVERSION: {
			LISTING_TITLE: "Day Conversion Listing",
			CREATE_TITLE: "Create New Day Conversion",
			DETAIL_TITLE: "Day Conversion Detail",
			EDIT_TITLE: "Edit Day Conversion",
			MODULE_NAME: "Day Conversion",

			LBL_NUMBER_OF_DAY: "Day",
			LBL_DAY_ENGLISH_WORD: "Day's Word",

			COL_DAY: "Day",
			COL_DAY_OF_WORD: "Day's Word"
		},
		QUOTATION_SUBJECT: {
			LISTING_TITLE: "Quotation Subject Listing",
			CREATE_TITLE: "Create New Quotation Subject",
			DETAIL_TITLE: "Quotation Subject Detail",
			EDIT_TITLE: "Edit Quotation Subject",

			MODULE_NAME: "Quotation Subject",
			LBL_QUOTATION_SUBJECT_CODE: "Quotation Subject Code",
			LBL_QUOTATION_SUBJECT_NAME: "Quotation Subject Name",
			LBL_QUOTATION_SUBJECT_TITLE: "Quotation Subject Title",
			LBL_QUOTATION_SUBJECT_CONTENT: "Quotation Subject Content",

			COL_CODE: "Quotation Subject Code",
			COL_NAME: "Quotation Subject Name",
			COL_SUBJECT_CONTENT: "Subject Description",

			LBL_QUOTATION_SUBJECT_DEFAULT: "Default for",
			NEW_QUOTATION_TITLE: "New Quotation",
			REVISED_QUOTATION_TITLE: "Revise Quotation"
		},
		CUSTOMER_REJECT_QUOTE_REASON: {
			LISTING_TITLE: "Customer Reject Reason Listing",
			CREATE_TITLE: "Create New Customer Reject Reason",
			DETAIL_TITLE: "Customer Reject Reason Detail",
			EDIT_TITLE: "Edit Customer Reject Reason",

			MODULE_NAME: "Customer Reject Reason",

			COL_REJECT_REASON_CODE: "Reject Reason Code",
			COL_REJECT_REASON_NAME: "Reject Reason Name",

			LBL_REJECT_REASON_CODE: "Reject Reason Code",
			LBL_REJECT_REASON_NAME: "Reject Reason Name"
		},
		ADMIN_REJECT_QUOTE_REASON: {
			LISTING_TITLE: "Admin Reject Reason Listing",
			CREATE_TITLE: "Create New Admin Reject Reason",
			DETAIL_TITLE: "Admin Reject Reason Detail",
			EDIT_TITLE: "Edit Admin Reject Reason",

			MODULE_NAME: "Admin Reject Reason",

			COL_REJECT_REASON_CODE: "Reject Reason Code",
			COL_REJECT_REASON_NAME: "Reject Reason Name",
			COL_SCS_CONFIRM: "Scs Confirm",

			LBL_REJECT_REASON_CODE: "Reject Reason Code",
			LBL_REJECT_REASON_NAME: "Reject Reason Name"
		},
		REVERT_QUOTE_REASON: {
			LISTING_TITLE: "Revert Reason Listing",
			CREATE_TITLE: "Create New Revert Reason",
			DETAIL_TITLE: "Revert Reason Detail",
			EDIT_TITLE: "Edit Revert Reason",

			MODULE_NAME: "Revert Reason",

			COL_REVERT_REASON_CODE: "Revert Reason Code",
			COL_REVERT_REASON_NAME: "Revert Reason Name",

			LBL_REVERT_REASON_CODE: "Revert Reason Code",
			LBL_REVERT_REASON_NAME: "Revert Reason Name"
		},
		DELIVERY_REMARK: {
			LISTING_TITLE: "Delivery Remark Listing",
			CREATE_TITLE: "Create New Delivery Remark",
			DETAIL_TITLE: "Delivery Remark Detail",
			EDIT_TITLE: "Edit Delivery Remark Detail",

			LBL_REMARK_ID_NAME: "Name",
			LBL_REMARK_ID_DESCRIPTION: "Description",

			MODULE_NAME: "Delivery Remark",

			COL_NAME: "Delivery Remark Name",
			COL_DESCRIPTION: "Delivery Remark Description"
		},
		CUSTOMER_UPDATE_LOG_REJECT_INFO: {
			LISTING_TITLE: "Reject Change A/C Request Reason",
			CREATE_TITLE: "Create New Reject Change A/C Request Reason",
			DETAIL_TITLE: "Reject Change A/C Request Reason Detail",
			EDIT_TITLE: "Edit Reject Change A/C Request Reason",
			TITLE_CREATE: "Create New Reject Change A/C Request Reason",

			LBL_REJECT_INFO_NAME: "Reject Change A/C Request Reason Name",
			LBL_REJECT_INFO_DESCRIPTION: "Description",

			MODULE_NAME: "Reject Change A/C Request Reason",

			COL_NAME: "Reject Change A/C Request Reason Name"
		},
		FORWARDER: {
			LISTING_TITLE: "Forwarder Listing",
			CREATE_TITLE: "Create New Forwarder",
			DETAIL_TITLE: "Forwarder Detail",
			EDIT_TITLE: "Edit Forwarder Detail",

			LBL_ID: "ID",
			LBL_NAME: "Name",
			LBL_FORWARDER_GROUP: "Group",
			LBL_FORWARDER_GROUP_ID: "Group",
			LBL_FORWARDER_GROUP_NAME: "Group Name",
			LBL_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_MOBILE_CONTACT_CALLING_CODE: "Contact No. Calling Code",
			LBL_EMAIL: "Contact Email",
			LBL_OFFICE_TEL_NO: "Office Tel No.",
			LBL_OFFICE_TEL_CALLING_CODE: "Office Tel No. Calling Code",
			LBL_OFFICE_FAX_NO: "Office Fax No.",
			LBL_OFFICE_FAX_CALLING_CODE: "Office Fax No. Calling Code",
			LBL_OTHER_CITY: "Custom City",
			LBL_OTHER_POSTCODE: "Custom Postcode",
			LBL_ADDRESS: "Address",
			LBL_COUNTRY: "Country",
			LBL_STATE: "State",
			LBL_CITY: "City",
			LBL_POSTCODE: "Postcode",
			LBL_URL: "Url",
			LBL_REMARK: "Remark",
			LBL_COUNTRY_ID: "Country",
			LBL_STATE_ID: "State",
			LBL_CITY_ID: "City",
			LBL_POSTCODE_ID: "Postcode",

			MODULE_NAME: "Forwarder",

			COL_ID: "Forwarder ID",
			COL_NAME: "Forwarder Name",
			COL_GROUP_ID: "Forwarder Group ID",
			COL_GROUP_NAME: "Forwarder Group Name",
			COL_MOBILE_CONTACT_NO: "Contact",
			COL_ADDRESS: "Address"
		},
		FORWARDER_GROUP: {
			LISTING_TITLE: "Forwarder Group Listing",
			CREATE_TITLE: "Create New Forwarder Group",
			DETAIL_TITLE: "Forwarder Group Detail",
			EDIT_TITLE: "Edit Forwarder Group Detail",

			LBL_ID: "ID",
			LBL_CODE: "Forwarder Group Code",
			LBL_NAME: "Forwarder Group Name",

			MODULE_NAME: "Forwarder Group",

			COL_ID: "Forwarder Group ID",
			COL_CODE: "Forwarder Group Code",
			COL_NAME: "Forwarder Group Name",
			COL_REFERENCE_COUNT: "Reference Count"
		},
		SHIPPER: {
			LISTING_TITLE: "Shipper Listing",
			CREATE_TITLE: "Create New Shipper",
			DETAIL_TITLE: "Shipper Detail",
			EDIT_TITLE: "Edit Shipper Detail",
			MODULE_NAME: "Shipper",

			LBL_SHIPPER_ID: "Shipper ID",
			LBL_SHIPPER_WAREHOUSE_ID: "Warehouse",
			LBL_SHIPPER_NAME: "Shipper Name",
			LBL_SHIPPER_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_SHIPPER_ADDRESS: "Address",
			LBL_SHIPPER_EMAIL: "Email",
			LBL_SHIPPER_OFFICE_TEL_NO: "Tel No.",
			LBL_SHIPPER_OFFICE_TEL_PIC: "Tel No. PIC",
			LBL_SHIPPER_OFFICE_FAX_NO: "Fax No.",
			LBL_SHIPPER_POSTCODE_ID: "Postcode",
			LBL_SHIPPER_OTHER_POSTCODE: "Other Postcode",
			LBL_SHIPPER_CITY_ID: "City",
			LBL_SHIPPER_OTHER_CITY: "Other City",
			LBL_SHIPPER_STATE_ID: "State",
			LBL_SHIPPER_COUNTRY_ID: "Country",
			LBL_SHIPPER_URL: "Url",
			LBL_SHIPPER_REMARK: "Remark",

			COL_SHIPPER_NAME: "Shipper Name",
			COL_SHIPPER_MOBILE_CONTACT_NO: "Mobile Contact No.",
			COL_SHIPPER_EMAIL: "Shipper Email"
		},
		TRANSPORTER: {
			LISTING_TITLE: "Transporter Listing",
			CREATE_TITLE: "Create New Transporter",
			DETAIL_TITLE: "Transporter Detail",
			EDIT_TITLE: "Edit Transporter Detail",
			MODULE_NAME: "Transporter",

			LBL_WEB_SITE: "Website",
			LBL_MOBILE_CONTACT_NO: "Mobile Contact No",
			LBL_MOBILE_CONTACT_PIC: "Mobile Contact PIC",
			LBL_MOBILE_CONTACT_NO_ALT: "Mobile Contact No (Alt)",
			LBL_MOBILE_CONTACT_PIC_ALT: "Mobile Contact (Alt) PIC",
			LBL_OFFICE_CONTACT_NO: "Office Contact No",
			LBL_OFFICE_CONTACT_PIC: "Office Contact PIC",
			LBL_ADDRESS: "Address",
			LBL_EMAIL: "Email",
			LBL_POSTCODE_ID: "Postcode",
			LBL_OTHER_POSTCODE: "Other Postcode",
			LBL_CITY_ID: "City",
			LBL_OTHER_CITY: "Other City",
			LBL_STATE_ID: "State",
			LBL_COUNTRY_ID: "Country",
			LBL_URL: "Url",
			LBL_REMARK: "Remark",
			LBL_PRINT_BARCODE_DO: "Print Barcode D/O",
			LBL_TRANSPORTER_NAME: "Transporter Name",
			LBL_TRANSPORTER_CODE: "Transporter Code",

			COL_TRANSPORTER_NAME: "Transporter Name",
			COL_TRANSPORTER_CODE: "Transporter Code",
			COL_MOBILE_CONTACT_NO: "Mobile Contact No.",
			COL_COUNTRY: "Country"
		},
		SHIPPING_LINE: {
			LISTING_TITLE: "Shipping Line Listing",
			CREATE_TITLE: "Create New Shipping Line",
			DETAIL_TITLE: "Shipping Line Detail",
			EDIT_TITLE: "Edit Shipping Line Detail",
			MODULE_NAME: "Shipping Line",

			LBL_SHIPPING_LINE_ID: "Shipping Line ID",
			LBL_SHIPPING_LINE_NAME: "Shipping Line Name",
			LBL_SHIPPING_LINE_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_SHIPPING_LINE_ADDRESS: "Address",
			LBL_SHIPPING_LINE_EMAIL: "Email",
			LBL_SHIPPING_LINE_OFFICE_TEL_NO: "Tel No.",
			LBL_SHIPPING_LINE_OFFICE_TEL_PIC: "Tel No. PIC",
			LBL_SHIPPING_LINE_OFFICE_FAX_NO: "Fax No.",
			LBL_SHIPPING_LINE_POSTCODE_ID: "Postcode",
			LBL_SHIPPING_LINE_OTHER_POSTCODE: "Other Postcode",
			LBL_SHIPPING_LINE_CITY_ID: "City",
			LBL_SHIPPING_LINE_OTHER_CITY: "Other City",
			LBL_SHIPPING_LINE_STATE_ID: "State",
			LBL_SHIPPING_LINE_COUNTRY_ID: "Country",
			LBL_SHIPPING_LINE_URL: "Url",
			LBL_SHIPPING_LINE_REMARK: "Remark",

			COL_SHIPPING_LINE_NAME: "Shipping Line Name",
			COL_SHIPPING_LINE_MOBILE_CONTACT_NO: "Mobile Contact No.",
			COL_SHIPPING_LINE_EMAIL: "Shipping Line Email"
		},
		INVALID_QUOTE_REASON: {
			LISTING_TITLE: "Invalid Reason Listing",
			CREATE_TITLE: "Create New Invalid Reason",
			DETAIL_TITLE: "Revert Invalid Detail",
			EDIT_TITLE: "Edit Invalid Reason",

			MODULE_NAME: "Invalid Reason",

			COL_REASON_CODE: "Invalid Reason Code",
			COL_REASON_NAME: "Invalid Reason Name",

			LBL_REASON_CODE: "Invalid Reason Code",
			LBL_REASON_NAME: "Invalid Reason Name"
		},
		SPECIAL_PRODUCT_STATUS: {
			CREATE_TITLE: "Create New Special Product Status",
			LISTING_TITLE: "Special Product Listing",
			MODULE_NAME: "Special Product Status",
			DETAIL_TITLE: "Special Product Status Detail",
			EDIT_TITLE: "Edit Special Product Status",

			LBL_CODE: "Special Product Status Code",
			LBL_NAME: "Special Product Status Name",

			LBL_IS_REJECT: "Reject",

			COL_CODE: "Code",
			COL_NAME: "Name"
		},
		WAREHOUSE_TYPE: {
			LISTING_TITLE: "Warehouse Type Listing",
			CREATE_TITLE: "Create New Warehouse Type",
			DETAIL_TITLE: "Warehouse Type Detail",
			EDIT_TITLE: "Update Warehouse Type",
			MODULE_NAME: "Warehouse Type",
			LBL_CODE: "Warehouse Type Code",
			LBL_NAME: "Warehouse Type Name",
			LBL_INTERNAL_WAREHOUSE: "Internal Warehouse",
			LBL_DEFAULT: "Default",
			LBL_DESCRIPTION: "Warehouse Type Description",
			COL_WAREHOUSE_TYPE_CODE: "Warehouse Type Code",
			COL_WAREHOUSE_TYPE_NAME: "Warehouse Type Name"
		},
		DISTRICT: {
			LISTING_TITLE: "District Listing",
			CREATE_TITLE: "Create New District",
			DETAIL_TITLE: "District Detail",
			EDIT_TITLE: "Edit District Detail",
			MODULE_NAME: "District",

			LBL_DISTRICT_NAME: "District Name",
			LBL_COUNTRIES: "Country",
			LBL_STATES: "State",
			LBL_CITY: "City",

			COL_NAME: "Name",
			COL_COUNTRY: "Country",
			COL_STATE: "State",
			COL_CITY: "City",

			MSG_FAILED_TO_UPDATE: "City has been failed saved",
			MSG_SUCCESS_TO_UPDATE: "City has been successfully saved",

			MSG_FAILED_CREATE_CITY: "New City has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New City has been successfully to created",
			MSG_FAILED_DELETE: "City failed to delete",

			MSG_SUCCESS_TO_DELETE: "City record successful to delete"
		},
		CONSIGNEE: {
			LISTING_TITLE: "Consignee Listing",
			CREATE_TITLE: "Create New Consignee",
			DETAIL_TITLE: "Consignee Detail",
			EDIT_TITLE: "Edit Consignee Detail",

			LBL_ID: "ID",
			LBL_NAME: "Name",
			LBL_CONSIGNEE_GROUP: "Group",
			LBL_CONSIGNEE_GROUP_ID: "Group",
			LBL_CONSIGNEE_GROUP_NAME: "Group Name",
			LBL_MOBILE_CONTACT_NO: "Mobile Contact No.",
			LBL_MOBILE_CONTACT_CALLING_CODE: "Contact No. Calling Code",
			LBL_EMAIL: "Contact Email",
			LBL_OFFICE_TEL_NO: "Office Tel No.",
			LBL_OFFICE_TEL_PIC: "Office Tel PIC",
			LBL_OFFICE_TEL_CALLING_CODE: "Office Tel No. Calling Code",
			LBL_OFFICE_FAX_NO: "Office Fax No.",
			LBL_OFFICE_FAX_CALLING_CODE: "Office Fax No. Calling Code",
			LBL_OTHER_CITY: "Custom City",
			LBL_OTHER_POSTCODE: "Custom Postcode",
			LBL_ADDRESS: "Address",
			LBL_COUNTRY: "Country",
			LBL_STATE: "State",
			LBL_CITY: "City",
			LBL_POSTCODE: "Postcode",
			LBL_URL: "Url",
			LBL_REMARK: "Remark",
			LBL_COUNTRY_ID: "Country",
			LBL_STATE_ID: "State",
			LBL_CITY_ID: "City",
			LBL_POSTCODE_ID: "Postcode",

			MODULE_NAME: "Consignee",

			COL_ID: "Consignee",
			COL_NAME: "Consignee Name",
			COL_GROUP_ID: "Consignee Group",
			COL_GROUP_NAME: "Consignee Group Name",
			COL_MOBILE_CONTACT_NO: "Contact",
			COL_ADDRESS: "Address"
		},
		CONSIGNEE_GROUP: {
			LISTING_TITLE: "Consignee Group Listing",
			CREATE_TITLE: "Create New Consignee Group",
			DETAIL_TITLE: "Consignee Group Detail",
			EDIT_TITLE: "Edit Consignee Group Detail",

			LBL_ID: "ID",
			LBL_CODE: "Code",
			LBL_NAME: "Name",

			MODULE_NAME: "Consignee Group",

			COL_ID: "Consignee Group ID",
			COL_CODE: "Consignee Group Code",
			COL_NAME: "Consignee Group Name",
			COL_REFERENCE_COUNT: "Reference Count"
		},
		TT_CHARGES: {
			LISTING_TITLE: "TT Charges Summary",
			CREATE_TITLE: "Create New TT Charges",
			DETAIL_TITLE: "View TT Charges",
			EDIT_TITLE: "Edit TT Charges",
			MODULE_NAME: "TT Charges",

			LBL_CHARGES_CODE: "TT Charges Code",

			LBL_CHARGES_NAME: "TT Charges Name",
			LBL_CURRENCY: "TT Currency",
			LBL_CURRENCY_CHARGES: "TT Charges Currency",

			LBL_TT_RANGE: "TT Range",
			LBL_TT_Charges: "Charges",
			LBL_RANGE_FROM: "From",
			LBL_RANGE_TO: "To",
			LBL_CHARGES: "Charges",

			VALIDATION: {
				TO_CHARGES_GREATER_THAN_ZERO: "TT Range To must greater than 0.",
				FROM_SMALLER_THAN_TO: "TT Range From must smaller than TT Range To.",
				CHARGES_GREATER_THAN_ZERO: "Charges greater than 0",
				CURRENCY_ID_DUPLICATE: "This TT Currency's Charges has been set. You are not allow to set again."
			}
		},
		TT_BANK_CODE: {
			LISTING_TITLE: "Bank Code Listing",
			CREATE_TITLE: "Create New Bank Code",
			DETAIL_TITLE: "Bank Code Detail",
			EDIT_TITLE: "Edit Bank Code Detail",

			LBL_ID: "ID",
			LBL_CODE: "Code",
			LBL_NAME: "Name",
			LBL_CREATED_BY_USERNAME: "created by",
			LBL_LAST_UPDATED_BY_USERNAME: "last updated by",

			MODULE_NAME: "Bank Code",

			COL_CODE: "Bank Code",
			COL_NAME: "Bank Name"
		},
		TT_BENEFICIARY_TYPE: {
			LISTING_TITLE: "Beneficiary Type Listing",
			CREATE_TITLE: "Create New Beneficiary Type",
			DETAIL_TITLE: "Beneficiary Type Detail",
			EDIT_TITLE: "Edit Beneficiary Type Detail",

			LBL_CODE: "Code",
			LBL_NAME: "Name",
			LBL_CREATED_BY_USERNAME: "created by",
			LBL_LAST_UPDATED_BY_USERNAME: "last updated by",

			MODULE_NAME: "Beneficiary Type",

			COL_CODE: "Beneficiary Type Code",
			COL_NAME: "Beneficiary Type Name",
			COL_ALLOW_GROUP_RATE: "Used TT Group Rate",
			COL_ALLOW_BOOKED_AMOUNT: "Allow Special Rate & Booked Amount",
			COL_LIMIT_TT: "Allow Limit TT"
		},
		TT_CATEGORY: {
			LISTING_TITLE: "TT Category Listing",
			CREATE_TITLE: "Create New TT Category",
			DETAIL_TITLE: "TT Category Detail",
			EDIT_TITLE: "Edit TT Category Detail",

			LBL_CREATED_BY_USERNAME: "created by",
			LBL_LAST_UPDATED_BY_USERNAME: "last updated by",

			MODULE_NAME: "TT Category",
			ACCOUNT_TYPE: "Account Type",
			CATEGORY_CODE: "Category Code",
			DEPOSIT_RANGE_FROM: "Deposit Range From",
			DEPOSIT_RANGE_TO: "Deposit Range",
			ACCOUNT_TYPES: {
				PUBLIC_PUBLIC: "Public - Public",
				PUBLIC_PRIVATE: "Public - Private ",
				PRIVATE_PRIVATE: "Private - Private"
			}
		},
		TT_GROUP: {
			LISTING_TITLE: "TT Group Listing",
			CREATE_TITLE: "Create New TT Group",
			DETAIL_TITLE: "TT Group Detail",
			EDIT_TITLE: "Edit TT Group Detail",

			LBL_ID: "ID",
			LBL_CODE: "Code",
			LBL_NAME: "Name",
			LBL_CREATED_BY_USERNAME: "created by",
			LBL_LAST_UPDATED_BY_USERNAME: "last updated by",
			LBL_CUSTOMER_DEFAULT: "Customer Default",
			MODULE_NAME: "TT Group",

			COL_CODE: "TT Group Code",
			COL_NAME: "TT Group Name"
		},
		TT_DAILY_RATE: {
			LISTING_TITLE: "TT Daily Rate Listing",
			CREATE_TITLE: "Create New TT Daily Rate",
			DETAIL_TITLE: "TT Daily Rate",
			EDIT_TITLE: "Edit TT Daily Rate",
			MODULE_NAME: "TT Daily Rate",
			HISTORY_LOG_TITLE: "History Log",

			LBL_CURRENCY: "Currency",
			LBL_TT_CURRENCY: "TT Currency",
			LBL_CURRENCY_DIFFERENT: "Daily Rate & Group Rate Diff (%)",
			LBL_TT_RATE: "Daily Rate",
			NOTES_MESSAGE: "<strong>Take Notes: </strong> Conversion Factor must set in Currencies Master Data Module. You are not allow to switch it.",
			REFRESH_TT_GROUP_MESSAGE: "{code} Daily Rate will be replace by TT Spread Rate",
			CONFIRMATION_FOR_SUMBIT: "Please ensure all rate are correct before submitting.",
			CONFIRMATION_FOR_SUMBIT_REMAIN: "Please ensure all rate are correct before submitting.<br />Rate will replace existing record.",
			GROUP_RATE_OVER_MSG: "Group Rate & Daily Rate different more than {num}%",
			DAILY_RATE_NEGATIVE_MSG: "Daily Rate cannpt be negative. Please check spread rate cannot greater than daily rate.",
			COL_EFFECTIVE_TIME_FROM: "Effective Time From",
			COL_EFFECTIVE_TIME_TO: "Effective Time To",
			COL_EFFECTIVE_TIME_TO_ERROR: "Time To must be greater than current time",
			COL_CURRENCY: "Currency",
			COL_EXCHANGE_CURRENCY: "TT Currency",
			COL_CONVERSION_FACTOR: "Conversion Factor",
			COL_RATE: "Daily Rate",
			COL_TT_CHARGES: "TT Charges",
			COL_LIMIT_TT: "Limit TT",
			COL_EFFECTIVE_TIME: "Effective Time",
			COL_TT_GROUP: "TT Group",
			COL_TT_GROUP_RATE: "TT Group Rate",
			COL_EXISTS_FACTOR: "Exists: {factor}",
			COL_NEW_FACTOR: "New: {factor}",

			MSG: {
				FACTOR_DIFF: "{currency} convert to {tt_currency} factor switch to {factor}",
				UNQIQUE_RECORD: "{tt_currency} daily rate created by other user. You are not allow to save.",
				DAILY_RATE_NOTE: "<Strong>Take Notes: </Strong> TT Group & TT Group Rate red colour, the spread rate different than master set.",
				SPREAD_RATE_UNDEFINED: "Failed to get spread rate. Please contact system admin",
				SPREAD_RATE_NOT_SET: "{tt_currency} spread rate not set",
				SPREAD_RATE_NOT_SET_GROUP: "{tt_currency} for {tt_group} group spread rate not set"
			}
		},
		TT_AGENT: {
			LISTING_TITLE: "TT Agent Listing",
			CREATE_TITLE: "Create New TT Agent",
			DETAIL_TITLE: "TT Agent Detail",
			EDIT_TITLE: "Edit TT Agent Detail",

			LBL_ID: "ID",
			LBL_CODE: "Code",
			LBL_NAME: "Name",
			LBL_AP_CREDITOR: "AP/BK Creditor",
			LBL_AGENT_CODE: "Agent Code",
			LBL_CURRENCY_DETAIL: "Setup Currency",
			LBL_TRUNCATE_CENT: "Truncate Cent",
			LBL_CREATED_BY_USERNAME: "created by",
			LBL_LAST_UPDATED_BY_USERNAME: "last updated by",
			LBL_ADD_NEW_RECORD: "Add Record",
			LBL_DOC_NUM_DETAIL: "Setup Doc No. Format",
			LBL_DOC_NUM_DEFAULT: "Please ensure at least one and only one default is defined for doc numbering.",

			MODULE_NAME: "TT Agent",

			COL_AP_CREDITOR: "AP/BK Creditor",
			COL_CODE: "TT Agent Code",
			COL_NAME: "TT Agent Name",
			COL_CURRENCY: "Currency",
			COL_TRUNCATE_CENT: "Truncate Cents",

			COL_NUM_DESC: "No. Desc",
			COL_NUM_PREFIX: "No. Prefix",
			COL_NUM_POSTFIX: "No. Postfix",
			COL_NUM_RESET: "Reset",
			COL_NUM_DIGIT_COUNT: "Digit Count",
			COL_NUM_LAST_NUM_INT: "Last No. (In Digit)",
			COL_NUM_LAST_NUM_FORMAT: "Last No. Format",
			COL_NUM_FORMAT: "No. Format",
			COL_DEFAULT: "Default",

			VALUE_CANNOT_MORE_THAN_TWENNTY_CHAR_VALIDATION: "{name} value including space cannot exceed 20 characters",

			MSG: {
				CURRENCY_DUPLICATE_SET: "{currency} Duplicate Set",
				CREDITOR_DUPLICATE: "{name} creditor used by other agent",
				DOC_NUM_UNIQUE: "{format} used by other agent"
			}
		},
		TT_CREDITOR: {
			LISTING_TITLE: "TT Creditor Listing",
			CREATE_TITLE: "Create New TT Creditor",
			DETAIL_TITLE: "TT Creditor Detail",
			EDIT_TITLE: "Edit TT Creditor Detail",

			LBL_ID: "ID",
			LBL_CODE: "Code",
			LBL_NAME: "Name",
			LBL_TYPE: "Type",
			LBL_CREATED_BY_USERNAME: "created by",
			LBL_LAST_UPDATED_BY_USERNAME: "last updated by",

			MODULE_NAME: "TT Creditor",

			COL_CODE: "Creditor Code",
			COL_NAME: "Creditor Name",
			COL_TYPE: "Type"
		},
		TT_GL_ACCOUNT: {
			LISTING_TITLE: "TT GL Account Listing",
			CREATE_TITLE: "Create New TT GL Account",
			DETAIL_TITLE: "TT GL Account Detail",
			EDIT_TITLE: "Edit TT GL Account Detail",

			LBL_ID: "ID",
			LBL_CODE: "Code",
			LBL_NAME: "Name",
			LBL_DESCRIPTION: "Description",
			LBL_COMPANY: "Company",
			LBL_TYPE: "Type",
			LBL_CREATED_BY_USERNAME: "created by",
			LBL_LAST_UPDATED_BY_USERNAME: "last updated by",

			MODULE_NAME: "TT GL Account",

			COL_CODE: "GL Account Code",
			COL_NAME: "GL Account Name",
			COL_COMPANY: "Company Name",
			COL_TYPE: "Type"
		},
		TT_DEFAULT_GL_ACCOUNT: {
			LISTING_TITLE: "TT Default GL Account Setting",
			CREATE_TITLE: "Create New TT Default GL Account",
			DETAIL_TITLE: "TT Default GL Account Detail",
			EDIT_TITLE: "Edit TT Default GL Account Detail",
			MODULE_NAME: "TT Default GL Account",

			LBL_AP_COMPANY: "AP Company",
			LBL_PAY_CONTRA_ACC: "Default Pay Contra Acc",
			LBL_TRUNCATE_CENT_GL_ACC: "Cent Truncate GL Acc",
			LBL_DISC_ACC: "Default Local Other Charges/Disc Acc",
			LBL_REFUND_GL_ACC: "Refund GL Acc",
			LBL_CURRENCY_MSM_DETAIL: "Currencies and Main Salesman Detail",
			LBL_ADD_CURRENCY_MSM: "Add Record",
			LBL_CREATED_BY_USERNAME: "created by",
			LBL_LAST_UPDATED_BY_USERNAME: "last updated by",

			MODULE_NAME: "TT Default GL Account",

			COL_PAY_CONTRA_ACC: "Default Pay Contra Acc",
			COL_TRUNCATE_CENT_GL_ACC: "Cent Truncate GL Acc",
			COL_DISC_ACC: "Default Local Other Charges/Disc Acc",
			COL_REFUND_GL_ACC: "Refund GL Acc",
			COL_CURRENCY: "Currency",
			COL_MSM: "Main Salesman",
			COL_PURCHAE_GL_ACC: "Purchase GL Account",
			COL_BENE_TYPE: "Bene Type",

			MSG: {
				COMPANY_SET: "{name} default GL account was set"
			}
		},
		TT_COMPANY: {
			LISTING_TITLE: "TT Company Listing",
			CREATE_TITLE: "Create New TT Company",
			DETAIL_TITLE: "TT Company Detail",
			EDIT_TITLE: "Edit TT Company Detail",

			LBL_CODE: "Code",
			LBL_NAME: "Name",
			LBL_CREATED_BY_USERNAME: "created by",
			LBL_LAST_UPDATED_BY_USERNAME: "last updated by",

			MODULE_NAME: "TT Company",

			COL_CODE: "Company Code",
			COL_NAME: "Company Name",
			COL_TYPE: "Type"
		},
		TT_CUSTOMER_SPECIAL_RATE: {
			LISTING_TITLE: "TT Customer Special Rate Listing",
			CREATE_TITLE: "Create New TT Customer Special Rate",
			DETAIL_TITLE: "TT Customer Special Rate Detail",
			EDIT_TITLE: "Edit TT Customer Special Rate Detail",
			HISTORY_LOG_TITLE: "TT Customer Special Rate History",

			LBL_CURRENCY: "Currency",
			LBL_TT_CURRENCY: "TT Currency",
			LBL_TT_GROUP: "TT Group",
			LBL_CURRENCY_DIFFERENT: "Daily Rate & Group Rate Diff (%)",
			LBL_TT_RATE: "Daily Rate",
			LBL_TT_SPECIAL_RATE: "Special Rate",

			LBL_CREATED_BY_USERNAME: "created by",
			LBL_LAST_UPDATED_BY_USERNAME: "last updated by",
			LBL_CURRENCY_SPECIAL_RATE: "Currencies Special Rates and New Rates",
			LBL_ADD_CURRENCY_SPECIAL_RATE: "Add new rate",
			LBL_ADJUSTMENT_RATE: "Adjustment Rate",
			LBL_NEW_DAILY_RATE: "New TT Group Rate",
			LBL_FINAL_RATE: "Final Rate",
			LBL_BOOKED_AMOUNT: "Booked Amount",
			LBL_FINAL_RATE_FORMULA: "Final Rate = New Daily Rate (+/-) Special Rate (+/-) Adjustment Rate",
			LBL_HISTORY_LOG_TITLE: '<h6 class="font-weight-bolder">History Log</h6><div ><span class="text-danger">Only Show Last 7 record. </span> <a href="{path}">View More</a></div>',
			LBL_BOOKED_AMOUNT_CANNOT_ZERO: "Booked Amount must greater than 0",
			LBL_FINAL_RATE_CANNOT_LESS_ZERO: "Final Rate cannot be less than 0",
			LBL_TT_CURRENCY_SETTING_NOT_EXIST: "Unable to find relevant tt currency setting for tt group and special rate",
			LBL_TT_CURRENCY_DAILY_RATE_NOT_EXIST: "Unable to find relevant tt group daily exchange rate",
			LBL_TT_CURRENCY_GROUP_NOT_EXIST: "Unable to find relevant customer tt currency group",
			LBL_CUSTOMER_CURRENCY_SETTING_NOT_EXIST: "Unable to find relevant customer tt currency settings",
			MODULE_NAME: "TT Customer Special Rate",

			COL_CUSTOMER_MARKING: "Customer Marking",
			COL_CURRENCY_CODE: "Currency Code",
			COL_TT_CURRENCY_CODE: "TT Currency Code",
			COL_CURRENT_TT_GROUP: "Current TT Group",
			COL_CURRENT_DAILY_RATE: "Current Daily Rate",
			COL_SPECIAL_RATE_CONVERSION_FACTOR: "(+/-)",
			COL_SPECIAL_RATE: "Special Rate",
			COL_NEW_TT_GROUP: "New TT Group",
			COL_NEW_DAILY_RATE: "New Daily Rate",
			COL_TT_GROUP: "TT Group",
			COL_TT_GROUP_RATE: "TT Group Rate",
			COL_ADJUSTMENT_RATE_CONVERSION_FACTOR: "(+/-)",
			COL_ADJUST_RATE: "Adjustment Rate",
			COL_FINAL_RATE: "Final Rate",
			COL_BOOKED_AMOUNT: "Booked Amount",
			COL_USE_BOOK_AMOUNT: "Used Book Amt",
			COL_LOCK: "Lock",
			COL_ACTION: "Action",
			COL_CREATED_ON: "Created On",
			MSG_CUSTOMER_MARKING_EXIST: "Customer Marking special rate has been set. ",
			MSG_CUSTOMER_MARKING_CURRENCY: "Customer Marking not found / Customer Marking's Currency not set",
			MSG_LIMIT_AMOUNT_EXCEEDED: "Booked Amount exceeded that balance tt limit. (Bal Limit: {balance})"
		},
		TT_SPREAD_RATE: {
			LISTING_TITLE: "TT Spread Rate Listing",
			CREATE_TITLE: "Create New TT Spread Rate",
			DETAIL_TITLE: "TT Spread Rate Detail",
			EDIT_TITLE: "Edit TT Spread Rate Detail",
			TITLE: "TT Spread Rate",

			LBL_CURRENCY: "Currency (To)",
			LBL_TT_CURRENCY: "TT Currency",
			LBL_TT_SPREAD_RATE: "Spread Rate",
			LBL_TT_GROUP: "TT Group",
			LBL_CURRENCY_DIFFERENT: "Daily Rate & Group Rate Diff (%)",
			LBL_TT_SPREAD_RATE_LIST: "Spread Rate List",

			LBL_CREATED_BY_USERNAME: "created by",
			LBL_LAST_UPDATED_BY_USERNAME: "last updated by",
			LBL_CURRENCY_SPECIAL_RATE: "Currencies Special Rates and New Rates",
			LBL_ADD_CURRENCY_SPECIAL_RATE: "Add currency special rate and new rate",
			LBL_ADJUSTMENT_RATE: "Adjustment Rate",
			LBL_NEW_DAILY_RATE: "New Daily Rate",
			LBL_FINAL_RATE: "Final Rate",
			LBL_BOOKED_AMOUNT: "Booked Amount",
			LBL_FINAL_RATE_FORMULA: "Final Rate = New Daily Rate (+/-) Special Rate (+/-) Adjustment Rate",
			LBL_HISTORY_LOG: "History Log",
			LBL_BOOKED_AMOUNT_CANNOT_ZERO: "Booked Amount must greater than 0",
			LBL_FINAL_RATE_CANNOT_LESS_ZERO: "Final Rate cannot be less than 0",
			LBL_TT_CURRENCY_SETTING_NOT_EXIST: "Unable to find customer marking tt group",
			LBL_TT_CURRENCY_DAILY_RATE_NOT_EXIST: "Unable to find relevant tt group daily exchange rate",

			MODULE_NAME: "TT Spread Rate",

			COL_CURRENCY_CODE: "Currency Code",
			COL_CURRENCY: "Currency (To)",
			COL_TT_CURRENCY: "TT Currency (From)",
			COL_ADJUSTMENT_INDICATOR: "(➕/➖)",
			COL_TT_GROUP: "TT Group",
			COL_SPREAD_RATE: "Spread Rate",
			COL_LOCK: "Lock",
			COL_ACTION: "Action",
			COL_CREATED_ON: "Created On",

			MSG_CUSTOMER_MARKING_EXIST: "Customer Marking has been created. "
		},
		INV_ON_HOLD: {
			CREATE_TITLE: "Create Invoice On Hold Status",
			DETAIL_TITLE: "Invoice On Hold Status Detail",
			EDIT_TITLE: "Edit Invoice On Hold Status",
			LISTING_TITLE: "Invoice On Hold Status Summary",
			MODULE_NAME: "Invoice On Hold",

			LBL_NAME: "On Hold Status Name",
			LBL_REMARK: "On Hold Status Remark",
			LBL_DEFAULT: "Default Selected",

			COL_NAME: "On Hold Status Name"
		},
		INV_REF: {
			CREATE_TITLE: "Create Invoice Ref ID",
			DETAIL_TITLE: "Invoice Ref ID Detail",
			EDIT_TITLE: "Edit Invoice Ref ID",
			LISTING_TITLE: "Invoice Ref ID Summary",
			MODULE_NAME: "Invoice Ref ID",

			LBL_REF_ID: "Ref Id",
			LBL_CID: "CID",

			COL_REF_ID: "Ref Id",
			COL_CONSIGNEE_NAME: "CID",

			MSG_BLOCK_EDIT_REF: "Ref Id used in Invoice, you are not allow to edit"
		},
		TT_REJECT_REASON: {
			TYPE: "Type",
			ERROR_DESC: "Name",
			ACTION: "Reason",
			MODULE_NAME: "Reject/Revert Reason",
			LISTING_TITLE: "Reject/Revert Reason List",
			CREATE_TITLE: "Create New Reject/Revery Reason",
			DETAIL_TITLE: "Reject/Revert Reason Detail",
			EDIT_TITLE: "Edit Reject/Revert Reason",
			LBL_DEPOSIT_STATUS: "Deposit/PN Payment Status",
			USE_FOR: "Use For"
		},
		LOCATION: {
			LISTING_TITLE: "Location List",
			DETAIL_TITLE: "Location Detail",
			EDIT_TITLE: "Edit Location",
			CREATE_TITLE: "Create New Location",
			MODULE_NAME: "Location",

			COL_LOCATION: "Location",
			COL_COUNTRY: "Country",
			COL_STATE: "State",
			COL_CITY: "City",
			COL_POSTCODE: "Postcode"
		},
		SPECIAL_PRODUCT_REMARK: {
			LISTING_TITLE: "Special Product Remark Listing",
			CREATE_TITLE: "Create New Special Product Remark",
			DETAIL_TITLE: "Special Product Remark Detail",
			EDIT_TITLE: "Edit Special Product Remark Detail",

			LBL_NAME: "Special Product Remark Title",
			LBL_DESCRIPTION: "Special Product Remark Description",

			MODULE_NAME: "Special Product Remark",

			COL_NAME: "Remark Title"
		}
	},
	/** END Master Data */
	SALESMAN_MANAGEMENT: {
		MAIN_SALESMAN: {
			CREATE_TITLE: "Create New Main Salesman",
			MODULE_NAME: "Main Salesman",
			EDIT_TITLE: "Edit Main Salesman",
			DETAIL_TITLE: "Main Salesman Detail",
			TAX_REBATE: "Tax Rebate",
			LISTING_TITLE: "Main Salesman Listing",
			STATUS: "Status",

			LBL_MSM_CODE: "Main Salesman Code",
			LBL_MSM_NAME: "Main Salesman Name",
			SHIPEMENT_TYPE: "Shipment Type",
			TAX_REBATE: "Tax Rebate",

			LBL_COMPANY: "Company",
			LBL_PARENT_MAIN_SALESMAN: "Parent Main Salesman",

			LBL_EMAIL: "Email",
			LBL_JOINDATE: "Join Date",

			LBL_CONTACT_NUMBER: "Office Number",
			LBL_MOBILE_NUMBER: "Mobile Number",
			LBL_WECHATID: "Wechat ID",

			LBL_SALESMAN_COSTING_GROUP: "Salesman Costing Group",

			LBL_LOGIN_NAME: "Username",
			LBL_PASSWORD: "Password",
			LBL_CONFIRM_PASSWORD: "Confirm Password",

			COL_MSM_CODE: "Main Salesman Code",
			COL_MSM_NAME: "Main Salesman Name",
			COL_JOIN_DATE: "Join Date",
			COL_WECHATID: "Wechat Id",
			COL_CONTACT_NO: "Office No.",
			COL_MOBILE_NO: "Mobile No.",
			COL_SHIPMENT_TYPE: "Shipment Type",
			BTN_GROUP_LIST: "Group List",

			COST_SETUP: {
				TITLE: "Main Salesman & Salesman Group Cost",

				LBL_MSM: "Main Salesman",
				LBL_SHIPMENT_TYPE: "Shipment Type",
				LBL_MAIN_ROUTE: "Main Route",
				LBL_SHIPMENT_MODE: "Shipment Mode",

				LBL_MAIN_SALESMAN_COST: "Main Salesman Cost",
				LBL_SALESMAN_GROUP_COST: "Salesman Group Cost",
				LBL_COST: "Cost",

				PH_MAIN_SALESMAN_COST: "Markup or %",
				COL_SALESMAN_GROUP: "Salesman Group",
				COL_MARKUP: "Markup"
			},
			MSG: {
				EMAIL_DUPLICATE: "Email Not Available.",
				USERNAME_DUPLICATE: "Username Not Available"
			}
		},
		SALESMAN: {
			LISTING_TITLE: "Salesman Listing",
			DETAIL_TITLE: "Salesman Detail",
			EDIT_TITLE: "Edit Salesman Detail",
			CREATE_TITLE: "Create New Salesman",
			MODULE_NAME: "Salesman",

			LBL_SM_CODE: "Salesman Code",
			LBL_SM_NAME: "Salesman Name",

			COL_SM_CODE: "Salesman Code",
			COL_SM_NAME: "Salesman Name",
			COL_MSM_NAME: "Main Salesman Name",
			COL_SM_QR: "Salesman Referral Link",

			LBL_LOGIN_NAME: "Username",
			LBL_PASSWORD: "Password",
			LBL_CONFIRM_PASSWORD: "Confirm Password",
			LBL_EMAIL: "Email",
			LBL_JOINDATE: "Join Date",

			LBL_CONTACT_NO_CALLING_CODE: "Office Number Calling Code",
			LBL_CONTACT_NUMBER: "Office Number",
			LBL_MOBILE_NUMBER: "Mobile Number",
			LBL_MOBILE_NO_CALLING_CODE: "Mobile Number Calling Code",
			LBL_WECHATID: "Wechat ID",
			LBL_MAINSALESMAN: "Main Salesman",
			LBL_SALESMAN_COST: "Salesman Cost",

			COL_MAIN_SALESMAN: "Main Salesman",
			COL_SM_COST_GROUP: "Salesman Group Cost",
			COL_SM_MIN_COST_GROUP: "Salesman Group Min Cost",

			MSG: {
				EMAIL_DUPLICATE: "Email Not Available.",
				USERNAME_DUPLICATE: "Username Not Available",
				BLOCK_REMOVE_MAINSALESMAN: "Salesman cost bind with {main_salesman}, You are not allow change main salesman.",
				SALESMAN_COST_ROUTE_DUPLICATE: "Salesman Cost route duplicate. You only allow select 1 only from selection",
				NO_COST_SELECTED: "Please select at least a cost.",
				MSG_ROUTE_DUPLICATION: "Salesman Cost Route exists. Please select from exist listing",
				MSG_COST_SET: "Row: {idx} salesman cost was set. Please select from exist listing"
			},

			COST_HEADER: {
				COL_ROUTE: "Route",
				COL_SHIPMENT_TYPE: "Shipt. Type",
				COL_SHIPMENT_MODE: "Shipt. Mode",
				COL_SALESMAN_MIN_GROUP: "Min Cost Group",
				COL_SALESMAN_GROUP: "Cost Group"
			},

			BTN_EXISTING: "Add From Exist",
			BTN_ADD_NEW_COST: "Add New Cost",

			COST_TITLE: "Existing Cost"
		}
	},
	COSTING: {
		LBL_DELIVERY_AGENT: "Delivery Agent",
		LBL_COURIER_COMPANY: "Courier Company",
		LBL_CHARGES_SETUP: "Setup Charges",
		LBL_LEAD_TIME: "Lead Time (Days)",
		LBL_LEAD_TIME_START_DAY: "Start Day",
		LBL_LEAD_TIME_END_DAY: "End Day",
		QUOTATION_LOG_TITLE: "Inquiry Quotation (S)",
		MSG_CONFIRMATION_REVISE_QUOTATION: "Found {record} quotation, are you updated all quotation(s) with new charges?",
		RANGE_CHARGES_TRANSIT_OPTIONS: {
			DIRECT: "Direct",
			PORT_KLANG: "Port Klang"
		},
		QUOTATION_SUMMARY: {
			COL_INQUIRY_NO: "Inquiry No.",
			COL_INQUIRY_ON: "Inquiry On",
			COL_QUOTATION_NO: "Quote. No.",
			COL_EFFECTIVE_ON: "Effective On"
		},

		LBL_RANGE: "Range",
		LBL_BASE_CHARGES: "Base Chrg.",
		LBL_CAT_TYPE_CHARGES: "Cat Type Chrg.",
		LBL_MAIN_SALESMAN_CHARGES: "Msm Chrg.",
		LBL_SM_CHARGES: "Chg.",
		LBL_QUOTE_PRICE: "Quote Chrg.",
		LBL_ADDITIONAL_CHARGES: "Add. Chrg",
		LBL_EXCEPTIONAL_CHARGES: "Exc. Chrg",
		LBL_EXCEPTIONAL_CHARGES_TYPE: "Exc.Chrg Type",
		LBL_MIN_CHARGES: "Min Chrg",
		LBL_CHARGES: "Chrg.",
		LBL_ROUTE: "Route",
		LBL_MIN_MARKUP: "Min Chrg Markup",
		LBL_MARKUP: "Chrg Markup",
		LBL_WITH_INSURANCE: "With Insurance",
		LBL_WITH_OUTSKIRT: "With Outskirt",
		CUSTOMER_COST: {
			LISTING_TITLE: "Customer Cost Summary",
			MODULE_NAME: "Customer Cost",
			CREATE_CUSTOMER_COST_TITLE: "Create Customer Cost",
			VIEW_CUSTOMER_COST_TITLE: "Customer Cost Detail",
			EDIT_CUSTOMER_COST_TITLE: "Edit Customer Cost",
			VIEW_FULL_COST: "Customer Cost",

			LBL_DELIVERY_ZONE: "Delivery Zone",
			LBL_ROUTE_COST: "Route Cost",
			MSG_SALESMAN_COST_SETUP: "Salesman Markup No Set"
		},
		CUSTOMER_COSTING_GROUP: {
			TITLE: "Customer Cost Group Setup",
			MODULE_NAME: "Customer Cost Group Setup",

			COST_GROUP_TAB_TITLE: "Cost Markup",
			MIN_COST_GROUP_TAB_TITLE: "Min Cost Markup",

			COL_GROUP_CODE: "Group Code",
			COL_GROUP_NAME: "Group Name",
			COL_UOM: "Uom",
			COL_MARKUP: "Markup",
			COL_MARKUP_TYPE: "Markup Type"
		},
		/**Start Area GROUP */
		AREA_GROUP: {
			LISTING_TITLE: "Area Group Listing",
			DETAIL_TITLE: "Area Group Detail",
			EDIT_TITLE: "Edit Area Group Detail",
			CREATE_TITLE: "Create New Area Group",
			MODULE_NAME: "Area Group",

			LBL_AREA_GROUP_CODE: "Area Group Code",
			LBL_AREA_GROUP_NAME: "Area Group name",
			LBL_REQUIRED_IN_QUOTAION: "Required For Quotation",
			LBL_ADDRESS_REQUIRED: "Destination Address Required",
			LBL_AGENT_FORWARDER_ADDRESS_REQUIRED: "Agent/Forwarder Address Required",

			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_AREA: "Area",
			LBL_AREAS: "Area By Shipment",
			LBL_AREA_GROUP_DESC: "Area Group Description",
			LBL_DEFAULT: "Default",
			LBL_DEFAULT_QUOTATION: "Quotation",
			LBL_SPECIAL_AREA_REQUIRED: "Area Selection Required",
			LBL_VERIFY_ADDRESS_REQUIRED: "Verify Address Required",

			COL_AREA_GROUP_CODE: "Area Group Code",
			COL_AREA_GROUP_NAME: "Area Group Name",
			COL_COUNTRY: "Country",
			COL_AREAS: "Area(s)"
		},
		/**END Area Group */
		/**Start Main Route */
		ROUTE: {
			LISTING_TITLE: "Route Listing",
			CREATE_TITLE: "Create New Route",
			DETAIL_TITLE: "Route Detail",
			EDIT_TITLE: "Edit Route",
			MODULE_NAME: "Route",

			LBL_ROUTE_CODE: "Route Code",
			LBL_ROUTE_NAME: "Route Name",
			LBL_ORIGIN_COUNTRY: "Origin Country",
			LBL_ORIGIN_AREA: "Origin Area/Warehouse",
			LBL_DESTINATION_AREA: "Destination Area/Warehouse",
			LBL_DESTINATION_Country: "Destination Country",
			LBL_WAREHOUSE: "Warehouse",
			BTN_BASE_COST: "Base Cost",

			COL_MAIN_ROUTE_CODE: "Route Code",
			COL_MAIN_ROUTE_NAME: "Route Name",
			COL_FROM: "Origin",
			COL_TO: "Destination",
			COL_TRANSIT_ROUTE: "Transit Route",
			MSG_MAIN_ROUTE_DUPLICATE: "Route not allow duplicated. ",
			LBL_TRANSIT_ROUTE: "Transit Route",
			LBL_TRANSIT_DESCRITION: "Transit Description",
			MSG_ROUTE_DUPLICATION: "{code} is not available."
		},
		/**End Route */
		/**Start Exceptional Cost */
		EXCEPTIONAL_COST: {
			LISTING_TITLE: "Exceptional Cost Listing",
			FORM_TITLE: "Exceptional Cost",
			CREATE_TITLE: "Create New Exceptional Cost",
			EDIT_TITLE: "Edit Exceptional Cost",
			DETAIL_TITLE: "Exceptional Cost Detail",
			MODULE_NAME: "Exceptional Cost",
			BUTTON_COST_SETUP: "New Exceptional Cost",
			LBL_ROUTE: "Route",

			COL_DESCRIPTION: "Exceptional Cost Type",
			COL_GROUP: "Group",

			LBL_CHARGES_NAME: "Charges Name",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",

			LBL_SAVE_AS_DEFAULT: "Save As Default",
			LBL_SAVE_AS_DEFAULT_STRUCTURE: "Save As Default Structure",
			LBL_COSTING: "Cost Setup",
			LBL_CHARGES_TITLE: "Charges Title",
			LBL_CHARGES_TYPE: "Charges Type",
			LBL_CHARGES_QUOTE_TITLE: "Quote Title",
			LCL_CHARGES_CALC_TYPE: "Charges Calc Type",
			LCL_ADD_CHARGES_CALC_TYPE: "Add. Chrg Calc Type",
			COL_M3: "M3",
			COl_CONDITION: "Condition",
			COl_CONDITION_QUOTE_DESC: "Condition Quote Desc",
			COl_MIN_CHARGES: "Min Charges",
			COl_RATE_TYPE: "Type",
			COl_CHARGES: "Charges",
			COL_CHARGES_NAME: "Charges Name",
			COL_COST: "Cost",
			COl_ADDITIONAL_CHARGES: "Add On Service Charges",
			COL_CONDIION_START: "Start",
			COL_CONDIION_END: "End",
			COL_SHIPMENT_TYPE: "Shipt. Type",
			COL_SHIPMENT_MODE: "Shipt. Mode",
			COl_CUSTOM_CHARGES: "Special Charges",
			COL_ROUTE: "Route",
			PH_ENTER_LENGTH: "Length",
			PH_ENTER_WIDTH: "Width",
			PH_ENTER_HEIGHT: "Height",
			CONFIRMATION_MESSAGE_CHANGE_DEFAULT_STRUCTURE: "Do you want to using default costing structure? ",

			VALIDATION: {
				RANGE_START_SMALLER_THAN_END: "Condition Start must be smaller than Condition End"
			},

			ROUTE_NOTICE: "Leave it empty for apply all route",
			CUSTOM_CHARGES_DESC:
				"The condition has special handling charges base on item weight/dimension, tick on it. <br /> Please take note, the system will auto print 'Charges Refer to Person Incharge on quotation and will skip add into invoice even item weight/dimesion hit condition'.",
			ADDITIONAL_CHARGES_DESC: "The charges will add on top base charges."
		},
		/**End Exceptional Cost */
		/**Start Base Cost */
		BASE_COST: {
			MODULE_NAME: "Base Cost",
			TITLE: "Base Cost",
			LISTING_TITLE: "Base Cost Summary",
			EDIT_TITLE: "Edit Base Cost",
			VIEW_TITLE: "View Base Cost",

			SUMMARY_TABLE: {
				COL_SHIPMENT_TYPE: "Shipt. Type",
				COL_SHIPMENT_MODE: "Shipt. Mode",
				COL_ROUTE: "Route",
				COL_LEAD_TIME: "Lead Time",
				COL_FORMULA: "Formula",
				COL_WEIGHT_ROUNDING: "Weight Rounding",
				COL_WEIGHT_CONDITION: "Weight Condition",
				COL_RANGE: "Range",
				COL_COST: "Cost",
				COL_MIN_CHARGES: "Min Charges",
				COL_CHARGES: "Charges"
			},

			WEIGHT_SELECTION: {
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W"
			},
			WEIGHT_COND_SELECTION: {
				BETWEEN_ACTUAL_VOLUME_WEIGHT: "Between (A.W & V.W)",
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W",
				ACTUAL_WEIGHT_GREATER_VOLUME_WEIGHT: "A.W > V.W",
				VOLUME_WEIGHT_GREATER_ACTUAL_WEIGHT: "V.W > A.W"
			},
			WEIGHT_METHOD_SELECTION: {
				ACTUAL_WEIGHT: "Actual Weight",
				AVERAGE_WEIGHT: "Average",
				HIGHEST_WEIGHT: "Highest Weight",
				VOLUME_WEIGHT: "Volume Weight"
			},
			MIN_CHARGES_SELECTION: {
				BY_AMOUNT: "By Amount",
				BY_VALUE: "By Value"
			},

			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",

			LBL_PARCEL_LOST_GUARANTEED: "Parcel Lost Guarantee",
			LCL_CALC_TYPE: "Calc Type",
			LBL_LEAD_TIME: "Lead Time",
			LBL_LEAD_TIME_DAY: "Days",
			LBL_FORMULA: "Formula",
			LBL_WEIGHT_ROUNDING: "Weight Rounding",
			LBL_ROUTE: "Route",
			LBL_WEIGHT: "Weight",
			LBL_WEIGHT_RANGE: "Weight Range",
			LBL_WEIGHT_CONDITION: "Weight Condition",
			LBL_DIFF_RANGE: "Diff Range",
			LBL_WEIGHT_METHOD: "Weight Method",
			LBL_WEIGHT_CONDITION_NOTE: "Notes: A.W = Actual Weight, V.W = Volume Weight",
			LBL_STANDARD_COST: "Charges",
			LBL_COST_RANGE: "Range",
			LBL_COST_RANGE_START: "From",
			LBL_COST_RANGE_END: "To",
			LBL_COST_UOM: "UOM",
			LBL_COST_TYPE: "Type",
			LBL_EXIST_COST: "Exist",
			LBL_NEW_COST: "New",
			LBL_MIN_COST: "Min Charges",
			LBL_ADJUST_COST: "Adjust Cost",
			LBL_COST: "Charges",
			LBL_MIN_COST_UOM: "Min Charges UOM",
			LBL_MIN_UOM_VALUE: "Min m3/kg",
			LBL_ADDITIONAL_CHARGES: "Additional Charges",
			LBL_EXCEPTIONAL_COST: "Exceptional Cost",
			LBL_TRANSIT_CHARGES: "Transit Charges",
			LBL_TRANSMIT_DESC: "Transit Description",
			MSG_EXCEPTION_COST_EMPTY: "{cost_name} selection record empty. Please set first before set base cost.",
			EXCEPTIONAL_COST_DETAIL: "Exceptional Cost Detail",
			CONFIRMATION_MESSAGE_CHANGE_DEFAULT_STRUCTURE: "Do you want to using default costing structure? ",
			BTN_ADD_WEIGHT_ROUNDING: "Add Weight Condition",
			BTN_ADD_NEW_ROUTE: "Add New Route",
			BTN_COPY_ROUTE: "Copy Route",
			BTN_REMOVE_ROUTE: "Delete Route",
			BTN_BASE_COST_SETUP: "Base Cost Setup",
			LBL_WEIGHT_QUOTE_DESC: "Weight Quote Desc",
			ENTER_COST_RANGE_QUOTE_DESC: "Enter Range Quote Desc",
			BUTTON_COST_SETUP: "Cost Setup",
			BTN_ADD_NEW_COST: "Add New Cost",
			BTN_REMOVE_COST: "Delete Cost",
			CREATE_TITLE: "Create New Base Cost",
			BUTTON_NEW_COST: "New Base Cost",
			LBL_MIN_ADJUST_CHARGES: "Min Charges' adjust cost",
			LBL_EXISTING_MIN_CHARGES: "Existing Min Charges",
			LBL_ADJUST_CHARGES: "Adjust Charges",
			LBL_EXISTING_CHARGES: "Existing Charges",

			VALIDATION: {
				COST_RANGE_START_SMALLER_THAN_END: "Cost Range From must be smaller than Cost Range To",
				SHIPMENT_MODE_DUPLCATE: "Shipment Mode is not allow duplicate in same route and same shipment type. Please re-select or remove from costing list",
				DUPLICATE_ROUTE_SHIPMENT: "Route and Shipment is not allow duplicate. Please re-select.",
				ADJUST_COST_GREATER_THEN_EXISTS_COST: "{name} cannot greater than {name1}",
				NEW_MIN_CHARGES_MUST_BE_GREATER_THAN_ZERO: "New Min Charges must be greater than 0",
				NEW_CHARGES_MUST_BE_GREATER_THAN_ZERO: "New Charges must be greater than 0"
			}
		},
		/**End Base Cost */
		/**Start Transit Cost */
		TRANSIT_COST: {
			MODULE_NAME: "Transit Cost",
			TITLE: "Transit Cost",
			LISTING_TITLE: "Transit Cost Summary Listing",
			EDIT_TITLE: "Edit Transit Cost",
			VIEW_TITLE: "View Transit Cost",

			SUMMARY_TABLE: {
				COL_SHIPMENT_TYPE: "Shipt. Type",
				COL_SHIPMENT_MODE: "Shipt. Mode",
				COL_ROUTE: "Route",
				COL_LEAD_TIME: "Lead Time",
				COL_FORMULA: "Formula",
				COL_WEIGHT_ROUNDING: "Weight Rounding",
				COL_WEIGHT_CONDITION: "Weight Condition",
				COL_RANGE: "Range",
				COL_COST: "Cost",
				COL_MIN_CHARGES: "Min Charges",
				COL_CHARGES: "Charges"
			},

			WEIGHT_SELECTION: {
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W"
			},
			WEIGHT_COND_SELECTION: {
				BETWEEN_ACTUAL_VOLUME_WEIGHT: "Between (A.W & V.W)",
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W",
				ACTUAL_WEIGHT_GREATER_VOLUME_WEIGHT: "A.W > V.W",
				VOLUME_WEIGHT_GREATER_ACTUAL_WEIGHT: "V.W > A.W"
			},
			WEIGHT_METHOD_SELECTION: {
				ACTUAL_WEIGHT: "Actual Weight",
				AVERAGE_WEIGHT: "Average",
				HIGHEST_WEIGHT: "Highest Weight"
			},
			MIN_CHARGES_SELECTION: {
				BY_AMOUNT: "By Amount",
				BY_VALUE: "By Value"
			},

			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",

			LBL_PARCEL_LOST_GUARANTEED: "Parcel Lost Guarantee",
			LBL_LEAD_TIME: "Lead Time",
			LBL_LEAD_TIME_START_DAY: "Start Day",
			LBL_LEAD_TIME_END_DAY: "End Day",
			LBL_LEAD_TIME_DAY: "Days",
			LBL_FORMULA: "Formula",
			LBL_WEIGHT_ROUNDING: "Weight Rounding",
			LBL_ROUTE: "Route",
			LBL_WEIGHT: "Weight",
			LBL_WEIGHT_RANGE: "Weight Range",
			LBL_WEIGHT_CONDITION: "Weight Condition",
			LBL_DIFF_RANGE: "Diff Range",
			LBL_WEIGHT_METHOD: "Weight Method",
			LBL_WEIGHT_CONDITION_NOTE: "Notes: A.W = Actual Weight, V.W = Volume Weight",
			LBL_STANDARD_COST: "Charges",
			LBL_COST_RANGE: "Range",
			LBL_COST_RANGE_START: "From",
			LBL_COST_RANGE_END: "To",
			LBL_COST_UOM: "UOM",
			LBL_COST_TYPE: "Type",
			LBL_EXIST_COST: "Exist",
			LBL_NEW_COST: "New",
			LBL_MIN_COST: "Min Charges",
			LBL_ADJUST_COST: "Adjust Cost",
			LBL_COST: "Charges",
			LBL_MIN_UOM_VALUE: "Min m3/kg",
			LBL_ADDITIONAL_CHARGES: "Additional Charges",
			LBL_EXCEPTIONAL_COST: "Exceptional Cost",
			LBL_TRANSIT_CHARGES: "Transit Charges",
			LBL_TRANSMIT_DESC: "Transit Description",
			MSG_EXCEPTION_COST_EMPTY: "{cost_name} selection record empty. Please set first before set base cost.",
			EXCEPTIONAL_COST_DETAIL: "Exceptional Cost Detail",
			CONFIRMATION_MESSAGE_CHANGE_DEFAULT_STRUCTURE: "Do you want to using default costing structure? ",
			BTN_ADD_WEIGHT_ROUNDING: "Add Weight Condition",
			BTN_ADD_NEW_ROUTE: "Add New Route",
			BTN_COPY_ROUTE: "Copy Route",
			BTN_REMOVE_ROUTE: "Delete Route",
			LBL_WEIGHT_QUOTE_DESC: "Weight Quote Desc",
			ENTER_COST_RANGE_QUOTE_DESC: "Enter Range Quote Desc",
			BUTTON_COST_SETUP: "Cost Setup",
			BTN_ADD_NEW_COST: "Add New Cost",
			BTN_REMOVE_COST: "Delete Cost",
			CREATE_TITLE: "Create New Transit Cost",
			BUTTON_NEW_COST: "New Transit Cost",

			VALIDATION: {
				COST_RANGE_START_SMALLER_THAN_END: "Cost Range From must be smaller than Cost Range To",
				SHIPMENT_MODE_DUPLCATE: "Shipment Mode is not allow duplicate in same route and same shipment type. Please re-select or remove from costing list",
				DUPLICATE_ROUTE_SHIPMENT: "Route and Shipment is not allow duplicate. Please re-select."
			}
		},
		/**End Transit Cost */
		/**Start Makring Cost */
		MARKING_COST: {
			MODULE_NAME: "Marking Cost",
			TITLE: "Marking Cost",
			LISTING_TITLE: "Marking Cost Summary Listing",
			EDIT_TITLE: "Edit Marking Cost",
			VIEW_TITLE: "View Marking Cost",
			CREATE_TITLE: "Create New Marking Cost",
			SUMMARY_TABLE: {
				COL_SHIPMENT_TYPE: "Shipt. Type",
				COL_SHIPMENT_MODE: "Shipt. Mode",
				COL_ROUTE: "Route",
				COL_LEAD_TIME: "Lead Time",
				COL_FORMULA: "Formula",
				COL_WEIGHT_ROUNDING: "Weight Rounding",
				COL_WEIGHT_CONDITION: "Weight Condition",
				COL_RANGE: "Range",
				COL_COST: "Cost",
				COL_MIN_CHARGES: "Min Charges",
				COL_CHARGES: "Charges",
				COL_MARKING: "Marking",
				COL_MSM_CODE: "Main Salesman",
				COL_SM_CODE: "Salesman"
			},

			WEIGHT_SELECTION: {
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W"
			},
			WEIGHT_COND_SELECTION: {
				BETWEEN_ACTUAL_VOLUME_WEIGHT: "Between (A.W & V.W)",
				ACTUAL_WEIGHT: "A.W",
				VOLUME_WEIGHT: "V.W",
				ACTUAL_WEIGHT_GREATER_VOLUME_WEIGHT: "A.W > V.W",
				VOLUME_WEIGHT_GREATER_ACTUAL_WEIGHT: "V.W > A.W"
			},
			WEIGHT_METHOD_SELECTION: {
				ACTUAL_WEIGHT: "Actual Weight",
				AVERAGE_WEIGHT: "Average",
				HIGHEST_WEIGHT: "Highest Weight"
			},
			MIN_CHARGES_SELECTION: {
				BY_AMOUNT: "By Amount",
				BY_VALUE: "By Value"
			},

			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",

			LBL_PARCEL_LOST_GUARANTEED: "Parcel Lost Guarantee",
			LBL_LEAD_TIME: "Lead Time",
			LBL_LEAD_TIME_START_DAY: "Start Day",
			LBL_LEAD_TIME_END_DAY: "End Day",
			LBL_LEAD_TIME_DAY: "Days",
			LBL_FORMULA: "Formula",
			LBL_WEIGHT_ROUNDING: "Weight Rounding",
			LBL_ROUTE: "Route",
			LBL_WEIGHT: "Weight",
			LBL_WEIGHT_RANGE: "Weight Range",
			LBL_WEIGHT_CONDITION: "Weight Condition",
			LBL_DIFF_RANGE: "Diff Range",
			LBL_WEIGHT_METHOD: "Weight Method",
			LBL_WEIGHT_CONDITION_NOTE: "Notes: A.W = Actual Weight, V.W = Volume Weight",
			LBL_STANDARD_COST: "Charges",
			LBL_COST_RANGE: "Range",
			LBL_COST_RANGE_START: "From",
			LBL_COST_RANGE_END: "To",
			LBL_COST_UOM: "UOM",
			LBL_COST_TYPE: "Type",
			LBL_EXIST_COST: "Exist",
			LBL_NEW_COST: "New",
			LBL_MIN_COST: "Min Charges",
			LBL_ADJUST_COST: "Adjust Cost",
			LBL_COST: "Charges",
			LBL_MIN_COST_UOM: "Min Charges UOM",
			LBL_MIN_UOM_VALUE: "Min m3/kg",
			LBL_ADDITIONAL_CHARGES: "Additional Charges",
			LBL_EXCEPTIONAL_COST: "Exceptional Cost",
			LBL_TRANSIT_CHARGES: "Transit Charges",
			LBL_TRANSMIT_DESC: "Transit Description",
			MSG_EXCEPTION_COST_EMPTY: "{cost_name} selection record empty. Please set first before set base cost.",
			EXCEPTIONAL_COST_DETAIL: "Exceptional Cost Detail",
			CONFIRMATION_MESSAGE_CHANGE_DEFAULT_STRUCTURE: "Do you want to using default costing structure? ",
			BTN_ADD_WEIGHT_ROUNDING: "Add Weight Condition",
			BTN_ADD_NEW_ROUTE: "Add New Route",
			BTN_COPY_ROUTE: "Copy Route",
			BTN_REMOVE_ROUTE: "Delete Route",
			BTN_BASE_COST_SETUP: "Base Cost Setup",
			LBL_WEIGHT_QUOTE_DESC: "Weight Quote Desc",
			ENTER_COST_RANGE_QUOTE_DESC: "Enter Range Quote Desc",
			BUTTON_COST_SETUP: "Cost Setup",
			BTN_ADD_NEW_COST: "Add New Cost",
			BTN_REMOVE_COST: "Delete Cost",

			BUTTON_NEW_COST: "New Marking Cost",
			LBL_MARKING: "Marking",

			VALIDATION: {
				COST_RANGE_START_SMALLER_THAN_END: "Cost Range From must be smaller than Cost Range To",
				SHIPMENT_MODE_DUPLCATE: "Shipment Mode is not allow duplicate in same route and same shipment type. Please re-select or remove from costing list",
				DUPLICATE_ROUTE_SHIPMENT: "Route and Shipment is not allow duplicate. Please re-select."
			}
		},
		/**End Makring Cost */
		/** Start Main Salesman Cost */
		MAIN_SALESMAN_COST: {
			LISTING_TITLE: "Main Salesman Cost Summary Listing",
			BUTTON_COST_SETUP: "New Cost Setup",
			CREATE_TILE: "Create New Main Salesman Cost ",
			MAIN_SALESMAN_DETAIL: "Main Salesman Cost",
			MODULE_NAME: "Main Salesman Cost",

			LBL_MSM: "Main Salesman",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_ROUTE: "Route",
			LBL_COST: "Charges Markup",

			COL_MSM: "Main Salesman",
			COL_SHIPMENT_TYPE: "Shipt. Type",
			COL_SHIPMENT_MODE: "Shipt. Mode",
			COL_ROUTE: "Route",
			COL_MIN_CHARGES: "Min Markup",
			COL_CHARGES: "Markup",
			COL_MIN_CHARGES_UOM: "Min Charges UOM",

			VALIDATION: {
				DUPLICATE_ENTRY: "{route} duplicate. Please re-select."
			},

			MSG: {
				SUCESS_SAVE: "Main Salesman cost has been save"
			}
		},
		/** End Main Salesman Cost */
		/** Start Salesman Cost Group */
		SALESMAN_COST_GROUP: {
			TITLE: "Salesman Cost Group Setup",
			MODULE_NAME: "Salesman Cost Group",
			LISTING_TITLE: "Salesman Cost Group Listing",
			BUTTON_COST_SETUP: "Cost Setup",
			BUTTON_COST_SETUP_BY_ROUTE_SHIPMENT: "Single Route & Shipment Cost Setup",
			SALESMAN_COST_GROUP_SETUP: "Salesman Cost Group Setup",

			COST_GROUP_TAB_TITLE: "Cost Markup",
			MIN_COST_GROUP_TAB_TITLE: "Min Cost Markup",

			LBL_MAIN_ROUTE: "Main Route",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_COST: "Cost Setup",
			LBL_SALESMAN_GROUP: "Salesman Group",
			LBL_MIN_COST_MARKUP: "Min Cost Markup",
			LBL_COST_MARKUP: "Markup",
			LBL_MARKUP_TYPE: "Markup Type",

			SUMMARY_HEADER: {
				COL_MAIN_ROUTE: "Main Route",
				COL_SHIPMENT_TYPE: "Shipment Type",
				COL_SHIPMENT_MODE: "Shipment Mode",
				COL_SALESMAN_GROUP: "Salesman Group",
				COL_MIN_MARKUP: "Min Markup",
				COL_MARKUP: "Markup",
				COL_UOM: "Uom"
			}
		},
		/** End Salesman Cost Group */
		/** Start Assign Salesman Cost*/
		SALESMAN_COST: {
			LISTING_TITLE: "Salesman Cost Listing",
			SALESMAN_COST_SETUP_TITLE: "Salesman Cost Setup",
			SEARCH_SALESMAN_TITLE: "Filter List",
			CREATE_TILE: "New Salesman Cost",
			DETAIL_TITLE: "Salesman Cost",
			MODULE_NAME: "Salesman Cost",
			LBL_ROUTE: "Route",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_MAIN_SALESMAN: "Main Salesman",
			LBL_SALESMAN: "Salesman",
			LBL_SALESMAN_COST_GROUP: "Cost Group",
			LBL_SALESMAN_MIN_COST_GROUP: "Min Cost Group",
			LBL_APPLY_ALL: "Apply All",
			LBL_GROUP_SELECTION_NAME: "{name} (Min M/P: {min_markup}, M/P: {markup})",
			MARKUP_VALUE: "{currency}{cost}{uom}",
			LBL_MIN_MARKUP: "Min M/P",
			LBL_MARKUP: "M/P",

			PH_ENTER_SALESMAN_CODE_NAME: "Enter Salesman code or Name",

			SUMMARY_HEADER: {
				COL_SM: "Salesman",
				COL_ROUTE: "Route",
				COL_SHIPMENT_TYPE: "Shipment Type",
				COL_SHIPMENT_MODE: "Shipment Mode",
				COL_SALESMAN_GROUP: "Cost Group",
				COL_MIN_SALESMAN_GROUP: "Min Cost Group",
				COL_MIN_MARKUP: "Min Markup",
				COL_MARKUP: "Markup",
				COL_MSM: "Main Salesman"
			},

			BUTTON_COST_SETUP: "Cost Setup",
			BUTTON_COST_SETUP_ROUTE_MAIN_ROUTE: "Cost Setup By Route & Shipment",
			BTN_SEARCH_SALESMAN: "Search Salesman",

			MSG_CONFIRM_APPLY_ALL: "Other Salesman Cost Group Salesman Selection will remove. Are you sure to continue?",
			MSG_REPLACE_SM_LIST: "This {sm_group} group salesman selection will replace.",
			MSG_REPLACE_SM_LIST_N_APPLY_ALL: "This {sm_group} group salesman selection will replace and Other Salesman Cost Group Salesman Selection will remove.",
			MSG_DUPLICATE_SM: "This {sm_name} assigned already assigned a cost group.",
			MSG_SALESMAN_COST_GROUP_NOT_FOUND: "No Salesman Cost Group record has been found",
			MSG_SALESMAN_RECORD: "No Salesman Records have been found",
			VALIDATION: {
				DUPLICATE_ENTRY: "{route} duplicate . Please re-select.",
				DUPLICATE_SALESMAN: "{salesman} markup duplicate set. Please re-select"
			},
			MSG: {
				SUCCESS_SAVE: "Salesman Cost has been save"
			}
		},
		/** End Assgin Salesman Group */
		/** Start Category Type Cost */
		CATEGORY_TYPE_COST: {
			TITLE: "Category Type Cost Listing",
			BUTTON_COST_SETUP: "New Cost Setup",
			BUTTON_COST_SETUP_SHIPMENT: "Create New Category Type Cost By Shipment",
			CATEGORY_TYPE_SETUP_TITLE: "Category Type Cost Setup",
			MODULE_NAME: "Category Type Cost",

			LBL_CATEGORY_TYPE_CODE: "Category Type Code",
			LBL_CATEGORY_TYPE_NAME: "Category Type Name",
			LBL_COST: "Cost",
			LBL_SHIPMENT_TYPE: "Shipment Type",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_MIN_CHARGES: "Min Cost",
			LBL_CHARGES: "Charges",
			LBL_ROUTE: "Route",

			LBL_COST_SETUP: "Cost Setup",
			COL_CATEGORY_TYPE_CODE: "Category Type Code",
			COL_SHIPMENT_TYPE: "Shipment Type",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_MIN_CHARGES_TYPE: "Min Cost Type",
			COL_MIN_CHARGES: "Min Cost",
			COL_CHARGES: "Cost",
			COL_COND: "Condition",
			COL_MAIN_ROUTE: "Route",
			COL_SETUP_COND: "Setup Type",
			COL_COST: "Cost",

			PH_ENTER_RANGE_START: "Start Range",
			PH_ENTER_RANGE_END: "End Range",

			VALIDATION: {
				DUPLICATE_ENTRY: "{route} duplicate. Please re-select."
			},

			MSG: {
				CATEGORY_TYPE_NOT_EXISTS: "Category Type not exists",
				CATEGORY_TYPE_COST_SUCCESS_SAVE: "Category Type cost has been save",
				CATEGORY_SHIPMENT_DUPLICATE: "Category Type & Shipment Duplicate, Please re-select again."
			},

			COST_SETUP_TYPE: {
				BY_ROUTE: "By Route",
				BY_CONDITION: "By Condition",
				WITH_INSURANCE: "With Insurance",
				WITH_OUTSKIRT: "With Outskirt"
			},

			BTN_COPY_ROUTE: "Copy Route",
			BTN_ADD_NEW_ROUTE: "Add New Route",
			BTN_REMOVE_ROUTE: "Remove Route"
		},
		/** End Category Type Cost */
		/** Start Customer Exceptional Cost */
		CUSTOMER_EXCEPTIONAL_COST: {
			LISTING_TITLE: "Customer Exceptional Cost Summary",

			LBL_CHARGES_TYPE: "Charges Type",
			LBL_CHARGES: "Exceptional Charges",

			COL_MAIN_SALESMAN: "Main Salesman",
			COL_SALESMAN: "Salesman",
			COL_MARKING: "Marking",
			COL_ROUTE: "From/To",
			COL_SHIPMENT_TYPE: "Shipment Type",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_COST: "Cost",
			COL_QUOTATION: "Quotation No.",
			COL_CHARGES: "Charges",
			COL_MIN_CHARGES: "Min Charges",
			COL_MARKUP: "Markup",
			COL_CUSTOMER: "Customer",
			COL_RANGE: "Range",
			COL_WAREHOUESE: "Warehouse",
			COL_CHARGES_TYPE: "Charges Type",
			COL_CHARGES_NAME: "Charges Name"
		},
		/** End Customer Exceptional Cost */
		MIN_MARKUP_SELECTION: {
			BY_AMOUNT: "By Amount",
			BY_VALUE: "By Value(m3 or kg)"
		},
		/** Start In Range Charges */
		IN_RANGE_CHARGES: {
			CREATE_TITLE: "Create New In Range Charges",
			LISTING_TITLE: "In Range Charges Summary",
			DETAIL_TITLE: "In Range Charges Detail",
			EDIT_TITLE: "Edit In Range Charges",
			MODULE_NAME: "In Range Charges",
			HISTORY_TITLE: "In Range Charges History",
			OUT_RANGE_CHRG_TITLE: "Out Range Charges Quotation",
			OUT_RANGE_QUOTATION_LIST: "Applied Inquiry Quotation",
			OUT_RANGE_CHARGE_RESET_TITLE: "Take Notes: Selected Quotation which applied out of range charges will revise with new charges. ",

			LBL_DISTANCE: "KM",
			LBL_COND_UOM: "Condition Uom",
			LBL_MIN_M3_KG_UOM: "Min M3/KG Uom",
			LBL_CHARGES_UOM: "Min M3/KG Uom",
			LBL_REVERIFY: "Re-verify",
			LBL_IMPORT_FROM: "Import From",

			PH_CONDITION_FROM: "Enter Condition From",
			PH_CONDITION_To: "Enter Condition To",
			PH_DISTANCE_FROM: "Enter KM From",
			PH_DISTANCE_TO: "Enter KM To",

			COL_CONDITION: "Condition",
			COL_LOCATION: "Location",
			COL_WAREHOUSE: "Warehouse",
			COL_DISTANCE: "KM",
			COL_MIN_M3_KG: "Min M3/KG",
			COL_MIN_CHRG: "Min Chrg",
			COL_CHRG: "Chrg",
			COL_REVERIFY: "Re-verify",

			BTN_AMEND_RECORD: "Edit Exists Charges ",

			MSG_DUPLICATE_RECORD: "{location} in range charges has been set for {warehouse}.",
			MSG_CONFIRM_NEW_PRICE: "New Price have been changes. Update Quotation.",
			MSG_CONFIRM_OUT_RANGE_QUOTATION: "Those Selected Out Range Charges applied into quotation will be revise."
		},
		/** End In Range Charges */

		/** Start Out Of Range Charges */
		OUT_OF_RANGE_CHARGES: {
			CREATE_TITLE: "Create New Out Of Range Charges",
			LISTING_TITLE: "Out Of Range Charges Summary",
			DETAIL_TITLE: "Out Of Range Charges Detail",
			EDIT_TITLE: "Edit Out Of Range Charges",
			MODULE_NAME: "Out Of Range Charges",
			HISTORY_TITLE: "Out Of Range Charges History",

			LBL_DISTANCE: "KM",
			LBL_COND_UOM: "Condition Uom",
			LBL_MIN_M3_KG_UOM: "Min M3/KG Uom",
			LBL_CHARGES_UOM: "Min M3/KG Uom",
			LBL_TRANSIT_FROM: "Transit From",
			LBL_EFFECTIVE_DATE_FROM: "Effective Start Date",
			LBL_EFFECTIVE_DATE_TO: "Effective End Date",

			PH_CONDITION_FROM: "Enter Condition From",
			PH_CONDITION_To: "Enter Condition To",
			PH_DISTANCE_FROM: "Enter KM From",
			PH_DISTANCE_TO: "Enter KM To",

			COL_CONDITION: "Condition",
			COL_DISTANCE: "KM",
			COL_MIN_M3_KG: "Min M3/KG",
			COL_MIN_CHRG: "Min Chrg",
			COL_CHRG: "Chrg",
			COL_TRANSIT_FROM: "Transit From",
			COL_EFFECTIVE_DATE_FROM: "Effective Date From",
			COL_EFFECTIVE_DATE_TO: "Effetive Date To",
			COL_APPLY_QUOTE_COUNT: "Applied Quote",
			COL_EXPIRE: "Expired",
			COL_IMPORT_COUNTRY: "Import Country",

			BTN_AMEND_RECORD: "Edit Exists Charges ",

			MSG_DUPLICATE_RECORD: "{location} in range charges has been set for {warehouse}{import_country}."
		}
		/** End Out Of Range Charges */
	},
	SETTINGS: {
		GENERAL: {
			TITLE: "General Settings",

			LBL_EMAIL_FROM: "Sender Email",
			LBL_DEFAULT_SALESMAN: "Default Salesman",
			LBL_WEIGHT_DECIMAL: "Weight Decimal (KG)",
			LBL_M3_DECIMAL: "Weight Decimal (M3)",
			LBL_WEIGHT_ROUNDING: "Weight Rounding",
			LBL_OTHER_WAREHOUSE_COMPANY: "Other Warehouses' Company",
			LBL_OTHER_WAREHOUSE_MOVE_TYPE: "Other Warehouses' Movement Type",
			LBL_INV_SERVICE_CHARGES: "Invoice Service Charges",
			LBL_NOTICE_TYPE_DUE_DAYS: "Notice Type Due Days",
			LBL_INV_SPECIAL_CHARGES: "Invoice Special Charges",
			IS_MASTER: "Is Master?",
			LBL_INV_TRANSIT_CHARGES: "Invoice Transit Charges",
			LBL_AGENT: "Agent",

			MODULE_NAME: "General Settings",

			LBL_LOGIN_TITLE: "Login Title",
			LBL_SIDE_TITLE_IMAGE: "Side Title Image",
			LBL_META_TITLE: "Meta Title",

			TAB_TITLE: {
				GENERAL: "General",
				CUSTOMER: "Customer",
				TRANSACTION: "Cost & Transaction",
				COMPANY_GROUP_DATABASE: "Company Sub Group Database"
			},

			COMPANY_GROUP_DATABASE: {
				COL_DATABASE_ANNOTATION: "Database Annotation Value"
			},
			MSG: {
				DATABASE_ANNOTATION_INCORRECT_FORMAT: "Database annotation could not be read"
			}
		},
		QUOTATION_STATUS: {
			LISTING_TITLE: "Quotation Status",
			DETAIL_TITLE: "Quotation Status Detail",
			EDIT_TITLE: "Edit Quotation Status Detail",
			NOTIFICATION_SETTINGS_TITLE: "Notification Settings",
			MODULE_NAME: "Quotation Status",

			LBL_QUOTATION_NAME: "Quotation Status Name",
			LBL_QUOTATION_ADMIN_DESCRIPTION: "Admin Description",
			LBL_QUOTATION_CUSTOMER_DESCRIPTION: "Customer Description",
			LBL_INVALID_QUOTE_SELECTION: "Invalid Quotation Selection",

			COL_NAME: "Name",
			COL_DESCRIPTION: "Description",

			BTN_NOTIFICATION_SETTINGS: "Notification Settings",

			EMAIL_SETTINGS: {
				INTERNAL_EMAIL_TITLE: "Internal Notification",
				SALESMAN_EMAIL_TITLE: "Salesman Notification",
				INTERNAL_NOTIFIY_TO_TITLE: "Internal Notify To",
				LBL_EMAIL_CONTENT: "Email Content",
				LBL_EMAIL_SUBJECT: "Email Subject",
				CUSTOMER_EMAIL_TITLE: "Customer Notification",
				SELECTION_EMAIL: "Email",
				SELECTION_INBOX: "Notification",
				SELECTION_SMS: "Sms",
				SELECTION_MOBILE: "Mobile Notification",
				LBL_SUPPORT_TYPE: "Support Type",
				LBL_NOTIFICATION: "Notification",
				LBL_NOTIFICATION_TITLE: "Notification Title",

				LBL_NOTIFICATION_CONTENT: "Notification Content",
				COL_SENT_TO: "Email Address",
				MSG_SUCCESSFUL: "Successfull save",
				MSG_ERROR: "Failed To Save"
			}
		},
		/**Start Exceptional Cost */
		EXCEPTIONAL_COST_TYPE: {
			LISTING_TITLE: "Exceptional Cost Type Listing",
			DETAIL_TITLE: "Exceptional Cost Type Detail",
			EDIT_TITLE: "Exceptional Cost Type Edit",
			MODULE_NAME: "Exceptional Cost Type",
			LBL_EXCEPTIONAL_COST_NAME: "Exceptional Cost Name",
			LBL_EXCEPTIONAL_COST_DESC: "Exceptional Cost Description",
			LBL_EXCEPTIONAL_COST_GROUP: "Group",
			LBL_CATEGORY: "Invoice Service Charges",

			COL_NAME: "Exceptional Cost Name",
			COL_DESCRIPTION: "Exceptional Cost Description",
			COL_GROUP: "Group",
			COL_INV_DESC: "Invoice Description"
		},
		/**End Exceptional Cost */
		/**Stat Weight Rounding */
		WEIGHT_ROUNDING: {
			LISTING_TITLE: "Weight Rounding",
			CREATE_TITLE: "Create New Weight Rounding",
			DETAIL_TITLE: "Weight Rounding Detail",
			EDIT_TITLE: "Edit Weight Rounding",
			MODULE_NAME: "Weight Rounding",

			LBL_CODE: "Weight Rounding Code",
			LBL_NAME: "Weight Rounding Name",
			LBL_ROUNDING: "Rounding",

			LBL_RATE: "Rate",
			LBL_ROUND_STYLE: "Round Up/Down",

			COL_CODE: "Weight Rounding Code",
			COL_NAME: "Weight Rounding Name",
			COL_RATE: "Rate",
			COL_ROUND_STYLE: "Round Up/Down",

			ROUNDUP_SELECTION: "Round Up",
			ROUNDDOWN_SELECTION: "Round Down"
		},
		/**End Weight Rounding */
		/**Start Language */
		LANGUAGE: {
			LISTING_TITLE: "Language Listing",
			CREATE_TITLE: "Create New Language",
			EDIT_TITLE: "Edit Language",
			DETAIL_TITLE: "Language Detail",
			MODULE_NAME: "Language",
			LBL_CODE: "Language Code",
			LBL_NAME: "Language Name",
			LBL_LOCALE: "Locale",
			LBL_SUPPORT_AT: "Support At",
			LBL_SORT_ORDER: "Sort Order",
			LBL_IMAGE: "Image",

			COL_CODE: "Language Code",
			COL_NAME: "Language Name",
			COL_SUPPORT_AT: "Support At",
			COL_SORT_ORDER: "Sequence",
			COL_FLAG: "Flag",

			SUPPORT_AT_SELECTION: {
				ALL: "Admin Portal & Customer Portal",
				ADMIN: "Admin Portal Only",
				CUSTOMER: "Customer Portal Only"
			}
		},
		/**End Language */
		/**Start Epo Setting */
		EPO_SETTING: {
			LISTING_TITLE: "Epo Setting Listing",
			CREATE_TITLE: "Create New Epo Setting",
			EDIT_TITLE: "Edit Epo Setting",
			DETAIL_TITLE: "Epo Setting Detail",
			MODULE_NAME: "Epo Setting",
			LBL_CODE: "Epo Setting Code",
			LBL_NAME: "Epo Setting Name",
			LBL_EPO_FORMAT_TYPE: "Epo Format Type",
			LBL_NOTICE_TYPE: "Notice Type",
			LBL_WAREHOUSE: "Warehouse",
			LBL_EPO_FORMAT_TITLE: "Epo Format Title",
			LBL_MARKING_REMARK: "Marking Remark",
			LBL_IMPORTANT_NOTICE: "Important Notice",
			LBL_SPECIAL_REMARK: "Special Remark",
			LBL_FOOTER_REMARK: "Footer Remark",
			LBL_DESCRIPTION: "Description",
			LBL_LOCALE: "Locale",
			LBL_SUPPORT_AT: "Support At",
			LBL_SORT_ORDER: "Sort Order",
			LBL_IMAGE: "Image",
			LBL_SHIPMENT_FORMAT_TITLE: "Shipment Format Title",
			LBL_SHIPMENT_MODE: "Shipment Mode",
			LBL_DELIVERY_ZONE: "Delivery Zone",
			LBL_PARCEL_GUARANTEED_TERM: "Parcel Guarantee Terms",
			LBL_TAX_REBATE_TERMS: "Tax Rebate Terms",

			COL_NAME: "Epo Setting Name",
			COL_NOTICE_TYPE: "Notice Type",

			COL_EPO_FORMAT_TITLE_NOTICE_TYPE: "Notice Type",
			COL_EPO_FORMAT_TITLE_WAREHOUSE: "Warehouse",
			COL_EPO_FORMAT_TITLE: "Title",
			COL_SHIPMENT_MODE: "Shipment",
			COL_DELIVERY_ZONE: "Delivery Zone",

			SUPPORT_AT_SELECTION: {
				ALL: "Admin Portal & Customer Portal",
				ADMIN: "Admin Portal Only",
				CUSTOMER: "Customer Portal Only"
			}
		},
		/**End Epo Setting */
		// Start PN Format Setting
		PAYABLE_NOTE_FORMAT_SETTING: {
			LISTING_TITLE: "PN Format Setting Listing",
			CREATE_TITLE: "Create New PN Format Setting",
			EDIT_TITLE: "Edit payable Setting",
			DETAIL_TITLE: "PN Format Setting Detail",
			MODULE_NAME: "PN Format Setting",

			LBL_NAME: "PN Format Setting Name",
			LBL_PAYABLE_FORMAT_TYPE: "PN Format Type",
			LBL_DESCRIPTION: "PN Format Description",
			LBL_PAYABLE_FORMAT_TITLE: "PN Format Title & Remark",
			LBL_FOOTER_REMARK: "Footer Remark",

			COL_NAME: "PN Format Setting Name",
			COL_PAYABLE_FORMAT_TITLE: "Title",
			COL_PAYMENT_METHOD: "Payment Method",
			COL_REMARK: "PN Remark",

			COL_CAPTION: "CAPTION",
			COL_DUE_DAYS: "Print Due Day(s)",

			MSG_FORMAT_TITLE_DUE_DAY: "Take Notes: Due Day(s) will depend customer marking and print in front on Payment Method/PN Remark",

			MSG_NAME_EXIST: "'{name}' is used, please change another name.",
			MSG_DUPLICATE_SET: "Warehouse: {warehouse}, Notice Type: {notice_type} was existed"
		},
		// End PN Format Setting
		/**Start Agent Setting */
		AGENT: {
			MODULE_NAME: "Agent",
			INDEX_TITLE: "Agent Listing",
			DETAIL_TITLE: "Agent Detail",
			EDIT_TITLE: "Edit Agent",
			CREATE_TITLE: "Create new Agent",
			API_PATH: "Api Path",
			AGENT_CODE: "Agent Code",
			LBL_DB_NAME: "DB Name",
			LBL_DB_HOST: "DB HOST",
			LBL_DB_PORT: "DB PORT",
			LBL_DB_USERNAME: "DB Username",
			LBL_DB_PASSWORD: "DB Password"
		},
		/**End Agent Setting */
		/**Start Inv Status */
		INV_STATUS: {
			CREATE_TITLE: "Create Invoice Status",
			DETAIL_TITLE: "Invoice Status Detail",
			EDIT_TITLE: "Edit Invoice Status",
			LISTING_TITLE: "Invoice Status Summary",
			MODULE_NAME: "Invoice Status",

			LBL_ID: "Id",
			LBL_NAME: "Status Name",
			LBL_REMARK: "Status Remark",
			LBL_COLOUR: "Status Colour",
			LBL_DEFAULT: "Default Selected"
		},
		/**end Inv Status */
		/**Start EPO Status */
		EPO_STATUS: {
			MODULE_NAME: "EPO Status",
			LISTING_TITLE: "EPO Status",

			PAGE: {
				EDIT_TITLE: "Edit EPO Status Detail",
				DETAIL_TITLE: "EPO Status Detail"
			},

			//LBL
			LBL_EPO_STATUS_NAME: "EPO Status Name",
			LBL_ADMIN_DESCRIPTION: "Admin Desc",
			LBL_CUSTOMER_DESCRIPTION: "Customer Desc",

			//COL
			COL_NAME: "Status Name",
			COL_ADMIN_DESCRIPTION: "Admin Description",
			COL_CUSTOMER_DESCRIPTION: "Customer Description",
			COL_COLOR_INDICATOR: "Color Indicator",
			COL_SHOW_CUSTOMER: "Show Customer",
			COL_STATUS: "Status"
		},
		/**End EPO Status */
		/** Start Warehouse Settings */
		WAREHOUSE_SETTINGS: {
			LISTING_TITLE: "Warehouse Settings Summary",
			DETAIL_TITLE: "{warehouse_name} Warehouse Settings",
			GIMWORLD_SETTINGS: "GIM WORLD ORDER",
			GRN_FORMAT_SETTINGS: "GRN Format Settings",
			PN_FORMAT_SETTINGS: "PN Format Settings",
			MODULE_NAME: "Warehouse Settings",
			PREVIEW_GRN_FORMAT: "Preview GRN Format",

			LBL_WAREHOUSE: "Warehouse",
			LBL_CONTACT: "Contact Person",
			LBL_COMPANY: "Supported Company",
			LBL_COMPANY_SUBGRP: "Supported Company Sub Group",
			LBL_QUOTATION_DESC: "Quotation Desc",

			LBL_GOODS_RECEIPT_PAYABLE_STICKER_FEE_CURRENCY: "Goods Received/Payable Note Sticker Fee Currency",
			LBL_GOODS_RECEIPT_PAYABLE_STICKER_FEE: "Goods Received/Payable Note Sticker Fee",
			LBL_QUOTE_AREA: "Replace {area} with State & City",
			LBL_WAREHOUSE_NAME_PRINT_IN_EPO: "Warehouse Name Print on EPO",
			LBL_FORWARDER: "Forwarder",
			LBL_FORWARDER_COUNTRY_ID: "Forwarder Country ID",
			LBL_FORWARDER_STATE_ID: "Forwarder State ID",
			LBL_GENERAL_SETTING: "General Settings",

			LBL_GIMWORLD_ORDER_CATEGORY: "Default Category",
			LBL_SERVICE_CHARGES: "Default Service Charges",
			LBL_GIMWORLD_ORDER_CURRENCY: "Default Currency",
			LBL_GIMWORLD_ORDER_LOCATION: "Drop At",
			LBL_GIMWORLD_SUPPLIER: "Supplier",

			LBL_TT_CURRENCIES: "TT Currencies",
			LBL_DEFAULT_TT_CURRENCY: "Default TT Currency",
			LBL_BLOCK_CREATE_EPO: "Block Create EPO",

			GRN_FORMAT: {
				LBL_GRN_FORMAT: "GRN Format",
				LBL_COMPANY_HEADER_TITLE: "Company Title",
				LBL_GRN_TITLE: "GRN Title",
				LBL_COMPANY_ADDRESS_CONTACT: "Company Address & Contact No.",
				LBL_GRN_REMARK: "GRN Remark",
				LBL_GRN_FOOTER_REMARK: "GRN Footer Remark",

				LBL_PIC: "GRN Contact Persion",
				LBL_CALLING_CODE: "GRN Contact Calling Code",
				LBL_CONTACT: "GRN Contact No."
			},

			PN_FORMAT: {
				LBL_COMPANY_HEADER_TITLE: "Company Title",
				LBL_COMPANY_ADDRESS_CONTACT: "Company Address & Contact No.",

				LBL_PIC: "PN Contact Persion",
				LBL_CALLING_CODE: "PN Contact Calling Code",
				LBL_CONTACT: "PN Contact No."
			},

			COL_PIC: "Person In Charge",
			COL_CONTACT_NUMBER: "Contact Number",
			COL_WECHAT_ID: "WeChat ID",
			COL_IS_DEFAULT: "Default",
			COL_IS_SHOW: "Show",
			COL_REMARK: "Remark",
			COL_CONTACT_CALLING_CODE: "Contact Number Calling Code",

			LBL_EPO_WAREHOUSE_CONTACT_REMARK: "EPO Warehouse Contact Remark",

			COL_SUPPORTED_COM: "Supported Company",
			COL_STICKER_FEE: "Sticker Fee",

			TAB_TITLE: {
				GENERAL: "General",
				ORDER_RECEIVE: "Order Receive",
				QUOTATION_EPO: "Quotation & Epo"
			},

			MSG_COMPANY_CODE_NOT_SET: "Company Record not found"
		},
		/** END Warehouse Settings */
		/** START DEPOSIT STATUS */
		TT_DEPOSIT_STATUS: {
			LISTING_TITLE: "Deposit/PN Status List",
			DETAIL_TITLE: "Deposit/PN Status Detail",
			EDIT_TITLE: "Edit Deposit/PN Status",
			MODULE_NAME: "Deposit/PN Status",
			NAME: "Name",
			CODE: "Code",
			ADMIN_DESCRIPTION: "Admin Desc",
			CUSTOMER_DESCRIPTION: "Customer Desc",
			MSG_GET_DETAIL_ERROR: "Failed to get Deposit/PN Status",
			LBL_REASON_REQUIRED: "Reason Required"
		}
		/** END DEPOSIT STATUS */
	},
	EPO_MANAGEMENT: {
		EPO_FORMAT: {
			EPO_FORMAT_PRINT_TITLE: "Print EPO",
			PREVIEW_TITLE: "Preview Epo"
		},
		EPO_LISTING: {
			LISTING_TITLE: "EPO Listing",
			CREATE_TITLE: "Create New EPO",
			DETAIL_TITLE: "EPO Detail",
			EDIT_TITLE: "Edit EPO Detail",
			MODULE_NAME: "EPO",
			EPO_MODULE_NAME: "EPO",
			LBL_EPO_DATE: "EPO Date",

			PH_ENTER_EPO_DATE_FROM: "Enter EPO Date From",
			PH_ENTER_EPO_DATE_TO: "Enter EPO Date To",

			COL_EPO_NO: "EPO No",
			COL_MARKING: "Marking",
			COL_DELIVERY_ID: "Delivery Id",
			COL_COMPANY: "Company",
			COL_SHIPMENT_TYPE: "Shipment Type",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_EPO_DATE: "Epo Date",
			COL_STATUS: "Status",
			COL_SUPPLIER_NAME: "Supplier Name",
			COL_GRAND_TOTAL: "Grand Total",
			COL_TOTAL_DEPOSIT: "Total Deposit",
			COL_EPO_TYPE: "EPO Type",

			BTN_VIEW: "View",
			BTN_DELETE: "Delete",

			TEXT_SAVE_AS_DRAFT: "Save As Draft"
		},
		ORDER: {
			LISTING_TITLE: "EPO Listing",
			CREATE_ORDER_TITLE: "Create new Order",
			EPO_ORDER_TITLE: "EPO Order for {epo_no}",
			EPO_INFO_TITLE: "EPO Info",
			WAREHOUSE_DETAILS_TITLE: "Warehouse Details",
			WAREHOUSE_LOCATION: "Warehouse Location",
			FROM_WAREHOUSE_TITLE: "From Which Warehouse",
			WAY_TO_SHIP_TITLE: "How would you like to ship?",
			LOADING_PORT_TITLE: "Loading Port",
			FORWARDER_INFORMATION_TITLE: "Forwarder Details",
			DELIVERY_ADDRESS_TITLE: "Destination",
			DESTINATION_PORT_TITLE: "Destination Port",
			SUPPLIER_DETAILS_TITLE: "Supplier Info",
			PRODUCT_DETAILS_TITLE: "Courier / Product Details",
			SPECIAL_REQUEST_TITLE: "Special Request",
			CUSTOMER_NOTE_TITLE: "Customer Notes",
			LBL_EPO_NO: "EPO No",
			LBL_EPO_TYPE: "Send To warehouse using courier",
			LBL_PO_EXISTS: "PO Exists?",
			LBL_CURRENCY: "Currency",
			LBL_COMPANY_NAME: "Company Name",
			LBL_PIC: "Person In Charge",
			LBL_EMAIL_ADDRESS: "Email Address",
			LBL_CONTACT_NUMBER: "Mobile Contact No.",
			LBL_BILL_ADDRESS: "Bill Address",
			LBL_OFFICE_CONTACT: "Office Contact No.",
			LBL_OFFICE_FAX_CONTACT: "Office Fax No.",
			LBL_CUSTOMER: "Customer",
			LBL_MARKING: "Customer Marking",
			LBL_SUPPLIER: "Supplier",
			LBL_SUPPLIER_ORDER_NO: "Supplier Order No",
			LBL_SUPPLIER_BANK_NAME: "Supplier Bank Name",
			LBL_SUPPLIER_BANK_ACCOUNT_NAME: "Supplier Bank Account Name",
			LBL_SUPPLIER_BANK_ACCOUNT_NO: "Supplier Bank Account No.",
			LBL_SUPPLIER_BANK_BRANCH: "Supplier Bank Branch",
			LBL_EXISTS_PO_NO: "Exists PO No.",
			LBL_DEPOSIT: "Deposit",
			LBL_ESTIMATE_DATE_DELIVERY: "Estimate Delivery Date",
			LBL_WAREHOUSE_TYPE: "Warehouse Type",
			LBL_WAREHOUSE: "Warehouse",
			LBL_PRODUCT_REMARK: "Remarks",
			LBL_EPO_SUBMIT_DATE: "EPO Date",
			LBL_DATE_FROM: "Date From",
			LBL_DATE_TO: "Date To",
			LBL_EPO_STATUS: "EPO Status",
			LBL_PLG: "Parcel Lost Guarantee",

			LBL_SUB_TOTAL: "Sub Total",
			LBL_TOTAL_DISCOUNT: "Total Discount",
			LBL_GRAND_TOTAL_AMOUNT: "Grand Total",

			EPO_TYPE_SELECTION_ORDER: "Order",
			EPO_TYPE_SELECTION_COURIER: "Courier",

			WAREHOUSE_SELECTION_OTHERS: "Others",

			PH_PIC: "Name",
			PH_SPECIAL_REQUEST: "Write a Message...",
			PH_LOADING_PORT: "Enter Loading Port",
			PH_DESTINATION_PORT: "Enter Destination Port",

			BTN_NEW_SUPPLIER: "New Supplier",

			COL_EPO_NO: "EPO No",
			COL_CREATED_AT: "Created On",
			COL_SHIPMENT_TYPE: "Shipment Type",
			COL_SHIPMENT_MODE: "Shipment Mode",
			COL_SUPPLIER: "Supplier",
			COL_COUNTRY: "From/To Country",
			COL_STATUS: "Status",
			COL_EFFECTIVE_ON: "Effective On",
			COL_PRODUCT_NAME: "Commodity Desc",
			COL_REF_TYPE: "Container Type",
			COL_MARKING: "Customer Marking",

			PH_AUTO_DOC_NO: "[Auto]",

			SHIPPING: {
				LBL_TYPE: "Shipment Type",
				LBL_MODE: "Shipment Mode",
				LBL_MOVEMENT: "Movement Type",
				LBL_TERM: "Shipment Term"
			},

			SUPPLIER: {
				LBL_NAME: "Supplier/Forwarder Name",
				LBL_ADDRESS: "Address",
				LBL_POSTCODE: "Postal Code",
				LBL_CITY: "City",
				LBL_STATE: "State",
				LBL_CONTACT_NUMBER: "Supplier/Forwarder Contact Number",
				LBL_CONTACT_NUMBER_CALLING_CODE: "Supplier/Forwarder Calling Code"
			},

			DELIVERY: {
				LBL_ADDRESS_NAME: "Destination Address",
				LBL_ADDRESS: "Address",
				LBL_BUILDING_NAME: "Building Name",
				LBL_UPSTAIRS: "upstairs",
				LBL_COUNTRY: "Country",
				LBL_STATE: "State",
				LBL_CITY: "City",
				LBL_POSTCODE: "Postal Code",
				LBL_BRN: "Business Register Number"
			},

			PRODUCT: {
				COL_COURIER: "Courier",
				COL_ITEM_CODE: "Item Code",
				COL_PRODUCT_NAME: "Product Name",
				COL_BRAND: "Brand",
				COL_CATEGORY_GROUP: "Type",
				COL_CATEGORY_GROUP_TYPE: "Type Selection",
				COL_CATEGORY_GROUP_OTHERS: "Type Others",
				COL_QUANTITY: "Qty",
				COL_UOM: "Uom",
				COL_UNIT_PRICE: "Unit Price",
				COL_DISCOUNT_AMOUNT: "Discount Amount",
				COL_TOTAL_AMOUNT: "Total Amount",
				COL_REMARKS: "Remark",
				COL_PHOTO: "Photo",
				COL_INV_DESC: "Invoice Desc",

				LBL_CONSIGNMENT_NO: "Consignment No",

				BTN_COPY: "Copy",
				BTN_ADD: "Add",
				BTN_DELETE: "Delete",
				BTN_UPLOAD: "Upload",
				BTN_CHANGE_CATEGORY_GROUP: "Modify Category Group",
				BTN_ADD_CONSIGNMENT_NO: "Add Consignment No",
				BTN_UPLOAD_PHOTO: "Upload Product Image",
				BTN_SUPPORT_DOCUMENT: "Upload Support Document",

				EXAMPLE_ENTER_PRODUCT_CATEGORY: "For Example Commodity Desc: Shirt, Shoes, Toy",
				EXAMPLE_ENTER_BRAND: "For Example Enter Multiple Brand: Addidas, Nike",

				PH_SELECT_CATEGORY_GROUP: "Please Select Category Group",
				PH_GOODS_AMOUNT_FROM: "Enter Amount From",
				PH_GOODS_AMOUNT_TO: "Enter Amount To",
				PH_GOODS_AMOUNT_CURRENCY_SYMBOL: "Enter Currency Symbol",

				MSG_GOODS_AMOUNT_FROM_GREATER_TO: "Amount To must be greater than Amount From",
				MSG_GOODS_AMOUNT_FROM_REQUIRED: "Amount From Required",
			},

			CATEGORY_GROUP_TYPE: {
				MODAL_TITLE: "Category Type Details",

				LBL_CATEGORY_TYPE: "Goods Type",
				LBL_CATEGORY_GROUP_TYPE: "Selection of {category_group_name} product",
				LBL_OTHER_CATEGORY_GROUP_TYPE: "Discribe for {category_group_name} product",
				LBL_VALUABLE_OBJECTS: "Valuable objects",
				LBL_FRAGILE_ITEMS: "Fragile items",
				LBL_OTHER_SENSITIVE_ITEMS: "Other Sensitive Items",
				LBL_FAKE_BRANDS: "Fake Brands",

				PH_OTHER_CATEGORY_GROUP_TYPE: "Please input and discribe for {category_group_name} product",
				PH_FAKE_BRANDS: "Please input and discribe for Fake Brands"
			},
			UPLOAD: {
				PRODUCT_IMAGE: "Upload Product Image",
				SUPPORTED_DOCUMENT: "Upload Supported Document",
				LBL_UPLOAD_PHOTO: "Click to select the images.",
				LBL_UPLOAD_FILE: "Click to select the file.",
				SUPPORT_IMAGE_SIZE: "Supported size should be less than 1 MB!",
				MSG_SUPPORT_IMAGE_SIZE_FORMAT: "Supported size should be less than 1 MB and format .jpg, .jpeg, .png",
				SUPPORT_DOCUMENT_SIZE: "Supported size should be less than 10 MB!",
				MSG_SUPPORT_FILE_SIZE_FORMAT: "Supported size should be less than 10 MB! and format .xls, .xlsx, .pdf, .doc, .docs, .csv"
			},

			NEW_SUPPLIER: {
				MODAL_TITLE: "Create New Supplier"
			},

			BTN_SUBMIT: "Submit",
			BTN_RESET: "Reset",
			SUCCESS: "EPO Order has been generate and EPO No: {epo_no}.",
			FAILED: "Oopps ... ..., Sorry EPO Order Failed to Generate. Please try again.",
			MSG_FAILED_UPLOAD_IMGAE: "Sorry Product Image Failed to Upload",
			MSG_FAILED_UPLOAD_DOCUMENT: "Sorry Document Failed to Upload",
			MSG_NO_SUPPLIER: "Supplier not found. Please create a new supplier before create order",
			MSG_NO_ADDRESS: "Delivery Addresses not found. Please create a new delivery address before requesting for a quote",
			MSG_BILL_ADDRESS_NOT_FOUND: "Billing Address not found. Please set.",

			MSG_GRAND_TOTAL_AMOUNT_NEGATIVE: "Grand Total amount cannot be negative",
			MSG_PRODUCT_TOTAL_AMOUNT_NEGATIVE: "Product Total amount cannot be negative",
			MSG_PRODUCT_UNIT_PRICE_EMPTY: "Warning! your product unit price contain zero or empty value",
			MSG_SUCCESFUL_SAVE_AS_DRAFT: "Successful save as draft",
			MSG_BLOCK_EDIT: "This epo created by customer, you not allow to edit",
			MSG_DOCUMENT_TYPE: "No Document Type matched for current Marking. Please contact admin",
			MSG_DOCUMENT_NUMBER: "No Document Number matched for current Marking. Please contact admin",
			MSG_EPO_FORMAT_SETTINGS: "EPO Format Setting not matched. Please contact customer service",
			MSG_EPO_NO_NOT_EXISTS: "Po No. has been used.",
			MSG_NOT_QUOTATION_ACKNOWLEDGE: "No Quotation (s) for this {marking} marking acknowleged by customer. ",

			MSG_CONFIRMATION_SUBMIT: "Please ensure all details are correct before submitting.",
			MSG_PERSONAL_DATA_INCOMPLETE: "Personal Data Incomplete. Please complete first",
			RESET_FORM_CONFIRMATION: "All Information will be reset. Are you sure?",
			MSG_BILL_ADDRESS_INCOMPLETE: "Billing Address Incomplete",
			MSG_BLOCK_PURCHASE_PARCEL_LOST_GUARANTEE: "Found item(s) mix, you are not allow to purchase parcel lost guarantee. <br/> Please split to 2 order.",

			ERROR: {
				MSG_SUPPLIER_ADDRESS_EMPTY: "Supplier Address is empty",
				MSG_DELIVERY_ADDRESS_EMPTY: "Delivery Address is empty",
				MSG_BILLING_ADDRESS_EMPTY: "Billing Address is empty",
				CONTACT_PIC_REQUIRED: "CUstomer Name is empty",

				MSG_DOCUMENT_TYPE_EMPTY: "Missing Document Type Settings",
				MSG_DOCUMENT_NUMBER_EMPTY: "Missing Document Number Settings",
				MSG_EPO_SETTING_EMPTY: "Missing Epo Format Setting",
				DEPOSIT_AMT_GREATER_THAN_EPO: "Grand Total should be not less than TTed Amount"
			}
		}
	},
	TRANSACTION: {
		QUOTATION_MANAGEMENT: {
			CREATE_LCL_TITLE: "Create New LCL Quotation",
			PREVIEW_QUOTATION_TITLE: "Preview Quotation",
			VIEW_SPECIAL_PRODUCT_TITLE: "View Special Product",
			CREATE_FCL_TITLE: "Create New Fcl Quotation",
			LOCATION_CHARGES_TITLE: "Location Charges",
			HISTORY_TITLE: "{quotation_no} History",
			REVISE_QUOTATION_BATCH_TITLE: "Quotation Batch Revise",
			APPROVED_REVISED_QUOTATION_BATCH: "Batch Approve Revise Quotation",
			DRAFT_STATUS: "Draft",
			ADDITIONAL_REVISE_QUOTATION_FIELD: "Additional Revise Quotation Field",
			DETAIL_TITLE: "{module_name} Inquiry Quotation Detail ",
			EDIT_TITLE: "Edit {module_name} Inquiry Quotation",
			SEARCH_CRITERIA_TITLE: "Search Criteria",
			REVISE_COST_TITLE: "Revise Cost: ",
			BLOCK_REVISE_QUOTE_MSG: "Selected Record cost not updated. Please verify.",
			MSG_NOT_DATA_SELECTED: "No record has been selected",
			MSG_REMOVE_FILE: "The file will be removed.",
			SPECIAL_PRODUCT_PENDING_REVISE_TITLE: "Sp Prod. Pend. Revise ",
			OVER_CHARGES_PENDING_REVISE_TITLE: "Over Chrg. Pend. Revise ",
			TITLE_BATCH_REVISE_CONFIRMATION: "Revise Quotation (s) Confirmation",

			LBL_INQUIRY_NO: "Inquiry No.",
			LBL_QUOTATION_NO: "Quotation No.",
			LBL_REVESION: "Revision",
			LBL_QUOTATION_FORMAT: "Format",
			LBL_EXPIRED_ON: "Expired On: ",
			LBL_INQUIRY_DATE: "Inquiry Date",
			LBL_GENERAL_MARKING: "Marking",
			LBL_CREATION_DATE: "Creation Date",
			LBL_EXCLUDE_SAVE_DRAFT: "Excl. Draft",
			LBL_VERIFIED_SPECIAL_PRODUCT_ONLY: "Verified Sp. Prod. Only",
			LBL_VERIFIED_OVER_CHARGES_ONLY: "Verified Over Charges Only",

			LBL_GENERAL_DELIVERY_ID: "Delivery ID",
			LBL_GENERAL_QUOTATION_DATE: "Issued On",
			LBL_GENERAL_EFFECTIVE_DATE: "Effective On",
			LBL_GENERAL_VALIDITY_UNTIL: "Validity Until",
			LBL_GENERAL_RE_QUOTATION: "Re:Quotation",
			LBL_SHIPMENT_DESC_ROUTE: "Shipment Route",
			INCLUDE_DELIVERY_ID: "Print Delivery ID",
			LBL_BILLING_INFOMRATION: "Billing Information",
			LBL_DELIVERY_ID: "Delivery ID",
			LBL_CUSTOMER_REVERT_REJECT: "Cust. Cancel Reject",

			LBL_MOVEMENT_TYPE: "Movement Type",
			LBL_SHIPMENT_TERMS: "Shipment Terms",
			LBL_WAREHOUSE_TYPE: "Warehouse Type",
			LBL_COUNTRY_FROM_TO: "From {origin_country} To {dest_country}",
			LBL_ORIGIN_COUNTRY: "Origin Country",
			LBL_DEST_COUNTRY: "Destination Country",
			LBL_WAREHOUSE: "Origin Warehouse",
			LBL_CUSTOMER_EMAIL: "Email",
			LBL_CUSTOMER_COMPANY: "Company",
			LBL_CUSTOMER_CONTACT: "Mobile No.",
			LBL_CUSTOMER_OFFICE_CONTACT: "Office Contact No.",
			LBL_CUSTOMER_CONTACT_ALT: "Alt Mobile No.",
			LBL_CUSTOMER_BILL_ADDRESS: "Billing Address",
			LBL_LOADIND_PORT: "Loading Port",
			LBL_DESTINATION_PORT: "Destination Port",
			LBL_SUPPLIER_NAME: "Supplier Name",
			LBL_SUPPLIER_CONTACT: "Supplier Contact No",
			LBL_DESTINATION_ADDRESS: "Destination Address",
			LBL_DESTINATION_ZONE: "Delivery Zone",
			LBL_SHIPMENT_DESC: "Shipment Description",
			LBL_SHIPMENT_ROUTE: "Shipment Route",
			LBL_FORWARDER_NAME: "Forwarder Name",
			LBL_FORWARDER_PERSON_INCHARGE: "Forwarder Person In Charge",
			LBL_FORWARDER_CONTACT_NO: "Forwarder Contact No",
			LBL_PRODUCT_SPECIAL_REQUEST: "Special Request",
			LBL_TAX_TYPE: "Tax Type",
			LBL_TAX: "Tax",
			LBL_TAX_REMARK: "Tax Remark",
			LBL_COMBINE_CREDIT_LIMIT: "Combine Credit Limit",
			LBL_CREDIT_LIMIT: "Credit Limit",
			LBL_FINANCIAL_RATE: "Financial Rate (%)",
			LBL_SALESMAN_FINANCIAL_RATE: "Salesman Financial Rate (%)",
			LBL_PAYMENT_TERMS: "Payment Terms",
			LBL_NOTICE_TYPE: "Notice Type",
			LBL_CREDIT_TERMS: "Credit Terms",
			LBL_CREDIT_TERMS_DESC: "Credit Terms Desc",
			LBL_LOST_INDEMNIFY: "Indemnify of Lost Goods",
			LBL_FREIGHT_CHARGE: "Freight Charges",
			LBL_INDEMNIFY_REMARK: "Indemnify of Lost Goods Remark",
			LBL_REMARK: "Quotation Remark",
			LBL_REJECTED_REASON: "Rejected Reason",
			LBL_REVERTED_REASON: "Reverted Reason",
			LB_INVALID_REASON: "Invalid Reason",
			LBL_INCLUDE_DRAFT: "Include Draft",
			LBL_OUTSKIRT: "Outskirt",
			LBL_DESTINATION_SPECIAL_ZONE: "Special Zone",
			LBL_ADDRESS: "Full Address",
			LCL_ROUTE_DESC: "Transit Desc",
			LCL_DESTINATION_COUNTRY: "Destination Country",
			LBL_EXCLUDE_MARKING: "Exclude Marking",
			LBL_EXCLUDE_DELIVERY_ID: "Exclude Delivery Id",
			LCL_NO_MARKUP: "No Markup",
			LBL_FORWARDAR: "Forwarder/Agent Details",
			LBL_VERIFY_ADDRESS: "Verify Address",
			LBL_OVER_RANGE: "Over KM?",
			LBL_NEW_OVER_RANGE: "New Over KM?",
			LBL_SCS_REMARK: "SCS Remark",
			LBL_IN_RANGE: "Within Range?",
			LBL_DISTANCE: "KM",
			LBL_CHARGES_SUMMARY: "Charges Summary",
			LBL_INCLUDE_CONFIRMED_PRODUCT: "Incl Confirm Prod",
			LBL_INCLUDE_CONFIRMED_CHARGES: "Incl Confirm Charges",
			LBL_REJECT_REMARK: "Reject Remark",
			LBL_PENDING_REVISE: "Only Pending Revise",
			LBL_ONLY_EXPIRE_CUSTOM_CHARGES: "Only Expire Custom Charges",
			LBL_ONLY_SPECIAL_PROD_EXPIRED: "Only Expire Special Products",

			REVISE_QUOTATION_NOTE: "**NOTE: <br />Leave the field empty if you do not wish to perform update on the particular field.",
			MSG_CONFIRMATION_MULTIPLE_REVISE:
				"Please make sure the revised quotation data is correct. If any mistake has taken you are not allowed revert quotation by batch.<br/> Are you sure to continue?",
			MSG_UNSUPPORTED_SHIPMENT: "Below selected shipment & warehouse only support LCL only (which having cost setup) ",
			MSG_CONFIRMATION_SAVE_ADDRESS: "The {label} will replace eixtsing record. Are you sure to continue",
			MSG_CONFIRMATION_REPLACE_CHARGES: "Existing charges will be replace. Are you sure to continue",
			MSG_REJECT_REMARK: "**NOTE: This Reject Remark will show in customer portal",
			MSG_CONFIRM_SWITCH_OFF_CHARGES: "The charges has been set will removed.",
			MSG_REVERT_OVER_CHARGES: "The new charges will be removed.",
			MSG_CHARGES_EXPIRE_NOTE: "**NOTE: Those charges was expired, you are not allow to select.",
			MSG_CONFIRMATION_FCL_OTHER_LCL_MARKUP_ZERO: "Part of charges markup set with 0.<br />Are you sure to continue?",
			MSG_CONFIRMATION_REQUEST_REVISE: "Quotation No.: {quotation_no} will set Pending Revise.",
			MSG_CONFIRMATION_REGENERATE_SERVICE_QUOTE_DESC: "Existing Service Charges Quote Description will be replace.",
			MSG_CONFIRMATION_SKIP_REVIEW_REVISE: "Are you sure skip to review/revise below inquiry quotation? <br /> {inquiry_no}",
			MSG_PENDING_LOG_NOT_FOUND: "Pending Log not found",
			MSG_SUCESSFUL_SKIP_REVIEW_REVISE: "Below Inquiry Quotation (s) were skipped to review/revise. <br /> {inquiry_no}",
			MSG_DUPLICATE_PENDING_LOG: "Selected Inquiry Quotation having more than 1 record which same inquiry no. You are not allowed revise quotation. ",
			MSG_PENDING_LOG_INVALID_STATUS: "Selected Inquiry Quotation (s) must be issue/acknowledge/revised.",
			MSG_QUOTATION_NOT_FOUND: "Inquiry Quotation: {inquiry_no} not found.",
			MSG_QUOTATION_DETAIL_NOT_FOUND: "Inquiry Quotation: {inquiry_no} detail not found.",
			MSG_CONFIRMATION_RESET_SPECIAL_PRODUCT_OVER_CHARGES: "The System will ignore other record. Are you sure to continue?",
			MSG_CONFIRMATION_REMAIN_SPECIAL_PRODUCT: "The system will ignore latest revised special product, you are not allow to revert this action. Are you sure to continue?",
			MSG_CONFIRMATION_REMAIN_CUSTOM_CHARGES: "The system will ignore latest revised over charges, you are not allow to revert this action. Are you sure to continue?",
			MSG_SUCCESS_REMAIN_SPECIAL_PRODUCT: "Inquiry Quotation: {inquiry_no} special product remain with existing record",
			MSG_OVER_CHARGES: "{warehouse} over charges removed from master record.",
			MSG_BLOCK_SET_REMOVED_CHARGES: "Selected Inquiry Quotation part over charges was removed from master record. You are not allowed to do revise quotation",
			MSG_BLOCK_SET_NEW_CHARGES: "Selected Inquiry Quotation new charges was expired. You are not allow to do revise quotation",
			MSG_BLOCK_SET_NEW_SPECIAL_PRODUCT: "Selected Inquiry Quotation new special product record was expired. You are not allow to do revise quotation",

			CREDIT_TERMS: {
				COL_TYPE_CHARGE: "Type of Charge",
				COL_DAY: "Day",
				COL_OVERDUE: "Overdue (%)",
				COL_MODE: "Mode",
				COL_FROM: "From",
				COL_REMARK: "Remark",
				CHARGES_TYPE_SELECTION: {
					PURCHASE_AMOUNT: "PA (Purchase Amount)",
					SERVICE_CHARGE: "SC (Service Charge)",
					TAX: "TA (Tax)"
				},
				MODE_SELECTION: {
					MONTHLY: "Monthly",
					YEARLY: "Yearly"
				},
				FROM_SELECTION: {
					TT_DATE: "By TT Date",
					INVOICE_DATE: "By Invoice Date",
					SO_RECEIVE_DATE: "By So Received Date",
					ERC_DATE: "By ERC Date"
				},
				TITLE_ADD_CREDIT_DAY: "Add New Day Conversion"
			},

			PRODUCT_DETAIL: {
				LBL_BRAND_DESC: "Brand Desc",
				LBL_SPECIAL_MATERIAL: "Spec Material",
				LBL_PRODUCT_DESC: "Commodity Desc",
				LBL_DIMENSION: "Total M3",
				LBL_WEIGHT: "Total Weight ({measure})",
				LBL_TOTAL_CARTON: "Total Carton",
				LBL_GOODS_AMT: "Amount",
				LBL_REMARK: "Remarks",
				LBL_PRODUCT_QUOTE_DESC: "Category Quote Desc",
				LBL_CATEGORY: "Product Category",
				LBL_CATEGORY_LVL1: "Product Category(Parent)",
				LBL_CATEGORY_LVL2: "Product Category(Lvl 2)",
				LBL_PARCEL_LOST_GUARANTEED: "Parcel Lost Guanrantee",
				LBL_PRODUCT_SPECIAL_REMARK: "AR Remark",
				LBL_REFERENCE_TYPE: "Reference Type",
				LBL_PRODUCT_SPECIAL_DESC: "Prod. Desc",
				LBL_SERVICE_CHARGES_QUOTE_DESC: "Service Charges Quote Description",
				LBL_SPECIAL_PROD_PRICE_TYPE: "Price Type",
				LBL_SPECIAL_PRODUCT: "Spec Prod.",
				LBL_SC_REJECT: "SCS Reject",
				LBL_SPECIAL_PROD_STATUS_ID: "Prod. Status",
				LBL_SPECIAL_PROD_CAT_TYPE: "Cat. Type",
				LBL_SPECIAL_PROD_PRICE_TYPE_REMARK: "Price Type Remark",
				LBL_SPECIAL_PROD_REMARK: "Scs Remark",
				PRODUCT_IMAGE_TITLE: "Product Images",
				SUPPORTED_DOC_TITLE: "Supported Document",
				UPLOAD_IMAGE_DOCUMENT_SPECIAL_PROD: "Upload Special Product Image / Supported Document",
				UPLOAD_IMAGE: "Upload Product Image",
				UPLOAD_FILE_TITLE: "Click here select file",
				MSG_SUPPORT_IMAGE_SIZE_FORMAT: "Supported size should be less than 1 MB and Supported Format .jpg, .jpeg, .png",
				MSG_SUPPORT_FILE_SIZE_FORMAT: "File supported size should be less than 10 MB(Format: .xls, .xlsx, .pdf, .doc, .docs, .csv).",
				TOOLTIP_PRICE_TYPE_REMARK: "Enter reason for changing price type.",
				TOOLTIP_UPLOAD_VIEW_IMAGE_SUPPORT_DOCUMENT: "View Product Image/Supported Document",

				LBL_SERVICE_CHARGES: "Service Charges Remark"
			},

			FCL_QUOTATION_TPL_SELECTION: {
				STANDARD: "Normal Quotation",
				STANDARDV2: "Normal Quotation V2"
			},

			LCL_QUOTATION_TPL_SELECTION: {
				STANDARD: "Normal Quotation"
			},

			LCL_COST: {
				LBL_SPECIAL_CHARGES: "Special Chrg",
				LBL_CHARGES: "Chrg",
				LBL_SPECIAL_CHARGES_REMARK: "Special Add. Chrg Quote Desc",
				LBL_ROUTE: "Route",
				LBL_COST: "Cost",
				LBL_RANGE: "Range",
				LBL_MIN_CHARGES: "Min Chrg",
				LBL_CUSTOMER_GROUP_MIN_COST: "Cust Min Markup",
				LBL_CUSTOMER_GROUP_COST: "Cust Markup",
				LBL_QUOTE_PRICE: "Quote Price",
				LBL_APPLY_SHIPMENT_ROUTE: "Apply Shipment Route",
				LBL_RESET_MSM_SM_COST: "Reset Main Salesman / Salesman Cost",
				LBL_BASE_CHARGES: "Base Chrg",
				LBL_CAT_TYPE_CHARGES: "Cat Type Chrg",
				LBL_MAIN_SALESMAN_CHARGES: "Main Salesman Chrg",

				LBL_MIN_M3_KG: "Min M3/KG",
				LBL_LOCATION_CHARGES_REMARK: "Charges Remark"
			},

			BTN_CREATE_MARKING: "New Marking",
			BTN_CREATE_QUOTE: "Create Inquiry Quotation",
			BUTTON_REJECTED: "Reject",
			BUTTON_REVERT: "Revert",
			BTN_ISSUE: "Approve & Issue",
			BTN_SYSTEM_GENERATE_SERVICE_DESC: "Gen. Service Chrg Desc.",
			BTN_INVALID_QUOTATION: "Invalid Quotation",
			BTN_ENABLE_EDIT_TRANSMIT_DESC: "Enabled Edit Transmit Desc",
			BTN_DISABLE_EDIT_TRANSMIT_DESC: "Disabled Edit Transmit Desc",
			BTN_ENABLE_EDIT_SHIPMENT_DESC: "Enabled Edit Shipment Desc",
			BTN_DISABLE_EDIT_SHIPMENT_DESC: "Disabled Edit Shipment Desc",
			BTN_ENABLE_EDIT_DESTINATION_PORT: "Enabled Edit Destination Port",
			BTN_DISABLE_EDIT_DESTINATION_PORT: "Disabled Edit Destination Port",
			BTN_ENABLE_EDIT_SHIPPING_TERMS: "Enabled Edit Shipment Terms",
			BTN_DISABLE_EDIT_SHIPPING_TERMS: "Disabled Edit Shipment Terms",
			BTN_ENABLE_EDIT_LOADING_PORT: "Enabled Edit Loading Port",
			BTN_DISABLE_EDIT_LOADING_PORT: "Disabled Edit Loading Port",
			BTN_DISABLE_EDIT_SERVICE_CHARGES_QUOTE: "Disabled Edit Service Charges",
			BTN_ENABLE_EDIT_SERVICE_CHARGES_QUOTE: "Enabled Edit Service Charges",
			BTN_ENABLE_EDIT_MOVEMENT_TYPE: "Enabled Edit Movement Type",
			BTN_DISABLE_EDIT_MOVEMENT_TYPE: "Disabled Edit Movement Type",
			BTN_RESET_TERMS_COND: "Reset Credit Terms",
			BTN_ENABLE_EDIT_QUOTE_TITLE: "Enabled Edit Exceptional Charge Quote Title",
			BTN_DISABLE_EDIT_QUOTE_TITLE: "Disabled Edit Exceptional Charge Quote Title ",
			BTN_REVISE_QUOTATION: "Revise Quotation",
			BTN_REQUEST_SCS_REVISE: "Request Revise",
			BTN_APPROVE_REVISE_QUOTATION: "Approve Revise Quotation",
			BTN_APPROVE_REVISE_QUOTATIONS: "Approve Revise Quotation (s)",
			BTN_REVISE_BATCH_QUOTATION: "Revise Quotation (s)",
			BTN_CONFIRM_N_ISSUE: "Confirm & Issued",
			BTN_HISTORY: "History",
			BTN_ADD_TRANSIT: "Add New Transit Charges",
			BTN_RESET_TRANSIT_DESCRIPTION: "Reset Transit Description",
			BTN_RESET_MSM_COST: "Reset Main Salesman Cost",
			BTN_RESET_SM_COST: "Reset Salesman Cost",
			BTN_CONFIRM_REVISE: "Confirm Revise",
			BTN_ADD_SPECIAL_CHARGES: "Special Add. Charges",
			BTN_RESET_COST: "Reset Cost",
			BTN_CREATE_DELIVERY_ADDRESS: "New Destination Add.",
			BTN_NEW_SHIPMENT_ROUTE: "Add Shipment Route",
			BTN_REMOVE_SHIP_ROUTE: "Remove Shipment Route",
			BTN_VIEW_SPECIAL_PRODUCT: "Check Special Product",
			BTN_ADD_NEW_CATEGORY: "New Category",
			BTN_RESET_TRANSIT: "Reset Transit Cost",
			BTN_RANGE_CHARGES: "View/Set Charges",
			BTN_ENABLE_EDIT_SCS_REJECT_REASON: "Enabled Edit Scs Reason",
			BTN_DISABLE_EDIT_SCS_REJECT_REASON: "Disabled Edit Scs Reject Reason",
			BTN_USE_EXIST_CHARGES: "Remain Exists Charges",
			BTN_APPLIED_NEW_CHARGES: "Applied New Charges",
			BTN_NEW_PROD: "Add New Commodity",
			BTN_UPDATE_OVER_KM_CHARGES: "Update Existing Charges",
			BTN_RESET_SPECIAL_PROD: "Reset Special Product",
			BTN_REMAIN: "Remain Current Record",

			SUB_TITLE: {
				CUSTOMER: "Customer Information",
				GENERAL: "General Information",
				SHIPPING: "Shipment Information",
				PRODUCT: "Product & Special Request Information",
				ORIGIN_ADDRESS: "Origin Address",
				DESTINATION_ADDRESS: "Destination Address/Zone",
				TERM_CONDITION: "Terms & Condition",
				LBL_EXCEPTIONAL_CHARGES: "Exceptional Charges",
				ORIGIN_DESTINATION_ADDRESS: "Origin & Destination",
				LCL_QUOTE_PRICE: "Standard Charges",
				LBL_TRANSIT_CHARGES: "Transit Charges"
			},

			LISTING_TITLE: {
				INQUIRY_SUMMARY: "Inquiry Summary",
				COND_CONFIRM: "Cond. Confirm",
				COND_APPROVE: "Cond. Approve",
				COST_CONFIRM: "Cost Confirm",
				REJECT_INQUIRY_QUOTATION: "Rejected/Cancelled Quotation",
				INVALID_QUOTATION: "Invalid Quotation",
				COST_APPROVE: "Cost Approve",
				SALESMAN_QUOTE: "Salesman Quote",
				QUOTE_CONFIRM: "Quote Confirm",
				QUOTE_APPROVE: "Quote Approve & Issue",
				QUOTE_PENDING_ISSUE: "Quotation Pending Issue",
				QUOTATION_PENDING_ACKNOWLEDGE: "Pending Acknowledge",
				QUOTATION_ACKNOWLEDGE: "Acknowledged Quotation",
				SCS_CONFIRM: "SCS Confirm",
				QUOTATION_COST: "Quotation Costing",
				REVISE_QUOTATION: "Revise Quote Confirm",
				SCS_CONFIRM_ALL: "Scs Verify Charges & Product",
				SCS_CONFIRM_CHARGES: "Scs Verify Charges",
				SCS_CONFIRM_SPECIAL_PROD: "Scs Verify Product",
				REVISE_TITLE: "Revise Quotation",
				APPROVE_REVISE: "Approve Revise",
				PENDING_REVISE: "Revise",
				OVER_RANGE_CHARGES_EXPIRE: "Over Km Chrg Expire",
				QUOTATION_EXPIRED: "Expired Quotation",
				SCS_REVISE_QUOTATION: "Scs Revise",
				BATCH_REVISE: "Batch Revise",
				PENDING_REVISE: "Pend. Revise"
			},

			PH_ENTER_INQUIRY_DATE_FROM: "Enter Inquiry Date From",
			PH_ENTER_INQUIRY_DATE_TO: "Enter Inquiry Date To",
			PH_GOODS_AMOUNT_CURRENCY_SYMBOL: "Currency Symbol",
			PH_GOODS_AMOUNT_FROM: "Enter Amount From",
			PH_GOODS_AMOUNT_TO: "Enter Amount To",
			PH_ENTER_CREATION_DATE_FROM: "Enter Creation Date From",
			PH_ENTER_CREATION_DATE_TO: "Enter Creation Date To",

			SUMMARY: {
				COL_INQUIRY_TYPE: "Inq. Type",
				COL_INQUIRY_NO: "Inq. No",
				COL_INQUIRY_DATE: "Inq. On",
				COL_QUOTATION_NO: "Quote. No",
				COL_CUSTOMER: "Cust. Name",
				COL_MARKING: "Marking",
				COL_SHIPMENT_TYPE: "Shpt Type",
				COL_SHIPMENT_MODE: "Shpt Mode",
				COL_SHIPMENT_TERMS: "Shpt Terms",
				COL_MOVEMENT_TYPE: "Movement",
				COL_COUNTRY: "From/To Country",
				COL_ORIGIN_DESTIONATION: "Wh. To Area",
				COL_ISSUED_ON: "Issued On",
				COL_SALESMAN: "Sm",
				COL_MAINSALESMAN: "Msm",
				COL_AREA: "Dest Area",
				COL_COMPANY_NAME: "Comp. Name",
				COL_COMPANY_GROUP: "Comp. Group",
				COL_LOADIND_PORT: "LPT",
				COL_DESTINATION_PORT: "DPT",
				LBL_DESTINATION_AREA: "{state}, {city}",
				COL_PRODUCT_NAME: "Commodity",
				COL_REF_TYPE: "Ref Type",
				COL_DELIVERY_ID: "Delivery Id",
				COL_CATEGORY_PARENT: "Category (Lvl 1)",
				COL_CATEGORY: "Category (Lvl 2)",
				COL_GOODS_AMOUNT_FROM: "Commodity Amt",
				COL_REMARKS: "Remarks",
				COL_REVISION: "Revision",
				COL_CREDIT_LIMITS: "Credit Limit",
				COL_BASE_ROUTE: "Cost Route",
				COL_TRANSIT_ROUTE: "Transit Route",
				COL_EXCEPTIONAL_CHARGES_TYPE: "Exceptional Charges Type",
				COL_EXCEPTIONAL_CHARGES: "Exceptional Charges",
				COL_TAX: "Tax",
				COL_SPECIAL_CHARGES: "Special Add. Chrg",
				COL_SALESMAN_MIN_GROUP: "Salesman Min Markup Grp",
				COL_SALESMAN_GROUP: "Salesman Markup Grp",
				COL_CUST_MIN_MARKUP_GROUP: "Cust. Min Markup Grp",
				COL_CUST_MARKUP_GROUP: "Cust. Markup Grp",
				COL_COMBINE_CREDIT_LIMITS: "Combine Credit Limit",
				COL_FINANCE_RATE: "Finance Rate",
				COL_SALESMAN_FINANCE_RATE: "Salesman Finance Rate",
				COL_INDEMNIFY_LOST_GOODS: "Indemnify Lost Goods",
				COL_FREIGHT_CHARGES: "Frieght Charges",
				COL_SHIPMENT_ROUTE: "Shpt Route",
				COL_DESTINATION_COUNTRY: "Dest Country",
				COL_QUOTATION_SUBJECT: "Re:Quotation",

				COL_DESTINATION_STATE: "Dest State",

				COL_GROUP_SUM: "Grp Sum",
				COL_QUOTE_CURRENCY: "Cur.",

				COL_MIN_CHARGES_TYPE: "Min Chg Type",
				COL_MIN_CHARGES_UOM: "Min Chg UOM",
				COL_MIN_CHARGES: "Min Chg",
				COL_QUOTE_MIN_CHARGES: "Min Quote Amt",

				COL_CHARGES_TYPE: "Chg Type",
				COL_CHARGES_BY: "Chg By",
				COL_CHARGES_UOM: "Chg UOM",
				COL_CHARGES: "Chg",
				COL_QUOTE_CHARGES: "Quote Amt",

				COL_TTL_MIN_CHARGES: "Ttl Min Chg",
				COL_TTL_MIN_QUOTE_CHARGES: "Ttl Min Quote Chg",

				COL_TTL_CHARGES: "Ttl Chg",
				COL_TTL_QUOTE_CHARGES: "Ttl Quote Chg",

				COL_CREATED_BY: "Created By",
				COL_CREATED_ON: "Created On",

				COL_CONFIRM_BY: "Confirmed By",
				COL_CONFIRM_ON: "Confirmed On",

				COL_APPROVED_BY: "Approved By",
				COL_APPROVED_ON: "Approved On",

				COL_ISSUE_BY: "Issued By",

				COL_REVERT_BY: "Reverted By",
				COL_REVERT_ON: "Reverted On",
				COL_REVERT_REASON: "Reverted Reason",

				COL_REJECTED_BY: "Rejected By",
				COL_REJECTED_ON: "Rejected On",
				COL_REJECT_REASON: "Rejected Reason",

				COL_INVALID_BY: "Invalided By",
				COL_INVALID_ON: "Invalided On",
				COL_INVALID_REASON: "Invalided Reason",
				COL_DELIVERY_STATES: "Delivery States",
				COL_TRANSIT_CHARGES: "Transit Chrg.",
				COL_TRANSIT_REMARK: "Transit Remark",

				COL_VERIFY_PROD: "Verify Prod",
				COL_VERIFY_ADDRESS: "Verify Address",

				COL_WITHIN_RANGE: "Within Range",
				COL_MIN_M3_KG: "Min M3/KG",
				COL_DISTANCE: "KM",

				COL_SET_SPECIAL_PROD: "Set Special Prod",
				COL_SET_OVER_CHARGES: "Set Over Charges",

				COL_CONFIRM_CHARGES_BY: "Scs Confirm Charges By",
				COL_CONFIRM_PROD_BY: "Scs Confirm Prod By",
				COL_PENDING_REVISE: "Pending Revise",
				COL_CUSTOM_CHARGE: "Spec. Prod / Cust. Chrg. Reset",
				COL_SPECIAL_PROD_EXIPRE: "Special Prod. Expired",
				COL_OVER_CAHRGES_EXPIRE: "Over KM Charges Expired",
				COL_SPECIAL_PRODUCT: "Special Prod.",
				COL_OVER_KM: "Over KM?",
				COL_EFFECTIVE_DATE: "Effective On"
			},

			SHIPMENT_DESC_SELECTION: {
				WTD: "Warehouse to Customer Door Step",
				WTP: "Warehouse to Destination Port",
				STD: "Supplier Warehouse To Customer Door Step",
				STP: "Supplier Warehouse To Destination Port",
				PTP: "Origin Port To Destination Port",
				PTD: "Origin Port To Customer Door Step",
				DPTD: "Destination Port To Door Step"
			},

			ERROR: {
				TRANSIT_COST_NOT_EXISTS: "Transit charges no found. Please refer to Costing Department.",
				EXCEPTIONAL_CHARGES_NOT_EXISTS: "{name} Charges not found. Please refer to Costing Department.",
				STARNDARD_CHARGES_NOT_SETUP: "Route ({route}) Standard Charges not exists. Please refer to Costing Department. ",
				MSM_COST_NOT_EXISTS: "Main Salesman Cost for {route} not exists. Please Setup.",
				SM_COST_NOT_EXISTS: "Salesman Cost for {route} not exists. Please Setup.",
				CUSTOMER_GROUP_COST_NOT_EXISTS: "Customer Group cost record not found. Please Setup.",
				CUSTOMER_GROUP_MIN_COST_NOT_EXISTS: "Customer Group Min cost record not found. Please Setup.",
				MSG_FAILED_TO_SAVE_DRAF: "Inquiry Quotation failed To save",
				SHIPMENT_DESC_WRONG_MSG: "This shipment from supplier warehouse. You are not allow select {shipment_type_desc}",
				SUPPLIER_SHIPMENT_DESC_WRONG_MSG: "This shipment from our warehouse. You are not allow select {shipment_type_desc}",
				MSG_BLOCK_EDIT_MARKUP: "Admin in quoting this {inquiry_no} inquiry quotation. You are not allow adjust markup. Please assist admin.",
				NO_DOCUMENT_SELECTED: "No Document has been selected",
				MSG_SPECIAL_PRODUCT_IMAGE_REQUIRED: "Special Product Image/Document Required.",
				MSG_NOT_ALLOW_SWITCH_SALESMAN: "The customer's other inquiry quotation, already assign another salesman. You are not allowed to switch salesman.",
				MSG_ROUTE_COST: "Cost Route Not Found. Please setup.",
				MSG_GOODS_AMOUNT_FROM_GREATER_TO: "Amount To must be greater than Amount From",
				MSG_GOODS_AMOUNT_FROM_REQUIRED: "Amount From is required",
				MSG_INQUIRY_DOC_REQUIRED: "Inquiry No. Format not set",
				MSG_QUOTATION_DOC_REQUIRED: "Quotation No. Format not set",
				MSG_POSTCODE_NOT_EXISTS: "Postcode not exist. Click &#8862; to add postcode.",
				MSG_LOCATION_CHARGES_NOT_EXISTS: "Over KM charges not set",
				MSG_CITY_NOT_EXISTS: "City not exist. Click  &#8862; to add city",
				MSG_NOT_EXISTS_MASTER_DATA: "{name} not exists in master record.",
				MSG_INQUIRY_REJECTED: "{inquiry_no} rejected.<br /> Reason: {reason} <br /> Rejected By: {rejected_by}",
				MSG_QUOTATION_SUBJECT_CONTENT_UNDEFINED: "Quotation Subject Content Undefined.",
				MSG_CUSTOM_TRANSIT_CHARGES_EXPIRED: "Below location (s) were expired. Please re-select charges.<br /> {location_name}",
				MSG_CUSTOM_TRANSIT_CHARGES_REVISE: "Over KM Charges has been revise with new price. ",
				MSG_SPECIAL_PRODUCT_PENDING_REVISE: "Found special product was reviewed. Please review/revise new record.",
				MSG_CUSTOM_CHARGES_PENDING_REVISE: "Found Over KM Charges was reviewed. Please review/revise new record."
			},

			SUCCESS: {
				MSG_SUCCESS_CONFIRM: "Quotation confirmed",
				MSG_SUCCESS_APPROVE: "Quotation approved",
				MSG_SUCCESS_ISSUE: "Quotation Issued",
				MSG_SUCCESS_SAVE_AS_DRAF: "Inquiry Quotation successful to save",
				MSG_SUCCESS_UPDATED_MARKUP: "Quotation successful save",
				MSG_SUCCESS_REVISED: "Quotation Successful revised & issued",
				MSM_COST_UPDATE: "Main Salesman cost successful updated",
				SM_COST_UPDATE: "Salesman cost successful updated",
				CUSTOMER_MIN_COST_UPDATE: "Customer Min Markup updated",
				CUSTOMER_COST_UPDATE: "Customer Markup updated",
				SUCCESS_REVISE: "Quotation(s) successful revised",
				MSG_SUCCESS_REQUEST_REVISE: "Quotation No.: {quotation_no} successful submit to revise.",
				MSG_SUCCESS_APPROVE_REVISE: "Quotation No: {quotation_no} successful approved issued"
			},

			FCLCOSTING: {
				LBL_CHARGES_TYPE: "Charges Type",
				LBL_GROUP_SUM: "Group Sum",
				LBL_CURRENCY: "Currency",
				LBL_UOM: "UOM",
				LBL_COSTING: "Quote Price",
				LBL_MARKUP: "Markup",
				LBL_QUOTE_PRICE: "Quote Price",
				LBL_COST_TITLE: "Cost",
				LBL_MIN_COST: "Min Quote Price",
				LBL_MIN_MARKUP: "Min Markup",
				LBL_MIN_QUOTE_PRICE: "Min Quote Price",
				LBL_ENABLE_MIN_CHARGES: "Enable/Disabled<br />Min Charges",
				LBL_MIN_COST_TYPE: "Min Chg By",
				LBL_MIN_COST_UOM: "UOM",
				LBL_COST: "Cost",

				PH_COST: "Enter Cost",
				PH_MARKUP: "Enter Markup",
				PH_QUOTE_PRICE: "Enter Quote Price",
				PH_MIN_COST: "Enter Min Cost",

				BTN_ADD_NEW_CHARGES: "Add New Charges",
				BTN_ADD_SUB_CHARGES: "Add New {charges_name}",
				LBL_COST_REMARK_SUMUP: "** &#10003; checkbox for sum up cost",

				VALIDATION: {
					CHARGES_TYPE_DUPLICATE: "Charges Type duplicate",
					QUOTE_PRICE_GREATER_EQUAL: "Quote Price must greater than or equal Cost",
					CHARGES_NOT_ALLOW_MARKUP: "Charges which not allow markup is not allow group togther with charges allow markup"
				},

				MSG_GROUP_SUM_ERROR: "This {charges_name} currency/cost uom/min cost type/min cost uom/allow markup is not allow group togther with {group} group charges ",
				LBL_TOTAL_COST: "Total Cost",
				COL_TOTAL_COST: "Cost",
				COL_TOTAL_MIN_COST: "Min Cost",
				COL_TOTAL_MARKUP: "Markup",
				COL_TOTAL_QUOTE_PRICE: "Quote Price",
				COL_TOTAL_MIN_MARKUP: "Min Markup",
				COL_TOTAL_MIN_QUOTE_PRICE: "Min Quote Price",

				MIN_COST_TYPE: {
					BY_AMOUNT: "Amount",
					BY_M3_KG: "M3/KG",
					BY_PERCENTAGE: "Percentage"
				},

				CHARGES_BY_TYPE: {
					BY_AMOUNT: "Amount",
					BY_PERCENTAGE: "Percentage"
				}
			},

			REJECT: {
				TITLE: "Reject Inquiry Quotation",
				MSG_CONFIRM_REJECT: "Are you sure to reject this {inquiry_no} inquiry quotation?",
				LBL_INQUIRY_NO: "Inquiry Quotation No.",
				LBL_REJECT_REASON: "Reject Reason",
				MSG_SUCCESSFULL: "{inquiry_no} has been successful rejected.",
				MSG_NOT_ALLOW_REJECT: "Ooops.. .. You are not allow reject this {inquiry_no}."
			},

			INVALID: {
				TITLE: "Invalid Quotation",
				LBL_INQUIRY_NO: "Inquiry Quotation No.",
				LBL_QUOTATION_NO: "Quotation No",
				LBL_INVALID_REASON: "Invalid Reason",
				MSG_SUCCESSFULL: "{quotation_no} has been successful set to invalid.",
				MSG_NOT_ALLOW_INVALID: "Ooops.. .. You are not allow this {quotation_no} to invalid.",
				LBL_QUOTATION_STATUS: "Set Quotation Status"
			},

			REVERT: {
				TITLE: "Revert Inquiry Quotation",
				LBL_INQUIRY_NO: "Inquiry Quotation No.",
				LBL_REVERT_REASON: "Revert Reason",
				MSG_SUCCESSFULL: "{inquiry_no} has been successful revert to {status_name}.",
				MSG_NOT_ALLOW_REVERT: "Ooops.. .. You are not allow revert this {inquiry_no}.",
				MSG_CONFIRMATION: "Are you sure to revert this {inquiry_no} inquiry quotation?",
				LBL_SCS_REVERT_TYPE: "Revert SCS Confirm",
				SCS_REVERT_TYPE: {
					ALL: "Over KM Charges & Product",
					CHARGES: "Over KM Charges Only",
					PRODUCT: "Special Product Only"
				}
			},

			EXTRA_CHARGES: {
				LBL_OUTSKIRT: "OutSkirt",
				LBL_CHARGES_TYPE: "Exceptional Charges Type",
				LBL_CHARGES: "Exceptional Charges",
				LBL_CHARGES_TITLE: "Charges Quote Title",

				COL_CONDITION: "Condition",
				COL_MIN_CHARGES: "Min Charges",
				COL_CHARGES: "Charges",
				COL_ADDTIONAL_CHARGES: "Add. Charges",

				BUTTON_ADD_NEW_EXCEPTIONAL_CHARGES: "Add New Exceptional Charges",
				BUTTON_REMOVE_EXCEPTION_CHARGES: "Remove Exception Charges"
			},

			MODULE_NAME: {
				COST_CONFIRM: "Cost Confirm",
				SALESMAN_QUOTE: "Salesman Quote",
				CONDITION_CONFIRM: "Condition Confirm",
				SCS_CONFIRM: "Scs Confirm"
			},

			SHIPMENT: {
				ROUTE: "Shipment Route",
				SHIPMENT_DESC: "Shipment Desc",
				COST_ROUTE: "Cost Route",
				TRANSIT_DESC: "Transit Desc"
			},

			REVISE_COST_TYPE: {
				STANDARD_CHARGES: "Standard Charges",
				TRANSIT_CHARGES: "Transit Charges",
				EXCEPTIONAL_CHARGES: "Exceptional Charges",
				CUSTOMIZE_TRANSIT_CHARGES: "Over KM Charges (Within Range)"
			}
		},
		SPECIAL_INSTRUCTION: {
			COL_INSTRUCTION_TYPE: "Instruction Type",
			COL_INSTRUCTION_TYPE_NAME: "Name",
			COL_INSTRUCTION_REF_NO: "Instruction Ref No",
			COL_VALID_DAY: "Valid Day",
			COL_DELIVERY_ID: "Delivery ID",
			COL_INSTRUCTION_DATE: "Instruction Date",
			COL_MARKING: "Marking",
			COL_START_FROM: "Start From",
			COL_NOTICE_TYPE: "Notice Type",
			PH_ENTER_INSTRUCTION_DATE_TO: "Instruction Date To",
			PH_ENTER_INSTRUCTION_DATE_FROM: "Instruction Date From",
			LBL_INSTRUCTION_TYPE_REMARK: "Instruction Type Remark",
			EPO_SELECTION_TITLE: "Select EPO",
			SPECIAL_INSTRUCTION_BY_MARKING: {
				CREATE_TITLE: "Create New Special Instruction By Marking",
				DETAIL_TITLE: "Special Instruction By Marking Detail",

				LBL_SPECIAL_INSTRUCTION_BY_MARKING: "New Special Instruction By Marking",
				LBL_INSTRUCTION_REF_NO: "Instruction Ref No",
				LBL_INSTRUCTION_DATE: "Instruction Date",

				MODULE_NAME: "Special Instruction By Marking",
				DELIVERY_ID: "Delivery ID",
				WAREHOUSE: "Warehouse",
				INSTRUCTION_REF_NO: "Instruction Ref No",
				INSTRUCTION_DATE: "Instruction Date",
				MARKING: "Marking",
				NOTICE_TYPE: "Notice Type",
				START_FROM: "Start From",
				VALID_DATE: "Valid Day",
				INSTRUCTION_TYPE: "Instruction Type",
				APPLY_TO_DEL_ADD: "Apply To All Del Add",

				LISTING_TITLE: "Special Instruction By Marking",
				EDIT_TITLE: "Edit Special Instruction By Marking",
				PH_ENTER_INSTRUCTION_DATE_TO: "Instruction Date To",
				PH_ENTER_INSTRUCTION_DATE_FROM: "Instruction Date From",
				PH_ENTER_INSTRUCTION_DATE: "Instruction Date",
				PH_ENTER_START_FROM: "Start From"
			},
			SPECIAL_INSTRUCTION_BY_EPO: {
				CREATE_TITLE: "Create New Special Instruction By EPO",
				DETAIL_TITLE: "Special Instruction EPO Detail",

				LBL_SPECIAL_INSTRUCTION_BY_EPO: "New Special Instruction By EPO/PO/SO",
				LBL_INSTRUCTION_REF_NO: "Instruction Ref No",
				LBL_INSTRUCTION_DATE: "Instruction Date",
				LBL_PO_DETAIL: "EPO/PO Detail",

				LBL_REMARK: "Remark",
				COL_REMARK: "Remark",
				COL_CASH_PAY: "Cash Payment",
				COL_EST_DELIVERY_DATE: "Est Delivery Date",
				COL_PO_AMOUNT: "PO Amount",
				COL_CURRANCY_RATE: "Currancy Rate",

				BTN_ADD_NEW_EPO: "Add New EPO/PO",
				BTN_ENABLE_NOTICE_TYPE: "Enable Edit Notice Type",
				BTN_DISABLE_NOTICE_TYPE: "Disable Edit Notice Type",
				BTN_SEARCH_EPO: "Search EPO",

				MODULE_NAME: "Special Instruction By EPO",
				DELIVERY_ID: "Delivery ID",
				WAREHOUSE: "Warehouse",
				INSTRUCTION_REF_NO: "Instruction Ref No",
				INSTRUCTION_DATE: "Instruction Date",
				MARKING: "Marking",
				NOTICE_TYPE: "Notice Type",
				START_FROM: "Start From",
				VALID_DATE: "Valid Day",
				INSTRUCTION_TYPE: "Instruction Type",
				APPLY_TO_DEL_ADD: "Apply To All Del Add",

				LISTING_TITLE: "Special Instruction By EPO",
				EDIT_TITLE: "Edit Special Instruction By EPO",

				PH_ENTER_INSTRUCTION_DATE: "Instruction Date",
				PH_ENTER_START_FROM: "Start From",

				INSTRUCTION_DATE_IS_REQUIRED: "Instruction Date is Required",
				START_FORM_IS_REQUIRED: "Start From is Required",
				MARKING_IS_REQUIRED: "Marking is Required",
				VALIDITY_IS_REQUIRED: "Valid Day is Required",
				DELIVERY_ADDRESS_IS_REQUIRED: "Delivery Address is Required",
				NOTICE_TYPE_IS_REQUIRED: "Notice Type is Required",
				WAREHOUSE_IS_REQUIRED: "Warehouse is Required",
				INSTRUCTION_TYPE_IS_REQUIRED: "Instruction Type is Required",
				PO_DIFFERENT_MARKING: "This {epo_no} EPO No. belong other customer marking.",
				PO_DIFFERENT_DELIVERY_ADDRESS: "This {epo_no} EPO No. belong other delivery address.",
				PO_SET_CHANGE_NOTICE_TYPE: "Below Epo reset notice type: <br />{epo_no}"
			}
		},
		TELEGRAPHIC_TRANSFER: {
			COL_LINK_TOTAL_OR_AMT: "Link Total OR Amt",
			COL_OR_NO: "OR No.",
			COL_OR_DATE: "OR Date",
			COL_CHQ_DATE: "Chq Date",
			COL_CHQ_NO: "Chq No",
			COL_BENE_NO_LIST: "Bene No List",
			COL_PAY_MODE_CODE: "Pay Mode Code",
			COL_OR_TYPE_CODE: "OR Type Code",
			COL_GL_ACCOUNT: "GL Acc",
			COL_BANK_CHARGE_AMOUNT: "Bank Chrg. Amt",
			COL_BANK_CHARGE_ACCOUNT: "Bank Chrg. Acc",
			COL_BANK: "Bank Account",

			COL_TOTAL_OR_AMOUNT: "Total OR Amount",
			COL_ACTUAL_AMOUNT: "Actual Amount",
			COL_MATCH_ACTUAL_AMOUNT: "Match?",
			COL_IS_GOR: "GOR",
			COL_GOR_NO: "GOR No.",
			COL_GOR_AMT: "GOR Amount",
			COL_IS_RETURN: "Return",
			COL_OR_AMT: "OR Amount",
			COL_IS_POSTED: "Posted",
			COL_OR_LIST: "OR List",
			COL_MATCH_AMT: "Match Amount",
			COL_DISCR_AMT: "Discrepancy Amount",
			COL_BANK_CHARGES: "Bank Charges",
			COL_DEBTOR_NAME: "Debtor Name",
			COL_IS_MATCHT: "Is Match",
			COL_PA_AMOUNT: "Pa Amount",
			COL_SC_AMOUNT: "Sc Amount",
			COL_TA_AMOUNT: "Ta Amount",

			POST_REVERSE: {
				MSG_POSTED: "Posted receipt successfully",
				MSG_REVERSED: "Reverse receipt successfully",
				MSG_FAILED_POSTED: "Failed post receipt",
				MSG_FAILED_REVERSE: "Failed reverse receipt",
				MSG_RECEIPT_ID: "Row: {idx} receipt id missing",
				MSG_POSTED_COMPANY_UNDEFINED: "Comp Sub Grp: {sub_grp} posted company not found",
				MSG_DEBTOR_NOT_FOUND: "Debtor ({debtor}) not found",
				MSG_GL_ACCOUNT_NOT_FOUND: "GL Account ({account_code}) not found",
				MSG_BANK_ACCOUNT_NOT_FOUND: "Bank Account ({account_code}) not found",
				MSG_PAY_MODE_NOT_FOUND: "Pay Mode ({pay_mode}) not found",
				MSG_POSTING_DATE_SMALL_CHQ_DATE: "Posting Date {posted_date} must not less than cheque date {chq_date}",
				MSG_POSTING_DATE_SMALL_DOC_DATE: "Posting Date {posted_date} must not less than document date {doc_date}"
			},

			RECEIPT_MAINTENANCE: {
				LISTING_TITLE: "Receipt Maintenance",
				CREATE_TITLE: "Create New Receipt",
				DETAIL_TITLE: "Receipt Detail",
				EDIT_TITLE: "Edit Receipt Detail",
				POST_RECEIPT_TITLE: "Post Receipt To Account",
				REVERSE_RECEIPT_TITLE: "Reverse Receipt From Account",

				LBL_RECEIPT_INFO: "Receipt Information",
				LBL_RECEIPT_NO: "Receipt No",
				LBL_MARKING: "Marking",
				LBL_DOCUMENT_DATE: "Doc Date",
				LBL_OR_TYPE: "OR Type",
				LBL_PAY_MODE: "Pay Mode",
				LBL_STOCK_TYPE: "Stock Type",
				LBL_BANK: "Bank",
				LBL_CUSTOMER_BANK: "Customer Bank",
				LBL_CHQ_DATE: "Cheque Date",
				LBL_CHQ_NO: "Cheque No",
				LBL_TOTAL_PA_AMT: "Total PA Amount",
				LBL_TOTAL_SC_AMT: "Total SC Amount",
				LBL_TOTAL_TA_AMT: "Total TA Amount",
				LBL_TOTAL_AMT: "Total OR Amount",
				LBL_APPLY_AMT: "Apply Amount",
				LBL_OUTSTANDING_AMT: "Outstanding Amount",
				LBL_REMARKS: "Remarks",
				LBL_SO_NO: "SO No",
				LBL_OR_INFO: "Official Receipt Information",
				LBL_POSTED: "Posted",
				LBL_OR_NO: "OR No",
				LBL_OR_DATE: "OR Date",
				LBL_ACTUAL_DATE: "Actual Date",
				LBL_ACTUAL_AMT: "Actual Amount",
				LBL_TT_DETAIL: "Telegraphic Transfer List",
				LBL_HIDE_IS_MATCH_RECORD: "Hide unmatched record",

				COL_RECEIPT_NO: "Receipt No",
				COL_DOCUMENT_DATE: "Doc. Date",
				COL_OR_TYPE: "OR Type",
				COL_PAY_MODE: "Pay Mode",
				COL_PA_AMT: "PA Amount",
				COL_SC_AMT: "SC Amount",
				COL_TA_AMT: "TA Amount",
				COL_AMT: "Amount",
				COL_OR_AMT: "OR Amount",
				COL_TOTAL_PA_AMT: "Total PA Amount",
				COL_TOTAL_SC_AMT: "Total SC Amount",
				COL_TOTAL_TA_AMT: "Total TA Amount",
				COL_TOTAL_OR_AMT: "Total OR Amount",
				COL_PAYMENT_AMT: "Payment Amount",
				COL_PAID: "Paid",
				COL_POSTED: "Posted",
				COL_POSTED_ON: "Posted On",
				COL_SO_NO: "SO NO",

				COL_TT_REF_NO: "TT Ref NO",
				COL_LOCAL_AMT: "Local Amount",
				COL_CURRENCY_CODE: "Cur. Code",
				COL_TT_CHARGES: "TT Charges",
				COL_EXCHANGE_AMOUNT: "Exchange Amount",
				COL_ALLOCATED_AMOUNT: "Allocated Amount",
				COL_OUTSTANDING_AMOUNT: "Outstanding Amount",

				MODULE_NAME: "Receipt Maintenance",
				AMOUNT_AT_LEAST_VALUE: "{name} value at least {amount}",
				MSG_TOTAL_PA_AMOUNT_ERROR_TITLE: "Total PA Amount cannot be less than sum of TT record(s) PA Amount",
				MSG_PA_AMOUNT_FOR_PAID_ERROR_TITLE: "Total PA Amount must be greater or equal than Applied PA Amount",
				MSG_OUTSTANDING_ERROR_TITLE: "Outstanding Amount cannot be less than zero",
				MSG_SC_AMOUNT_FOR_PAID_ERROR_TITLE: "Total SC Amount must be greater or equal than Applied SC Amount"
			},
			RECEIPT_PAYMENT: {
				LISTING_TITLE: "Receipt Payment",
				CREATE_TITLE: "Create New Receipt Payment",
				DETAIL_TITLE: "Receipt Payment Detail",
				EDIT_TITLE: "Edit Receipt Payment Detail",

				LBL_RECEIPT_INFO: "Receipt Payment Information",
				LBL_CHQ_NO: "Cheque No",
				LBL_CHQ_NO_FROM: "Cheque No From",
				LBL_CHQ_NO_TO: "Cheque No To",
				LBL_CUSTOMER_BANK: "Customer Bank",
				LBL_CHQ_DATE: "Cheque Date",
				LBL_COMPANY: "Company",
				LBL_GL_ACCOUNT: "GL Account",
				LBL_BANK_GL_ACCOUNT: "Bank GL Acc",
				LBL_BANK_GL_ACCOUNT_CODE: "Bank GL Acc Code",
				LBL_BANK_GL_ACCOUNT_NAME: "Bank GL Acc Name",
				LBL_ACTUAL_AMT: "Actual Amount",
				LBL_BANK_CHARGES_ACC: "Bank Charges Account",
				LBL_BANK_CHARGES_AMT: "Bank Charges Amount",
				LBL_ISSUE_GOR: "Issue GOR",
				LBL_CHEQUE_RETURN: "Cheque Return",
				LBL_GOR_NO: "GOR No",
				LBL_GOR_AMOUNT: "GOR Amount",
				LBL_REMARKS: "Remarks",
				LBL_OR_AMOUNT_FROM: "Total OR Amount From",
				LBL_OR_AMOUNT_TO: "To",
				LBL_FILTER: "Filter",
				LBL_POSTED: "Posted",
				LBL_FILTER_BY: "Filter by(Unpaid receipt)",

				LBL_TT_RECEIPT_LIST: "TT Receipt List",
				LBL_IMPORT_TT_RECEIPT_PAYMENT: "Import TT Receipt Payment Excel File",
				LBL_POSTING: "Posting TT Receipt",
				LBL_UNPOSTING: "Reverse TT Receipt",
				LBL_TT_RECEIPT_NO: "TT Receipt No.",
				LBL_TT_RECEIPT_NO_FROM: "TT Receipt No. from",
				LBL_TT_RECEIPT_NO_TO: "TT Receipt No. to",
				LBL_OR_DATE: "OR Date.",

				COL_DOCUMENT_DATE: "Document Date",
				COL_RECEIPT_NO: "Receipt No",
				COL_COMPANY_NAME: "Company Name",
				COL_BANK_ACCOUNT: "GL Bank Account",
				COL_BANK: "Customer Bank",
				COL_TTL_OR_APPLIED: "OR Amt Applied",

				COL_SELECT: "Select",
				COL_DOCUMENT_NO: "Document No.",
				COL_CO_ID: "CO. ID",
				COL_MSM: "Main Salesman",
				COL_SM: "Salesman",
				COL_MARKING: "Marking",
				COL_PA_AMT: "Total PA Amount",
				COL_SC_AMT: "Total SC Amount",
				COL_TA_AMT: "Total TA Amount",
				MODULE_NAME: "Receipt Payment",

				ACTUAL_AMOUNT_AND_TOTAL_OR_AMOUNT_VALUE:
					"Sum of Total OR Amount and gor amount {total_or_amount} cannot be greater than sum of actual amount and bank charges amount {total_actual_and_bank_amount}.\nActual amount should be {actual_amount} to match total or amount",
				GOR_NO_SHOULD_EMPTY: "GOR No should be empty without issue gor",
				EXCEL_DUPLICATE_RECORD: "Duplicate Record found",
				GOR_AMOUNT_SHOULD_ZEROY: "GOR Amount value should be zero without issue gor",
				MSG_ACTUAL_AMOUNT_ERROR_TITLE: "Invalid Actual Amount",
				MSG_FILTER_OR_AMT_ERROR_TITLE: "OR Amount From cannot be higher than OR Amount To",
				MSG_UNABLE_SAVE_DUPLICATE_RECORD_TITLE: "Unable to save duplicate receipt payment record.",
				MSG_ALL_RECEIPT_UNPAID_ERROR_TITLE: "All TT Receipt with match chq no. must be paid before create or update receipt payment.",
				MSG_ALL_RECEIPT_CHQ_NO_POST_TITLE: "All TT Receipt with same chq no. must be {post_record} together.\nSystem will auto select other TT Receipt with same chq no {cheque_no} ",
				MSG_ALL_RECEIPT_CHQ_NO_POST_UNSELECT_TITLE:
					"All TT Receipt with same chq no. must be {post_record} together.\nSystem will auto unselect other TT Receipt with same chq no {cheque_no} ",
				MSG_RECEIPT_PAYMENT_NOT_MATCH_AMOUNT:
					"TT Receipt Payment with cheque no. - {cheque_no_list} actual amount not match. System not allow to post TT Receipt unmatch amount with TT Receipt Payment",
				MSG_NOT_ALLOWED_DELETE_REASON_TITLE: "System found this receipt payment has include paid tt receipt",
				MSG_SAME_CHQ_NO_EXIST_TITLE: "Reminder: Same Cheque No. {chq_no} without any receipt paid found in the system",
				MSG_UNABLE_FOUND_GL_ACCOUNT: "Unable Found GL Account"
			},
			TT_ASSIGNMENT: {
				LISTING_TITLE: "TT Assignment",
				CREATE_TITLE: "Create New TT Assignment",
				DETAIL_TITLE: "TT Assignment Detail",
				EDIT_TITLE: "Edit TT Assignment Detail",
				LBL_AGENT: "Agent",
				LBL_CURRENCY: "Currency",
				LBL_REFERENCE_NO: "Reference No.",
				LBL_REFERENCE_NO_FROM: "Reference No. From",
				LBL_REFERENCE_NO_TO: "Reference No. To",
				LBL_CHQ_NO_FROM: "Cheque No From",
				LBL_CHQ_NO_TO: "Cheque No To",
				LBL_DOCUMENT_DATE_FROM: "Document Date From",
				LBL_DOCUMENT_DATE_TO: "Document Date To",
				LBL_TT_ASSIGNMENT_INFO: "TT Assignment Information",
				LBL_DATE: "Document Date",
				LBL_TRANSACTION_TYPE: "Transaction Type",
				LBL_AGENT_ID: "Agent ID",
				LBL_TRANSFER_AGENT_ID: "Fund Transfer Agent ID",
				LBL_BENE_TYPE: "Beneficiary Type",
				LBL_FUND_TRANSFER: "Fund Transfer",
				LBL_TRUNCATE_CENT: "Truncate Cents",
				LBL_CURRENCY_ID: "Currency ID",
				LBL_CURRENCY_RATE: "Currency Rate",
				LBL_SPREAD: "Spread",
				LBL_CHQ_NO: "Cheque No",
				LBL_PURCHASE_GL_ACCOUNT: "Purchase GL Account",
				LBL_REMARK: "Remark",
				LBL_POSTED: "Posted",

				LBL_TT_DETAIL_LIST: "TT Record List",
				LBL_APPLY_AMOUNT: "Apply Amount",
				LBL_ASSIGN: "Assign",
				LBL_ASSIGN_ALL: "Assign All",
				LBL_CLEAR_AMOUNT: "Clear Amount",
				LBL_PAY_BY_DETAIL: "Pay By Information",
				LBL_PAY_AGENT_ID: "Pay Agent ID",
				LBL_PAY_CURRENCY_ID: "Pay Currency ID",
				LBL_PAY_TT_RATE: "Pay TT Rate",
				LBL_PAY_TT_AMOUNT: "Pay TT Amount",
				LBL_PROCESSING_FEE: "Processing Fee",
				LBL_TOTAL_PAY_TT_AMOUNT: "Total Pay TT Amount",
				LBL_PAY_RATE: "Pay Rate",
				LBL_PAY_LOCAL_AMOUNT: "Pay Local Amount",
				LBL_LOCAL_TT_CHARGES: "Local TT Charges",

				LBL_TT_HEADER_DETAIL: "Telegraphic Transfer Record List",
				LBL_AMOUNT_DETAIL: "TT Record Amount Details",
				LBL_AMOUNT: "Amount",
				LBL_LOCAL_AMOUNT: "Local Amount",
				LBL_LOCAL_TRUNCATE_CENT: "Local Truncate Cent",
				LBL_DISCOUNT_AMOUNT: "Discount Amount",
				LBL_LOCAL_DISCOUNT_AMOUNT: "Local Discount Amount",
				LBL_LOCAL_ADJUSTMENT: "Local Adjustment",
				LBL_LOCAL_OTHER_CHARGES: "Local Other Charges",
				LBL_LOCAL_GRAND_AMOUNT: "Local Grand Amount",
				LBL_POSTING: "Posting",
				LBL_UNPOSTING: "Unposting",

				COL_AGENT: "Agent",
				COL_TRANSACTION_TYPE: "Transaction Type",
				COL_CURRENCY_CODE: "Currency Code",
				COL_CURRENCY_RATE: "Currency Rate",
				COL_REFERENCE_NO: "Reference No",
				COL_DOCUMENT_DATE: "Document Date",
				COL_CHQ_NO: "Chq No.",
				COL_TT_AGENT: "TT Agent",
				COL_FUND_TRANSFER_TT_AGENT: "Fund transfer TT Agent",
				COL_TT_GL_ACCOUNT_LIST: "TT GL Account List",
				COL_PURCHASE_GL_ACCOUNT: "Purchase GL Account",
				COL_IS_FUND_TRANSFER: "Is Fund Transfer?",
				COL_CURRENCY: "Currebcy",
				COL_SPREAD: "Spread",
				COL_REMARK: "Remark",
				COL_ASSIGN_AMOUNT: "Assign Amount",
				COL_LOCAL_ASSIGN_AMOUNT: "Local Assign Amount",
				COL_AMOUNT: "Amount",
				COL_LOCAL_AMOUNT: "Local Amount",
				COL_DISCOUNT_AMOUNT: "Discount Amount",
				COL_LOCAL_DISCOUNT_AMOUNT: "Local Discount Amount",
				COL_LOCAL_ADJUSTMENT: "Local Adjustment",
				COL_LOCAL_OTHER_CHARGES: "Local Other Charges",
				COL_TRUNCATE_CENT: "Truncate Cents",
				COL_LOCAL_TRUNCATE_CENT: "Local Truncate Cents",
				COL_LOCAL_GRAND_AMOUNT: "Local Grand Amount",
				COL_PAY_AGENT: "Pay Agent",
				COL_PAY_CURRENCY: "Pay Currency",
				COL_PAY_TT_RATE: "Pay TT Rate",
				COL_PAY_TT_AMOUNT: "Pay TT Amount",
				COL_PROCESSING_FEE: "Processing fee",
				COL_TOTAL_PAY_TT_AMOUNT: "Total Pay TT Amount",
				COL_PAY_RATE: "Pay Rate",
				COL_PAY_LOCAL_AMOUNT: "Pay Local Amount",
				COL_LOCAL_TT_CHARGES: "Local TT Amount",

				COL_CO_ID: "CO. ID",
				COL_CID: "CID",
				COL_DOCUMENT_NO: "Doc No.",
				COL_TT_CHARGES: "TT Charges",
				COL_DEPARTMENT: "Department",
				COL_FINAL_TT_AMT: "Final TT Amt",
				COL_FINAL_TT_AMT_LOCAL: "Final Local TT Amt",
				COL_TOTAL_TT_AMT: "Total TT Amount",
				COL_TOTAL_TT_AMT_LOCAL: "Total Local TT Amt",
				COL_TT_DATE: "TT Date",
				COL_ACKNOWLEDGED_DATE: "Ack. Date",
				COL_OUTSTANDING: "Outstanding Amt",
				COL_ALLOCATED_AMOUNT: "Allocated Amt",
				COL_TRUNCATE: "Truncate",
				COL_BENE_TYPE: "Bene Type",
				COL_CUSTOMER_REMARK: "Customer Remark",
				COL_AR_REMARK: "AR Remark",
				COL_AP_REMARK: "AP Remark",
				COL_PURCHAE_GL_ACC: "Pur GL A/C",

				COL_SELECT: "Select",
				COL_IS_POSTED: "Posted",
				COL_CREATED_USERNAME: "Created By",
				COL_LAST_UPDATED_USERNAME: "Last Updated BY",

				MODULE_NAME: "TT Assignment",

				MSG_CHQ_NO_TO_EMPTY_TITLE: "Chq No. From has a value, Chq No. To cannot be empty.",
				MSG_CHQ_NO_FROM_EMPTY_TITLE: "Chq No. To has a value, Chq No. From cannot be empty.",
				MSG_DIFF_AGENT_SAME_CURRENCY_TITLE: "Different pay by agent cannot choose same TT currency",
				MSG_CURRENCY_ERROR_TITLE: "Please select a currency for convert foreign amount to local amount.",
				MSG_LOCAL_GRAND_AMOUNT_ERROR_TITLE: "Local Grand Amount cannot be less than zero",
				MSG_TOTAL_PAY_TT_AMOUNT_ERROR_TITLE: "Total Pay TT Amount cannot be less than zero",
				MSG_LOCAL_OTHER_CHARGES_ERROR_TITLE: "Local Other Charges cannot be less than zero",
				MSG_CURRENCY_RATE_NO_FOUND_TITLE: "Currency Rate Not Set.",
				MSG_SPECIFIC_CURRENCY_RATE: "{currency} rate not set",
				MSG_OUTSTANDING_ERROR_TITLE: "Outstanding Amount cannot be less than zero",
				MSG_PAY_RATE_ERROR_TITLE: "The system has calculated a negative value in the converted rate. Please confirm that the pay rate and spread value are correctly.",
				MSG_GL_ACC_NO_FOUND_TITLE: "Reminder: Relevant purchase gl account not found in TT Default GL Account.",
				MSG_TT_RECORD_GL_ACCOUNT_NOT_FOUND_TITLE: "TT record's Purchase Gl Account cannot be empty when the assign amount is not equal to zero.",
				MSG_CURRENCY_CODE_NO_FOUND_TITLE: "Local currency code MYR not found from Master Data - Currencies"
			},

			MSG: {
				APPROVE_CAUTION: "Caution",
				OR_AMOUNT_FINAL_AMOUNT_NOT_TALLY: "OR Amount and Final TT Amount is not tally. Are you sure to continue?"
			}
		},
		INVOICE_MANAGEMENT: {
			CREATE_BY_REF_ID_TITLE: "Create Invoice By Ref ID",
			INVOICE_SUMMARY_TITLE: "Invoice Summary",
			INVOICE_CALCULATION_TITLE: "Invoice Service Charges Calculation",
			CREATE_TITLE: "Create New Invoice",
			DETAIL_TITLE: "View Invoice",
			EDIT_TITLE: "Edit Invoice",
			CURR_RATE_DETAIL: "Deposit Rate",
			TITLE_EXPIRE: "<strong>Expire On:</strong> {expire}",
			TITLE_EFFECTIVE: "<strong>Eff. On:</strong> {expire}",
			REF_ID_HEADER: "Ref Id Information",
			DEPOSIT_FINANCE_RATE: "Deposit F-m/up",
			SIMPLE_MODE: "Simple Mode",
			ADD_SO_BY_REF: "Add S/O From Loaded [Ref Id: {ref_id}]",
			REF_ID_SELECTION_TITLE: "Select Ref Id",
			IMPORT_EXCEL_TITLE: "Import Invoice Item(s)",

			MODULE_NAME: "Invoice",
			PREVIEW_INVOICE_TITLE: "Preview Invoice",
			CREATE_BY_REF: {
				SEARCH_FORM: {
					LBL_REF_ID: "Ref Id",
					LBL_LOAD_DATE: "Loaded Date",
					LBL_WAREHOUSE: "Warehouse",

					PH_LOADED_FROM: "Loaded Date From",
					PH_LOADED_TO: "Loaded Date To"
				},
				SUMMARY: {
					COL_SO: "SO No.",
					COL_RECEIVE_DATE: "Received Date",
					COL_DO: "D/O No.",
					COL_TOTAL_ITEM: "Ttl Item",
					COL_TOTAL_CTN: "Ttl Ctn",
					COL_TOTAL_WEIGHT: "Ttl Weight (kg)",
					COL_TOTAL_M3: "Ttl M3",
					COL_CURRENCY_CODE: "Cur.",
					COL_CURRENCY_RATE: "Cur. Rate",
					COL_TOTAL_PURAMT: "Ttl Pur Amt",
					COL_TOTAL_KG_M3: "Ttl kg/m3",
					COL_TOTAL_QUANTITY: "Ttl Quantity",
					COL_RECEIVED_DATE: "Rec. Date",
					COL_SO_REMARK: "SO Remark",
					COL_ORDER_WH_CODE: "Rcv Warehouse"
				},
				SUMMARY_HEADER: {
					LBL_REF_ID: "Ref Id",
					LBL_LOAD_DATE: "Loaded Date",
					LBL_REF_STATUS: "Status",
					LBL_TOTAL_ITEM: "Ttl Item",
					LBL_TOTAL_CTN: "Ttl Ctn",
					LBL_TOTAL_WEIGHT: "Ttl Weight (kg)",
					LBL_TOTAL_M3: "Ttl M3",
					LBL_TOTAL_CM3: "Ttl CM3",
					LBL_SHIPMENT: "Shipment",
					LBL_ORIGIN_COUNRTY: "Origin Country"
				},
				INVOICE_HEADER_SLOT: {},

				SEARCH_FIELD_REQUIRED: "At Least 1 field must be fill in",
				NO_REF_ID_SELECTED: "Please select a Ref Id",
				MESSAGE: {
					LOADING_ID: "Loading ID",
					WAREHOUSE_ID: "Warehouse ID",
					RATE_MISSING: "<strong>{currency_code}</strong> Daily Rate not set.",
					QUOTATION_CHARGES_CURRENCY_RATE_MISSING: "Quotation Currency Charges Rate Not Set",
					QUOTATION_FOUND: "Some Of Marking Quotation Not Found",
					CONFIMATION_SAVE_WITHOUT_QUOTATION: "Some Of Marking Quotation Not Found for this Shipment. Are you sure to continue?",
					CUSTOMER_COSTING_NOT_FOUND: "Found some of customer marking costing not setup. Are you sure to continue?",
					SERVICE_CHARGES_CAT_FOUND: "Invoice Stock ID for Service Charges not set. Please contact PIC."
				}
			},
			REF_STATUS_CLOSED: "Closed",
			INVOICE_FORMAT: {
				NO_ITEM: "No Item",
				NORMAL: "No Item",
				SERVICE_CHARGE_ONLY: "Service Charge",
				DIVIDE_EQUALLY: "Divide Equally",
				NO_SC: "No Sc",
				NO_FORMAT: ""
			},
			LBL_LINK_INVOICE: "Link Inv No.",
			LBL_INVOICE_NO: "Inv. No.",
			LBL_INVOICE_DATE: "Inv. Date",
			LBL_INVOICE_DATE_FROM: "Inv. Date From",
			LBL_INVOICE_DATE_TO: "Inv. Date To",
			LBL_NOTE: "Note",
			LBL_ON_HOLD_REMARK: "Inv. Remark",
			LBL_ON_HOLD: "Inv Status",
			LBL_CURRENCY: "Currency",
			LBL_CURRENCY_RATE: "Curr. Rate",
			LBL_INVOICE_FORMAT: "Inv. Format",
			LBL_AMENDED_COPY: "Amended Copy",
			LBL_PRINT_ZERO_AMT: "Print Zero Amount?",
			LBL_INVOICE_REMARK: "Remark",
			LBL_REF_ID: "Ref ID",
			LBL_LOADED_WAREHOUSE: "Loading Warehouse",
			LBL_QUOTATION: "Quotation",
			LBL_SEPARATE_SEVICE_CHARGES_INV: "Separate S/C Inv",
			LBL_SV_CHG_INV_MARKING: "S/C Inv Marking",
			LBL_INV_PER_SO: "SO/EPO Per Inv.",
			LBL_PLG: "PLG Requested",
			LBL_TAX: "Tax Rate",
			LBL_ITEM: "Item: ",
			LBL_FROM_WMS: "WMS",
			LBL_RECEIVE_DATE: "Received Date",

			LBL_DEPOSIT: "Deposit",
			LBL_ADV_OR: "Advance OR",
			LBL_ADV_OR_TAX: "Adv OR Tax",
			LBL_CUSTOMER_PAID: "Total Customer Paid",
			LBL_TAX_AMT: "Tax Amount",
			LBL_NET_AMT: "Net Amount",
			LBL_ORDER_WAREHOUSE: "Order Wh",
			LBL_DO_NO: "D/O No.",
			LBL_SUPPLIER: "Supplier",
			LBL_IS_SENSITIVE: "Is Sensitive?",

			BTN_ADD_FROM_CONSIGNMENT: "Add From Consignment",
			BTN_DEPOSIT: "Get Deposit",
			BTN_ADVANCE_OR: "Get Advance OR",
			BTN_INV_CALC: "Service Charges",
			BTN_ADD_LOADED: "Add From Loaded",
			BTN_ADD_DO: "Add From D/O",

			INV_DETAILS: {
				COL_SO: "S/O No.",
				COL_DO: "D/O No.",
				COL_STOCK_ID: "Stock ID",
				COL_INV_DESC: "Inv Desc",
				COL_ITEM_CODE: "Item Code",
				COL_STOCK_DESC1: "Stock Desc 1",
				COL_STOCK_DESC2: "Stock Desc 2",
				COL_INV_QTY: "Inv Qty",
				COL_UOM: "Uom",
				COL_BUYING_PRICE: "Buy Price",
				COL_PUR_AMT: "Pur Amt",
				COL_M3: "M3",
				COL_CM3: "CM3",
				COL_FINANCE_CHARGES: "F-m/up Chrg",
				COL_QUOTE_FINANCE_CHARGES_RATE: "Quote F-m/up(%)",
				COL_FINANCE_CHARGES_RATE: "F-m/up(%)",
				COL_PUR_AMT_INC_FM: "PA F-m/up",
				COL_LOCAL_PUR_AMT_INC_FM: "LPA F-m/up",
				COL_COST_METHOD: "Costing Method",
				COL_COSTING_MARKUP: "Costing Markup",
				COL_DISC_AMT: "Disc Amt",
				COL_DEPOSIT: "Deposit",
				COL_SUP_DEPOSIT: "Sup Deposit",
				COL_BUY_PRICE_LOCAL: "Local Buy Price",
				COL_UNIT_PRICE: "Unit Price",
				COL_SERVICE_CHARGES: "Srv Chrg",
				COL_SERVICE_CHARGES_ITEM: "Srv Chrg per item",
				COL_CTN: "CTN",
				COL_KG: "Kg",
				COL_GROSS_AMOUNT: "Gross Amt",
				COL_NET_AMOUNT: "Net Amt",
				COL_TAX_ID: "Tax",
				COL_TAX_RATE: "Tax Rate (%)",
				COL_TAX_AMT: "Tax Amt",
				COL_PACKING: "Packing",
				COL_FINANCE_AMT: "Finance Amt",
				COL_IS_SERVICE_CHARGES: "Is Srv Chrg",
				COL_CUST_DEPOSIT: "Cust Deposit",
				COL_MY_PAID: "My Paid",
				COL_CASH_PAYMENT: "Cash Payment"
			},
			BTN_ADD_NEW: "Add Item",
			INV_LISTING: {
				COL_INVOICE_NO: "Invoice No.",
				COL_INVOICE_DATE: "Invoice Date",
				COL_CREDIT_TERMS: "Credit Terms",
				COL_CURRENCY_CODE: "Currency",
				COL_CURRENCY_RATE: "Curr. Rate",
				COL_REF_ID: "Ref Id",
				COL_INV_FORMAT: "Invoice Format",
				COL_TTL_QTY: "Ttl. Qty",
				COL_TTL_PUR_AMT: "Ttl. Pur Amt",
				COL_TTL_DIS_AMT: "Ttl. Disc Amt",
				COL_TTL_DEPOSIT: "Cust. Deposit",
				COL_TT_DEPOSIT: "TT Deposit",
				COL_TTL_GROSS_AMT: "Ttl Gross Amt",
				COL_TTL_NET_AMT: "Ttl Net Amt",
				COL_TTL_CTN: "Ttl. CTN",
				COL_TTL_M3: "Ttl. M3",
				COL_TTL_KG: "Ttl. KG",
				COL_TTL_TAX_AMT: "Ttl. Tax Amt",
				COL_TTL_SERVICE_CHARGES: "Ttl. Service Charges",
				COL_ON_HOLD: "On hold Status",
				COL_TTL_ITEMS: "Ttl Items",
				COL_TTL_CM3: "Ttl Cm3"
			},
			CURR_RATE_FORMULA: {
				RATE_TYPE: "Rate Type",
				DAILY_RATE: "Daily Rate",
				DAILY_RATE_MESSAGE: "No daily rate has been set. Default rate use as daily rate.",
				RECEIVE_AMT: "Rec. Amt",
				TT_DEPOSIT: "TT Deposit",
				TT_DEPOSIT_LOCAL_AMT: "TT Deposit Local Amt",
				BAL_AMT: "Bal Amt",
				BAL_LOCAL_AMT: "Bal Local Amt",
				DEPOSIT_REF_NO: "Deposit Ref No.",
				DEPOSIT_DATE: "Deposit Date",
				USED_DEPOSIT: "Used Deposit",
				DEPOSIT_CURR_CODE: "Curr. Code",
				DEPOSIT_CURR_RATE: "Curr. Rate",
				TT_CURR_CODE: "TT Curr. Code",
				TT_CURR_RATE: "TT Curr. Rate",
				DEPOSIT_PAY_AMT: "Deposit Amt",
				DEPOSIT_TT_PAY_AMT: "TT Amt",
				DEPOSIT_LOCAL_AMT: "Deposit Local Amt",
				INV_RATE_CAL: "Inv Rate Cal",
				BAL_LOCAL_AMT_CAL: "Bal Local Amt Cal",
				WITHOUT_OR: "Without OR",
				FINANCE_RATE: "F-m/up(%)",
				ACTUAL_FINANCE_RATE: "Final F-m/up(%)",
				FINANCE_AMT: "F-m/up Amt",
				SUPP_DEPOSIT: "Sup. Deposit"
			},
			INVOICE_RATE_TYPE: {
				NORMAL: "Normal",
				FIX_RATE: "Fix Rate",
				SPECIAL_RATE: "Special Rate"
			},
			INV_CALC: {
				QUOTATION_NO: "Quote. No.",
				MIN_CHARGES: "Min Chg",
				SERVICE_CHARGE: "Ser. Chg",
				BASE_CHARGES: "Base Chg",
				MSM_CHARGES: "Msm. Chg",
				SM_CHARGES: "Sm. Chg",
				CUSTOMER_CHG: "Cust. Chg",
				CATEGORY_TYPE: "Cat Type",
				CAT_CHG: "Cat Chg",
				SPECIAL_CHARGES: "Special Chg",
				TTL_SERVICE_CHARGE: "Ttl Charges",
				STANDARD_CHARGE: "Charges",
				ADDITIONAL_CHARGE: "Add. Charges",
				STANDARD_CHARGES_TITLE: "Standard Chrg",
				EXCEPTION_CHARGES_TITLE: "Exceptional Charges",
				TRANSIT_CHARGES_TITLE: "Transit Charges",
				ROUTE_NAME: "Route",
				TRANSIT_CHG: "Transit Chg",
				OVER_CHG: "Over Chg",
				CURRENCY_RATE: "Curr. Rate",
				CURRENCY_CODE: "Curr",
				LOCAL_SERVICE_CHARGE: "Local Ser. Chg",
				CHARGES_TYPE: "Chg Type"
			},
			MSG_NO_QUOTATION: "{marking} quotation not found for this shipment.",
			MSG_CAT_COST_NOT_FOUND: "{cat_name}[{cat_type_name}] not found for this shipment",
			MSG_CUSTOMER_COSTING_NOT_FOUND: "The customer cost not yet setup for this shipment",
			MSG_FAILED_VALIDATE_LINK_INV: "Failed to validate {name}",
			MSG_INV_NO_NOT_FOUND: "{inv_no} not found",
			MSG_ASSIGNED_OTHER_IV: "{inv_no} linked with other invoice no",
			MSG_UNLINK_INV: "{inv_no} successfull unlink",
			MSG_SELETED_ITEM: "No item has been selected",
			MSG_IV_DETAIL_REF_ID_NOT_FOUND: "Ref Id: {ref_id} S/O not found",
			MSG_IV_DETAIL_DO_NO_ID_NOT_FOUND: "D/O No: {do_no} S/O not found",
			MSG_FAILED_VALID_DO_NO: "Failed to validated this {do_no} D/O No.",
			MSG_DO_NO_NOT_EXISTS: "This {do_no} D/O No not exists",
			MSG_REF_ID_INVALID: "{ref_id} not found.",
			MSG_DUPLICATE_REF_ID: "Found Duplicate Ref Id. Please Select.",
			MSG_IMPORT_IV_DETAIL_COLUMN_NOT_MERGE: "Invoice detail column not match",
			MSG_SO_MARKING_DIFF: "Customer Marking different than S/O Customer Marking",
			MSG_SO_DELIVERY_ID_DIFF: "Delivery Id different than S/O Delivery Id",
			MSG_SO_CURRENCY_DIFF: "Currency different than S/O Currency",
			MSG_SO_SENSITIVE_DIFF: "Is Sensitive value different than S/O set",
			MSG_SO_SUPPLIER_DIFF: "Supplier different than S/O Supplier",
			MSG_DETAIL_FIELD_NOT_FOUND: "Row: {idx}, {field}: {value} not found",
			MSG_DETAIL_FIELD_DUPPLICATE: "Row: {idx}, {field}: {value} duplicate",
			MSG_SELECTED_DIFF_IV: "{name} selected different than import",
			MSG_REPLACE_CRRENCY_RATE: "{name} entred different than import.<br /> Press '{btn_name}' to replace entered value",
			MSG_REFRESH_RATE_CONFIRMATION: "Invoicce Date has been changed. Currncy Rate will be refresh. Press '{btn_name}' to continue.<br /> Press '{cancel_btn_name}' to dismiss."
		}
	},
	CUSTOMER_MANAGEMENT: {
		CUSTOMER: {
			LISTING_TITLE: "Customer Listing",
			CREATE_TITLE: "Create New Customer",
			DETAIL_TITLE: "Customer Detail",
			EDIT_TITLE: "Edit Customer Detail",
			RESET_LOGIN_TITLE: "Reset Login Email/Password",

			CREATE_CUSTOMER_MARKING_TITLE: "Create New Customer Marking",

			TITLE_CUSTOMER_LOGIN_INFO: "Login Information",
			TITLE_CUSTOMER_BASIC_INFO: "Basic Information",
			TITLE_CUSTOMER_BILLING_ADDRESS_INFO: "Billing Address Information",
			TITLE_CUSTOMER_COMPANY_INFO: "Company Information",
			TITLE_CUSTOMER_TAX_EINV: "Tax & E-Invoice",

			LBL_CUSTOMER_SELECT_TYPE: "N",
			LBL_INCL_NO_MARKING_SET: "Incl No Marking",
			LBL_ONLY_NO_MARKING: "Show No Marking Only",

			LBL_CUSTOMER_LOGIN_INFO_TYPE: "Customer Login",
			LBL_CUSTOMER_LOGIN_EXITING_INFO: "Customer Login Selection",

			LBL_LOGIN_MSM_REFERRAL_CODE: "Referral Main Salesman",
			LBL_LOGIN_SM_REFERRAL_CODE: "Referral Salesman",
			LBL_CUSTOMER_MARKING: "Marking",
			LBL_CUSTOMER_DEBTOR_ID: "Debtor Id",
			LBL_CUSTOMER_PARTNER_NAME: "Partner Name",
			LBL_CUSTOMER_NAME: "Partner Name",
			LBL_CUSTOMER_CUSTOMER_TYPE: "Customer Type",
			LBL_CUSTOMER_NRIC_OR_BRN: "NRIC / BRN",
			LBL_CUSTOMER_EMAIL: "Email",
			LBL_CUSTOMER_NICKNAME: "Login Nickname",
			LBL_CUSTOMER_MOBILE_NO: "Mobile No",
			LBL_CUSTOMER_PASSWORD: "Password",
			LBL_CUSTOMER_CONFIRM_PASSWORD: "Confirm Password",
			LBL_CUSTOMER_CONTROL_ACCOUNT: "Control Account",
			LBL_CUSTOMER_MAINSALESMAN: "Main Salesman",
			LBL_CUSTOMER_SALESMAN: "Salesman",
			LBL_CUSTOMER_CURRENCY: "Invoice Currency",
			LBL_CUSTOMER_CREDIT_TERM: "Credit Term",
			LBL_CUSTOMER_NOTICE_TYPE: "Notice Type",
			LBL_CUSTOMER_CREDIT_TERM_DAYS: "Term Days{terms_name}",
			LBL_CUSTOMER_IS_DEFAULT: "Is Default",
			LBL_CUSTOMER_IS_VERIFIED: "Is Verified",
			LBL_UPLOAD_BRN: "Upload NRIC/BRN",
			LBL_CUSTOMER_GST_NO: "GST No",
			LBL_CUSTOMER_GST_VERIFY_DATE: "GST Verify Date",
			LBL_CUSTOMER_GST_USE_NONE_DEFAULT_CODE: "Use None Default GST Code",
			CHECKBOX_USE_NONE_DEFAULT: "Use None Default",
			LBL_CUSTOMER_DEFAULT_INVOICE_FORMAT: "Default Invoice Format",
			LBL_SPECIAL_RATE: "Special Rate",
			LBL_SPECIAL_RATE_REMARK: "Special Rate Remark",
			LBL_SM_FINANCE_RATE: "Salesman Finance Rate",
			LBL_FINANCE_RATE: "Finance Rate",
			LBL_USE_CUSTOMER_DO_HEADER: "Use Customer D/O Header",
			LBL_ENABLE_EPO_INVOICE_DESC: "Use EPO Invoice Desc",
			LBL_CUSTOMER_SERVICE_CHG_INV_MARKING: "S/C Invoice Marking",

			LBL_CUSTOMER_USERNAME: "Email",
			LBL_CUSTOMER_RE_PASSWORD: "Confirm Password",
			LBL_CUSTOMER_OVERDUE_LIMIT: "Overdue Limit",
			LBL_CUSTOMER_BILLING_ADDRESS: "Billing Address",
			LBL_CUSTOMER_CONTACT_PERSON_NAME: "Contact Person Name",
			LBL_CUSTOMER_CONTACT_EMAIL: "Contact Email",
			LBL_CUSTOMER_CONTACT_MOBILE_NO: "Contact Mobile No",
			LBL_CUSTOMER_CONTACT_OFFICE_NO: "Contact Office No",
			LBL_CUSTOMER_CONTACT_FAX_NO: "Contact Fax No",
			LBL_CUSTOMER_COMPANY_NAME: "Company Name",
			LBL_CUSTOMER_COMPANY_WEBSITE: "Company Website",
			LBL_CUSTOMER_TAX_TYPE: "Tax Type",
			LBL_CUSTOMER_TAX: "Tax",
			LBL_CUSTOMER_CTOS_NO: "CTOS Account",
			LBL_CUSTOMER_CTOS_DATE: "CTOS Date",

			LBL_CUSTOMER_BILLING_ADD: "Billing Address",
			LBL_CUSTOMER_BILLING_ADD_DESCRIPTION: "Address Description",
			LBL_CUSTOMER_BILLING_ADD_REMARK: "Attention",
			LBL_CUSTOMER_BILLING_ADD_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_BILLING_ADD_ADDRESS: "Billing Address",
			LBL_CUSTOMER_BILLING_ADD_COUNTRY: "Country",
			LBL_CUSTOMER_BILLING_ADD_STATE: "State",
			LBL_CUSTOMER_BILLING_ADD_CITY: "City",
			LBL_CUSTOMER_BILLING_ADD_OTHER_CITY: "Other City",
			LBL_CUSTOMER_BILLING_ADD_POSTCODE: "Postcode",
			LBL_CUSTOMER_BILLING_ADD_OTHER_POSTCODE: "Other Postcode",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_CONTACT_PIC_NAME: "Mobile Contact PIC Name",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_CONTACT_CALLING_CODE: "Mobile Contact Calling Code",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_CONTACT_NO: "Mobile Contact No",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_ALT_CONTACT_PIC_NAME: "Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_ALT_CONTACT_NO: "Mobile Alt Contact No",
			LBL_CUSTOMER_BILLING_ADD_MOBILE_ALT_CONTACT_CALLING_CODE: "Mobile Alt Contact CALLING CODE",
			LBL_CUSTOMER_BILLING_ADD_OFFICE_TEL_PIC_NAME: "Office Tel PIC Name",
			LBL_CUSTOMER_BILLING_ADD_OFFICE_TEL_NO: "Office Tel No",
			LBL_CUSTOMER_BILLING_ADD_OFFICE_TEL_CALLING_CODE: "Office Tel Calling Code",
			LBL_CUSTOMER_BILLING_ADD_WEBSITE: "Website",
			LBL_CUSTOMER_BILLING_ADD_EMAIL: "Email",
			LBL_CUSTOMER_BILLING_ADD_ALT_EMAIL: "Alt Email",

			LBL_CUSTOMER_MARKING_BILLING_ADD: "Billing Address",
			LBL_CUSTOMER_MARKING_BILLING_ADD_DESCRIPTION: "Address Description",
			LBL_CUSTOMER_MARKING_BILLING_ADD_REMARK: "Attention",
			LBL_CUSTOMER_MARKING_BILLING_ADD_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_ADDRESS: "Billing Address",
			LBL_CUSTOMER_MARKING_BILLING_ADD_COUNTRY: "Country",
			LBL_CUSTOMER_MARKING_BILLING_ADD_STATE: "State",
			LBL_CUSTOMER_MARKING_BILLING_ADD_CITY: "City",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OTHER_CITY: "Other City",
			LBL_CUSTOMER_MARKING_BILLING_ADD_POSTCODE: "Postcode",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OTHER_POSTCODE: "Other Postcode",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_CONTACT_PIC_NAME: "Mobile Contact PIC Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_CONTACT_NO: "Mobile Contact No",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_ALT_CONTACT_PIC_NAME: "Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_MOBILE_ALT_CONTACT_NO: "Mobile Alt Contact No",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OFFICE_TEL_PIC_NAME: "Office Tel PIC Name",
			LBL_CUSTOMER_MARKING_BILLING_ADD_OFFICE_TEL_NO: "Office Tel No",
			LBL_CUSTOMER_MARKING_BILLING_ADD_WEBSITE: "Website",
			LBL_CUSTOMER_MARKING_BILLING_ADD_EMAIL: "Email",
			LBL_CUSTOMER_MARKING_BILLING_ADD_ALT_EMAIL: "Alt Email",
			LBL_CUSTOMER_MARKING_USE_BILLING_MARKING: "Use Marking Billing?",
			LBL_CUSTOMER_MARKING_NOTICE_TPYE_DUE_DAYS: "PN Due Days",

			LBL_CUSTOMER_PREFERENCE_TERRITORY: "Territory",
			LBL_CUSTOMER_PREFERENCE_INDUSTRIES: "Industries",
			LBL_CUSTOMER_PREFERENCE_PARTNER_TYPE: "Partner Type",
			LBL_CUSTOMER_PREFERENCE_GL_SALES_ACCOUNT: "GL Sales Account",
			LBL_CUSTOMER_PREFERENCE_STATEMENT_TYPE: "Statement Type",
			LBL_CUSTOMER_PREFERENCE_TT_GROUP: "TT Group",
			LBL_CUSTOMER_PREFERENCE_ISSUE_INVOICE_ONE_SO: "SO/EPO Per Invoice",
			LBL_CUSTOMER_PREFERENCE_INV_WEIGHT_DECIMAL_PLACE: "Invoice m3 decimal place",
			LBL_CUSTOMER_PREFERENCE_SEPARATE_GODD_AMT_SEV_CHARGE: "Separate Service Charges Invoice",
			LBL_CUSTOMER_PREFERENCE_PURCHASE_PLG: "Parcel Guanrantee",
			LBL_CUSTOMER_PREFERENCE_INV_M3_MARKUP: "Invoice m3 Markup (%)",

			LBL_CUSTOMER_CREDIT_CONTROL_CREDIT_LIMIT: "Credit Limit",
			LBL_CUSTOMER_CREDIT_CONTROL_CREDIT_LIMIT_OVER_ACTION_TYPE: "Action On Credit Limit was over",
			LBL_CUSTOMER_CREDIT_CONTROL_COMBINE_LIMIT: "Combine Limit",
			LBL_CUSTOMER_CREDIT_CONTROL_COMBINE_LIMIT_OVER_ACTION_TYPE: "Action On Combine Limit was Over",

			LBL_CUSTOMER_CREDIT_CONTROL_OVERDUE_DAYS: "Overdue Days",
			LBL_CUSTOMER_CREDIT_CONTROL_OVERDUE_LIMIT: "Overdue Limit",

			LBL_CUSTOMER_NOTE_INVOICE_NOTE: "Invoice Note",
			LBL_CUSTOMER_NOTE_AR_NOTE: "AR Note",

			LBL_CUSTOMER_TRANSACTION_DESC_INVOICE_DESC: "Invoice Description",
			LBL_CUSTOMER_TRANSACTION_DESC_DN_DESC: "DN Description",
			LBL_CUSTOMER_TRANSACTION_DESC_CN_DESC: "CN Description",
			LBL_CUSTOMER_TRANSACTION_DESC_PAYMENT_DESC: "Transaction Description",

			LBL_CUSTOMER_INTEREST_PENALTY_PA_TITLE: "PA (Purchase)",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_MODE: "PA Penalty Mode",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_RATE: "PA Penalty Rate",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_TERMS_DAY: "PA Penalty Terms Day",
			LBL_CUSTOMER_INTEREST_PENALTY_PA_ALLOW_DAY: "PA Penalty Allow Day",

			LBL_CUSTOMER_INTEREST_PENALTY_SC_TITLE: "SC (Service Charge)",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_MODE: "SC Penalty Mode",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_RATE: "SC Penalty Rate",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_TERMS_DAY: "SC Penalty Terms Day",
			LBL_CUSTOMER_INTEREST_PENALTY_SC_ALLOW_DAY: "SC Penalty Allow Day",

			LBL_CUSTOMER_INTEREST_PENALTY_TA_TITLE: "TA (TAX)",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_MODE: "TA Penalty Mode",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_RATE: "TA Penalty Rate",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_TERMS_DAY: "TA Penalty Terms Day",
			LBL_CUSTOMER_INTEREST_PENALTY_TA_ALLOW_DAY: "TA Penalty Allow Day",

			LBL_CUSTOMER_CTOS_CTOS_DATE: "CTOS Date",
			LBL_CUSTOMER_CTOS_CTOS_ACCOUNT: "CTOS ACC",
			LBL_CUSTOMER_CTOS_MONITORING_DATE: "Monitoring Date",
			LBL_CUSTOMER_CTOS_ETR_SUBMISSION_DATE: "eTR Submission Date",
			LBL_CUST_TRANS_SETTINGS: "Transaction Settings",
			LBL_CUST_CURRENCY_SETTINGS: "Currency Settings",
			LBL_CUST_INV_FORMAT_DIVIDE_ADD_SC: "Divide Add. Service Charges",
			LBL_ENABLE_ALLOW_ACCESS_SUB_ACCOUNT: "Access Sub Account",

			LBL_SST_EXEMPT: "SST Exempted?",
			LBL_A8_KCG_INV_FORMAT: "A8 To Kcg Inv Format",
			LBL_NEW_NRIC_OR_BRN: "New Register No.",
			LBL_NRIC: "Nric",
			LBL_BRN: "Old Register No.",
			LBL_TIN_NO: "Tin No.",
			LBL_GENERAL_TIN_NO: "General Tin No.",
			LBL_TIN_NO: "Tin No.",
			LBL_GENERAL_TIN: "General Tin?",
			LBL_EDIT_TIN_VALIDATED: "Enter Marking To Clear Validated",
			LBL_TIN_VALIDATED: "Tin Validated",
			LBL_TIN_VALIDATED_DATE: "Validated On",
			LBL_EINV_EMAIL: "E-Invoice Email",
			LBL_EINV_CONTACT_NO: "E-Invoice Contact No.",
			LBL_EINV_CONTACT: "E-Invoice Contact",
			LBL_BLOCK_SEND_WELCOME_MSG: "Block Send Msg",
			LBL_BLOCK_SEND_WELCOME_MSG_BY: "Block Send Msg By",
			LBL_UNBLOCK_SEND_WELCOME_MSG_BY: "UnBlock Send Msg By",
			LBL_ONLY_BLOCK_SEND_WELCOME_MSG: "Only Block Send Msg By",
			LBL_RESET_PASSWORD: "Reset Password",
			LBL_PASSWORD: "Password",
			LBL_SEND_EMAIL_TO_CUSTOMER: "Send Email",

			LBL_CUSTOMER_CREATED_ON: "Customer Created On",
			LBL_MARKING_CREATED_ON: "Customer Marking Created On",
			LBL_SIGINUP_ONLY: "Sign Up Only",

			TAB_TITLE_ADDRESS: "Address",
			TAB_TITLE_CONTACT: "Contact",
			TAB_TITLE_MARKING_BILLING: "Marking Billing",
			TAB_TITLE_PREFERENCE: "Preference",
			TAB_TITLE_CREDIT_CONTROL: "Credit Control",
			TAB_TITLE_NOTE: "Note",
			TAB_TITLE_TRANSACTION_DESC: "Transaction Desc",
			TAB_TITLE_INTEREST_PENALTY: "Interest Penalty",
			TAB_TITLE_CTOS: "CTOS",
			TAB_TITLE_NEW_MARKING: "New Marking",
			TAB_TITLE_TRANSACTION_SETTINGS: "Transaction & Currecy Rate Settings",

			MODULE_NAME: "Customer",
			CUSTOMER_MARKING_MODULE_NAME: "Customer Marking",

			BTN_CREATE_NEW_CUSTOMER_MARKING: "New Marking",
			BTN_SAVE_NEW_CUSTOMER_MARKING: "Save New Marking",
			BTN_LOGIN: "Login As Customer",
			BTN_VALID_TIN: "Validate Tin",
			BTN_RESET_LOGIN: "Reset Login Email/Password",

			COL_COMPANY: "Company",
			COL_MAINSALESMAN: "Main Salesman",
			COL_SALESMAN: "Salesman",
			COL_NAME: "Name",
			COL_REGISTER_NO: "Nric/New Register No",
			COL_OLD_REGISTER_NO: "Old Register No",
			COL_CUSTOMER_SUPPLIER_EMAIL: "Customer Supplier Email",
			COL_MARKING: "Marking",
			COL_MARKING_STATUS: "Marking Status",
			COL_CREDIT_TERMS: "Credit Terms",
			COL_INV_FORMAT: "Inv Format",
			COL_MARKING_CREATED_ON: "Marking Created On",
			COL_REFERRAL_SM: "Referral SM",

			DESC_CUSTOMER_MARKING_BILLING_ADD_ALT_EMAIL: "Able to enter multiple email by semicolon(;), etc:gim_admin@gim.com;gim_admin2@gim.com;gim_admin3@gim.com",

			MESSAGE_SWITCH_NEW_MARKING_TAB: "Current marking info changes will lose. (Ignore if you didn't make any changes or saved)",
			MSG_SERVICE_CHG_INV_MARKING: "For new marking, left it blank the service charges invoice marking is same as goods amount invoice.",

			CUSTOMER_EMAIL_EXISTS: "Customer Login email exists",
			CUSTOMER_NAME_EXISTS: "Partner Name exists. (New Register No: {register_no}.)",
			NRICORBRN_EXISTS: "New Register No exists. (Partner Name: {partner_name}.)",
			CUSTOMER_MARKING_UNIQUE_ERROR: "Marking must be unique for same company group",
			BTN_CANCEL_NEW_MARKING: "Cancel New Marking",
			MSG_SWITCH_EPO_INVOICE_DESC: "{name} in customer marking will be change.",
			MSG_QUOTATION_INQ: '<div class="text-left"><strong>Credit Terms/Finance Rate/Interest Penalty has changed may impact below inquiry quotation(s):</strong><br />{inquiry_no}</div>',
			STATEMENT_TYPE: {
				OPEN_ITEM: "Open Item",
				BROUGHT_FORWARD: "Brought Forward"
			},
			LOGIN_TYPE: {
				NEW: "New",
				EXISTING: "Existing"
			},
			OVER_ACTION_TYPE: {
				NO_ACTION: "No Action",
				NOTIFY_N_CONTINUE: "Notify and Continue",
				NOTIFY_N_BLOCK: "Notify and Block",
				AUTHORIZE_REQUIRED: "Authorize Required"
			},
			INTEREST_PENALTY_MODE: {
				BY_INVOICE: "By Invoice",
				BY_ERC_DATE: "By ERC Date",
				BY_SO_DATE: "By SO Received Date",
				BY_TT_DATE: "By TT Date"
			},
			CUSTOMER_SELECT_TYPE: {
				INDIVIDUAL: "Individual",
				COMPANY: "Company"
			},
			CURRENCY_TABLE: {
				CURRENCY_CODE: "Curr. Code",
				INV_RATE: "Invoice Curr. Rate"
			},
			MSG_SAVE_BILL_AS_DELIVERY_ADDRESS: "Save As Delivery Address. Are you sure to continue?",
			MESSAGE_DUE_DAYS_CONFIRMATION: "Due Days is 0. Are you sure to continue?",
			MESSAGE_CREDIT_LIMIT_CONFIRMATION: "Credit Limit is 0. Are you sure to continue?",
			BTN_ADD_SPECIAL_RATE: "Add Currency TT Group",
			MESSAGE_CANCEL_ADD_NEW_MARKING: "The new customer marking detail will be removed without save. Are you sure to continue?",
			MESSAGE_REQUIRED_CURRENCY_GROUP: "Currency Settings with TT Group is required",
			STATUS: {
				UNVERIFIED: "Un-verified",
				VERIFIED: "Verified",
				LOCKED: "Locked",
				INACTIVE: "Inactive"
			},
			STATUS_MSG: "Login email {status}. {contact_customer}",
			STATUS_MSG1: "Please contact customer to verify email.",
			MARKING_FORMAT_VALIDATION: {
				MARKING_LENGTH: "Must be {min} to {max} character.",
				MARKING_SPECIAL_CHARACTER: "Allow {len} special character({special_character}) character only/Any alphanumeric character.",
				MARKING_UNIQUE: "Marking is available"
			},
			MSG_BLOCK_CREATE_ACCOUNT: "Found account not yet assign marking, please set it first instead of create new account. Below accounts not yet assign marking:<br />{name}",
			MSG_EDIT_DELIVERY_ADDRESS: "Billing Address saved as delivery address. Are you want viewing delivery address detail?",
			MSG_BLOCK_CREATE_DIFFERENT_SALESMAN_MARKING_SAME_COUNTRY: "You not allow create marking with Salesman Code ({salesman}) for {country}",
			MSG_CONFIRM_DUPLICATE_SALESMAN: "Below salesman created marking for {country}: <br />{salesman}",
			MSG_BLOCK_REGISTER_ACCOUNT: "{partner_name} and {register_no} regsitered with another login email. Marking not yet created. Please use back instead create new account",
			MSG_DUPLCATE_REGISTER_ACCOUNT: "Referral Salesman for {partner_name} account already registered with another email ",
			MSG_CONFIRM_REGSITER_ACCOUNT: "{partner_name} regsitered with another login email. Are you sure to continue?",
			MSG_CONFIRM_USE_LOGIN_EMAIL: "Login Email registered. Are you sure to continue?",
			MSG_DATABASE_ANNOTATION_NULL_OR_EMPTY: "Database annotation is not set.",
			MSG_DATABASE_ANNOTATION_NOT_VALID: "Database annotation is not valid.",
			MSG_SUB_ACCOUNT_DESC: "Allow the customer to access User Management in Customer Portal",
			MSG_MARKING_DUPLICATE_CONFIRMATION: '{current_marking} duplicate with below marking(s), Are you sure to continue save?<br> <span class="text-primary">{markings}</span>',
			MSG_SUCCESS_RESET_EMAIL_PASSWORD: "Email/Password was successful to changed"
		},
		DELIVERY_ADDRESS: {
			LISTING_TITLE: "Delivery Address Listing",
			CREATE_TITLE: "Create New Delivery Address",
			DETAIL_TITLE: "Delivery Address Detail",
			EDIT_TITLE: "Edit Delivery Address Detail",
			MODULE_NAME: "Delivery Address",

			TITLE_DELIVERY_ADDRESS_BASIC_INFO: "Basic Information",
			TITLE_DELIVERY_ADDRESS_ADDRESS_AND_INFO: "Delivery Address & Contact Info",
			TITLE_DELIVERY_ADDRESS_SETTINGS: "Settings",

			LBL_DELIVERY_ADDRESS_DELIVERY_ID: "Delivery Address Id",
			LBL_DELIVERY_ADDRESS_DELIVERY_ADDRESS_DESCRIPTION: "Name/Company name",
			LBL_DELIVERY_ADDRESS_DEFAULT_BILLING: "Default Billing Address ?",
			LBL_DELIVERY_ADDRESS_MARKING: "Marking",
			LBL_DELIVERY_ADDRESS_CUSTOMER: "Customer",
			LBL_DELIVERY_ADDRESS_PAYMENT_TYPE: "Payment Type",
			LBL_DELIVERY_ADDRESS_NOTICE_TYPE_ID: "Notice Type ID",
			LBL_DELIVERY_ADDRESS_PRINT_ADDRESS_IN_INVOICE: "Print Address In Invoice",
			LBL_DELIVERY_ADDRESS_PRINT_DELIVERY_ADDRESS: "Print Delivery Address",
			LBL_DELIVERY_ADDRESS_CONTACT_NAME: "Contact Name",
			LBL_DELIVERY_ADDRESS_CONTACT_EMAIL: "Email",
			LBL_DELIVERY_ADDRESS_CONTACT_WEBSITE: "Contact Website",
			LBL_DELIVERY_ADDRESS_BUILDING_NAME: "Building Name",
			LBL_DELIVERY_ADDRESS_ADDRESS: "Address",
			LBL_DELIVERY_ADDRESS_POSTCODE: "Postcode",
			LBL_DELIVERY_ADDRESS_OTHER_POSTCODE: "Other Postcode",
			LBL_DELIVERY_ADDRESS_CITY: "City",
			LBL_DELIVERY_ADDRESS_OTHER_CITY: "Other City",
			LBL_DELIVERY_ADDRESS_STATE: "State",
			LBL_DELIVERY_ADDRESS_COUNTRY: "Country",
			LBL_DELIVERY_ADDRESS_TEL_NO: "Office Contact No",
			LBL_DELIVERY_ADDRESS_TEL_PIC: "Office Contact Person In Charge",
			LBL_DELIVERY_ADDRESS_TEL_NO_WITH_PIC: "Tel No + PIC",
			LBL_DELIVERY_ADDRESS_MOBILE_NO: "Mobile Contact No",
			LBL_DELIVERY_ADDRESS_MOBILE_PIC: "PIC (MOBILE)",
			LBL_DELIVERY_ADDRESS_MOBILE_NO_WITH_PIC: "Mobile No + PIC",
			LBL_DELIVERY_ADDRESS_FAX_NO: "Fax No",
			LBL_DELIVERY_ADDRESS_RECEIVING_HOUR: "Receiving Hour",
			LBL_DELIVERY_ADDRESS_REMARK_ID: "Delivery Remark",
			LBL_DELIVERY_ADDRESS_REMARK: "Remark",
			LBL_DELIVERY_ADDRESS_USE_CUSTOMER_DO: "Use Customer DO ?",
			LBL_DELIVERY_ADDRESS_CUSTOMER_DO_HEADER_ID: "Customer Do Header ID",
			LBL_DELIVERY_ADDRESS_RESHIP: "Reship?",
			LBL_DELIVERY_ADDRESS_EXP_FORWARDER_ID: "Exp Forwarder ID",
			LBL_DELIVERY_ADDRESS_FORWARDER_COSTING_TYPE: "Forwarder Costing Type",
			LBL_DELIVERY_ADDRESS_AUTHORISED_LETTER: "Authorised Letter ?",
			LBL_DELIVERY_ADDRESS_HAS_ESYSTEM_ID: "Has E-System ID ?",
			LBL_DELIVERY_ADDRESS_GO_UPSTAIRS: "Go Upstairs ?",
			LBL_DELIVERY_ADDRESS_UPSTAIRS_COSTING_TYPE: "U/P Costing Type",
			LBL_DELIVERY_ADDRESS_FOLLOW_DELIVERY_ID_DUE_DAY: "Follow Delivery ID Due Day ?",
			LBL_DELIVERY_ADDRESS_DUE_DAYS: "Due Days",
			LBL_DELIVERY_ADDRESS_BY_AIR_SPECIAL_BILLING: "By Air Special Billing ?",
			LBL_DELIVERY_ADDRESS_BRN: "NRIC / BRN",
			LBL_DELIVERY_ADDRESS_MOBILE_NO_ALT: "Mobile Contact No (Alt)",
			LBL_DELIVERY_ADDRESS_MOBILE_NO_ALT_PIC: "Mobile Contact (Alt) Person In Charge",
			LBL_DELIVERY_ADDRESS_CUSTOMER_NAME: "Customer Name",
			LBL_DELIVERY_ADDRESS_RECEIVING_DAY: "Receiving Day",
			LBL_DELIVERY_ADDRESS_RECEIVING_REMARK: "Receiving Time Remark",
			LBL_DELIVERY_ID_DUPLICATE: "Only Duplicate Del Id",

			COL_DELIVERY_ID: "Delivery ID",
			COL_CUSTOMER_NAME: "Customer Name",
			COL_MARKING: "Marking",
			COL_CODE: "Code",
			COL_NAME: "Name",
			COL_COMPANY_NAME: "Delivery Address Description / Company Name",
			COL_MSM_NAME: "Main Salesman",
			COL_SM_NAME: "Salesman",
			COL_CONTACT_NO: "Mobile Contact No",
			COL_MOBILE_PIC: "Person In Charge",
			COL_UPSTAIRS: "Upstairs?",
			COL_COUNTRY: "Country",
			COL_STATE: "State",
			COL_CITY: "City",
			COL_POSTCODE: "Postcode",

			PH_RECEIVING_HOUR_FROM: "-- Receiving Hour From --",
			PH_RECEIVING_HOUR_TO: "-- Receiving Hour To --",

			PH_RECEIVING_DAY_FROM: "-- Receiving Day From --",
			PH_RECEIVING_DAY_TO: "-- Receiving Day To --",

			DELIVERY_ID_VALIDATION: {
				DELIVERY_ID_LENGTH: "Must be {min} to {max} character.",
				DELIVERY_ID_SPECIAL_CHARACTER: "Allow {len} special character({special_character}) character only/Any alphanumeric character.",
				DELIVERY_ID_UNIQUE: "Delivery ID is available",
				DELIVERY_ID_UNIQUE_WITH_CUSTOMER: "<Strong>Delivery ID available with below customer(s)</strong>:<br /> {customer_list}"
			},

			MSG_MARKING_ID_SAVE_AS_DELIVERY_ID: "Delivery Id will be assign after 1st marking id generated.",
			MSG_CANNOT_EDIT__DELIVERY_ID_MARKING: "Delivery Id assigned with 1st marking id generated. You not allow to edit",
			MSG_BLOCK_UPDATE_DELIVERY_ID: "Delivery Id used in Quotation/EPO, you are not allow to edit",
			MSG_BLOCK_UPDATE_COUNRTY_STATE_ID: "Delivery Address used in Quotation/EPO, you are not allow to edit country/state.",
			MSG_BLOCK_EDIT_ADDRESS: "Having Transaction not completed. You not allow to edit Address"
		},
		CUSTOMER_SUPPLIER: {
			LISTING_TITLE: "Customer Supplier Listing",
			CREATE_TITLE: "Create New Customer Supplier",
			DETAIL_TITLE: "Customer Supplier Detail",
			EDIT_TITLE: "Edit Customer Supplier",
			MODULE_NAME: "Customer Supplier",

			LBL_CUSTOEMR_SUPPLIER_CUSTOMER: "Customer",
			LBL_CUSTOEMR_SUPPLIER_NAME: "Customer Supplier Name",
			LBL_CUSTOMER_SUPPLIER_MOBILE_CONTACT_PIC_NAME: "Mobile Contact PIC Name",
			LBL_CUSTOMER_SUPPLIER_MOBILE_CONTACT_NO: "Mobile Contact No",
			LBL_CUSTOMER_SUPPLIER_MOBILE_ALT_CONTACT_PIC_NAME: "Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_SUPPLIER_MOBILE_ALT_CONTACT_NO: "Mobile Alt Contact No",
			LBL_CUSTOMER_SUPPLIER_OFFICE_TEL_PIC_NAME: "Office Tel PIC Name",
			LBL_CUSTOMER_SUPPLIER_OFFICE_TEL_NO: "Office Tel No",
			LBL_CUSTOMER_SUPPLIER_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_SUPPLIER_ADDRESS: "Address",
			LBL_CUSTOMER_SUPPLIER_POSTCODE: "Postcode",
			LBL_CUSTOMER_SUPPLIER_OTHER_POSTCODE: "Other Postcode",
			LBL_CUSTOMER_SUPPLIER_CITY: "City",
			LBL_CUSTOMER_SUPPLIER_OTHER_CITY: "Other City",
			LBL_CUSTOMER_SUPPLIER_STATE: "State",
			LBL_CUSTOMER_SUPPLIER_COUNTRY: "Country",
			LBL_CUSTOMER_SUPPLIER_EMAIL: "Email",
			LBL_CUSTOMER_SUPPLIER_REMARK: "Remark",

			BTN_CREATE: "Create New Countries",

			COL_NAME: "Customer Supplier Name",
			COL_CUSTOMER_SUPPLIER_EMAIL: "Customer Supplier Email",
			COL_MSM_NAME: "Main Salesman",
			COL_SM_NAME: "Salesman",
			COL_MARKING: "Marking",
			COL_CUSTOMER_NAME: "Customer Name",
			COL_CUSTOMER_EMAIL: "Customer Email",
			COL_CUSTOMER_SUPPLIER_CONTACT_NO: "Customer Supplier Mobile Contact No",
			COL_CUSTOMER_LOGIN_NAME: "Customer Login Name",
			COL_CUSTOMER_LOGIN_EMAIL: "Customer Email",
			COL_CUSTOMER_SUPPLIER_COUNTRY: "Country",
			COLCUSTOMER_SUPPLIER_CITY: "City",
			COL_CALIING_CODE: "Phone Code",

			MSG_FAILED_TO_UPDATE: "Customer Supplier has been failed saved",
			MSG_SUCCESS_TO_UPDATE: "Customer Supplier has been successfully saved",

			MSG_FAILED_CREATE_COUNTRIES: "New Customer Supplier has been failed to created",
			MSG_SUCCESS_TO_CREATE: "New Customer Supplier has been successfully to created",
			MSG_SUCCESS_TO_DELETE: "Customer Supplier has been deleted",

			MSG_CALLING_CODE_EMPTY: "Calling Code records have been not found"
		},
		CUSTOMER_DO_HEADER: {
			LISTING_TITLE: "Customer D/O Header Listing",
			CREATE_TITLE: "Create New Customer D/O Header",
			DETAIL_TITLE: "Customer D/O Header Detail",
			EDIT_TITLE: "Edit Customer D/O Header Detail",

			TITLE_CUSTOMER_DO_HEADER_BASIC_INFO: "Basic Information",
			TITLE_CUSTOMER_DO_HEADER_ADDRESS_AND_INFO: "Address & Contact",

			LBL_CUSTOMER_DO_HEADER_CODE: "D/O Header Code",
			LBL_CUSTOMER_DO_HEADER_NAME: "Customer Name",
			LBL_CUSTOMER_DO_HEADER_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_DO_HEADER_FAX_NO: "Fax No",
			LBL_CUSTOMER_DO_HEADER_WEBSITE: "Website",
			LBL_CUSTOMER_DO_HEADER_DO_NUMBER_FORMAT: "D/O Number Format",
			LBL_CUSTOMER_DO_HEADER_REMARK: "Remark",
			LBL_CUSTOMER_DO_HEADER_MOBILE_NO: "Mobile Contact No",
			LBL_CUSTOMER_DO_HEADER_CONTACT_NAME: "Mobile Contact PIC Name",
			LBL_CUSTOMER_DO_HEADER_MOBILE_NO_ALT: "Mobile Alt Contact No",
			LBL_CUSTOMER_DO_HEADER_MOBILE_NO_ALT_PIC: "Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_DO_HEADER_TEL_PIC: "Office Tel PIC Name",
			LBL_CUSTOMER_DO_HEADER_TEL_NO: "Office Tel No",
			LBL_CUSTOMER_DO_HEADER_CONTACT_PIC: "Contact Pic",
			LBL_CUSTOMER_DO_HEADER_CONTACT_NO: "Contact No",

			CUSTOMER_DO_HEADER_CODE_EXISTS: "D/O Header Code Exists",

			MODULE_NAME: "Customer Do Header",

			COL_CODE: "D/O Header Code",
			COL_NAME: "Customer Name",
			COL_CUSTOMER_EMAIL: "Customer Email",
			COL_DO_NUMBER_FORMAT: "DO Number Format",
			COL_COUNTRY: "Country",
			COL_STATES: "States",

			MSG_DO_NUMBER_FORMAT_NOT_SET: "D/O Number Format not set yet."
		},
		CUSTOMER_UPDATE_LOG: {
			LISTING_TITLE: "Request Change Account Name/BRN Listing",
			DETAIL_TITLE: "Request Change Account Name/BRN Detail",
			EDIT_TITLE: "Verify Request Change Account Name/BRN",

			TITLE_CUSTOMER_UPDATE_LOG_ACCOUNT_INFO: "New / Update Information",
			TITLE_CUSTOMER_UPDATE_LOG_CURRENT_INFO: "Current Information",
			TITLE_CUSTOMER_UPDATE_LOG_ADDRESS_AND_INFO: "Address & Contact",
			TITLE_CUSTOMER_UPDATE_LOG_HISTORY_LOG: "History Log",
			TITLE_CUSTOMER_UPDATE_LOG_DUPLIDATE_CUSTOMER: "Duplicate Account",

			LBL_CUSTOMER_UPDATE_LOG_NEW_CUSTOMER_NAME: "Customer New Name",

			LBL_CUSTOMER_UPDATE_LOG_REQUEST_TYPE: "Request Type",
			LBL_CUSTOMER_UPDATE_LOG_CUSTOMER_NAME: "Customer Name",
			LBL_CUSTOMER_UPDATE_LOG_CUSTOMER_TYPE: "Customer Type",
			LBL_CUSTOMER_UPDATE_LOG_CUSTOMER_NRIC_OR_BRN: "NRIC / New Registe No",
			LBL_CUSTOMER_UPDATE_LOG_CUSTOMER_OLD_REGISTER_NO: "Old Register No",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_MOBILE_CONTACT_PIC_NAME: "Billing Mobile Contact PIC Name",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_MOBILE_CONTACT_NO: "Billing Mobile Contact No",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_MOBILE_ALT_CONTACT_PIC_NAME: "Billing Mobile Alt Contact PIC Name",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_MOBILE_ALT_CONTACT_NO: "Billing Mobile Alt Contact No",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_OFFICE_TEL_PIC_NAME: "Billing Office Tel PIC Name",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_OFFICE_TEL_NO: "Billing Office Tel No",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_BUILDING_NAME: "Building Name",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_ADDRESS: "Address",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_POSTCODE: "Postcode",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_OTHER_POSTCODE: "Other Postcode",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_CITY: "City",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_OTHER_CITY: "Other City",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_STATE: "State",
			LBL_CUSTOMER_UPDATE_LOG_BILLING_COUNTRY: "Country",
			LBL_CUSTOMER_UPDATE_LOG_EMAIL: "Email",
			LBL_CUSTOMER_UPDATE_LOG_STATUS: "Status",
			LBL_PROOF_DOCUMENT: "Proof Document",

			LBL_CUSTOMER_UPDATE_LOG_REMARK_ID: "Reject Remark",
			LBL_CUSTOMER_UPDATE_LOG_REMARK: "Remark",
			LBL_CUSTOMER_UPDATE_LOG_REJECT_REMARK: "Other Reject Remark",
			REQUEST_TYPE_SELECTION_CREATE_NEW: "Create New",
			REQUEST_TYPE_SELECTION_UPDATE: "Update",

			MODULE_NAME: "Request Change Account Name/BRN",

			COL_REQUEST_TYPE: "Request Type",
			COL_CUSTOMER_NAME: "Customer Name",
			COL_NEW_NAME: "Customer New Name",
			COL_ID: "ID",
			COL_NRIC_OR_BRN: "Exists New Register No/NRIC",
			COL_EXIST_OLD_REGISTER_NO: "Exists Old Register No.",
			COL_UPD_OLD_REGISTER_NO: "Update Old Register No.",
			COL_UPDATE_AT: "Update at",
			COL_HISTORY: "History",
			COL_NEW_NRIC_OR_BRN: "Update New Register No./NRIC",
			COL_REQUESTED_ON: "Requested On",
			COL_STATUS: "Status",
			COL_REASON: "Reason",
			COL_LOGIN_EMAIL: "Registered Email",

			TEXT_REDIRECT_TO_CUSTOER_PAGE: "Are you sure want to go Customer Editor Page."
		}
	},

	TEMPLATE_SETTINGS: {
		ANNOUNCEMENT_MANAGEMENT: {
			LISTING_TITLE: "Announcement Listing",
			CREATE_TITLE: "Create Announcement",
			EDIT_TITLE: "Edit Announcement",
			DETAIL_TITLE: "Announcement Detail",
			MODULE_NAME: "Announcement",

			ANNOUNCEMENT_NAME: "Announcement Name",
			ANNOUNCEMENT_URL: "Announcement URL",
			ANNOUNCEMENT_TITLE_ENG: "Announcement Title (English)",
			ANNOUNCEMENT_TITLE_CHI: "Announcement Ttile (Chinese)",
			ANNOUNCEMENT_TEXT_ENG: "Announcement Text (English)",
			ANNOUNCEMENT_TEXT_CHI: "Announcement Text (Chinese)",
			ANNOUNCEMENT_START_DATE: "Announcement Start Date",
			ANNOUNCEMENT_END_DATE: "Announcement End Date",

			LIMIT_ACTIVE_ERROR: "Only one announcement can be set as active"
		},
		NOTICE_MANAGEMENT: {
			LISTING_TITLE: "Notice Listing",
			CREATE_TITLE: "Create Notice",
			EDIT_TITLE: "Edit Notice",
			DETAIL_TITLE: "Notice Detail",
			MODULE_NAME: "Notice",

			NOTICE_NAME: "Notice Name",
			NOTICE_URL: "Notice URL",
			NOTICE_TITLE_ENG: "Notice Title (English)",
			NOTICE_TITLE_CHI: "Notice Ttile (Chinese)",
			NOTICE_TEXT_ENG: "Notice Text (English)",
			NOTICE_TEXT_CHI: "Notice Text (Chinese)",
			NOTICE_START_DATE: "Notice Start Date",
			NOTICE_END_DATE: "Notice End Date",

			LIMIT_ACTIVE_ERROR: "Only one notice can be set as active"
		},
		SLIDER_MANAGEMENT: {
			LISTING_TITLE: "Slider Listing",
			ADD_TITLE: "Add New Slider",
			DETAIL_TITLE: "Slider Detail",
			EDIT_TITLE: "Edit Slider",
			MODULE_NAME: "Slider",

			SLIDER_NAME: "Slider Name",
			SLIDER_SEQUENCE: "Slider Sequence",
			SLIDER_CAPTION: "Slider Caption",
			SLIDER_IMAGE_ENGLISH: "English version",
			SLIDER_IMAGE_CHINESE: "Chinese version",
			SLIDER_URL: "Slider URL",
			SLIDER_START_DATE: "Slider Start Date",
			SLIDER_END_DATE: "Slider End Date",
			SLIDER_IMAGER: "Slider Image",
			SHOW_LOGIN_PAGE: "Show in Login Page",

			MSG_FAILED_TOUPLOAD_IMAGE: "Not able to upload image",
			MSG_FAILED_TOUPDATE: "Not able to update details",

			MSG_SUPPORT_IMAGE_FORMAT: "*Support JPG,JPEG,PNG,GIF file, file size not more that 500kb.",
			MSG_SUPPORT_IMAGE_SIZE: "*image size(W x L) 1920x690",
			MSG_SUPPORT_IMAGE_SIZE_LOGIN: "*image size(W x L) 880*700",

			MSG_IMAGE_ERROR_TITLE: "File size is too huge",
			MSG_IMAGE_ERROR_SIZE: "The size of file should be less than 500 KB"
		},
		BANNER_MANAGEMENT: {
			LISTING_TITLE: "Banner Listing",
			ADD_TITLE: "Add New Banner",
			DETAIL_TITLE: "Banner Detail",
			EDIT_TITLE: "Edit Banner",
			MODULE_NAME: "Banner",

			BANNER_NAME: "Banner Name",
			BANNER_CAPTION: "Banner Caption",
			BANNER_IMAGE: "Banner Image",
			BANNER_IMAGE_ENGLISH: "English version",
			BANNER_IMAGE_CHINESE: "Chinese version",
			BANNER_SEQUENCE: "Banner Sequence",
			BANNER_URL: "Banner URL",
			BANNER_START_DATE: "Banner Start Date",
			BANNER_END_DATE: "Banner End Date",

			MSG_FAILED_TOUPLOAD_IMAGE: "Not able to upload image",

			MSG_IMG_SUPPORTED: "*Support JPG,JPEG,PNG,GIF file, file size not more that 500kb.",
			MSG_IMAGE_SIZE: "*image size(W x L) 590x208",

			MSG_IMAGE_ERROR_TITLE: "File size is too huge",
			MSG_IMAGE_ERROR_SIZE: "The size of file should be less than 500 KB"
		}
	},
	CMS: {
		PAGE: {
			LISTING_TITLE: "Page Listing",
			ADD_NEW_PAGE: "Add New Page",
			ADD_CMS: "Add New CMS Page",
			DETAIL_TITLE: "Page Detail",
			EDIT_TITLE: "Edit Page",
			PAGE_NAME: "Page Name",
			PAGE_LANGUAGE: "Page Language",
			PAGE_SLUG: "Page Slug",
			PAGE_DESCRIPTION: "Page Description",
			PAGE_LAYOUT: "Page Layout",
			PAGE_MENU: "Page Menu",
			PAGE_TITLE: "Page Title",
			PAGE_CONTENT: "Page Content",

			MODULE_NAME: "Page",

			PAGE_LAYOUT_SELECTION: {
				NORMAL: "Normal Layout",
				BARE: "Bare Layout"
			}
		},
		MENU: {
			LISTING_TITLE: "Menu Listing",
			ADD_NEW_MENU: "Add New Menu",
			ADD_CMS: "Add New CMS Menu",
			DETAIL_TITLE: "Menu Detail",
			EDIT_TITLE: "Edit Menu",
			LBL_MENU_NAME: "Menu Name",
			LBL_MENU_DESCRIPTION: "Description",
			LBL_MENU_TYPE: "Menu Type",

			COL_MENU_NAME: "Menu Name",
			COL_MENU_TYPE: "Menu Type",
			COL_MENU_DESC: "Description",

			MODULE_NAME: "Menu",
			MENU_TYPE: {
				CMS_PAGE: "CMS Page",
				HEADER_PAGE: "Header (System Use Unselectable)",
				FOOTER_PAGE: "Footer (System Use Unselectable)",
				DASHBOARD_PAGE: "Dashboard (System Use Unselectable)"
			},
			BTN_MENU_ITEMS: "Menu Items"
		},
		MENU_ITEMS: {
			MENU_ITEM_TITLE: "{menu_title} - {subtitle}",
			LISTING_TITLE: "Menu Item Listing",
			ADD_NEW_MENU_ITEMS: "Add New Menu Item",
			ADD_CMS: "Add New CMS Menu Item",
			DETAIL_TITLE: "Menu Item Detail",
			EDIT_TITLE: "Edit Menu Item",
			LBL_MENU_ITEMS_NAME: "Menu Item Name",
			LBL_MENU_ITEMS_TITLE: "Title",
			LBL_MENU_ITEMS_TYPE: "Menu Item Type",
			LBL_MENU_ITEMS_CMS_PAGE: "CMS Page",
			LBL_MENU_ITEMS_URL: "Menu URL",
			LBL_MENU_ITEMS_REQUIRED_TYPE: "Required Type",
			LBL_MENU_ITEMS_REDIRECT: "Redirect",
			LBL_LANGUAGE_SELECTION: "Language Selection",
			REDIRECT_OPEN_NEW_TAB: "Open with new tab",
			LBL_MENU_ITEMS_ORDER_SEQUENCE: "Order Sequence",
			LBL_IS_FORM: "Form Page?",

			COL_MENU_ITEMS_NAME: "Menu Name",
			COL_MENU_ITEMS_TITLE: "Title",
			COL_MENU_ITEMS_TYPE: "Menu Item Type",
			COL_MENU_ITEMS_REDIRECT: "Redirect",

			MODULE_NAME: "Menu Item",
			MENU_TYPE: {
				EXTERNAL_LINK: "External Link",
				SYSTEM_PAGE: "System Page",
				CMS_PAGE: "CMS Page"
			}
		}
	},
	PRODUCT_MANAGEMENT: {
		CATEGORY_TYPE: {
			LISTING_TITLE: "Category Type Listing",
			CREATE_TITLE: "Create New Category Type",
			DETAIL_TITLE: "Category Type Detail",
			EDIT_TITLE: "Edit Category Type Detail",

			LBL_CATEGORY_TYPE_CODE: "Category Type Code",
			LBL_CATEGORY_TYPE_NAME: "Category Type Name",
			LBL_CATEGORY_TYPE_DESCRIPTION: "Category Type Description ",
			BTN_SET_SENSITIVE: "Set Sensitive As Default Wording",
			MODULE_NAME: "Category Type",

			COL_CATEGORY_TYPE_CODE: "Category Type Code",
			COL_CATEGORY_TYPE_NAME: "Category Type Name",
			COL_USE_FOR_RECEIVING: "Use For EPO/Receiving",
			COL_PRINT_ON_QUOTATION: "Print on Quotation",
			COL_DEFAULT_QUOTE: "Must Quote",
			COL_SPECIAL_CHARGES: "Special Charges",
			COL_PRINT_ON_EPO: "Print On EPO",
			SENSITIVE_TITLE: "Sensitive",
			LBL_MUST_QUOTE: "Must Quote",
			LBL_QUOTE_WITH_SPECIAL_ADD_CHARGES: "Quote with Special Additional Charges",
			LBL_QUOTE_WITH_PRODUCT_CATEGORY: "Quote with Product Category ",
			LBL_PRINT_ON_EPO: "Print On Epo",
			LBL_CATEGORY_TYPE_FORMAT: "EPO Format Type",
			LBL_FORMAT_TYPE_DESCRIPTION: "Format Type Description"
		},
		SENSITIVE_TYPE: {
			LISTING_TITLE: "Sensitive Type Listing",
			CREATE_TITLE: "Create New Sensitive Type",
			DETAIL_TITLE: "Sensitive Type Detail",
			EDIT_TITLE: "Edit Sensitive Type Detail",

			LBL_CATEGORY_TYPE: "Category Type",
			LBL_CATEGORY_GROUP_TYPE_CODE: "Sensitive Type Code",
			LBL_CATEGORY_GROUP_TYPE_NAME: "Sensitive Type Name",
			LBL_CATEGORY_GROUP_TYPE_DESCRIPTION: "Description",

			MODULE_NAME: "Sensitive Type",

			COL_CODE: "Sensitive Type Code",
			COL_NAME: "Sensitive Type Name",
			COL_USE_FOR_EPO: "Use for EPO/Receiving",
			COL_PRINT_ON_QUOTE: "Print on Quotation",
			COL_ALLOW_PLG: "Allow Purchase Parcel Lost Guarantee",
			LBL_PRINT_ON_EPO: "Print On EPO",
			LBL_EPO_TYPE_FORMAT: "EPO Type Format",
			LBL_FORMAT_TYPE_DESCRIPTION: "Format Type Description"
		},
		SPECIAL_PRODUCT: {
			LISTING_TITLE: "Special Product Listing",
			DETAIL_TITLE: "Special Product Detail",
			EDIT_TITLE: "Edit Special Product",
			MODULE_NAME: "Special Product",
			CREATE_TITLE: "Create Special Product",
			HISTORY_SUMMARY_TITLE: "Special Product History",

			SUMMARY: {
				COL_PRODUCT_NAME: "Product Name",
				COL_PRODUCT_STATUS: "Product Status",
				COL_PRODUCT_IMAGE: "Product Image",
				COL_VERIFIED_BY: "Verified By",
				COL_VERIFIED_ON: "Verified On",
				COL_PRODUCT_CATEGORY: "Product Category Lvl 2",
				COL_PRODUCT_CATEGORY_PARENT: "Product Category Parent",
				COL_INQUIRY_QUOTATION: "Inquiry Quotation No.",
				COL_PRODUCT_REMARK: "Product Remark",
				COL_EFFECTIVE_DATE: "Effective Date",
				COL_EXPIRE_DATE: "Expire Date",
				COL_PRICE_TYPE: "Price Type",
				COL_PRODUCT_ADD_ON_REMARK: "Other(s) Remark"
			},

			LBL_PRODUCT_NAME: "Product Name",
			LBL_CATEGORY: "Product Category",
			LBL_PRODUCT_STATUS: "Product Status",
			LBL_CATEGORY_PARENT: "Produdct Category (Parent)",
			LBL_ADD_ON_REMARK: "Other(s) Remark",

			LBL_PRODUCT_IMAGE: "Product Image",
			LBL_EXPIRED_DATE: "Expire Date",
			LBL_EXPIRED: "Expired",
			LBL_PRICE_TYPE: "Price Type",

			PH_ENTER_EFFECTIVE_DATE_FROM: "Enter Effective Date From",
			PH_ENTER_EFFECTIVE_DATE_TO: "Enter Effective Date To",

			PH_ENTER_EXPIRE_DATE_FROM: "Enter Expire Date From",
			PH_ENTER_EXPIRE_DATE_TO: "Enter Expire Date To"
		}
	},
	TT: {
		MODULE_NAME: "TT Request",
		DEPOSIT_PAYMENT_SUMMARY_TITLE: "TT Summary",
		TOOLTIP: {
			REFRESH: "Refresh Rate",
			EDIT: "Edit Rate",
			SAVE: "Save Rate",
			SWITCH_FACTOR: "Switch Factor"
		},
		BUTTON: {
			VERIFY: "Verify",
			APPROVE: "Approve",
			REJECT: "Reject",
			REVERT: "Revert",
			ACKNOWLEDGE: "Acknowledge",
			ASSSIGN: "Assign",
			REJECT_VERIFY: "Reject Verification",
			REJECT_APPROVE: "Reject Approval",
			REJECT_ACKNOWLEDGE: "Reject Acknowledgement",
			REJECT_ASSSIGN: "Reject Assignment",
			REQUEST_AGAIN: "Request Again"
		},
		DEPOSIT_STATUS: {
			TEXT: "Deposit Status"
		},
		PAGE: {
			REQUEST_INDEX_TITLE: "Request Listing",
			REQUEST_CREATE_TITLE: "Create New Request",
			VERIFY_TITLE: "Verify Listing",
			ACKNOWLEDGE_TITLE: "Acknowledge TT",
			ACKNOWLEDGE_SUMMARY_TITLE: "Acknowledge Summary Listing",
			APPROVE_SUMMARY_TITLE: "Approve Listing",
			VIEW_REQUEST_TITLE: "View Request",
			EDIT_REQUEST_TITLE: "Edit Request",
			VIEW_VERIFY_TITLE: "Verify Request",
			EDIT_VERIFY_TITLE: "Verify Request",
			VIEW_APPROVE_TITLE: "Approve Request",
			EDIT_APPROVE_TITLE: "Approve Request",
			VIEW_ACKNOWLEDGE_TITLE: "Acknowledge Request",
			EDIT_ACKNOWLEDGE_TITLE: "Acknowledge Request",
			BENE_DETAIL_TITLE: "Bene Details",
			REQUEST_MODULE: "Request",
			REJECT_SUMMARY_TITLE: "Rejected TT Summary",
			REJECT_TITLE: "Rejected TT"
		},
		INDEX: {
			DEPOSIT_STATUS: "Deposit Status",
			REF_NO: "Reference No.",
			TT_DATE: "TT Date",
			TOTAL_TT_AMOUNT: "Ttl TT Amt",
			TOTAL_LOCAL_TT_AMOUNT: "Ttl Local TT Amt",
			TT_CHARGES: "TT Charges",
			TT_CATEGORY: "TT Category",
			ACKNOWLEDGE_DATE: "Ack Date",
			CURRENCY: "Currency",
			WAREHOUSE_COUNTRY: "WH Country",
			REJECT_REASON: "Reject Reason",
			EPO_NO: "Epo No",
			STATUS_RECEIVED_AMT: "Status Inquiry Received Amt",
			PAYABLE_RECEIVED_AMT: "Payable Notes Received Amt",
			Q_NUMBER: "Q No",
			WAREHOUSE: "Warehouse",
			DEPARTMENT: "Department",
			VIEW: "View"
		},
		HEADER_MODEL: {
			REF_NO: "Reference Number",
			WAREHOUSE: "Warehouse",
			WAREHOUSE_COUNTRY: "Warehouse Country",
			MARKING: "Marking",
			CURRENCY: "TT Currency",
			RATE: "Rate",
			ACCOUNT_TYPE: "Account Type",
			BENE_TYPE: "Bene Type",
			TT_CATEGORY: "TT Category",
			CUSTOMER_REMARK: "Customer Remark",
			AP_REMARK: "AP Remark",
			OTHER_AMT: "Other Amount",
			OTHER_LOCAL_AMT: "Other Local Amount",
			WITHOUT_OR: "Without OR?",
			FINANCE_P: "F-m/up(%)",
			TT_CHARGES_CURRENCY: "TT Charges Currency",
			TT_CHARGES: "TT Charges",
			EXT_TO_LOCAL: "Ext To Local",
			TOTAL_SO_PAY_AMT: "Total SO Pay Amount",
			TOTAL_TT_AMT: "Total TT Amount",
			TOTAL_LOC_TT_AMT: "Total Local TT Amount",
			OUTGOING_CURRENCY: "Outgoing Currency",
			CONVERTED_RATE: "Converted Rate",
			ADD_EPO_SO: "Add EPO / SO",
			EPO_CURRENCY: "Epo Currency",
			ANOTHER_TT_CURRENCY: "Another TT Currency?",
			ACTUAL_AMOUNT: "Actual Amount",
			OR_AMOUNT: "OR Amount",
			TT_ADJUSTMENT_FACTOR: "TT Adjustment Factor",
			TT_ADJUSTMENT: "TT Adjustment Amount",
			CONVERTED_DIFFERENCE: "Conversion Difference",
			SELECT_DIALOGBOX_TITLE: "Select EPO/SO"
		},
		BENE_MODEL: {
			BENE_TITLE: "Beneficiary Details",
			INTERMEDIATE_TITLE: "Intermediate Bank Details",
			INTERMEDIATE_CHECKBOX: "Intermediate Bank?",
			ACC_HOLDER: "Account Holder",
			ACC_NO: "Account Number",
			BENE_CONTACT_PIC: "Bene Contact PIC",
			BENE_CONTACT_CALLING_CODE: "Bene Calling Code",
			BENE_CONTACT_NO: "Bene Contact Number",
			RI_NO: "Ri Number",
			BANK_NAME: "Bank Name",
			BANK_CITY: "Bank City",
			BANK_COUNTRY: "Bank Country",
			BANK_ADDRESS: "Bank Address",
			BENE_ADDRESS: "Bene Address",
			BANK_REMARK: "Bank Remark",
			BANK_CODE: "BIC / Swift Code",
			IBAN: "IBAN",
			INTER_BANK_NAME: "Intermediate Bank Name",
			INTER_BANK_ACC: "Intermediate Account No",
			INTER_SWIFT_CODE: "Intermediate BIC / Swift Code",
			INTER_IBAN_CODE: "Intermediate IBAN Code",
			BENE_DETAIL_NAME: "Beneficiary Detail Name"
		},
		DETAIL_MODEL: {
			EPO_NO: "EPO/SO",
			PN_DOC_NO: "Payable Notes No",
			EPO_AMOUNT: "Epo Amt",
			PAY_AMOUNT: "TT Amt",
			PAY_AMOUNT_LOCAL: "Local TT Amt",
			ADJUSTMENT_INDICATOR: "+/-",
			ADJUSTMENT_AMOUNT: "Adj. Amt",
			ADJUSTMENT_AMOUNT_LOCAL: "Local Adj Amt",
			REMARK: "Remark",
			OUTSTANDING_AMOUNT: "O/S Amt",
			CANCEL: "Cancel",
			ADD: "Add",
			SUBTOTAL: "Sub Total",
			SUBTOTAL_LOCAL: "Local Sub Total",
			AMOUNT_IN_PROCESS: "Amt In Process",
			RECEIVED_AMOUNT: "Received Amt",
			ALT_DEPOSIT: "TTed Amt ({mod})",
			ALT_APPLIED_ADJUSTMENT: "Applied TT Adj. ({mod})",
			ALT_PAY_AMOUNT: "Pay Amt ({mod})",
			ALT_ADJUSTMENT_AMOUNT: "Adj. Amt ({mod})",
			ALT_SUBTOTAL: "Sub Ttl ({mod})",
			AMOUNT: "Amt",
			REASON: "Reason",
			CLOSED_DN: "Closed / DN Amt"
		},
		FOOTER_MODEL: {
			SUBMIT: "Submit",
			VERIFY: "Verify",
			APPROVE: "Approve",
			ACKNOWLEDGE: "Acknowledge",
			SAVE_AS_DRAFT: "Save As Draft",
			REJECT: "Reject",
			CANCEL: "Cancel"
		},
		ADD_EPO_SO: "Add EPO / Order Received (SO) ",
		REJECT_REQUEST_TITLE: "Reject TT Request, Reference No.: {reference_no}",

		REJECT_APPROVE_TITLE: "Reject to Approve TT, Reference No.: {reference_no}",
		MSG_CONFIRM_APPROVE_REJECT: "Are you sure reject to approve this {reference_no} tt?",
		MSG_ACK_CONTAIN_OTHER: "Below Ref No. record not in Pending Acknowledge:<br/ >{ref_no}",
		// VERIFY_REJECT: "Reject",
		// APPROVE_REJECT: "Reject",
		// ACKNOWLEDGE_REJECT: "Reject",
		VERIFY: "Verified",
		APPROVE: "Approved",
		ACKNOWLEDGE: "Acknowledged",
		DATE_FROM: "Date From",
		DATE_TO: "Date To",
		EPO_NO: "Epo No",
		STATUS: "Status",
		MAIN_SALESMAN: "Main Salesman",
		SALESMAN: "Salesman",
		CURRENCY_CODE: "Currency Code",
		AMOUNT: "Amount",
		EPO_DATE: "Epo Date",
		CUSTOMER_NAME: "Customer Name",
		SO_NO: "SO No",
		RECEIVE_DATE: "Receive Date",
		TTED_AMOUNT: "TTed Amount",
		TTED_AMOUNT_LOCAL: "Local TTed Amount",
		SUPPLIER_NAME: "Supplier Name",
		SHIPMENT_TYPE: "Shipment Type",
		DISCOUNT_AMOUNT: "EPO/SO Discount",
		OTHER_AMOUNT: "Other Amount",
		IS_DEPOSIT: "Is Deposit?",
		GRAND_TOTAL: "Grand Total",
		RECEIVED_AMOUNT: "Received Amount",
		APPLIED_TT_ADJUSTMENT: "Applied TT Adj.",
		APPLIED_TT_ADJUSTMENT_LOCAL: "Local Applied TT Adj.",
		FINAL_SO_PAY_AMOUNT: "Final SO Pay Amount",
		FINAL_TT_AMOUNT: "Final TT Amount",
		FINAL_TT_AMOUNT_LOCAL: "Final Local TT Amount",
		CONFIRM: "Confirm",
		REJECT: "Reject",
		SO_MODULE: "Order Received",
		MSG_RATE_EXPIRED: "Currency rate effective time expired, TT request is prohibited",
		MSG_TT_CHARGES_NOT_FOUND: "Bank city is not set, tt charges is empty.",
		MSG_TT_LIMIT_EXCEED: "TT Amount exceeded set TT limit.",
		MSG_REJECT_REASON_EMPTY: "Reject Reason cannot be empty",
		MSG_DEPOSIT_STATUS_CHANGE_CANNOT_MODIFY: "TT request has been approved/acknowledged, cannot be modified",
		MSG_CANNOT_REVERT: "TT request contains epo has been processed in order receiving, revert action is denied.",
		PAY_AMOUNT_CANNOT_EXCEED: "Pay amount cannot exceed outstanding amount",
		MINUS_ADJ_CANNOT_EXCEED: "Discount type adjustment amount cannot exceed pay amount",
		LBL_SHOW_TT_ZERO: "Show TT = 0 ?",
		SIMPLE_MODE: "Simple Mode",
		SHOW_ADJUSTMENT: "Show Adjustment",
		SHOW_MARKING: "Show Marking?",
		REJECT_REASON: {
			SELECT_REASON: "Reject reason",
			OTHER: "Other reason",
			PH_OTHER: "Please input other reason"
		},
		MSG: {
			BLOCK_EDIT_REQUEST: "{reference_no} in aprroving. You are not allow to edit",
			LIMIT_FULLY_USED: "Today Limit fully booked. Please asists person in charge"
		},
		LBL_EPO_SO_DATE: "EPO/Received Date",
		Desc_LIMIT: "Available Limit: {balance}",
		LBL_PAYMENT_TYPE: "Type"
	},
	CLOSE_TT: {
		MODULE_NAME: "Close TT",
		LISTING_TITLE: "Close TT Summary",
		CREATE_TTILE: "Create Close TT",
		VIEW_TITLE: "Close TT Detail",
		EDIT_TITLE: "Edit Close TT",
		SO_SUMMARY_TITLE: "OutStanding TT Deposit",

		LBL_DEPOSIT_NO: "TT Ref No.",

		SO_DETAIL: "OutStanding TT Detail(s)",
		COL_REMARK_PER_SO: "S/O Remark",
		COL_DEPOSIT_DATE: "Deposit Date",
		COL_BENE_TYPE: "Bene Type",
		COL_DEPOSIT_AMOUNT: "Deposit Amt",
		COL_SO_CURRENCY: "S/O Curr.",
		COL_APPLIED_DEPOSIT: "Applied Deposit",
		COL_TT_CURRENCY: "TT Currency",
		COL_CLOSED_AMOUNT: "Closed Amt",
		COL_BAL_DEPOSIT: "Bal. Deposit",
		COL_TT_CHARGES: "Ttl TT Chrages",
		COL_TT_ADJUSTMENT: "Ttl Adj.",
		SHOW_DETAILS: "Show Details"
	},
	BLOCK_TT: {
		MODULE_NAME: "Block TT",
		LISTING_TITLE: "Block TT Summary",
		CREATE_TTILE: "Create Block TT",
		VIEW_TITLE: "Block TT Detail",
		EDIT_TITLE: "Edit Block TT",
		SO_SUMMARY_TITLE: "S/O With Outstanding Payment",
		SO_DETAIL: "S/O Detail(s)",
		COL_REMARK_PER_SO: "S/O Remark"
	},
	TT_CN: {
		MODULE_NAME: "TT CN",
		LISTING_TITLE: "TT CN Summary",
		CREATE_TTILE: "Create TT CN",
		VIEW_TITLE: "TT CN Detail",
		EDIT_TITLE: "Edit TT CN",
		SO_SUMMARY_TITLE: "S/O With Outstanding Payment",
		SO_DETAIL: "OutStanding TT Detail(s)",
		COL_REMARK_PER_SO: "S/O Remark",

		LBL_CN_NO: "CN No.",
		LBL_CN_DATE: "CN Date",
		LBL_REASON: "CN Reason",

		LBL_CN_ADJUSTMENT: "Adjustment",
		LBL_TAX_AMOUNT: "Tax Amount",
		LBL_NET_AMOUNT: "Net CN Amount",

		CONFIRMATION_CHANGE_RATE: "Found {number} S/O using same currency.  Are you willing to change to a new currency rate?"
	},
	TT_DN: {
		MODULE_NAME: "TT DN",
		LISTING_TITLE: "TT DN Summary",
		CREATE_TTILE: "Create TT DN",
		VIEW_TITLE: "TT DN Detail",
		EDIT_TITLE: "Edit TT DN",
		SO_SUMMARY_TITLE: "OutStanding TT Deposit",
		SO_DETAIL: "Outstanding TT Detail(s)",
		COL_REMARK_PER_SO: "S/O Remark",

		LBL_DN_NO: "DN No.",
		LBL_DN_DATE: "DN Date",
		LBL_REASON: "DN Reason",

		LBL_DN_ADJUSTMENT: "Adjustment",
		LBL_TAX_AMOUNT: "Tax Amount",
		LBL_NET_AMOUNT: "Net DN Amount",

		LBL_TT_CURRENCY: "TT Curr.",
		LBL_TT_OUTSTANDING_AMOUNT: "TT Bal Amt",

		CONFIRMATION_CHANGE_RATE: "Found {number} S/O using same currency.  Are you willing to change to a new currency rate?",

		COL_ALT_DN_AMOUNT: "Alt DN Amt.",
		COL_ALT_DN_CURRENCY_RATE: "Alt Curr. Rate",
		COL_ALT_DN_CURRENCY_FACTOR: "Alt Curr. Factor"
	},
	TT_BOOKING: {
		MODULE_NAME: "Booking",
		PAGE: {
			INDEX_TITLE: "TT Booking Listing"
		},
		COL_EX_CHANGE_CURRENCY: "Ex Change Currency",
		COL_TT_AMOUNT: "TT Amount",
		COL_PN_PAYMENT_AMOUNT: "PN Payment Amount",
		COL_PA_PAYMENT_AMOUNT: "Payment Advice Amount",
		COL_BAL_BOOKED_TT: "Bal. TT Booked Amount"
	},
	DO_MANAGEMENT: {
		MSG: {
			STATUS_CLOSE_NOT_ALLOW_EDIT: "D/O Status was closed. You are not allow to edit",
			CONFIRM_CLOSE_REF_ID: "Are you sure to closed {ref_id} ref id(s) D/O?",
			CONFIRM_OPEN_REF_ID: "Are you sure to re-open {ref_id} ref id(s) D/O?",
			CONFIRM_REF_ID_ASSIGN_DATE: "Found  {ref_id} ref id(s) part D/O not yet created or closed. Are you sure to continue?",
			PARTIAL_CREATE_CLOSE: "D/O partialy created or closed",
			CONFIRM_APPROVE_CHANGE_ADD: "Once New Del. Addr. changed, you are now allow to revert. Are you sure to continue?",
			SUCCESS_APPROVE: "D/O No.: {do_no} Del. Addr. was changed. Are you want re-print D/O",
			SUCCESS_REVERT: "D/O No.: {do_no} Change Del. Addr. has been revert.",
			SUCCESS_REJECT: "D/O No.: {do_no} Change Del. Addr. has been reject.",
			ERROR_DO_NO_NOT_FOUND: "D/O No.: {do_no} not found",
			REQUEST_CHANGE_ADD_NOT_FOUND: "D/O No.: {do_no} Change Del. Add. not requested",
			ERROR_REQ_CHANGE_ADD_APPROVED: "D/O No.: {do_no} Change Del. Add. was approved",
			ERROR_REQ_CHANGE_ADD_REVERTED: "D/O No.: {do_no} Change Del. Add. was reverted",
			ERROR_REQ_CHANGE_ADD_REJECTED: "D/O No.: {do_no} Change Del. Add. was rejected",
			CONFIRM_REVERT: "D/O No.: {do_no}, revert Change Del. Add.",
			CONFIRM_REJECT: "D/O No.: {do_no}, reject Change Del. Add."
		},
		TITLE: {
			REJECT: "Reject to Change Delivery Address, D/O No.: {do_no}. ",
			REVERT: "Revert to Change Delivery Address, D/O No.: {do_no}. ",
			CHANGE_ADDR_STATUS: "Change Del. Addr Status",
			REQ_CHANGE_DEL_ADDR: "Request Change Del. Addr.",
			APPROVE_CHANGE_DEL_ADDR: "Approve Change Del. Addr.",
			CHANGE_DEL_ADDR_STATUS: "Change Del. Addr."
		},
		BUTTON: {
			REQUEST_CHANGE_DELIVERY_ADD: "Req Change Del. Addr"
		},
		DO_SUMMARY: {
			LISTING_TITLE: "D/O Summary Listing",
			CREATE_TITLE: "Create New D/O",
			DETAIL_TITLE: "D/O Detail",
			EDIT_TITLE: "Edit D/O Detail",
			MODULE_NAME: "D/O Summary",
			DO_HEADER: "D/O Header",
			ADD_SO_WIN_TITLE: "Add S/O by Ref ID",
			PRINT_DO_TITLE: "Preview D/O",
			ASSIGN_DO_DATE_TRANSPORTER_TITLE: "Assign D/O Date & Transporter",

			DO_FORMAT: "D/O Format",
			CREATE_DO_BY_REFID: "Create D/O By Ref ID",
			WAREHOUSE: "Warehouse",
			AGENT: "Agent",
			DATE: "Date",
			PH_ENTER_DATE_FROM: "Enter Date From",
			PH_ENTER_DATE_TO: "Enter Date To",
			SUBMIT: "Submit",
			CANCEL: "Cancel",
			SELECT_REF_ID: "Select Ref Id",
			DO_DETAIL: "D/O Detail",
			DETAIL: "Detail",
			DELIVERY_ADDRESS_CONTACT_INFORMATION: "Delivery Address & Contact Information",
			STOCK_ID: "Stock ID",
			ITEM_CODE: "Item Code",
			STOCK_DESC1: "Stock Desc 1",
			STOCK_DESC2: "Stock Desc 2",
			RCV_QTY: "Rcv Qty",
			UOM: "Uom",
			CTN: "CTN",
			WEIGHT: "Weight (kg)",
			M3: "M3",
			SO_NO: "S/O No.",
			PACK_TYPE: "Pack Type",
			ADD_FROM_REF_ID: "Add From Ref ID",
			PRINT_DO_BY_REF_ID: "Print D/O By Ref ID",
			FULL_MARKING: "Full Marking",
			CUSTOMER_NAME: "Customer Name",

			LBL_WEB_SITE: "Main Salesman",
			LBL_CONTACT_NO: "Contact No",
			LBL_ADDRESS: "Address",
			LBL_EMAIL: "Email",
			LBL_URL: "Url",
			LBL_REMARK: "Remark",
			LBL_PRINT_BARCODE_DO: "Print Barcode D/O",
			LBL_REF_ID: "Ref ID",
			LBL_MARKING_ID: "Marking",
			LBL_DELIVERY_ADDRESS_ID: "Delivery ID",
			LBL_COMPANY_NAME: "Company Name",
			LBL_INCLUDE_ASSIGNED: "Incl. Assigned D/O",
			LBL_TRANSPORTER: "Transporter",

			BTN_ENABLE_EDIT_FORWARDER: "Enable Edit Exp Forwarder ID",
			BTN_DISABLE_EDIT_FORWARDER: "Disable Edit Exp Forwarder ID",
			BTN_ENABLE_DO_ADDR: "Enable Edit D/O Address",
			BTN_DISABLE_DO_ADDR: "Disable Edit D/O Address",

			COL_EXP_FORWARDER_ID: "Exp Forwarder ID",
			COL_MAIN_SALESMAN: "Main Salesman",
			COL_SALESMAN: "Salesman",
			COL_MARKING: "Marking",
			COL_REF_ID: "Ref ID",
			COL_INV_NO: "Inv No.",
			COL_DO_NO: "D/O No.",
			COL_DO_DATE: "D/O Date",
			COL_CUST_DO_NO: "Cust D/O No.",
			COL_ARRIVAL_DATE: "Arrival Date",
			COL_DEL_ID: "DEL ID",
			COL_COMPANY_NAME: "Company Name",
			COL_NRCI_BRN: "NRCI/BRN",
			COL_BUILDING_NAME: "Building Name",
			COL_ADDRESS: "Address",
			COL_COUNTRY: "Country",
			COL_POSTCODE: "Postcode",
			COL_CITY: "City",
			COL_STATE: "State",
			COL_MOBILE_CONTACT: "Mobile Contact",
			COL_OFFICE_NO: "Office No",
			COL_RESHIP: "Reship",
			COL_DO_DELIVERY_ADDRESS_FORMAT: "D/O Delivery Address Format",
			COL_SO_NO: "SO NO",
			COL_RECEIVED_DATE: "Received Date",
			COL_TTL_ITEM: "Ttl Item",
			COL_TTL_CTN: "Ttl CTN",
			COL_TTL_M3: "Ttl M3",
			COL_TTL_WEIGHT: "Ttl Kg",
			COL_RESHIP: "Reship",
			COL_CHANGE_ADDR_STATUS: "Change Del. Addr Status",
			COL_DO_STATUS: "D/O STATUS",

			DETAILS: {
				REF_ID: "Ref Id",
				LOADED_DATE: "Loaded Date",
				TTL_ITEM: "Ttl Item",
				TTL_CTN: "Ttl Ctn",
				TTL_WEIGHT: "Ttl Weight (kg)",
				TTL_M3: "Ttl M3",
				MARKING: "Marking",
				DEL_ID: "DEL ID"
			},
			MSG_REF_ID_LOADING_STATUS: "{ref_id} loading still open",
			MSG_REF_ID_NOT_FOUND: "{ref_id} loaded order record not found",
			MSG_REF_ID_CREATED_DO: "{ref_id}'s order was created D/O",
			MSG_TTL_M3_ZERO_CONFIRMATION: "Ttl M3 is 0",
			MSG_SO_NOT_SELECTED: "No S/O has selected",
			TOOLTIP_GIMWORLD_CONTACT_NO: "This Field to record GimWorld Customer Delivery Contact No.",
			MSG_CUSTOMER_MARKING_SELECTED_DIFF: "Selected S/O (s) Customer Marking Different then Customer Marking Selected",
			MSG_DELIVERY_ID_SELECTED_DIFF: "Selected S/O (s) Del. ID Different then Del. ID Selected",
			DO: "D/O"
		}
	},
	PAYMENT_ADVICE: {
		MODULE_NAME: "Payment Advice",
		APPROVE_MODULE_NAME: "Payment Advice Approve",
		CREATE_TITLE: "Create New Payment Advice",
		VIEW_TITLE: "View Payment Advice",
		EDIT_TITLE: "Edit Payment Advice",
		DELETE_REJECT_TITLE: "Deleted/Rejected Payment Advice",
		APPROVE_TITLE: "Approve Payment Advice",
		ACKNOWLEDGE_TITLE: "Acknowledge Payment Advice",
		INDEX_TITLE: "Payment Advice Listing",
		APPROVE_INDEX_TITLE: "Payment Advice Approve Listing",
		DETAIL_TITLE: "Payment Advice Details",
		DEPARTMENT: "Department",
		CURRENCY: "Currency",
		LBL_PAYMENT_DATE: "Payment Date",
		LBL_DETAIL: "Details",
		MSG: {
			DETAIL_TT_AMOUNT_NOT_TALLY: "Total detail amount not tally with TT Amount.",
			BENE_FIELDS_REQUIRED: "Bene Information Required",
			BENE_DETAIL_RESET: "Bene Detail will reset following selected record, Are you sure to continue?",
			LIMIT_AMOUNT_EXCEEDED: "Total TT Amount exceeded that balance tt limit. (Bal Limit: {balance})",
			BLOCK_DELETE: "The Payment Advice status was swicth to {status}, You are not allow to delete"
		},
		TABLE_COL: {
			NAME: "Name",
			ACCOUNT_HOLDER: "Acc Holder",
			ACCOUNT_NO: "Acc No",
			BANK_NAME: "Bank",
			CONTACT_NO: "Contact",
			CONTACT_PIC: "PIC"
		},
		CREATE_BENE: "Create New Bene",
		SELECT_BENE: "Select Bene",
		BENE_SEARCHFORM: {
			COL_BENE_NAME: "Bene Name"
		}
	},
	DEPARTMENT_SETTING: {
		MODULE_NAME: "Department Setting",
		LBL_DEPARTMENT_SETTING: "Department Setting",
		PAGE: {
			INDEX_TITLE: "Department Setting Summary",
			DETAIL_TITLE: "Department Setting Detail",
			EDIT_TITLE: "Edit Department Setting",
			CREATE_TITLE: "Create New Department Setting"
		},
		TABLE_COL: {
			MODULE_NAME: "Module Name",
			DEPARTMENT_NAME: "Department Name",
			PERMITTED_DEPARTMENTS: "Can Access Department",
			HIDE_FIELDS: "Hidden Field Keys"
		},
		VALIDATE: {
			NAME_SELECTION: "Department name cannot be empty."
		},
		LBL: {
			SETTING_NAME: "Module Name"
		},
		PH_HIDE_FIELDS: "Input field keys wished to be hidden, if any."
	},
	ROLES_TYPE: {
		MODULE_NAME: "Roles Type",
		PAGE: {
			INDEX_TITLE: "Roles Type Listing",
			DETAIL_TITLE: "Roles Type Detail",
			EDIT_TITLE: "Edit Roles Type",
			CREATE_TITLE: "Create New Roles Type"
		},
		TABLE_COL: {
			ROLE_NAME: "Role Type",
			INDICATOR: "Indicator"
		},
		VALIDATE: {
			INDICATOR_MAX: "Indicator cannot be greater than 65,535"
		},
		LBL: {
			ROLE_NAME: "Role Type",
			INDICATOR: "Indicator"
		},
		PH: {
			ROLE_NAME: "Role Type",
			INDICATOR: "Indicator"
		}
	},
	SYSTEM_LOG: {
		ADMIN_API_LOG_TITLE: "Admin Api Log",
		CUSTOMER_API_LOG_TITLE: "Customer Api Log",
		WMS_API_LOG_TITLE: "WMS Api Log",
		SYNC_WMS_LOG_TITLE: "Sync Wms Log",
		QUOTATION_AUDIT: "Quotation Log",
		LOG_TITLE: "Log Result",
		LBL_LOG_DATE: "Log Date",
		LBL_LOG_DATE_FROM: "Date From",
		LBL_LOG_DATE_TO: "Date To",
		LBL_ERROR_CODE: "Error Code",
		COL_LOG_DATE: "Log Date",
		COL_REQUEST_PATH: "Path",
		COL_REQUEST_METHOD: "Method",
		COL_MSG: "Message",
		COL_RESULT: "Result",
		COL_ERROR_CODE: "Error Code"
	},
	AUDIT_LOG: {
		QUOTATION_LOG: "Quotation Log",
		LOG_TITLE: "Log Result",
		LBL_LOG_DATE: "Log Date",
		LBL_LOG_DATE_FROM: "Date From",
		LBL_LOG_DATE_TO: "Date To",
		LBL_ERROR_CODE: "Error Code",
		COL_LOG_DATE: "Log Date",
		COL_ACTION: "Action",
		COL_USERNAME: "User",
		COL_DATA: "Message",
		COL_RESULT: "Result",
		COL_ERROR_CODE: "Error Code"
	},
	EMAIL_LOG: {
		TITLE: "Email Log",
		EMAIL_MESSAGR_TITLE: "Email Message/Error Message",
		LBL_SENT_DATE: "Sent On",
		LBL_SENT_TO: "Sent To",

		PORTAL_CUSTOMER: "Customer",
		PORTAL_ADMIN: "Admin",

		COL_SENT_ON: "Sent On",
		COL_SENT_TO: "Sent To",
		COL_SUBJECT: "Subject",
		COL_SENT_STATUS: "Sent Status",
		COL_SENT_ERROR_CODE: "Error Code",
		COL_PORTAL: "Portal",

		SENT_STATUS: {
			SENT: "Sent",
			FAILED: "Failed"
		}
	},
	REPORT: {
		TT_REPORT: "TT Report",

		TT_COL: {
			CURRENCY_GROUP: "Ex Currency Group",
			POSTED_COM_ID: "Posted Co ID",
			BENE_TYPE: "Bene Type",
			CID: "CID",
			EX_AMT: "Exchange Amt",
			EX_RATE: "Rate",
			LOCAL_AMOUNT: "Local Amt",
			Ex_CURRENCY: "Ex Currency"
		},
		TT_SUMMARY_BY_MSM: {
			TITLE: "Deposit Summary Report By MSM",
			LBL_ACK_DATE: "Ack Date",
			LBL_BENE_TYPE: "Bene Type",
			LBL_CID: "CID"
		}
	}
};
