import Vue from "vue";
import Router from "vue-router";
import i18n from "@/core/plugins/vue-i18n";
Vue.use(Router);

export default new Router({
	base: "/",
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/dashboard",
			component: () => import("@/view/layout/Layout"),
			children: [
				{
					path: "/dashboard",
					name: "dashboard",
					component: () => import("@/view/pages/Dashboard.vue"),
					meta: {
						title: i18n.t("MENU.ASIDE.DASHBOARD")
					}
				},
				/**Start Customer Management */
				{
					path: "/customer-management",
					name: "customer-management",
					component: () => import("@/view/pages/customer_management/CustomerManagement.vue"),
					children: [
						/**Start Customer */
						{
							path: "customer",
							redirect: "customer/index",
							name: "customer",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/customer_management/customer/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER.LISTING_TITLE")
										})
									}
								},
								{
									path: "create/",
									component: () => import("@/view/pages/customer_management/customer/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id/:marking_id?",
									component: () => import("@/view/pages/customer_management/customer/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id/:marking_id?",
									component: () => import("@/view/pages/customer_management/customer/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER.EDIT_TITLE")
									}
								}
							]
						},
						/**End Customer */
						/**Start Customer Address */
						{
							path: "delivery-address",
							redirect: "delivery-address/index",
							name: "delivery-address",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/customer_management/delivery_address/index.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.DELIVERY_ADDRESS.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/customer_management/delivery_address/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.DELIVERY_ADDRESS.CREATE_TITLE")
									}
								},
								{
									path: "create/:customer_id",
									component: () => import("@/view/pages/customer_management/delivery_address/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.DELIVERY_ADDRESS.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/customer_management/delivery_address/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.DELIVERY_ADDRESS.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/customer_management/delivery_address/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.DELIVERY_ADDRESS.EDIT_TITLE")
									}
								}
							]
						},
						/**End Customer Address */
						/**Start Customer Do Header */
						{
							path: "customer-do-header",
							redirect: "customer-do-header/index",
							name: "customer-do-header",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/customer_management/customer_do_header/index.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER_DO_HEADER.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/customer_management/customer_do_header/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER_DO_HEADER.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/customer_management/customer_do_header/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER_DO_HEADER.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/customer_management/customer_do_header/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER_DO_HEADER.EDIT_TITLE")
									}
								}
							]
						},
						/**End Customer Do Header */
						/**Start Customer Supplier */
						{
							path: "customer-supplier",
							redirect: "customer-supplier/index",
							name: "customer-supplier",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/customer_management/customer_supplier/index.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER_SUPPLIER.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/customer_management/customer_supplier/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER_SUPPLIER.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/customer_management/customer_supplier/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER_SUPPLIER.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/customer_management/customer_supplier/detail.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER_SUPPLIER.EDIT_TITLE")
									}
								}
							]
						},
						/**End Customer Supplier */
						/**Start Customer Update Log */
						{
							path: "customer-update-log",
							redirect: "customer-update-log/index",
							name: "customer-update-log",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/customer_management/customer_update_log/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER_UPDATE_LOG.LISTING_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/customer_management/customer_update_log/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER_UPDATE_LOG.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/customer_management/customer_update_log/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER_UPDATE_LOG.EDIT_TITLE")
										})
									}
								}
							]
						}
						/**End Customer Update Log */
					]
				},
				/**End Customer Management */
				/**Start User, Role & Data Filter Management */
				{
					path: "/user-management",
					name: "user-management",
					component: () => import("@/view/pages/user_n_role_management/UserRoleManagement.vue"),
					children: [
						{
							path: "user",
							redirect: "user/index",
							name: "user",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/user_n_role_management/user/index.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.USER.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/user_n_role_management/user/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.USER.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/user_n_role_management/user/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.USER.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/user_n_role_management/user/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.USER.EDIT_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/role-management",
					name: "role-management",
					component: () => import("@/view/pages/user_n_role_management/UserRoleManagement.vue"),
					children: [
						{
							path: "role",
							redirect: "role/index",
							name: "role",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/user_n_role_management/role/index.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.ROLE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/user_n_role_management/role/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.ROLE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/user_n_role_management/role/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.ROLE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/user_n_role_management/role/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.ROLE.EDIT_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/data-filter-management",
					name: "data-filter-management",
					component: () => import("@/view/pages/user_n_role_management/UserRoleManagement.vue"),
					children: [
						{
							path: "data-filter",
							redirect: "data-filter/index",
							name: "data-filter",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/user_n_role_management/data_filter/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("USER_N_ROLE_MANAGEMENT.DATA_FILTER.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/user_n_role_management/data_filter/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("USER_N_ROLE_MANAGEMENT.DATA_FILTER.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/user_n_role_management/data_filter/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("USER_N_ROLE_MANAGEMENT.DATA_FILTER.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/user_n_role_management/data_filter/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("USER_N_ROLE_MANAGEMENT.DATA_FILTER.EDIT_TITLE")
										})
									}
								}
							]
						}
					]
				},
				/**End User, Role & Data Filter Management */
				/**Start Salesman Management */
				{
					path: "/salesman-management",
					name: "salesman-management",
					component: () => import("@/view/pages/salesman_management/Master.vue"),
					children: [
						{
							path: "main-salesman",
							redirect: "main-salesman/index",
							name: "main-salesman",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/salesman_management/main_salesman/index.vue"),
									meta: {
										title: i18n.t("SALESMAN_MANAGEMENT.MAIN_SALESMAN.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/salesman_management/main_salesman/detail.vue"),
									meta: {
										title: i18n.t("SALESMAN_MANAGEMENT.MAIN_SALESMAN.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/salesman_management/main_salesman/detail.vue"),
									meta: {
										title: i18n.t("SALESMAN_MANAGEMENT.MAIN_SALESMAN.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/salesman_management/main_salesman/detail.vue"),
									meta: {
										title: i18n.t("SALESMAN_MANAGEMENT.MAIN_SALESMAN.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "salesman",
							redirect: "salesman/index",
							name: "salesman",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/salesman_management/salesman/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SALESMAN_MANAGEMENT.SALESMAN.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/salesman_management/salesman/detail.vue"),
									meta: {
										title: i18n.t("SALESMAN_MANAGEMENT.SALESMAN.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/salesman_management/salesman/detail.vue"),
									meta: {
										title: i18n.t("SALESMAN_MANAGEMENT.SALESMAN.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/salesman_management/salesman/detail.vue"),
									meta: {
										title: i18n.t("SALESMAN_MANAGEMENT.SALESMAN.EDIT_TITLE")
									}
								}
							]
						}
					]
				},
				/**End Salesman Management */
				/**Start Costing Management */
				{
					path: "/cost",
					name: "cost",
					component: () => import("@/view/pages/costing/Costing.vue"),
					children: [
						{
							path: "customer-cost-group",
							redirect: "customer-cost-group/index",
							name: "customer-cost-group",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/customer_costing_group/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("COSTING.CUSTOMER_COSTING_GROUP.TITLE")
										})
									}
								},
								{
									path: "edit",
									component: () => import("@/view/pages/costing/customer_costing_group/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("COSTING.CUSTOMER_COSTING_GROUP.TITLE")
										})
									}
								}
							]
						},
						{
							path: "area-group",
							redirect: "area-group/index",
							name: "area-group",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/area_group/index.vue"),
									meta: {
										title: i18n.t("COSTING.AREA_GROUP.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/area_group/detail.vue"),
									meta: {
										title: i18n.t("COSTING.AREA_GROUP.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/area_group/detail.vue"),
									meta: {
										title: i18n.t("COSTING.AREA_GROUP.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/area_group/detail.vue"),
									meta: {
										title: i18n.t("COSTING.AREA_GROUP.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "area",
							redirect: "area/index",
							name: "area",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/area/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.AREA.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/area/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.AREA.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/area/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.AREA.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/area/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.AREA.EDIT_TITLE")
										})
									}
								}
							]
						},
						/**Start Route */
						{
							path: "route",
							redirect: "route/index",
							name: "route",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/route/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("COSTING.ROUTE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/route/detail.vue"),

									meta: {
										title: i18n.t("COSTING.ROUTE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/route/detail.vue"),
									meta: {
										title: i18n.t("COSTING.ROUTE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/route/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("COSTING.ROUTE.EDIT_TITLE")
										})
									}
								}
							]
						},
						/** End Route */
						/** Start Base Cost */
						{
							path: "base-cost",
							redirect: "base-cost/index",
							name: "base-cost",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/base_cost/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("COSTING.BASE_COST.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/base_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.BASE_COST.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/base_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.BASE_COST.VIEW_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/base_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.BASE_COST.EDIT_TITLE")
									}
								}
							]
						},
						/** End Base Cost */
						/** Start Transit Cost */
						{
							path: "transit-cost",
							redirect: "transit-cost/index",
							name: "transit-cost",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/transit_cost/index.vue"),
									meta: {
										title: i18n.t("COSTING.TRANSIT_COST.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/transit_cost/create.vue"),
									meta: {
										title: i18n.t("COSTING.TRANSIT_COST.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/transit_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.TRANSIT_COST.VIEW_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/transit_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.TRANSIT_COST.EDIT_TITLE")
									}
								}
							]
						},
						/** End Transit Cost */
						/** Start Marking Cost */
						{
							path: "marking-cost",
							redirect: "marking-cost/index",
							name: "marking-cost",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/marking_cost/index.vue"),
									meta: {
										title: i18n.t("COSTING.MARKING_COST.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/marking_cost/create.vue"),
									meta: {
										title: i18n.t("COSTING.MARKING_COST.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/marking_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.MARKING_COST.VIEW_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/marking_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.MARKING_COST.EDIT_TITLE")
									}
								}
							]
						},
						/** End marking Cost */
						/** Start Exceptional Cost */
						{
							path: "exceptional-cost",
							redirect: "exceptional-cost/index",
							name: "exceptional-cost",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/exceptional_cost/index.vue"),
									meta: {
										title: i18n.t("COSTING.EXCEPTIONAL_COST.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/exceptional_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.EXCEPTIONAL_COST.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/exceptional_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.EXCEPTIONAL_COST.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/exceptional_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.EXCEPTIONAL_COST.EDIT_TITLE")
									}
								}
							]
						},
						/** End Exceptional Cost */
						/** Start Main Salesman Cost */
						{
							path: "msm-cost",
							redirect: "msm-cost/index",
							name: "msm-cost",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/main_salesman_cost/index.vue"),
									meta: {
										title: i18n.t("COSTING.MAIN_SALESMAN_COST.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/main_salesman_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.MAIN_SALESMAN_COST.CREATE_TILE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/main_salesman_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.MAIN_SALESMAN_COST.MAIN_SALESMAN_DETAIL")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/main_salesman_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.MAIN_SALESMAN_COST.MAIN_SALESMAN_DETAIL")
									}
								}
							]
						},
						/** End Main Salesman Cost */
						/** Start Salesman Cost Group*/
						{
							path: "salesman-cost-group",
							redirect: "salesman-cost-group/index",
							name: "salesman-cost-group",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/salesman_group_cost/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("COSTING.SALESMAN_COST_GROUP.LISTING_TITLE")
										})
									}
								},
								{
									path: "edit",
									component: () => import("@/view/pages/costing/salesman_group_cost/index.vue"),
									meta: {
										title: i18n.t("COSTING.SALESMAN_COST_GROUP.TITLE")
									}
								}
							]
						},
						/** End Salesman Cost Group*/
						/** Start Assign Salesman Cost Group */
						{
							path: "salesman-cost",
							redirect: "salesman-cost/index",
							name: "salesman-cost",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/salesman_cost/index.vue"),
									meta: {
										title: i18n.t("COSTING.SALESMAN_COST.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/salesman_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.SALESMAN_COST.CREATE_TILE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/salesman_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.SALESMAN_COST.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/salesman_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.SALESMAN_COST.DETAIL_TITLE")
									}
								}
							]
						},
						/** End Assign Salesman Cost Group */
						/** Start Category Type Cost */
						{
							path: "category-type-cost",
							redirect: "category-type-cost/index",
							name: "category-type-cost",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/category_type_cost/index.vue"),
									meta: {
										title: i18n.t("COSTING.CATEGORY_TYPE_COST.TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/category_type_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.CATEGORY_TYPE_COST.CATEGORY_TYPE_SETUP_TITLE")
									}
								},
								{
									path: "create/:catId",
									component: () => import("@/view/pages/costing/category_type_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.CATEGORY_TYPE_COST.CATEGORY_TYPE_SETUP_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/category_type_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.CATEGORY_TYPE_COST.CATEGORY_TYPE_SETUP_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/category_type_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.CATEGORY_TYPE_COST.CATEGORY_TYPE_SETUP_TITLE")
									}
								}
							]
						},
						/** End Category Type Cost */
						/** Start Customer Cost */
						{
							path: "customer-cost",
							redirect: "customer-cost/index",
							name: "customer-cost",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/customer_cost/index.vue"),
									meta: {
										title: i18n.t("COSTING.CUSTOMER_COST.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/customer_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.CUSTOMER_COST.CREATE_CUSTOMER_COST_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/customer_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.CUSTOMER_COST.VIEW_CUSTOMER_COST_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/customer_cost/detail.vue"),
									meta: {
										title: i18n.t("COSTING.CUSTOMER_COST.EDIT_CUSTOMER_COST_TITLE")
									}
								}
							]
						},
						/** End Customer Cost */
						/** Start Customer Exceptional Cost */
						{
							path: "customer-exceptional-cost",
							redirect: "customer-exceptional-cost/index",
							name: "customer-exceptional-cost",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/customer_exceptional_cost/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("COSTING.CUSTOMER_EXCEPTIONAL_COST.LISTING_TITLE")
										})
									}
								}
							]
						},
						/** End Customer Exceptional Cost */
						/** Start In Range Charges */
						{
							path: "in-range-charges",
							redirect: "in-range-charges/index",
							name: "in-range-charges",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/in_range_charges/index.vue"),
									meta: {
										title: i18n.t("COSTING.IN_RANGE_CHARGES.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/in_range_charges/detail.vue"),
									meta: {
										title: i18n.t("COSTING.IN_RANGE_CHARGES.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/in_range_charges/detail.vue"),
									meta: {
										title: i18n.t("COSTING.IN_RANGE_CHARGES.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/in_range_charges/detail.vue"),
									meta: {
										title: i18n.t("COSTING.IN_RANGE_CHARGES.EDIT_TITLE")
									}
								}
							]
						},
						/** End In Range Charges */
						/** Start Out Of Range Charges */
						{
							path: "out-range-charges",
							redirect: "out-range-charges/index",
							name: "out-range-charges",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/costing/out_range_charges/index.vue"),
									meta: {
										title: i18n.t("COSTING.OUT_OF_RANGE_CHARGES.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/costing/out_range_charges/detail.vue"),
									meta: {
										title: i18n.t("COSTING.OUT_OF_RANGE_CHARGES.CREATE_TITLE")
									}
								},
								{
									path: "copy/:id",
									component: () => import("@/view/pages/costing/out_range_charges/detail.vue"),
									meta: {
										title: i18n.t("COSTING.OUT_OF_RANGE_CHARGES.DETAIL_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/costing/out_range_charges/detail.vue"),
									meta: {
										title: i18n.t("COSTING.OUT_OF_RANGE_CHARGES.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/costing/out_range_charges/detail.vue"),
									meta: {
										title: i18n.t("COSTING.OUT_OF_RANGE_CHARGES.EDIT_TITLE")
									}
								}
							]
						}
						/** End Out Of Range Charges */
					]
				},
				/**End Costing Management */
				/**Start Settings Management */
				{
					path: "/settings",
					name: "settings",
					component: () => import("@/view/pages/settings/Settings.vue"),
					children: [
						{
							path: "general",
							redirect: "general/index",
							name: "general",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/general/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.GENERAL.TITLE")
										})
									}
								}
							]
						},
						{
							path: "quotation-status",
							redirect: "quotation-status/index",
							name: "quotation_status",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/transaction/quotation_status/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.QUOTATION_STATUS.LISTING_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/transaction/quotation_status/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.QUOTATION_STATUS.EDIT_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/transaction/quotation_status/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.QUOTATION_STATUS.DETAIL_TITLE")
										})
									}
								},
								{
									path: "notification-settings/:id",
									component: () => import("@/view/pages/settings/transaction/quotation_status/notification_settings.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.QUOTATION_STATUS.NOTIFICATION_SETTINGS_TITLE")
										})
									}
								}
							]
						},
						/**Invoice On Hold */
						{
							path: "inv-status",
							redirect: "inv-status/index",
							name: "inv-status",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/transaction/inv_status/index.vue"),
									meta: {
										title: i18n.t("SETTINGS.INV_STATUS.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/settings/transaction/inv_status/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.INV_STATUS.CREATE_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/transaction/inv_status/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.INV_STATUS.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/transaction/inv_status/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.INV_STATUS.DETAIL_TITLE")
									}
								}
							]
						},
						// agent setting
						{
							path: "agent",
							redirect: "agent/index",
							name: "agent",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/agent/index.vue"),
									meta: {
										title: i18n.t("SETTINGS.AGENT.INDEX_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/settings/agent/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.AGENT.CREATE_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/agent/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.AGENT.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/agent/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.AGENT.DETAIL_TITLE")
									}
								}
							]
						},
						{
							path: "exceptional-cost-type",
							redirect: "exceptional-cost-type/index",
							name: "exceptionalCostMaster",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/costing/exceptional_cost_type/index.vue"),
									meta: {
										title: i18n.t("SETTINGS.EXCEPTIONAL_COST_TYPE.LISTING_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/costing/exceptional_cost_type/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.EXCEPTIONAL_COST_TYPE.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/costing/exceptional_cost_type/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.EXCEPTIONAL_COST_TYPE.DETAIL_TITLE")
									}
								}
							]
						},
						{
							path: "weight-rounding",
							redirect: "weight-rounding/index",
							name: "weight_rounding",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/costing/weight_rounding/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.WEIGHT_ROUNDING.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/settings/costing/weight_rounding/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.WEIGHT_ROUNDING.CREATE_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/costing/weight_rounding/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.WEIGHT_ROUNDING.EDIT_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/costing/weight_rounding/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.WEIGHT_ROUNDING.DETAIL_TITLE")
										})
									}
								}
							]
						},
						{
							path: "language",
							redirect: "language/index",
							name: "language",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/language/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.LANGUAGE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/settings/language/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.LANGUAGE.CREATE_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/language/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.LANGUAGE.EDIT_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/language/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SETTINGS.LANGUAGE.DETAIL_TITLE")
										})
									}
								}
							]
						},
						{
							path: "epo-setting",
							redirect: "epo-setting/index",
							name: "epo-setting",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/epo_setting/index.vue"),
									meta: {
										title: i18n.t("SETTINGS.EPO_SETTING.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/settings/epo_setting/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.EPO_SETTING.CREATE_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/epo_setting/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.EPO_SETTING.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/epo_setting/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.EPO_SETTING.DETAIL_TITLE")
									}
								}
							]
						},
						{
							path: "payable-note-format-setting",
							redirect: "payable-note-format-setting/index",
							name: "payable-note-format-setting",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/payable_note_format_setting/index.vue"),
									meta: {
										title: i18n.t("SETTINGS.PAYABLE_NOTE_FORMAT_SETTING.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/settings/payable_note_format_setting/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.PAYABLE_NOTE_FORMAT_SETTING.CREATE_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/payable_note_format_setting/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.PAYABLE_NOTE_FORMAT_SETTING.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/payable_note_format_setting/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.PAYABLE_NOTE_FORMAT_SETTING.DETAIL_TITLE")
									}
								}
							]
						},

						/**start Warehouse Settings */
						{
							path: "warehouse-setting",
							redirect: "warehouse-setting/index",
							name: "warehouse-setting",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/warehouse_settings/index.vue"),
									meta: {
										title: i18n.t("SETTINGS.WAREHOUSE_SETTINGS.LISTING_TITLE")
									}
								},
								{
									path: "edit/:warehouse_id",
									component: () => import("@/view/pages/settings/warehouse_settings/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.WAREHOUSE_SETTINGS.DETAIL_TITLE")
									}
								},
								{
									path: "detail/:warehouse_id",
									component: () => import("@/view/pages/settings/warehouse_settings/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.WAREHOUSE_SETTINGS.DETAIL_TITLE")
									}
								}
							]
						},
						/**start Department Settings */
						{
							path: "department-setting",
							redirect: "department-setting/index",
							name: "department-setting",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "create",
									component: () => import("@/view/pages/settings/department_settings/detail.vue"),
									meta: {
										title: i18n.t("DEPARTMENT_SETTING.PAGE.CREATE_TITLE")
									}
								},
								{
									path: "index",
									component: () => import("@/view/pages/settings/department_settings/index.vue"),
									meta: {
										title: i18n.t("DEPARTMENT_SETTING.PAGE.INDEX_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/department_settings/detail.vue"),
									meta: {
										title: i18n.t("DEPARTMENT_SETTING.PAGE.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/department_settings/detail.vue"),
									meta: {
										title: i18n.t("DEPARTMENT_SETTING.PAGE.DETAIL_TITLE")
									}
								}
							]
						},
						/**start Roles Type Settings */
						{
							path: "roles-type",
							redirect: "roles-type/index",
							name: "roles-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/roles_type/index.vue"),
									meta: {
										title: i18n.t("ROLES_TYPE.PAGE.INDEX_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/settings/roles_type/detail.vue"),
									meta: {
										title: i18n.t("ROLES_TYPE.PAGE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/roles_type/detail.vue"),
									meta: {
										title: i18n.t("ROLES_TYPE.PAGE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/roles_type/detail.vue"),
									meta: {
										title: i18n.t("ROLES_TYPE.PAGE.EDIT_TITLE")
									}
								}
							]
						},
						/** TT Deposit Status */
						{
							path: "deposit-pn-status",
							redirect: "deposit-pn-status/index",
							name: "deposit_pn_status",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/transaction/deposit_status/index.vue"),
									meta: {
										title: i18n.t("SETTINGS.TT_DEPOSIT_STATUS.LISTING_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/transaction/deposit_status/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.TT_DEPOSIT_STATUS.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/transaction/deposit_status/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.TT_DEPOSIT_STATUS.EDIT_TITLE")
									}
								}
							]
						},
						/**start EPO Status Settings */
						{
							path: "epo-status",
							redirect: "epo-status/index",
							name: "epo_status",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/epo_status/index.vue"),
									meta: {
										title: i18n.t("SETTINGS.EPO_STATUS.LISTING_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/epo_status/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.EPO_STATUS.PAGE.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/epo_status/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.EPO_STATUS.PAGE.DETAIL_TITLE")
									}
								}
							]
						}
						/**end EPO Status Settings */
					]
				},
				/**End  Settings Management*/
				/**Start Special Instruction */
				{
					path: "/special-instruction",
					name: "special-instruction",
					component: () => import("@/view/pages/transaction/Transaction.vue"),
					children: [
						/**Start special instruction by marking */
						{
							path: "by-marking",
							redirect: "by-marking/index",
							name: "by-marking",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/special_instruction/special_instruction_by_marking/index.vue"),
									meta: {
										title: i18n.t("TRANSACTION.SPECIAL_INSTRUCTION.SPECIAL_INSTRUCTION_BY_MARKING.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/transaction/special_instruction/special_instruction_by_marking/detail.vue"),
									meta: {
										title: i18n.t("TRANSACTION.SPECIAL_INSTRUCTION.SPECIAL_INSTRUCTION_BY_MARKING.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/special_instruction/special_instruction_by_marking/detail.vue"),
									meta: {
										title: i18n.t("TRANSACTION.SPECIAL_INSTRUCTION.SPECIAL_INSTRUCTION_BY_MARKING.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/special_instruction/special_instruction_by_marking/detail.vue"),
									meta: {
										title: i18n.t("TRANSACTION.SPECIAL_INSTRUCTION.SPECIAL_INSTRUCTION_BY_MARKING.EDIT_TITLE")
									}
								}
							]
						},
						/**End special instruction by marking */
						/**Start special instruction by epo */
						{
							path: "by-epo",
							redirect: "by-epo/index",
							name: "by-epo",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/special_instruction/special_instruction_by_epo/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TRANSACTION.SPECIAL_INSTRUCTION.SPECIAL_INSTRUCTION_BY_EPO.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/transaction/special_instruction/special_instruction_by_epo/detail.vue"),
									meta: {
										title: i18n.t("TRANSACTION.SPECIAL_INSTRUCTION.SPECIAL_INSTRUCTION_BY_EPO.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/special_instruction/special_instruction_by_epo/detail.vue"),
									meta: {
										title: i18n.t("TRANSACTION.SPECIAL_INSTRUCTION.SPECIAL_INSTRUCTION_BY_EPO.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/special_instruction/special_instruction_by_epo/detail.vue"),
									meta: {
										title: i18n.t("TRANSACTION.SPECIAL_INSTRUCTION.SPECIAL_INSTRUCTION_BY_EPO.EDIT_TITLE")
									}
								}
							]
						}
						/**End special instruction by epo */
					]
				},
				/**End  Special Instruction*/
				/**Start EPO Management */
				{
					path: "/epo-management",
					component: () => import("@/view/pages/transaction/Transaction.vue"),
					children: [
						/**Start EPO Listing */
						{
							path: "/",
							redirect: "index",

							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/epo_management/index.vue"),
									name: "epo-summary",
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("EPO_MANAGEMENT.EPO_LISTING.LISTING_TITLE")
										})
									}
								},
								{
									path: "create/",
									component: () => import("@/view/pages/transaction/epo_management/detail.vue"),
									meta: {
										title: i18n.t("EPO_MANAGEMENT.EPO_LISTING.CREATE_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/epo_management/detail.vue"),
									meta: {
										title: i18n.t("EPO_MANAGEMENT.EPO_LISTING.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/epo_management/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("EPO_MANAGEMENT.EPO_LISTING.DETAIL_TITLE")
										})
									}
								},
								{
									path: "copy/:id",
									component: () => import("@/view/pages/transaction/epo_management/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("EPO_MANAGEMENT.EPO_LISTING.CREATE_TITLE")
										})
									}
								}
							]
						}
						/**End EPO Listing */
					]
				},
				/**End  EPO Management*/
				/**Start Quotation Management */
				{
					path: "/quotation-management",
					component: () => import("@/view/pages/transaction/Transaction.vue"),
					children: [
						{
							path: "/",
							meta: {
								without_filter_type: 1,
								inquiry_summary: 1,
								path: []
							},
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "summary",
									meta: {
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.INQUIRY_SUMMARY")
									},
									component: () => import("@/view/pages/transaction/quotation/index.vue")
								},
								{
									path: "pend-revise",
									meta: {
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.PENDING_REVISE"),
										pend_revise_report: 1
									},
									component: () => import("@/view/pages/transaction/quotation/batch_revise.vue")
								}
							]
						},
						/** Start LCL */
						{
							path: "lcl",
							meta: {
								module: 0,
								skip_cond: "skip_confirm_lcl_condition",
								module_title: i18n.t("MENU.ASIDE.QUOTATION_MANAGEMENT_LCL")
							},
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "inquiry-summary",
									redirect: "inquiry-summary/index",
									name: "lcl-inquiry-summary",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: null,
										priv: "PMS_LCL_INQUIRY_SUMMARY",
										add_status: null,
										/** Set 0 when status greater than 450 */
										hide_preview: 0,
										hide_invalid: 0,
										inquiry_summary: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.INQUIRY_SUMMARY")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										}
									]
								},
								{
									path: "cond-confirm",
									redirect: "cond-confirm/index",
									name: "lcl-cond-confirm",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 1,
										priv: "PMS_LCL_CONDITION_CONFIRM",
										add_status: [0],
										hide_preview: 1,
										hide_invalid: 1,
										edit_marking: 1,
										show_custom_chrg_btn: 0,
										enable_custom_transit_ar_remark: 1,
										skip_validate_customer_markup: 1,
										cond_confirm: 1,
										save_api_path: "confirm-lcl",
										msg_status_name: "CONFIRM",
										show_add_shipment_route: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.COND_CONFIRM")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "create",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue"),
											meta: {
												new: 1
											}
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "cond-approve",
									redirect: "cond-approve/index",
									name: "lcl-cond-approve",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 60,
										priv: "PMS_LCL_CONDITION_APPROVE",
										add_status: [50, 70],
										hide_preview: 1,
										hide_invalid: 1,
										approve_scs_reject: 1,
										skip_validate_customer_markup: 1,
										block_edit_shipment_route: 1,
										hide_conf_btn: 1,
										approve_btn: 1,
										cond_approve: 1,
										save_api_path: "approve-lcl",
										msg_status_name: "APPROVE",
										block_upd_image: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.COND_APPROVE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "scs-confirm",
									redirect: "scs-confirm/index",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 52,
										priv: ["PMS_LCL_SCS_CONFIRM_CHARGES", "PMS_LCL_SCS_CONFIRM_SPECIAL_PROD"],
										add_status: [650],
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										hide_billing: 1,
										split_del_address: 1,
										hide_shipment_desc: 1,
										show_custom_chrg_btn: 1,
										hide_transit_desc: 1,
										enable_scs_remark: 1,
										scs_confirm: 1,
										scs_confirm_all: 1,
										skip_validate_customer_markup: 1,
										skip_validate_cost: 1,
										show_conf_btn: 1,
										block_upd_image: 1,
										save_api_path: "scs-confirm-lcl",
										msg_status_name: "CONFIRM",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.SCS_CONFIRM_ALL"),
										remain_after_edit: 1
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "scs-confirm-charges",
									redirect: "scs-confirm-charges/index",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 52,
										priv: "PMS_LCL_SCS_CONFIRM_CHARGES",
										add_status: [650],
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										hide_billing: 1,
										split_del_address: 1,
										hide_shipment_desc: 1,
										show_custom_chrg_btn: 1,
										hide_transit_desc: 1,
										enable_scs_remark: 1,
										scs_confirm: 1,
										scs_confirm_charges: 1,
										skip_validate_cost: 1,
										show_conf_btn: 1,
										block_upd_image: 1,
										save_api_path: "scs-confirm-lcl",
										msg_status_name: "CONFIRM",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.SCS_CONFIRM_CHARGES"),
										remain_after_edit: 1
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "scs-confirm-special-prod",
									redirect: "scs-confirm-special-prod/index",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 52,
										priv: "PMS_LCL_SCS_CONFIRM_SPECIAL_PROD",
										add_status: [650],
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										hide_billing: 1,
										hide_cost: 1,
										split_del_address: 1,
										hide_shipment_desc: 1,
										scs_confirm: 1,
										scs_confirm_prod: 1,
										skip_validate_customer_markup: 1,
										skip_validate_cost: 1,
										show_conf_btn: 1,
										block_upd_image: 1,
										save_api_path: "scs-confirm-lcl",
										msg_status_name: "CONFIRM",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.SCS_CONFIRM_SPECIAL_PROD"),
										remain_after_edit: 1
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "salesman-quote",
									redirect: "salesman-quote/index",
									name: "lcl-salesman-quote",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 101,
										priv: "PMS_LCL_SALESMAN_QUOTE",
										add_status: [100, 270],
										hide_preview: 1,
										hide_invalid: 1,
										hide_draft: 1,
										hide_shipment_desc: 1,
										hide_transit_exceptional_btn: 1,
										block_edit_shipment_route: 1,
										block_edit_transit_remark: 1,
										salesman_quote: 1,
										save_api_path: "salesman-quote-lcl",
										msg_status_name: "CONFIRM",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.SALESMAN_QUOTE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "quote-confirm",
									redirect: "quote-confirm/index",
									name: "lcl-quote-confirm",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 101,
										priv: "PMS_LCL_QUOTE_CONFIRM",
										add_status: [100, 270],
										hide_preview: 1,
										hide_invalid: 1,
										block_edit_shipment_route: 1,
										show_conf_btn: 0,
										block_salesman_edit: 1,
										quote_confirm: 1,
										save_api_path: "confirm-quote-lcl",
										msg_status_name: "CONFIRM",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTE_CONFIRM")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "quote-approve",
									redirect: "quote-approve/index",
									name: "lcl-quote-approve",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 300,
										priv: "PMS_LCL_QUOTE_APPROVE",
										add_status: null,
										hide_preview: 0,
										hide_invalid: 1,
										hide_conf_btn: 1,
										block_edit_shipment_route: 1,
										quote_approve: 1,
										save_api_path: "approve-quote-lcl",
										msg_status_name: "ISSUE",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTE_APPROVE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "quote-pending-acknowledge",
									redirect: "quote-pending-acknowledge/index",
									name: "lcl-quote-pending-acknowledge",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 450,
										priv: "PMS_LCL_PENDING_ACKNOWLEDGE",
										add_status: [455, 800],
										hide_preview: 0,
										hide_invalid: 0,
										pending_ack: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTATION_PENDING_ACKNOWLEDGE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "acknowledged-quote",
									redirect: "acknowledged-quote/index",
									name: "lcl-acknowledged-quote",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 500,
										priv: "PMS_LCL_ACKNOWLEDGE",
										add_status: null,
										hide_preview: 0,
										hide_invalid: 0,
										quote_ack: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTATION_ACKNOWLEDGE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "reject",
									redirect: "reject/index",
									name: "lcl-reject",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 3,
										priv: "PMS_LCL_REJECT",
										add_status: [2, 550],
										hide_preview: 1,
										hide_invalid: 1,
										quote_reject: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.REJECT_INQUIRY_QUOTATION")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "invalid-quote",
									redirect: "invalid-quote/index",
									name: "lcl-invalid-quote",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 600,
										priv: "PMS_LCL_INVALID",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										invalid_quote: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.INVALID_QUOTATION")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "quote-expire",
									redirect: "quote-expire/index",
									name: "lcl-quote-expire",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 450,
										priv: "PMS_LCL_REVISE",
										add_status: [500, 700, 800],
										hide_preview: 1,
										hide_invalid: 1,
										custom_charges_expire: 1,
										special_product_expire: 1,
										quote_expire: 1,
										revise: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTATION_EXPIRED")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "pending-revise",
									redirect: "pending-revise/index",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: null,
										priv: "PMS_LCL_APPROVE_REVISE",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										hide_draft: 1,
										pending_revise: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.PENDING_REVISE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "single/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "revise-quote",
									name: "lcl-revise-quote",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 700,
										priv: "PMS_LCL_REVISE",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										hide_draft: 1,
										revise: 1,
										msg_status_name: "REVISED",
										revise_quote: 1,
										show_conf_btn: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.REVISE_TITLE")
									},
									children: [
										{
											path: ":id",
											meta: {
												single_revise: 1,
												save_api_path: "revise-lcl-quote"
											},
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "batch-revise",

									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 700,
										priv: "PMS_LCL_REVISE",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										hide_draft: 1,
										revise: 1,
										msg_status_name: "REVISED",
										revise_quote: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.REVISE_TITLE")
									},
									children: [
										{
											path: "/",
											name: "batch-revise-lcl-quote",
											meta: {
												title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.REVISE_QUOTATION_BATCH_TITLE")
											},
											component: () => import("@/view/pages/transaction/quotation/lcl/revise_quote.vue")
										}
									]
								},
								{
									path: "approve-revise",
									redirect: "approve-revise/index",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 700,
										priv: "PMS_LCL_REVISE",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										hide_draft: 1,
										revise: 1,
										revise_quote: 1,
										save_api_path: "approve-revise-lcl-quote",
										approve_revise: 1,
										msg_status_name: "REVISED",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.APPROVE_REVISE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										},
										{
											path: "edit/:id",
											meta: {
												edit_revise: 1
											},
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								},
								{
									path: "history",
									redirect: "history/index",
									name: "lcl-history",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										history_page: 1
									},
									children: [
										{
											path: ":id",

											component: () => import("@/view/pages/transaction/quotation/lcl/history.vue")
										}
									]
								},
								{
									path: "history-detail",
									redirect: "history-detail/index",
									name: "lcl-history-detail",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										history_detail: 1
									},
									children: [
										{
											path: ":id",
											component: () => import("@/view/pages/transaction/quotation/lcl/detail.vue")
										}
									]
								}
							]
						},
						/** Start FCL */
						{
							path: "fcl",
							meta: {
								module: 1,
								mod_str: "fcl",
								skip_cond: "skip_confirm_fcl_condition",
								module_title: i18n.t("MENU.ASIDE.QUOTATION_MANAGEMENT_FCL")
							},
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "inquiry-summary",
									redirect: "inquiry-summary/index",
									name: "fcl-inquiry-summary",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: null,
										priv: "PMS_FCL_INQUIRY_SUMMARY",
										add_status: null,
										/** Set 0 when status greater than 450 */
										hide_preview: 1,
										hide_invalid: 1,
										inquiry_summary: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.INQUIRY_SUMMARY")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										}
									]
								},
								{
									path: "quotation-cost",
									redirect: "quotation-cost/index",
									name: "fcl-quotation-cost",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: null,
										priv: "PMS_FCL_COSTING_SUMMARY",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										quote_cost: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTATION_COST")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										}
									]
								},
								/**cond-confirm */
								{
									path: "cond-confirm",
									redirect: "cond-confirm/index",
									name: "fcl-cond-confirm",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 1,
										priv: "PMS_FCL_CONDITION_CONFIRM",
										add_status: [0],
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										edit_marking: 1,
										hide_scs_remark: 1,
										hide_cost: 1,
										hide_shipment_text_box: 1,
										show_conf_btn: 1,
										cond_confirm: 1,
										save_api_path: "confirm-fcl",
										msg_status_name: "CONFIRM",
										edit_supplier: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.COND_CONFIRM")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "create",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue"),
											meta: {
												new: 1
											}
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**cond-approve */
								{
									path: "cond-approve",
									redirect: "cond-approve/index",
									name: "fcl-cond-approve",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 50,
										priv: "PMS_FCL_CONDITION_APPROVE",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										edit_marking: 1,
										hide_scs_remark: 1,
										hide_cost: 1,
										hide_shipment_text_box: 1,
										hide_conf_btn: 1,
										approve_btn: 1,
										cond_approve: 1,
										save_api_path: "approve-fcl",
										msg_status_name: "APPROVE",
										edit_supplier: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.COND_APPROVE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**cost-confirm */
								{
									path: "cost-confirm",
									redirect: "cost-confirm/index",
									name: "fcl-cost-confirm",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 100,
										priv: "PMS_FCL_COSTING_CONFIRM",
										cost_confirm: 1,
										cond_status: [1] /** Check in index only skip condition settings enable */,
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										split_del_address: 1,
										hide_shipment_text_box: 1,
										show_custom_chrg_btn: 1,
										hide_transit_desc: 1,
										enable_scs_remark: 1,
										all_reject_selection: 1,
										reject_status: 70,
										show_conf_btn: 1,
										hide_markup: 1,
										save_api_path: "confirm-cost-fcl",
										msg_status_name: "CONFIRM",
										edit_supplier: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.COST_CONFIRM"),
										remain_after_edit: 1
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**cost-approve */
								{
									path: "cost-approve",
									redirect: "cost-approve/index",
									name: "fcl-cost-approve",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 200,
										priv: "PMS_FCL_COSTING_APPROVE",
										add_status: null,
										cost_approve: 1,
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										split_del_address: 1,
										hide_shipment_text_box: 1,
										all_reject_selection: 1,
										reject_status: 70,
										approve_btn: 1,
										hide_markup: 1,
										save_api_path: "approve-cost-fcl",
										msg_status_name: "APPROVE",
										edit_supplier: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.COST_APPROVE"),
										remain_after_edit: 1
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**salesman-quote */
								{
									path: "salesman-quote",
									redirect: "salesman-quote/index",
									name: "fcl-salesman-quote",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 250,
										priv: "PMS_FCL_SALESMAN_QUOTE",
										add_status: [270],
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										hide_draft: 1,
										hide_shipment_desc: 1,
										hide_shipment_text_box: 1,
										block_edit_transit_remark: 1,
										salesman_quote: 1,
										save_api_path: "salesman-quote-fcl",
										msg_status_name: "CONFIRM",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.SALESMAN_QUOTE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**quote-confirm */
								{
									path: "quote-confirm",
									redirect: "quote-confirm/index",
									name: "fcl-quote-confirm",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 270,
										priv: "PMS_FCL_QUOTE_CONFIRM",
										add_status: [250, 70],
										hide_preview: 1,
										hide_invalid: 1,
										show_service_chrg_remark: 1,
										approve_scs_reject: 1,
										show_conf_btn: 1,
										block_salesman_edit: 1,
										quote_confirm: 1,
										save_api_path: "confirm-quote-fcl",
										msg_status_name: "CONFIRM",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTE_CONFIRM")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**quote-approve */
								{
									path: "quote-approve",
									redirect: "quote-approve/index",
									name: "fcl-quote-approve",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 300,
										priv: "PMS_FCL_QUOTE_APPROVE",
										add_status: null,
										hide_preview: 0,
										hide_invalid: 1,
										show_service_chrg_remark: 1,
										hide_conf_btn: 1,
										quote_approve: 1,
										save_api_path: "approve-quote-fcl",
										msg_status_name: "APPROVE",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTE_APPROVE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**quote-pending-acknowledge */
								{
									path: "quote-pending-acknowledge",
									redirect: "quote-pending-acknowledge/index",
									name: "fcl-quote-pending-acknowledge",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 450,
										priv: "PMS_FCL_PENDING_ACKNOWLEDGE",
										add_status: null,
										hide_preview: 0,
										hide_invalid: 0,
										pending_ack: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTATION_PENDING_ACKNOWLEDGE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**acknowledge-quote */
								{
									path: "acknowledged-quote",
									redirect: "acknowledged-quote/index",
									name: "fcl-acknowledged-quote",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 500,
										priv: "PMS_FCL_ACKNOWLEDGE",
										add_status: null,
										hide_preview: 0,
										hide_invalid: 0,
										quote_ack: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTATION_ACKNOWLEDGE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**reject*/
								{
									path: "reject",
									redirect: "reject/index",
									name: "fcl-reject",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 3,
										priv: "PMS_FCL_REJECT",
										add_status: [2, 550],
										hide_preview: 1,
										hide_invalid: 1,
										quote_reject: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.REJECT_INQUIRY_QUOTATION")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**invalid*/
								{
									path: "invalid-quote",
									redirect: "invalid-quote/index",
									name: "fcl-invalid-quote",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 600,
										priv: "PMS_FCL_INVALID",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										invalid_quote: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.INVALID_QUOTATION")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								}
							]
						},
						/**Start Other Lcl */
						{
							path: "other-lcl",
							name: "other-lcl",
							meta: {
								module: 2,
								mod_str: "other_lcl",
								skip_cond: "skip_confirm_other_lcl_condition",
								module_title: i18n.t("MENU.ASIDE.QUOTATION_MANAGEMENT_OTHERS_LCL")
							},
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "inquiry-summary",
									redirect: "inquiry-summary/index",
									name: "other-lcl-inquiry-summary",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: null,
										priv: "PMS_OTHER_LCL_INQUIRY_SUMMARY",
										add_status: null,
										/** Set 0 when status greater than 450 */
										hide_preview: 1,
										hide_invalid: 1,
										inquiry_summary: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.INQUIRY_SUMMARY")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										}
									]
								},
								{
									path: "quotation-cost",
									redirect: "quotation-cost/index",
									name: "other-lcl-quotation-cost",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: null,
										priv: "PMS_OTHER_LCL_COSTING_SUMMARY",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										quote_cost: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTATION_COST")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										}
									]
								},
								/**cond-confirm */
								{
									path: "cond-confirm",
									redirect: "cond-confirm/index",
									name: "other-lcl-cond-confirm",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 1,
										priv: "PMS_OTHER_LCL_CONDITION_CONFIRM",
										add_status: [0],
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										edit_marking: 1,
										hide_scs_remark: 1,
										hide_cost: 1,
										hide_shipment_text_box: 1,
										show_conf_btn: 1,
										cond_confirm: 1,
										save_api_path: "confirm-fcl",
										msg_status_name: "CONFIRM",
										edit_supplier: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.COND_CONFIRM")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "create",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue"),
											meta: {
												new: 1
											}
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**cond-approve */
								{
									path: "cond-approve",
									redirect: "cond-approve/index",
									name: "other-lcl-cond-approve",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 50,
										priv: "PMS_OTHER_LCL_CONDITION_APPROVE",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										edit_marking: 1,
										hide_scs_remark: 1,
										hide_cost: 1,
										hide_shipment_text_box: 1,
										hide_conf_btn: 1,
										approve_btn: 1,
										cond_approve: 1,
										save_api_path: "approve-fcl",
										msg_status_name: "APPROVE",
										edit_supplier: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.COND_APPROVE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**cost-confirm */
								{
									path: "cost-confirm",
									redirect: "cost-confirm/index",
									name: "other-lcl-cost-confirm",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 100,
										priv: "PMS_OTHER_LCL_COSTING_CONFIRM",
										cost_confirm: 1,
										cond_status: [1] /** Check in index only skip condition settings enable */,
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										split_del_address: 1,
										hide_shipment_text_box: 1,
										show_custom_chrg_btn: 1,
										hide_transit_desc: 1,
										enable_scs_remark: 1,
										all_reject_selection: 1,
										reject_status: 70,
										show_conf_btn: 1,
										hide_markup: 1,
										save_api_path: "confirm-cost-fcl",
										msg_status_name: "CONFIRM",
										edit_supplier: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.COST_CONFIRM"),
										remain_after_edit: 1
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**cost-approve */
								{
									path: "cost-approve",
									redirect: "cost-approve/index",
									name: "other-lcl-cost-approve",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 200,
										priv: "PMS_OTHER_LCL_COSTING_APPROVE",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										split_del_address: 1,
										hide_shipment_text_box: 1,
										all_reject_selection: 1,
										reject_status: 70,
										approve_btn: 1,
										hide_markup: 1,
										cost_approve: 1,
										save_api_path: "approve-cost-fcl",
										msg_status_name: "APPROVE",
										edit_supplier: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.COST_APPROVE"),
										remain_after_edit: 1
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**salesman-quote */
								{
									path: "salesman-quote",
									redirect: "salesman-quote/index",
									name: "other-lcl-salesman-quote",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 250,
										priv: "PMS_OTHER_LCL_SALESMAN_QUOTE",
										add_status: [270],
										hide_preview: 1,
										hide_invalid: 1,
										hide_validity: 1,
										hide_draft: 1,
										hide_shipment_desc: 1,
										hide_shipment_text_box: 1,
										block_edit_transit_remark: 1,
										salesman_quote: 1,
										save_api_path: "salesman-quote-fcl",
										msg_status_name: "CONFIRM",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.SALESMAN_QUOTE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**quote-confirm */
								{
									path: "quote-confirm",
									redirect: "quote-confirm/index",
									name: "other-lcl-quote-confirm",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 270,
										priv: "PMS_OTHER_LCL_QUOTE_CONFIRM",
										add_status: [250, 70],
										hide_preview: 1,
										hide_invalid: 1,
										show_service_chrg_remark: 1,
										approve_scs_reject: 1,
										show_conf_btn: 1,
										block_salesman_edit: 1,
										quote_confirm: 1,
										save_api_path: "confirm-quote-fcl",
										msg_status_name: "CONFIRM",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTE_CONFIRM")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**quote-approve */
								{
									path: "quote-approve",
									redirect: "quote-approve/index",
									name: "other-lcl-quote-approve",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 300,
										priv: "PMS_OTHER_LCL_QUOTE_APPROVE",
										add_status: null,
										hide_preview: 0,
										hide_invalid: 1,
										show_service_chrg_remark: 1,
										hide_conf_btn: 1,
										quote_approve: 1,
										save_api_path: "approve-quote-fcl",
										msg_status_name: "APPROVE",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTE_APPROVE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**quote-pending-acknowledge */
								{
									path: "quote-pending-acknowledge",
									redirect: "quote-pending-acknowledge/index",
									name: "other-lcl-quote-pending-acknowledge",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 450,
										priv: "PMS_OTHER_LCL_PENDING_ACKNOWLEDGE",
										add_status: [455, 800],
										hide_preview: 0,
										hide_invalid: 0,
										pending_ack: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTATION_PENDING_ACKNOWLEDGE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**acknowledge-quote */
								{
									path: "acknowledged-quote",
									redirect: "acknowledged-quote/index",
									name: "other-lcl-acknowledged-quote",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 500,
										priv: "PMS_OTHER_LCL_ACKNOWLEDGE",
										add_status: null,
										hide_preview: 0,
										hide_invalid: 0,
										quote_ack: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTATION_ACKNOWLEDGE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**reject*/
								{
									path: "reject",
									redirect: "reject/index",
									name: "other-lcl-reject",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 3,
										priv: "PMS_OTHER_LCL_REJECT",
										add_status: [2, 550],
										hide_preview: 1,
										hide_invalid: 1,
										quote_reject: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.REJECT_INQUIRY_QUOTATION")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**invalid*/
								{
									path: "invalid-quote",
									redirect: "invalid-quote/index",
									name: "other-lcl-invalid-quote",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 600,
										priv: "PMS_OTHER_LCL_INVALID",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										invalid_quote: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.INVALID_QUOTATION")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								/**Over Range Reset*/
								{
									path: "quote-expire",
									redirect: "quote-expire/index",
									name: "other-lcl-quote-expire",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 450,
										priv: "PMS_OTHER_LCL_REVISE",
										add_status: [500, 700, 800],
										hide_preview: 1,
										hide_invalid: 1,
										custom_charges_expire: 1,
										quote_expire: 1,
										revise: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.QUOTATION_EXPIRED")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								{
									path: "scs-revise",
									redirect: "scs-revise/index",
									name: "other-lcl-scs_revise",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 650,
										priv: "PMS_OTHER_LCL_SCS_REVISE",
										add_status: [],
										hide_preview: 1,
										hide_invalid: 1,
										scs_revise: 1,
										revise: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.SCS_REVISE_QUOTATION"),
										show_conf_btn: 1,
										save_api_path: "scs-revise-fcl",
										msg_status_name: "REVISED"
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								{
									path: "revise-quote",
									redirect: "revise-quote/index",
									name: "other-lcl-revise-quote",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 700,
										priv: "PMS_OTHER_LCL_REVISE",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										hide_draft: 1,
										revise: 1,
										revise_quote: 1,
										show_conf_btn: 1,
										block_upd_image: 1,
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.REVISE_TITLE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										}
									]
								},
								{
									path: "approve-revise",
									redirect: "approve-revise/index",
									name: "other-lcl-approve-revise",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									meta: {
										status: 750,
										priv: "PMS_OTHER_LCL_APPROVE_REVISE",
										add_status: null,
										hide_preview: 1,
										hide_invalid: 1,
										hide_draft: 1,
										revise: 1,
										approve_revise_quote: 1,
										approve_btn: 1,
										save_api_path: "approve-revise-fcl",
										block_upd_image: 1,
										msg_status_name: "REVISE_ISSUE",
										page_title: i18n.t("TRANSACTION.QUOTATION_MANAGEMENT.LISTING_TITLE.APPROVE_REVISE")
									},
									children: [
										{
											path: "index",
											component: () => import("@/view/pages/transaction/quotation/index.vue")
										},
										{
											path: "detail/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "edit/:id",
											component: () => import("@/view/pages/transaction/quotation/fcl/detail.vue")
										},
										{
											path: "by-batch",
											component: () => import("@/view/pages/transaction/quotation/fcl/revise_quote.vue")
										}
									]
								}
							]
						}
					]
				},
				/**End Quotation Management */
				/**Start TT Transaction */
				{
					path: "/tt",
					name: "tt",
					component: () => import("@/view/pages/transaction/Transaction.vue"),
					children: [
						{
							path: "reject",
							redirect: "reject/index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							meta: {
								page: "reject",
								path: "tt/reject",
								show_edit: 0,
								permission_name: ["PMS_TT_REQUEST", "PMS_TT_VERIFY", "PMS_TT_APPROVE", "PMS_TT_ACKNOWLEDGE"]
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/index.vue"),
									// import("@/view/pages/transaction/tt/request/index.vue"),
									meta: {
										title: i18n.t("TT.PAGE.REJECT_SUMMARY_TITLE"),
										deposit_status: [],
										deposit_status_by_permission: [
											{
												perm_name: "PMS_TT_REQUEST",
												status: [21]
											},
											{
												perm_name: "PMS_TT_VERIFY",
												status: [21]
											},
											{
												perm_name: "PMS_TT_APPROVE",
												status: [31]
											},
											{
												perm_name: "PMS_TT_ACKNOWLEDGE",
												status: [41]
											}
										],
										create_route: null,
										module_name: i18n.t("TT.PAGE.REJECT_TITLE"),
										permission_name: "",
										controls_name: "filter-request-deposit_summary",
										view_status_id: [21, 27, 31, 41, 51, 9010, 12, 13],
										edit_status_id: [],
										delete_status_id: [],
										show_tt_zero: 0,
										draft_filter: 1,
										disabled_deposit_status: 0,
										show_delete: 0,
										path: "/tt/reject"
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/detail.vue"),

									//import("@/view/pages/transaction/tt/request/detail.vue"),
									meta: {
										title: i18n.t("TT.PAGE.REJECT_TITLE"),
										page_id: 999,
										errorPath: "/tt/reject/index",
										request: 1,
										ignore_edit: [22, 11]
									}
								}
							]
						},
						{
							path: "verify",
							redirect: "verify/index",

							component: {
								render(c) {
									return c("router-view");
								}
							},
							meta: {
								permission_name: "PMS_TT_VERIFY",
								page: "verify",
								path: "tt/verify",
								show_edit: 0,
								reject_status: 21,
								reject_title: "TT.REJECT_REQUEST_TITLE",
								reject_confirmation_title: "TT.MSG_CONFIRM_REQUEST_REJECT",
								create_by_admin: 0
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/index.vue"),
									// import("@/view/pages/transaction/tt/request/index.vue"),
									meta: {
										title: i18n.t("TT.PAGE.VERIFY_TITLE"),
										deposit_status: [10, 20, 21, 22],
										create_route: null,
										module_name: i18n.t("TT.PAGE.VERIFY_TITLE"),
										permission_name: "PMS_TT_VERIFY",
										controls_name: "filter-verify-deposit",
										view_status_id: [10, 11, 20, 21, 22],
										edit_status_id: [10, 11, 20],
										delete_status_id: [],
										show_tt_zero: 0,
										draft_filter: 0,
										disabled_deposit_status: 0,
										show_delete: 0,
										path: "/tt/verify"
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/detail.vue"),
									//import("@/view/pages/transaction/tt/verify/detail.vue"),
									meta: {
										title: i18n.t("TT.PAGE.VIEW_VERIFY_TITLE"),
										page_id: 2,
										errorPath: "/tt/verify/index",
										ignore_edit: [22]
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/detail.vue"),
									//import("@/view/pages/transaction/tt/verify/detail.vue"),
									meta: {
										title: i18n.t("TT.PAGE.EDIT_VERIFY_TITLE"),
										page_id: 2,
										errorPath: "/tt/verify/index",
										ignore_edit: [22]
									}
								}
							]
						},
						{
							path: "request",
							redirect: "request/index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							meta: {
								permission_name: "PMS_TT_REQUEST",
								page: "request",
								path: "tt/request",
								show_edit: 1
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/index.vue"),
									// import("@/view/pages/transaction/tt/request/index.vue"),
									meta: {
										title: i18n.t("TT.PAGE.REQUEST_INDEX_TITLE"),
										deposit_status: [0, 10, 11, 20, 22],
										create_route: "/tt/request/create",
										module_name: i18n.t("TT.PAGE.REQUEST_MODULE"),
										permission_name: "PMS_TT_REQUEST",
										controls_name: "filter-request-deposit_summary",
										view_status_id: [0, 10, 11, 20, 22],
										edit_status_id: [0, 10, 20],
										delete_status_id: [0, 10, 20],
										show_tt_zero: 0,
										draft_filter: 1,
										disabled_deposit_status: 0,
										show_delete: 1,
										path: "/tt/request",
										create_by_admin: 1
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/detail.vue"),
									//import("@/view/pages/transaction/tt/request/detail.vue"),
									// import("@/view/pages/transaction/tt/request/create.vue"),
									meta: {
										title: i18n.t("TT.PAGE.REQUEST_CREATE_TITLE"),
										request: 1
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/detail.vue"),

									//import("@/view/pages/transaction/tt/request/detail.vue"),
									meta: {
										title: i18n.t("TT.PAGE.VIEW_REQUEST_TITLE"),
										page_id: 1,
										errorPath: "/tt/request/index",
										request: 1,
										ignore_edit: [22, 11]
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/detail.vue"),
									//import("@/view/pages/transaction/tt/request/detail.vue"),
									meta: {
										title: i18n.t("TT.PAGE.EDIT_REQUEST_TITLE"),
										page_id: 1,
										errorPath: "/tt/request/index",
										ignore_edit: [22, 11]
									}
								}
							]
						},
						{
							path: "approve",
							redirect: "approve/index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							meta: {
								permission_name: "PMS_TT_APPROVE",
								page: "approve",
								path: "tt/approve",
								show_edit: 1,
								reject_status: 31,
								reject_title: "TT.REJECT_APPROVE_TITLE",
								reject_confirmation_title: "TT.MSG_CONFIRM_APPROVE_REJECT",
								create_by_admin: 0,
								deposit_status: 30
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/index.vue"),
									// import("@/view/pages/transaction/tt/approve/index.vue"),
									meta: {
										title: i18n.t("TT.PAGE.APPROVE_SUMMARY_TITLE"),
										deposit_status: [20, 22, 30],
										create_route: null,
										module_name: null,
										permission_name: "PMS_TT_APPROVE",
										controls_name: "filter-request-deposit_summary",
										view_status_id: [20, 22, 30],
										edit_status_id: [20, 22, 30],
										delete_status_id: [],
										show_tt_zero: 0,
										draft_filter: 0,
										disabled_deposit_status: 0,
										show_delete: 0,
										path: "/tt/approve",
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/detail.vue"),
									// import("@/view/pages/transaction/tt/approve/detail.vue"),
									meta: {
										title: i18n.t("TT.PAGE.VIEW_APPROVE_TITLE"),
										page_id: 3,
										errorPath: "/tt/approve/index",
										ignore_edit: [32],
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/detail.vue"),
									// import("@/view/pages/transaction/tt/approve/detail.vue"),
									meta: {
										title: i18n.t("TT.PAGE.EDIT_APPROVE_TITLE"),
										page_id: 3,
										errorPath: "/tt/approve/index",
										ignore_edit: [32],
									}
								}
							]
						},
						{
							path: "acknowledge",
							redirect: "acknowledge/index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							meta: {
								permission_name: "PMS_TT_REQUEST",
								page: "acknowledge",
								path: "tt/acknowledge",
								show_edit: 0,
								reject_status: 41,
								reject_title: "TT.REJECT_ACKNOWLEDGE_TITLE",
								reject_confirmation_title: "TT.MSG_CONFIRM_ACKNOWLEDGE_REJECT",
								create_by_admin: 0,
								deposit_status: 40
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/index.vue"),
									meta: {
										title: i18n.t("TT.PAGE.ACKNOWLEDGE_SUMMARY_TITLE"),
										deposit_status: [30, 90, 40, 100],
										selected_status: [30, 90],
										is_ack: true,
										modules: [
											{
												id: 1
											}
										],
										create_route: null,
										module_name: null,
										permission_name: "PMS_TT_REQUEST",
										controls_name: "filter-request-deposit_summary",
										view_status_id: [30, 32, 90, 40],
										edit_status_id: [30, 32, 90, 40],
										delete_status_id: [],
										show_tt_zero: 0,
										draft_filter: 0,
										disabled_deposit_status: 0,
										show_delete: 0,
										hide_edit: true,
										path: "/tt/acknowledge"
									}
								},
								{
									path: "index1",
									component: () => import("@/view/pages/transaction/tt/acknowledge/index.vue"),
									meta: {
										title: i18n.t("TT.PAGE.ACKNOWLEDGE_SUMMARY_TITLE"),
										deposit_status: [30, 32, 90, 40],
										create_route: null,
										module_name: null,
										permission_name: "PMS_TT_Acknowledge",
										controls_name: "filter-request-deposit_summary",
										view_status_id: [30, 32, 90],
										edit_status_id: [30, 32, 90, 40],
										delete_status_id: [],
										show_tt_zero: 0,
										draft_filter: 0,
										disabled_deposit_status: 0,
										show_delete: 0,
										path: "/tt/acknowledge"
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/detail.vue"),
									meta: {
										title: i18n.t("TT.PAGE.VIEW_ACKNOWLEDGE_TITLE"),
										page_id: 4,
										errorPath: "/tt/acknowledge/index"
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/deposit_payment/detail.vue"),
									meta: {
										title: i18n.t("TT.PAGE.EDIT_ACKNOWLEDGE_TITLE"),
										page_id: 4,
										errorPath: "/tt/acknowledge/index",
									}
								}
							]
						},
						{
							path: "pn-payment",
							redirect: "pn-payment/index",
							name: "payable-payment",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/payable_payment_confirm/index.vue"),
									meta: {
										title: i18n.t("PN_PAYMENT.APPROVE_SUMMARY")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/payable_payment_confirm/detail.vue"),
									meta: {
										title: i18n.t("PN_PAYMENT.PN_PAYMENT_DETAIL_TITLE"),
										page: 0,
										priv: null,
										btn_name: ''
									}
								},
								{
									path: "approve/:id",
									component: () => import("@/view/pages/transaction/tt/payable_payment_confirm/detail.vue"),
									meta: {
										title: i18n.t("PN_PAYMENT.MODULE_NAME_APPROVE"),
										page: 2,
										priv: "APR",
										btn_name: i18n.t('COMMON.BTN_APPROVE'),
										confirm_msg: 'PN_PAYMENT.APPROVE_PN_CONFIRMATION',
										new_status: 90
									}
								},
								{
									path: "ack/:id",
									component: () => import("@/view/pages/transaction/tt/payable_payment_confirm/detail.vue"),
									meta: {
										title: i18n.t("PN_PAYMENT.MODULE_NAME_ACKNOWLEDGE"),
										page: 3,
										priv: "ACK",
										btn_name: i18n.t('COMMON.BTN_ACKNOWLEDGE'),
										new_status: 40
									}
								},
							]
						},
						{
							path: "payment-advice",
							redirect: "payment-advice/index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/payment_advice/index.vue"),
									meta: {
										title: i18n.t("PAYMENT_ADVICE.INDEX_TITLE"),
										deposit_status: [0, 20, 30, 40, 100, 60, 70],
										default_status: [0, 20],
										hide_create_btn: false,
										hide_delete_btn: false,
										hide_edit_btn: false,
										hide_apv_btn: false

									}
								},
								{
									path: "delete-summary",
									component: () => import("@/view/pages/transaction/tt/payment_advice/index.vue"),
									meta: {
										title: i18n.t("PAYMENT_ADVICE.DELETE_REJECT_TITLE"),
										deposit_status: [21, 31, 41, 12, 13],
										default_status: [],
										hide_create_btn: true,
										hide_delete_btn: true,
										hide_edit_btn: true,
										hide_apv_btn: false
									}
								},
								{
									path: "approve-summary",
									component: () => import("@/view/pages/transaction/tt/payment_advice/index.vue"),
									meta: {
										title: i18n.t("PAYMENT_ADVICE.APPROVE_TITLE"),
										deposit_status: [20, 30],
										default_status: [],
										hide_create_btn: true,
										hide_delete_btn: true,
										hide_edit_btn: true,
										hide_apv_btn: true
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/transaction/tt/payment_advice/detail.vue"),
									meta: {
										title: i18n.t("PAYMENT_ADVICE.CREATE_TITLE"),
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/payment_advice/detail.vue"),
									meta: {
										title: i18n.t("PAYMENT_ADVICE.VIEW_TITLE"),
										is_approve: false,
										is_ack: false
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/payment_advice/detail.vue"),
									meta: {
										title: i18n.t("PAYMENT_ADVICE.EDIT_TITLE"),
										is_approve: false,
										is_ack: false
									}
								},
								{
									path: "approve/:id",
									component: () => import("@/view/pages/transaction/tt/payment_advice/detail.vue"),
									meta: {
										title: i18n.t("PAYMENT_ADVICE.APPROVE_TITLE"),
										is_approve: true,
										is_ack: false,
										approve_status: 30,
										reject_status: 31
									}
								},
								{
									path: "acknowledge/:id",
									component: () => import("@/view/pages/transaction/tt/payment_advice/detail.vue"),
									meta: {
										title: i18n.t("PAYMENT_ADVICE.ACKNOWLEDGE_TITLE"),
										is_approve: false,
										is_ack: true,
										ack_status: 40,
									}
								}
							]
						},
						{
							path: "booking",
							redirect: "booking/index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/tt_booking/index.vue"),
									meta: {
										title: i18n.t("TT_BOOKING.PAGE.INDEX_TITLE")
									}
								}
							]
						},
						{
							path: "close-tt",
							redirect: "close-tt/index",
							name: "close-tt",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/close_tt/index.vue"),
									meta: {
										title: i18n.t("CLOSE_TT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/transaction/tt/close_tt/detail.vue"),
									meta: {
										title: i18n.t("CLOSE_TT.CREATE_TTILE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/close_tt/detail.vue"),
									meta: {
										title: i18n.t("CLOSE_TT.VIEW_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/close_tt/detail.vue"),
									meta: {
										title: i18n.t("CLOSE_TT.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "credit-note",
							redirect: "credit-note/index",
							name: "credit-note",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/credit_note/index.vue"),
									meta: {
										title: i18n.t("TT_CN.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/transaction/tt/credit_note/detail.vue"),
									meta: {
										title: i18n.t("TT_CN.CREATE_TTILE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/credit_note/detail.vue"),
									meta: {
										title: i18n.t("TT_CN.VIEW_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/credit_note/detail.vue"),
									meta: {
										title: i18n.t("TT_CN.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "debit-note",
							redirect: "debit-note/index",
							name: "debit-note",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/debit_note/index.vue"),
									meta: {
										title: i18n.t("TT_DN.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/transaction/tt/debit_note/detail.vue"),
									meta: {
										title: i18n.t("TT_DN.CREATE_TTILE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/debit_note/detail.vue"),
									meta: {
										title: i18n.t("TT_DN.VIEW_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/debit_note/detail.vue"),
									meta: {
										title: i18n.t("TT_DN.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "block-tt",
							redirect: "block-tt/index",
							name: "block-tt",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/block_tt/index.vue"),
									meta: {
										title: i18n.t("BLOCK_TT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/transaction/tt/block_tt/detail.vue"),
									meta: {
										title: i18n.t("BLOCK_TT.CREATE_TTILE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/block_tt/detail.vue"),
									meta: {
										title: i18n.t("BLOCK_TT.VIEW_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/block_tt/detail.vue"),
									meta: {
										title: i18n.t("BLOCK_TT.EDIT_TITLE")
									}
								}
							]
						},
						/**Start Receipt Maintenance */
						{
							path: "receipt-maintenance",
							redirect: "receipt-maintenance/index",
							name: "receipt-maintenance",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/receipt_maintenance/index.vue"),
									meta: {
										title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.RECEIPT_MAINTENANCE.LISTING_TITLE")
									}
								},
								{
									path: "create/",
									component: () => import("@/view/pages/transaction/tt/receipt_maintenance/create.vue"),
									meta: {
										title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.RECEIPT_MAINTENANCE.CREATE_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/receipt_maintenance/detail.vue"),
									meta: {
										title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.RECEIPT_MAINTENANCE.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/receipt_maintenance/detail.vue"),
									meta: {
										title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.RECEIPT_MAINTENANCE.DETAIL_TITLE")
									}
								},
								{
									path: "post",
									component: () => import("@/view/pages/transaction/tt/receipt_maintenance/post_reverse.vue"),
									meta: {
										title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.RECEIPT_MAINTENANCE.POST_RECEIPT_TITLE"),
										posted: 0,
										api_path: 'tt-receipt-payment/receiptPaymentUnpost'
									}
								},
								{
									path: "reverse",
									component: () => import("@/view/pages/transaction/tt/receipt_maintenance/post_reverse.vue"),
									meta: {
										title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.RECEIPT_MAINTENANCE.REVERSE_RECEIPT_TITLE"),
										posted: 1,
										api_path: 'tt-receipt-payment/receiptPaymentPost'
									}
								}
							]
						},
						/**End Receipt Maintenance Listing */
						/**Start Receipt Payment Listing */
						{
							path: "receipt-payment",
							redirect: "receipt-payment/index",
							name: "receipt-payment",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/receipt_payment/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.RECEIPT_PAYMENT.LISTING_TITLE")
										})
									}
								},
								{
									path: "create/",
									component: () => import("@/view/pages/transaction/tt/receipt_payment/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.RECEIPT_PAYMENT.CREATE_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/receipt_payment/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.RECEIPT_PAYMENT.EDIT_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/receipt_payment/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.RECEIPT_PAYMENT.DETAIL_TITLE")
										})
									}
								}
							]
						},
						/**End Receipt Payment Listing*/

						/**Start TT Assignment Listing */
						{
							path: "assignment",
							redirect: "assignment/index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/tt_assignment/index.vue"),
									meta: {
										title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.TT_ASSIGNMENT.LISTING_TITLE")
									}
								},
								{
									path: "create/",
									component: () => import("@/view/pages/transaction/tt/tt_assignment/create.vue"),
									meta: {
										title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.TT_ASSIGNMENT.CREATE_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/tt_assignment/detail.vue"),
									meta: {
										title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.TT_ASSIGNMENT.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/tt_assignment/detail.vue"),
									meta: {
										title: i18n.t("TRANSACTION.TELEGRAPHIC_TRANSFER.TT_ASSIGNMENT.DETAIL_TITLE")
									}
								}
							]
						},
						/**End TT Assignment Listing*/
						/** TT Customer Special Rate */
						{
							path: "customer-special-rate",
							redirect: "customer-special-rate/index",
							name: "customer-special-rate",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/tt/tt_customer_special_rate/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CUSTOMER_SPECIAL_RATE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/transaction/tt/tt_customer_special_rate/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CUSTOMER_SPECIAL_RATE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/tt/tt_customer_special_rate/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CUSTOMER_SPECIAL_RATE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/tt/tt_customer_special_rate/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CUSTOMER_SPECIAL_RATE.EDIT_TITLE")
									}
								}
							]
						}
					]
					/**End TT Transaction */
				},
				{
					path: "/template-settings",
					name: "template-settings",
					component: () => import("@/view/pages/template_settings/TemplateSettings.vue"),
					children: [
						{
							path: "slider-management",
							redirect: "slider-management/index",
							name: "slider-management",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/template_settings/slider_management/index.vue"),
									meta: {
										title: i18n.t("TEMPLATE_SETTINGS.SLIDER_MANAGEMENT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/template_settings/slider_management/detail.vue"),
									meta: {
										title: i18n.t("TEMPLATE_SETTINGS.SLIDER_MANAGEMENT.ADD_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/template_settings/slider_management/detail.vue"),
									meta: {
										title: i18n.t("TEMPLATE_SETTINGS.SLIDER_MANAGEMENT.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/template_settings/slider_management/detail.vue"),
									meta: {
										title: i18n.t("TEMPLATE_SETTINGS.SLIDER_MANAGEMENT.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "announcement-management",
							redirect: "announcement-management/index",
							name: "announcement-management",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/template_settings/announcement_management/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.ANNOUNCEMENT_MANAGEMENT.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/template_settings/announcement_management/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.ANNOUNCEMENT_MANAGEMENT.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/template_settings/announcement_management/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.ANNOUNCEMENT_MANAGEMENT.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/template_settings/announcement_management/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.ANNOUNCEMENT_MANAGEMENT.EDIT_TITLE")
										})
									}
								}
							]
						},
						{
							path: "banner-management",
							redirect: "banner-management/index",
							name: "banner-management",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/template_settings/banner_management/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.BANNER_MANAGEMENT.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/template_settings/banner_management/add.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.BANNER_MANAGEMENT.ADD_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/template_settings/banner_management/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.BANNER_MANAGEMENT.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/template_settings/banner_management/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.BANNER_MANAGEMENT.EDIT_TITLE")
										})
									}
								}
							]
						},
						{
							path: "notice-management",
							redirect: "notice-management/index",
							name: "notice-management",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/template_settings/notice_management/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.NOTICE_MANAGEMENT.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/template_settings/notice_management/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.NOTICE_MANAGEMENT.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/template_settings/notice_management/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.NOTICE_MANAGEMENT.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/template_settings/notice_management/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TEMPLATE_SETTINGS.NOTICE_MANAGEMENT.EDIT_TITLE")
										})
									}
								}
							]
						}
					]
				},
				/**Start Invoice */
				{
					path: "/invoice-management",
					component: () => import("@/view/pages/transaction/Transaction.vue"),
					children: [
						{
							path: "/",
							redirect: "invoice-management/index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "invoice-summary",
									component: () => import("@/view/pages/transaction/invoice/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TRANSACTION.INVOICE_MANAGEMENT.INVOICE_SUMMARY_TITLE")
										})
									}
								},
								{
									path: "create-by-ref-id",
									name: "create-invoice-by-ref-id",
									component: () => import("@/view/pages/transaction/invoice/create_by_ref_id.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TRANSACTION.INVOICE_MANAGEMENT.CREATE_BY_REF_ID_TITLE")
										})
									}
								},
								{
									path: "create",
									name: "create-new-invoice",
									component: () => import("@/view/pages/transaction/invoice/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TRANSACTION.INVOICE_MANAGEMENT.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									name: "invoice-detail",
									component: () => import("@/view/pages/transaction/invoice/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TRANSACTION.INVOICE_MANAGEMENT.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									name: "invoice-edit",
									component: () => import("@/view/pages/transaction/invoice/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("TRANSACTION.INVOICE_MANAGEMENT.EDIT_TITLE")
										})
									}
								}
							]
						}
					]
				},
				/**End Invoice*/
				{
					path: "/cms",
					name: "cms",
					component: () => import("@/view/pages/cms/Cms.vue"),
					children: [
						{
							path: "page",
							redirect: "page/index",
							name: "page",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/cms/page/index.vue"),
									meta: {
										title: i18n.t("CMS.PAGE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/cms/page/detail.vue"),
									meta: {
										title: i18n.t("CMS.PAGE.ADD_NEW_PAGE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/cms/page/detail.vue"),
									meta: {
										title: i18n.t("CMS.PAGE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/cms/page/detail.vue"),
									meta: {
										title: i18n.t("CMS.PAGE.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "menu",
							redirect: "menu/index",
							name: "menu",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/cms/menu/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("CMS.MENU.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/cms/menu/add.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("CMS.MENU.ADD_NEW_MENU")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/cms/menu/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("CMS.MENU.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/cms/menu/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("CMS.MENU.EDIT_TITLE")
										})
									}
								}
							]
						},
						{
							path: "menu-item/",
							redirect: "menu-item/index",
							name: "menu-item",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							meta: {
								page: "menu_item"
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/cms/menu_item/index.vue")
								},
								{
									path: "index/:id",
									component: () => import("@/view/pages/cms/menu_item/index.vue")
								},
								{
									path: "create/:menu_id",
									component: () => import("@/view/pages/cms/menu_item/detail.vue")
								},
								{
									path: "detail/:menu_id/:id",
									component: () => import("@/view/pages/cms/menu_item/detail.vue")
								},
								{
									path: "edit/:menu_id/:id",
									component: () => import("@/view/pages/cms/menu_item/detail.vue")
								}
							]
						}
					]
				},
				{
					path: "/do-management",
					component: () => import("@/view/pages/transaction/Transaction.vue"),
					children: [
						{
							path: "/",
							redirect: "index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "do_summary",
									component: () => import("@/view/pages/transaction/do_management/index.vue"),
									meta: {
										title: i18n.t("DO_MANAGEMENT.DO_SUMMARY.MODULE_NAME"),
										do_create: 1
									}
								},
								{
									path: "create",
									name: "create-do",
									component: () => import("@/view/pages/transaction/do_management/detail.vue"),
									meta: {
										title: i18n.t("DO_MANAGEMENT.DO_SUMMARY.CREATE_TITLE"),
										permission_name: "PMS_DO_SUMMARY"
									}
								},
								{
									path: "create-by-ref-id",
									name: "create-do-ref-id",
									component: () => import("@/view/pages/transaction/do_management/create_by_ref_id.vue"),
									meta: {
										title: i18n.t("DO_MANAGEMENT.DO_SUMMARY.CREATE_DO_BY_REFID")
									}
								},
								{
									path: "detail/:id",
									name: "do-detail",
									component: () => import("@/view/pages/transaction/do_management/detail.vue"),
									meta: {
										title: i18n.t("DO_MANAGEMENT.DO_SUMMARY.DETAIL_TITLE"),
										permission_name: "PMS_DO_SUMMARY"
									}
								},
								{
									path: "edit/:id",
									name: "do-edit",
									component: () => import("@/view/pages/transaction/do_management/detail.vue"),
									meta: {
										title: i18n.t("DO_MANAGEMENT.DO_SUMMARY.EDIT_TITLE"),
										permission_name: "PMS_DO_SUMMARY"
									}
								}
							]
						},
						{
							path: "close",
							name: "do-close-by-ref-id",
							component: () => import("@/view/pages/transaction/do_management/index_by_ref_id.vue"),
							meta: {
								title: i18n.t("MENU.ASIDE.CLOSE_DO_BY_REF_ID"),
								do_page: "close_do",
								confirm_msg: i18n.t("DO_MANAGEMENT.MSG.CONFIRM_CLOSE_REF_ID")
							}
						},
						{
							path: "open",
							name: "do-open-by-ref-id",
							component: () => import("@/view/pages/transaction/do_management/index_by_ref_id.vue"),
							meta: {
								title: i18n.t("MENU.ASIDE.OPEN_DO_BY_REF_ID"),
								do_page: "open_do",
								confirm_msg: i18n.t("DO_MANAGEMENT.MSG.CONFIRM_OPEN_REF_ID")
							}
						},
						{
							path: "assign",
							name: "do-assign",
							component: () => import("@/view/pages/transaction/do_management/index_by_ref_id.vue"),
							meta: {
								title: i18n.t("DO_MANAGEMENT.DO_SUMMARY.ASSIGN_DO_DATE_TRANSPORTER_TITLE"),
								do_page: "assign_date"
							}
						},
						{
							path: "req-change-delivery",
							redirect: "req-change-delivery/index",
							name: "req-change-delivery",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/do_management/index.vue"),
									meta: {
										title: i18n.t("DO_MANAGEMENT.TITLE.REQ_CHANGE_DEL_ADDR"),
										req_change_addr: 1
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/transaction/do_management/detail.vue"),
									meta: {
										title: i18n.t("DO_MANAGEMENT.TITLE.REQ_CHANGE_DEL_ADDR"),
										permission_name: "PMS_DO_CHANGE_DELIVERY",
										change_address: 1
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/do_management/detail.vue"),
									meta: {
										title: i18n.t("DO_MANAGEMENT.TITLE.REQ_CHANGE_DEL_ADDR"),
										permission_name: "PMS_DO_CHANGE_DELIVERY",
										change_address: 1
									}
								}
							]
						},
						{
							path: "approve-change-delivery",
							redirect: "approve-change-delivery/index",
							name: "approve-change-delivery",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/transaction/do_management/index.vue"),
									meta: {
										title: i18n.t("DO_MANAGEMENT.TITLE.APPROVE_CHANGE_DEL_ADDR"),
										approve_change_addr: 1,
										change_address_status: [1, 10]
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/transaction/do_management/detail.vue"),
									meta: {
										title: i18n.t("DO_MANAGEMENT.TITLE.APPROVE_CHANGE_DEL_ADDR"),
										permission_name: "PMS_DO_CHANGE_DELIVERY",
										change_address: 1,
										approve_address: 1
									}
								}
							]
						}
					]
				},
				{
					path: "/product",
					name: "product",
					component: () => import("@/view/pages/product_management/ProductManagement.vue"),
					children: [
						/**Start Category Type */
						{
							path: "category-type",
							redirect: "category-type/index",
							name: "category-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/product_management/category_type/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY_TYPE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/product_management/category_type/detail.vue"),
									meta: {
										title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/product_management/category_type/detail.vue"),
									meta: {
										title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/product_management/category_type/detail.vue"),
									meta: {
										title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						/**End Category Type*/
						/**Start Sensitive Type */
						{
							path: "sensitive-type",
							redirect: "sensitive-type/index",
							name: "sensitive-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/product_management/sensitive_type/index.vue"),
									meta: {
										title: i18n.t("PRODUCT_MANAGEMENT.SENSITIVE_TYPE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/product_management/sensitive_type/detail.vue"),
									meta: {
										title: i18n.t("PRODUCT_MANAGEMENT.SENSITIVE_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/product_management/sensitive_type/detail.vue"),
									meta: {
										title: i18n.t("PRODUCT_MANAGEMENT.SENSITIVE_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/product_management/sensitive_type/detail.vue"),
									meta: {
										title: i18n.t("PRODUCT_MANAGEMENT.SENSITIVE_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						/**End Sensitive Type */
						/**Start Category */
						{
							path: "category",
							redirect: "category/parent/index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "parent",
									redirect: "parent/index",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									children: [
										{
											path: "index",
											name: "category-parent-index",
											component: () => import("@/view/pages/product_management/category/index_parent.vue"),
											meta: {
												title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY_PARENT.LISTING_TITLE")
											}
										},
										{
											path: "create",
											name: "category-parent-create",
											component: () => import("@/view/pages/product_management/category/detail.vue"),
											meta: {
												title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY_PARENT.CREATE_TITLE")
											}
										},
										{
											path: "detail/:id",
											name: "category-parent-view",
											component: () => import("@/view/pages/product_management/category/detail.vue"),
											meta: {
												title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY_PARENT.DETAIL_TITLE")
											}
										},
										{
											path: "edit/:id",
											name: "category-parent-edit",
											component: () => import("@/view/pages/product_management/category/detail.vue"),
											meta: {
												title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY_PARENT.EDIT_TITLE")
											}
										}
									]
								},
								{
									path: "child",
									redirect: "child/index",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									children: [
										{
											path: "index",
											name: "category-child-index",
											component: () => import("@/view/pages/product_management/category/index.vue"),
											meta: {
												title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY.LISTING_TITLE")
											}
										},
										{
											path: "create",
											name: "category-child-create",
											component: () => import("@/view/pages/product_management/category/detail.vue"),
											meta: {
												title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY.CREATE_TITLE")
											}
										},
										{
											path: "detail/:id",
											name: "category-child-view",
											component: () => import("@/view/pages/product_management/category/detail.vue"),
											meta: {
												title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY.DETAIL_TITLE")
											}
										},
										{
											path: "edit/:id",
											name: "category-child-edit",
											component: () => import("@/view/pages/product_management/category/detail.vue"),
											meta: {
												title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY.EDIT_TITLE")
											}
										}
									]
								}
							]
						},
						/**End Category */
						/** START Special Product */
						{
							path: "special-product",
							redirect: "special-product/index",
							name: "special-product",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/product_management/special_product/index.vue"),
									meta: {
										title: i18n.t("PRODUCT_MANAGEMENT.SPECIAL_PRODUCT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/product_management/special_product/detail.vue"),
									meta: {
										title: i18n.t("PRODUCT_MANAGEMENT.SPECIAL_PRODUCT.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/product_management/special_product/detail.vue"),
									meta: {
										title: i18n.t("PRODUCT_MANAGEMENT.SPECIAL_PRODUCT.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/product_management/special_product/detail.vue"),
									meta: {
										title: i18n.t("PRODUCT_MANAGEMENT.SPECIAL_PRODUCT.EDIT_TITLE")
									}
								}
							]
						}
						/** End Special Product */
					]
				},
				{
					path: "/master-data",
					name: "master-data",
					component: () => import("@/view/pages/master_data/MasterData.vue"),
					children: [
						{
							path: "charges-type",
							redirect: "charges-type/index",
							name: "charges-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/transaction/charges_type/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.CHARGES_TYPE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/transaction/charges_type/detail.vue"),

									meta: {
										title: i18n.t("MASTER_DATA.CHARGES_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/transaction/charges_type/detail.vue"),

									meta: {
										title: i18n.t("MASTER_DATA.CHARGES_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/transaction/charges_type/detail.vue"),

									meta: {
										title: i18n.t("MASTER_DATA.CHARGES_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "notice-type-group",
							redirect: "notice-type-group/index",
							name: "notice-type-group",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/transaction/notice_type_group/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.NOTICE_TYPE_GROUP.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/transaction/notice_type_group/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.NOTICE_TYPE_GROUP.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/transaction/notice_type_group/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.NOTICE_TYPE_GROUP.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/transaction/notice_type_group/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.NOTICE_TYPE_GROUP.EDIT_TITLE")
										})
									}
								}
							]
						},
						{
							path: "notice-type",
							redirect: "notice-type/index",
							name: "notice-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/transaction/notice_type/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.NOTICE_TYPE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/transaction/notice_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.NOTICE_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/transaction/notice_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.NOTICE_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/transaction/notice_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.NOTICE_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "doc-type",
							redirect: "doc-type/index",
							name: "doc-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/transaction/doc_type/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.DOC_TYPE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/transaction/doc_type/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.DOC_TYPE.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/transaction/doc_type/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.DOC_TYPE.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/transaction/doc_type/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.DOC_TYPE.EDIT_TITLE")
										})
									}
								}
							]
						},
						{
							path: "doc-num",
							redirect: "doc-num/index",
							name: "doc-num",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/transaction/doc_num/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.DOC_NUM.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/transaction/doc_num/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DOC_NUM.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/transaction/doc_num/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DOC_NUM.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/transaction/doc_num/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DOC_NUM.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "countries",
							redirect: "countries/index",
							name: "countries",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/countries_region/countries/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.COUNTRIES.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/countries_region/countries/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COUNTRIES.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/countries_region/countries/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COUNTRIES.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/countries_region/countries/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COUNTRIES.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "states",
							redirect: "states/index",
							name: "states",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/countries_region/states/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.STATES.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/countries_region/states/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.STATES.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/countries_region/states/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.STATES.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/countries_region/states/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.STATES.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "cities",
							redirect: "cities/index",
							name: "cities",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/countries_region/cities/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.CITIES.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/countries_region/cities/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CITIES.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/countries_region/cities/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CITIES.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/countries_region/cities/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CITIES.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "district",
							redirect: "district/index",
							name: "district",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/countries_region/district/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DISTRICT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/countries_region/district/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DISTRICT.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/countries_region/district/detail.vue"),

									meta: {
										title: i18n.t("MASTER_DATA.DISTRICT.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/countries_region/district/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DISTRICT.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "delivery-zone",
							redirect: "delivery-zone/index",
							name: "delivery-zone",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/countries_region/delivery_zone/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_ZONE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/countries_region/delivery_zone/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_ZONE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/countries_region/delivery_zone/detail.vue"),

									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_ZONE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/countries_region/delivery_zone/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_ZONE.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "postcode",
							redirect: "postcode/index",
							name: "postcode",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/countries_region/postcodes/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.POSTCODES.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/countries_region/postcodes/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.POSTCODES.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/countries_region/postcodes/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.POSTCODES.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/countries_region/postcodes/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.POSTCODES.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "location",
							redirect: "location/index",
							name: "location",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/countries_region/location/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.LOCATION.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/countries_region/location/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.LOCATION.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/countries_region/location/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.LOCATION.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/countries_region/location/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.LOCATION.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "tax-type",
							redirect: "tax-type/index",
							name: "tax-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/currencies_tax/tax_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TAX_TYPE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/currencies_tax/tax_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TAX_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/currencies_tax/tax_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TAX_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/currencies_tax/tax_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TAX_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "currency-group",
							redirect: "currency-group/index",
							name: "currency-group",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/currencies_tax/currency_group/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CURRENCY_GROUP.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/currencies_tax/currency_group/details.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CURRENCY_GROUP.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/currencies_tax/currency_group/details.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CURRENCY_GROUP.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/currencies_tax/currency_group/details.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CURRENCY_GROUP.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "currencies",
							redirect: "currencies/index",
							name: "currencies",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/currencies_tax/currencies/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.CURRENCIES.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/currencies_tax/currencies/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CURRENCIES.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/currencies_tax/currencies/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CURRENCIES.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/currencies_tax/currencies/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CURRENCIES.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "port",
							redirect: "port/index",
							name: "port",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/countries_region/port/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.PORT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/countries_region/port/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.PORT.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/countries_region/port/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.PORT.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/countries_region/port/detail.vue"),

									meta: {
										title: i18n.t("MASTER_DATA.PORT.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "company",
							redirect: "company/index",
							name: "company",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/company/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COMPANY.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/company/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COMPANY.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/company/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COMPANY.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/company/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.COMPANY.EDIT_TITLE")
										})
									}
								}
							]
						},
						{
							path: "warehouse",
							redirect: "warehouse/index",
							name: "warehouse",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/warehouse/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.WAREHOUSE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/warehouse/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/warehouse/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/warehouse/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "shipment-mode",
							redirect: "shipment-mode/index",
							name: "shipment-mode",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/shipment_mode/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_MODE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/shipment_mode/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_MODE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/shipment_mode/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_MODE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/shipment_mode/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_MODE.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "movement-type",
							redirect: "movement-type/index",
							name: "movement-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/movement_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.MOVEMENT_TYPE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/movement_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.MOVEMENT_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/movement_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.MOVEMENT_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/movement_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.MOVEMENT_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "shipment-terms",
							redirect: "shipment-terms/index",
							name: "shipment-terms",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/shipment_terms/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_TERMS.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/shipment_terms/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_TERMS.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/shipment_terms/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_TERMS.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/shipment_terms/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.SHIPMENT_TERMS.EDIT_TITLE")
										})
									}
								}
							]
						},
						{
							path: "ref-type",
							redirect: "ref-type/index",
							name: "ref-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/reference_type/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.REFERENCE_TYPE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/reference_type/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.REFERENCE_TYPE.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/reference_type/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.REFERENCE_TYPE.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/reference_type/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.REFERENCE_TYPE.EDIT_TITLE")
										})
									}
								}
							]
						},
						/**Start Courier Company */
						{
							path: "courier-company",
							redirect: "courier-company/index",
							name: "courier-company",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/courier_company/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COURIER_COMPANY.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/courier_company/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COURIER_COMPANY.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/courier_company/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COURIER_COMPANY.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/courier_company/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COURIER_COMPANY.EDIT_TITLE")
									}
								}
							]
						},
						/**End Courier Company */
						{
							path: "shipment-type",
							redirect: "shipment-type/index",
							name: "shipment-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/shipment_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_TYPE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/shipment_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/shipment_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/shipment_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						/**Start Forwarder */
						{
							path: "forwarder",
							redirect: "forwarder/index",
							name: "forwarder",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/forwarder/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.FORWARDER.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/forwarder/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.FORWARDER.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/forwarder/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.FORWARDER.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/forwarder/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.FORWARDER.EDIT_TITLE")
										})
									}
								}
							]
						},
						/**End Forwarder */
						/**Start Forwarder Group*/
						{
							path: "forwarder-group",
							redirect: "forwarder-group/index",
							name: "forwarder-group",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/forwarder_group/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.FORWARDER_GROUP.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/forwarder_group/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.FORWARDER_GROUP.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/forwarder_group/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.FORWARDER_GROUP.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/forwarder_group/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", { page_title: i18n.t("MASTER_DATA.FORWARDER_GROUP.EDIT_TITLE") })
									}
								}
							]
						},
						/**End Forwarder Group*/
						/**Start Shipper */
						{
							path: "shipper",
							redirect: "shipper/index",
							name: "shipper",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/shipper/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.SHIPPER.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/shipper/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.SHIPPER.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/shipper/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.SHIPPER.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/shipper/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.SHIPPER.EDIT_TITLE")
										})
									}
								}
							]
						},
						/**End Shipper */
						/**Start Shipping Line */
						{
							path: "shipping-line",
							redirect: "shipping-line/index",
							name: "shipping-line",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/shipping_line/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.SHIPPING_LINE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/shipping_line/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.SHIPPING_LINE.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/shipping_line/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.SHIPPING_LINE.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/shipping_line/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.SHIPPING_LINE.EDIT_TITLE")
										})
									}
								}
							]
						},
						/**End Shipping Line
			/**Start Transporter */
						{
							path: "transporter",
							redirect: "transporter/index",
							name: "transporter",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/transporter/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.TRANSPORTER.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/transporter/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.TRANSPORTER.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/transporter/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.TRANSPORTER.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/transporter/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.TRANSPORTER.EDIT_TITLE")
										})
									}
								}
							]
						},
						/**End Transporter
			/**Start Consignee */
						{
							path: "consignee",
							redirect: "consignee/index",
							name: "consignee",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/consignee/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", { page_title: i18n.t("MASTER_DATA.CONSIGNEE.LISTING_TITLE") })
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/consignee/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", { page_title: i18n.t("MASTER_DATA.CONSIGNEE.CREATE_TITLE") })
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/consignee/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", { page_title: i18n.t("MASTER_DATA.CONSIGNEE.DETAIL_TITLE") })
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/consignee/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", { page_title: i18n.t("MASTER_DATA.CONSIGNEE.EDIT_TITLE") })
									}
								}
							]
						},
						/**End Consignee */
						/**Start Consignee Group*/
						{
							path: "consignee-group",
							redirect: "consignee-group/index",
							name: "consignee-group",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/consignee_group/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.CONSIGNEE_GROUP.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/shipment/consignee_group/create.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.CONSIGNEE_GROUP.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/shipment/consignee_group/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.CONSIGNEE_GROUP.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/shipment/consignee_group/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", { page_title: i18n.t("MASTER_DATA.CONSIGNEE_GROUP.EDIT_TITLE") })
									}
								}
							]
						},
						/**End Consignee Group*/
						/**Start Credit Terms */
						{
							path: "credit-terms",
							redirect: "credit-terms/index",
							name: "credit-terms",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/customer/credit_terms/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CREDIT_TERMS.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/customer/credit_terms/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CREDIT_TERMS.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/customer/credit_terms/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CREDIT_TERMS.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/customer/credit_terms/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CREDIT_TERMS.EDIT_TITLE")
									}
								}
							]
						},
						/**End Credit Terms */
						/**Start Industries */
						{
							path: "industries",
							redirect: "industries/index",
							name: "industries",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/customer/industries/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.INDUSTRIES.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/customer/industries/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INDUSTRIES.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/customer/industries/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INDUSTRIES.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/customer/industries/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INDUSTRIES.EDIT_TITLE")
									}
								}
							]
						},
						/**End Industries */
						/**Start Partner Type */
						{
							path: "partner-type",
							redirect: "partner-type/index",
							name: "partner-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/customer/partner_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.PARTNER_TYPE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/customer/partner_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.PARTNER_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/customer/partner_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.PARTNER_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/customer/partner_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.PARTNER_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						/**End Partner Type */
						/** Start Forwarder Costing Type**/
						{
							path: "forwarder-costing-type",
							redirect: "forwarder-costing-type/index",
							name: "forwarder-costing-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/customer/forwarder_costing_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.FORWARDER_COSTING_TYPE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/customer/forwarder_costing_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.FORWARDER_COSTING_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/customer/forwarder_costing_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.FORWARDER_COSTING_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/customer/forwarder_costing_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.FORWARDER_COSTING_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						/** End Forwarder Costing Type**/
						/** Start GL Account Setting**/
						{
							path: "gl-account-setting",
							redirect: "gl-account-setting/index",
							name: "gl-account-setting",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/customer/gl_account_setting/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.GL_ACCOUNT_SETTING.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/customer/gl_account_setting/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.GL_ACCOUNT_SETTING.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/customer/gl_account_setting/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.GL_ACCOUNT_SETTING.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/customer/gl_account_setting/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.GL_ACCOUNT_SETTING.EDIT_TITLE")
									}
								}
							]
						},
						/** End GL Account Setting**/
						/**Start Delivery Remark */
						{
							path: "delivery-remark",
							redirect: "delivery-remark/index",
							name: "delivery-remark",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/customer/delivery_remark/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_REMARK.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/customer/delivery_remark/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_REMARK.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/customer/delivery_remark/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_REMARK.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/customer/delivery_remark/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_REMARK.EDIT_TITLE")
									}
								}
							]
						},
						/**End Delivery Remark */
						/**Start Customer Update Log Reject Info */
						{
							path: "customer-update-log-reject-info",
							redirect: "customer-update-log-reject-info/index",
							name: "customer-update-log-reject-info",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/customer/customer_update_log_reject_info/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CUSTOMER_UPDATE_LOG_REJECT_INFO.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/customer/customer_update_log_reject_info/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CUSTOMER_UPDATE_LOG_REJECT_INFO.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/customer/customer_update_log_reject_info/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CUSTOMER_UPDATE_LOG_REJECT_INFO.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/customer/customer_update_log_reject_info/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CUSTOMER_UPDATE_LOG_REJECT_INFO.EDIT_TITLE")
									}
								}
							]
						},
						/**End Customer Update Log Reject Info*/
						/**Start Territory */
						{
							path: "territory",
							redirect: "territory/index",
							name: "territory",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/countries_region/territory/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TERRITORY.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/countries_region/territory/detail.vue"),

									meta: {
										title: i18n.t("MASTER_DATA.TERRITORY.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/countries_region/territory/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TERRITORY.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/countries_region/territory/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TERRITORY.EDIT_TITLE")
									}
								}
							]
						},
						/**End Territory */
						/**Start UOM */
						{
							path: "uom",
							redirect: "uom/index",
							name: "uom",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/stock/uom/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.UOM.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/stock/uom/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.UOM.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/stock/uom/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.UOM.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/stock/uom/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.UOM.EDIT_TITLE")
										})
									}
								}
							]
						},
						/**End UOM */
						/* Wesly 04/05/2021 Start*/
						{
							path: "validity",
							redirect: "validity/index",
							name: "validity",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/quotation/validity/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.VALIDITY.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/quotation/validity/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.VALIDITY.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/quotation/validity/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.VALIDITY.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/quotation/validity/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.VALIDITY.EDIT_TITLE")
									}
								}
							]
						},
						/* Wesly 04/05/2021 End*/
						/* Wesly 09/05/2021 indemnify Start*/
						{
							path: "indemnify",
							redirect: "indemnify/index",
							name: "indemnify",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/quotation/indemnify/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INDEMNIFY.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/quotation/indemnify/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INDEMNIFY.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/quotation/indemnify/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INDEMNIFY.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/quotation/indemnify/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.INDEMNIFY.EDIT_TITLE")
										})
									}
								}
							]
						},
						/* Wesly 09/05/2021 indemnify End*/
						/* Wesly 09/05/2021 freights Charges Start*/
						{
							path: "freight-charges",
							redirect: "freight-charges/index",
							name: "freight-charges",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/quotation/freight_charges/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.FREIGHT_CHARGES.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/quotation/freight_charges/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.FREIGHT_CHARGES.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/quotation/freight_charges/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.FREIGHT_CHARGES.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/quotation/freight_charges/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.FREIGHT_CHARGES.EDIT_TITLE")
									}
								}
							]
						},
						/* Wesly 09/05/2021 freights Charges*/
						/** Start Warehouse Location */
						{
							path: "warehouse-location",
							redirect: "warehouse-location/index",
							name: "warehouse-location",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/warehouse_location/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.WAREHOUSE_LOCATION.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/warehouse_location/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE_LOCATION.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/warehouse_location/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE_LOCATION.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/warehouse_location/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE_LOCATION.EDIT_TITLE")
									}
								}
							]
						},
						/** End Warehouse Location */
						/**Start Tax */
						{
							path: "tax",
							redirect: "tax/index",
							name: "tax",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/currencies_tax/tax/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TAX.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/currencies_tax/tax/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TAX.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/currencies_tax/tax/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TAX.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/currencies_tax/tax/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TAX.EDIT_TITLE")
									}
								}
							]
						},
						/**End Tax */
						/**Start instruction type */
						{
							path: "instruction-type",
							redirect: "instruction-type/index",
							name: "instruction-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/transaction/instruction_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INSTRUCTION_TYPE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/transaction/instruction_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INSTRUCTION_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/transaction/instruction_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INSTRUCTION_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/transaction/instruction_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INSTRUCTION_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						/**End instruction type */
						/**Start Pay Mode */
						{
							path: "pay-mode",
							redirect: "pay-mode/index",
							name: "pay-mode",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/transaction/pay_mode/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.PAY_MODE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/transaction/pay_mode/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.PAY_MODE.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/transaction/pay_mode/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.PAY_MODE.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/transaction/pay_mode/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.PAY_MODE.EDIT_TITLE")
										})
									}
								}
							]
						},
						/**End Pay Mode */
						/**Start Quotation Subject */
						{
							path: "quotation-subject",
							redirect: "quotation-subject/index",
							name: "quotation-subject",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/quotation/quotation_subject/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.QUOTATION_SUBJECT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/quotation/quotation_subject/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.QUOTATION_SUBJECT.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/quotation/quotation_subject/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.QUOTATION_SUBJECT.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/quotation/quotation_subject/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.QUOTATION_SUBJECT.EDIT_TITLE")
									}
								}
							]
						},
						/**End Quotation Subject */
						/**Start Customer Reject Reason */
						{
							path: "customer-reject-quote-reason",
							redirect: "customer-reject-quote-reason/index",
							name: "customer-reject-quote-reason",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/quotation/customer_reject_reason/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CUSTOMER_REJECT_QUOTE_REASON.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/quotation/customer_reject_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CUSTOMER_REJECT_QUOTE_REASON.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/quotation/customer_reject_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CUSTOMER_REJECT_QUOTE_REASON.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/quotation/customer_reject_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CUSTOMER_REJECT_QUOTE_REASON.EDIT_TITLE")
									}
								}
							]
						},
						/**End Customer Reject Reason */
						/**Start Admin Reject Reason */
						{
							path: "admin-reject-quote-reason",
							redirect: "admin-reject-quote-reason/index",
							name: "admin-reject-quote-reason",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/quotation/admin_reject_reason/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.ADMIN_REJECT_QUOTE_REASON.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/quotation/admin_reject_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.ADMIN_REJECT_QUOTE_REASON.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/quotation/admin_reject_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.ADMIN_REJECT_QUOTE_REASON.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/quotation/admin_reject_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.ADMIN_REJECT_QUOTE_REASON.EDIT_TITLE")
									}
								}
							]
						},
						/**End Admin Reject Reason */
						/**Start Revert Quotation Reason */
						{
							path: "revert-quote-reason",
							redirect: "revert-quote-reason/index",
							name: "revert-quote-reason",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/quotation/revert_reason/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.REVERT_QUOTE_REASON.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/quotation/revert_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.REVERT_QUOTE_REASON.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/quotation/revert_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.REVERT_QUOTE_REASON.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/quotation/revert_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.REVERT_QUOTE_REASON.EDIT_TITLE")
									}
								}
							]
						},
						/**End Revert Quotation Reason */
						/**Start Quotation Invalid Reason */
						{
							path: "quote-invalid-reason",
							redirect: "quote-invalid-reason/index",
							name: "quote-invalid-reason",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/quotation/invalid_reason/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INVALID_QUOTE_REASON.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/quotation/invalid_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INVALID_QUOTE_REASON.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/quotation/invalid_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INVALID_QUOTE_REASON.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/quotation/invalid_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INVALID_QUOTE_REASON.EDIT_TITLE")
									}
								}
							]
						},
						/**End Quotation Invalid Reason */
						/**Start Special Product Status */
						{
							path: "special-product-status",
							redirect: "special-product-status/index",
							name: "special-product-status",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/product/special_prod_status/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SPECIAL_PRODUCT_STATUS.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/product/special_prod_status/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SPECIAL_PRODUCT_STATUS.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/product/special_prod_status/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SPECIAL_PRODUCT_STATUS.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/product/special_prod_status/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SPECIAL_PRODUCT_STATUS.EDIT_TITLE")
									}
								}
							]
						},
						/**End Special Product Status */
						/**Start warehouse type Status */
						{
							path: "warehouse-type",
							redirect: "warehouse-type/index",
							name: "warehouse-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "warehouse-type-index",
									component: () => import("@/view/pages/master_data/warehouse_type/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.WAREHOUSE_TYPE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									name: "warehouse-type-create",
									component: () => import("@/view/pages/master_data/warehouse_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "warehouse-type-detail",
									component: () => import("@/view/pages/master_data/warehouse_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									name: "warehouse-type-edit",
									component: () => import("@/view/pages/master_data/warehouse_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						/**End warehouse type Status */
						/** START TT Master  */
						/** TT Charges */
						{
							path: "tt-charges",
							redirect: "tt-charges/index",
							name: "tt-charges",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-charges-index",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_charges/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CHARGES.LISTING_TITLE")
									}
								},
								{
									path: "create",
									name: "tt-charges-create",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_charges/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CHARGES.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "tt-charges-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_charges/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CHARGES.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									name: "tt-charges-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_charges/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CHARGES.EDIT_TITLE")
									}
								}
							]
						},
						/** TT Beneficiary Type */
						{
							path: "tt-beneficiary-type",
							redirect: "tt-beneficiary-type/index",
							name: "tt-beneficiary-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-beneficiary-type-index",
									component: () => import("@/view/pages/master_data/tt_instruction/beneficiary_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_BENEFICIARY_TYPE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									name: "tt-beneficiary-type-create",
									component: () => import("@/view/pages/master_data/tt_instruction/beneficiary_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_BENEFICIARY_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "tt-beneficiary-type-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/beneficiary_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_BENEFICIARY_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									name: "tt-beneficiary-type-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/beneficiary_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_BENEFICIARY_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						/** TT Category */ {
							path: "tt-category",
							redirect: "tt-category/index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-category",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_category/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CATEGORY.LISTING_TITLE")
									}
								},
								{
									path: "create",
									name: "tt-category-create",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_category/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CATEGORY.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "tt-category-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_category/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CATEGORY.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									name: "tt-category-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_category/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CATEGORY.EDIT_TITLE")
									}
								}
							]
						},
						/** TT Agent */
						{
							path: "tt-bank-code",
							redirect: "tt-bank-code/index",
							name: "tt-bank-code",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-bank-code-index",
									component: () => import("@/view/pages/master_data/tt_instruction/bank_code/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_BANK_CODE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									name: "tt-bank_code-create",
									component: () => import("@/view/pages/master_data/tt_instruction/bank_code/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_BANK_CODE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "tt-bank_code-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/bank_code/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_BANK_CODE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									name: "tt-bank_code-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/bank_code/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_BANK_CODE.EDIT_TITLE")
									}
								}
							]
						},
						/** TT Group */
						{
							path: "tt-group",
							redirect: "tt-group/index",
							name: "tt-group",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-group-index",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_group/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.TT_GROUP.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									name: "tt-group-create",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_group/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_GROUP.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "tt-group-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_group/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_GROUP.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									name: "tt-group-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_group/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_GROUP.EDIT_TITLE")
									}
								}
							]
						},
						/** TT Reject Reason */
						{
							path: "tt-reject-reason",
							redirect: "tt-reject-reason/index",
							name: "tt-reject-reason",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-reject-reason-index",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_reject_reason/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_REJECT_REASON.LISTING_TITLE")
									}
								},
								{
									path: "create",
									name: "tt-reject-reason-create",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_reject_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_REJECT_REASON.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "tt-reject-reason-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_reject_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_REJECT_REASON.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									name: "tt-reject-reason-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_reject_reason/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_REJECT_REASON.EDIT_TITLE")
									}
								}
							]
						},
						/**TT DAILY RATE */
						{
							path: "tt-daily-rate",
							redirect: "tt-daily-rate/index",
							name: "tt-daily-rate",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-daily-rate-index",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_daily_rate/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_DAILY_RATE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									name: "tt-daily-rate-create",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_daily_rate/rate.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_DAILY_RATE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:currency_id/:date",
									name: "tt-daily-rate-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_daily_rate/rate.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_DAILY_RATE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:currency_id/:date",
									name: "tt-daily-rate-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_daily_rate/rate.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_DAILY_RATE.EDIT_TITLE")
									}
								}
							]
						},
						/** TT Agent */
						{
							path: "tt-agent",
							redirect: "tt-agent/index",
							name: "tt-agent",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-agent-index",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_agent/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_AGENT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									name: "tt-agent-create",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_agent/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_AGENT.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "tt-agent-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_agent/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_AGENT.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									name: "tt_agent-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_agent/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_AGENT.EDIT_TITLE")
									}
								}
							]
						},
						/** TT Creditor */
						{
							path: "tt-creditor",
							redirect: "tt-creditor/index",
							name: "tt-creditor",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-creditor-index",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_creditor/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CREDITOR.LISTING_TITLE")
									}
								},
								{
									path: "create",
									name: "tt-creditor-create",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_creditor/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CREDITOR.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "tt-creditor-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_creditor/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CREDITOR.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									name: "tt_creditor-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_creditor/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_CREDITOR.EDIT_TITLE")
									}
								}
							]
						},
						/** TT GL Account */
						{
							path: "tt-gl-account",
							redirect: "tt-gl-account/index",
							name: "tt-gl-account",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-gl-account-index",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_gl_account/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_GL_ACCOUNT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									name: "tt-gl-account-create",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_gl_account/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_GL_ACCOUNT.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "tt-gl-account-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_gl_account/detail.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.TT_GL_ACCOUNT.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:id",
									name: "tt-gl-account-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_gl_account/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_GL_ACCOUNT.EDIT_TITLE")
									}
								}
							]
						},
						/** TT Default GL Account */
						{
							path: "tt-default-gl-account",
							redirect: "tt-default-gl-account/index",
							name: "tt-default-gl-account",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-default-gl-account-index",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_default_gl_account/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_DEFAULT_GL_ACCOUNT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									name: "tt-default-gl-account-create",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_default_gl_account/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_DEFAULT_GL_ACCOUNT.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "tt-default-gl-account-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_default_gl_account/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_DEFAULT_GL_ACCOUNT.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									name: "tt_default-gl-account-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_default_gl_account/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_DEFAULT_GL_ACCOUNT.EDIT_TITLE")
									}
								}
							]
						},
						/** TT Company */
						{
							path: "tt-company",
							redirect: "tt-company/index",
							name: "tt-company",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-company-index",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_company/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_COMPANY.LISTING_TITLE")
									}
								},
								{
									path: "create",
									name: "tt-company-create",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_company/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_COMPANY.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									name: "tt-company-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_company/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_COMPANY.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									name: "tt_company-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_company/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.TT_COMPANY.EDIT_TITLE")
									}
								}
							]
						},
						/** TT Spread Rate */
						{
							path: "tt-spread-rate",
							redirect: "tt-spread-rate/index",
							name: "tt-spread-rate",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "tt-spread-rate-index",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_spread_rate/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.TT_SPREAD_RATE.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									name: "tt-spread-rate-create",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_spread_rate/rate.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.TT_SPREAD_RATE.CREATE_TITLE")
										})
									}
								},
								{
									path: "detail/:currency_id",
									name: "tt-spread-rate-detail",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_spread_rate/rate.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.TT_SPREAD_RATE.DETAIL_TITLE")
										})
									}
								},
								{
									path: "edit/:currency_id",
									name: "tt-spread-rate-edit",
									component: () => import("@/view/pages/master_data/tt_instruction/tt_spread_rate/rate.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.TT_SPREAD_RATE.EDIT_TITLE")
										})
									}
								}
							]
						},
						/** END TT MASTER */
						/**Invoice Ref Id */
						{
							path: "inv-ref",
							redirect: "inv-ref/index",
							name: "inv-ref",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/transaction/inv_ref/index.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("MASTER_DATA.INV_REF.LISTING_TITLE")
										})
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/transaction/inv_ref/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INV_REF.CREATE_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/transaction/inv_ref/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INV_REF.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/transaction/inv_ref/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.INV_REF.DETAIL_TITLE")
									}
								}
							]
						},
						/**Start Special Product Remark */
						{
							path: "special-product-remark",
							redirect: "special-product-remark/index",
							name: "special-product-remark",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/product/special_product_remark/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SPECIAL_PRODUCT_REMARK.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/product/special_product_remark/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SPECIAL_PRODUCT_REMARK.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/product/special_product_remark/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SPECIAL_PRODUCT_REMARK.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/product/special_product_remark/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SPECIAL_PRODUCT_REMARK.EDIT_TITLE")
									}
								}
							]
						},
						/**End Special Product Remark */
						/**Start Delivery Agent */
						{
							path: "delivery-agent",
							redirect: "delivery-agent/index",
							name: "delivery-agent",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/delivery_agent/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_AGENT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/delivery_agent/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_AGENT.CREATE_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/delivery_agent/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_AGENT.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/delivery_agent/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DELIVERY_AGENT.DETAIL_TITLE")
									}
								}
							]
						}
						/** End Delivery Agent */
					]
				},
				/** End Master Data */
				/** Start Log */
				{
					path: "/system-log",
					redirect: "/system-log/admin-api",
					component: () => import("@/view/pages/log/Log.vue"),
					children: [
						{
							path: "/",
							redirect: "/system-log/admin-api",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "admin-api",
									component: () => import("@/view/pages/log/log_summary.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SYSTEM_LOG.ADMIN_API_LOG_TITLE")
										})
									}
								},
								{
									path: "customer-api",
									component: () => import("@/view/pages/log/log_summary.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SYSTEM_LOG.CUSTOMER_API_LOG_TITLE")
										})
									}
								},
								{
									path: "wms-api",
									component: () => import("@/view/pages/log/log_summary.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SYSTEM_LOG.WMS_API_LOG_TITLE")
										})
									}
								},
								{
									path: "sync-wms",
									component: () => import("@/view/pages/log/log_summary.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("SYSTEM_LOG.SYNC_WMS_LOG_TITLE")
										})
									}
								}
							]
						}
					]
				},
				{
					path: "/audit-log",
					component: () => import("@/view/pages/log/Log.vue"),
					children: [
						{
							path: "/",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "quotation",
									component: () => import("@/view/pages/log/audit_log.vue"),
									meta: {
										title: i18n.t("COMMON.META_TTILE", {
											page_title: i18n.t("AUDIT_LOG.QUOTATION_LOG")
										})
									}
								}
							]
						}
					]
				}
			]
		},
		{
			path: "/",
			component: () => import("@/view/pages/auth/login_pages/Login"),
			children: [
				{
					name: "login",
					path: "/login",
					component: () => import("@/view/pages/auth/login_pages/Login"),
					meta: {
						title: i18n.t("AUTH.LOGIN.TITLE")
					}
				}
			]
		},
		{
			name: "forgot-password",
			path: "/forgot-password/:vtoken",
			component: () => import("@/view/pages/auth/login_pages/ForgotPassword"),
			meta: {
				title: i18n.t("AUTH.FORGOT.TITLE")
			}
		},
		{
			path: "*",
			redirect: "/404"
		},
		{
			// the 404 route, when none of the above matches
			path: "/404",
			name: "404",
			component: () => import("@/view/pages/error/Error-1.vue")
		}
	]
});
