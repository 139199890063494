export const locale = {
	WEEKDAYS: {
		MONDAY: "Monday",
		TUESDAY: "Tuesday",
		WEDNESDAY: "Wednesday",
		THURSDAY: "Thursday",
		FRIDAY: "Friday",
		SATURDAY: "Saturday",
		SUNDAY: "Sunday",
	},
	COMMON: {
		LBL_AGENT: "GBizs Agent",
		LBL_MOBILE_CONTACT_NO: "Mobile Contact No.",
		LBL_MOBILE_CONTTACT_PIC: "Mobile Contact Pic",

		LBL_ALT_MOBILE_CONTACT_NO: "Mobile Contact No. (Alt)",
		LBL_ALT_MOBILE_CONTTACT_PIC: "Mobile Contact Pic (Alt)",
		LBL_STATUS: "Status",

		LBL_OFFICE_NO: "Office No. ",
		LBL_OFFICE_NO_PIC: "Office No. PIC",
		LBL_SET_INVALID_PERSONNEL: "Invalid By",
		LBL_APPROVED_BY: "Approved By",
		LBL_REJECTED_BY: "Rejected By",
		LBL_REVERTED_BY: "Reverted By",
		LBL_ACKNOWLEDGE_BY: "Acknowledge By",
		LBL_VERIFY_BY: "Verify By",
		LBL_ACTION_TIME: "Action Time",
		LBL_REFERENCE_NO: "Ref. No.",
		LBL_HISTORY_DATE: "Modified Date",
		LBL_PARCEL_GUARANTEE: "Parcel Guarantee",
		LBL_REMARK: "Remark",
		LBL_AR_REMARK: "AR Remark",
		LBL_AP_REMARK: "AP Remark",
		LBL_RECEIVED_AMOUNT: "Received Amt",
		LBL_MARKING: "Marking",
		LBL_SO_NO: "S/O No.",
		LBL_NOTICE_TYPE: "Notice Type",
		LBL_EMAIL: "Email",
		LBL_TIN_NO: "Tin No.",
		LBL_CATEGORY_TYPE: "Category Type",

		LBL_FAX_NO: "Fax No.",
		LBL_FAX_NO_PIC: "Fax No. PIC",
		LBL_SERIAL_NO: "No.",

		SHIP_AIR: "Air",
		SHIP_ROAD: "Road",
		SHIP_SEA: "Sea",

		TXT_ALL: "All",
		TOTAL_TITLE: "Total",
		TXT_RANGE: "Between {from} - {to}",
		TXT_ABV: ">= {from}",
		TXT_BELOW: "<= {to}",

		SELECTION_OTHERS: "Other",
		PH_ENTER_TEXTBOX: "Enter {txt_fieldname}",
		PH_ENTER_DATE: "Enter Date",
		PH_ENTER_DATE_FROM: "Enter {name} From",
		PH_ENTER_DATE_TO: "Enter {name} To",
		PHP_ENTER_SEARCH: "Enter {name} To Search",
		MSG_SUPPORT_IMAGE_SIZE_FORMAT:
			"Supported size should be less than 1 MB and Supported Format .jpg, .jpeg, .png",
		MSG_IMAGE_FORMAT_NOT_SUPPORTED:
			"Image format not supported. Supported format: .jpg, .jpeg, .png",
		MSG_SUPPORT_DOC_SIZE_FORMAT:
			"Supported Document size should be less than 10 MB",
		MSG_PROGRESS: "Please wait a moment ... ....",
		MSG_SUPPORT_EXCEL_FILE: "Supported File Type: .xlsx, .xls",
		MSG_ROW_ERROR: "Row: {idx}",

		STATUS: {
			INACTIVE: "Inactive",
			ACTIVE: "Active",
			SUSPEND: "Suspend",
			UNVERIFIED: "Un-verify",
			YES: "Yes",
			WGI: "WGI",
			NO: "No",
			REQUIRED: "Required",
			OPTIONAL: "Optional",
			DRAFT: "Save As Draft",
			PENDING: "Pending",
			APPROVED: "Approved",
			REJECTED: "Rejected",
			VERIFY: "Verifying",
			CLOSED: "Closed",
			OPEN: "Open",
			ACKNOWLEDGE: "Acknowledge",
			NEW: "New",
			EXPIRE: "Expired",
			VALID: "Valid",
			LOCKED: "Locked",
			REJECT: "Reject",
			REVERT: "Revert",
			VERIFIED: "Verified",
			BLOCKED: "Blocked"
		},

		MSG: {
			NETWORK_ERR: "Unstable Network Connection",
			NETWORK_MESS: "We're detecting a network error. Please ensure you have a stable internet connection and try again.",
			TIMEOUT_ERROR: "System Timeout",
			TIMEOUT_MESS: "Your session has timed out. Please log in again",
			API_SERVER_DOWN: "Server Connection Refused. Please contact System Admin.",
			MSG_DATA_NOT_FOUND: "No Records Found",
			DATA_NOT_AVAILABLE: "No Data Available",
			SUCCESSFUL_IMPORT_EXCEL: "Import excel successfully",
			API_SERVER_DOWN_WITH_NAME: "{name} Server Connection Refused, Please Contact System Admin",
			AGENT_PATH_MISSING: "{name} route not set",
			VERSION_NOT_MATCH: "The data has been modified, please reload.",
		},

		VALIDATION: {
			INVALID: "{name} is not valid",
			REQUIRED: "{name} is required",
			REQUIRED_GREATER_ZERO: "{name} must be greater than zero",
			MIN_LENGTH: "{name} minimum length is {min}",
			AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
			NOT_FOUND: "The requested {name} is not found",
			REF_NOT_FOUND: "Ref/Doc No.: {ref_no} is not found",
			INVALID_LOGIN: "The login detail is incorrect",
			REQUIRED_FIELD: "Required field",
			MIN_LENGTH_FIELD: "Minimum field length:",
			MAX_LENGTH_FIELD: "Maximum field length:",
			INVALID_FIELD: "Field is not valid",
			NUMBER: "{name} must be number",
			CANNOT_EQUAL: "{p1} cannot be the same as {p2}",
			EXPIRE: "{name} must be greater than current date",

			MISSING_REQ_DATA: "Please fill in all details",
			INVALID_WARNING_MSG: "Please, provide correct data!",
			DUPLICATE_ENTRY: "{name} is duplicate.",
			UNIQUE_FIELD: "{name} must be unique.",
			DOCUMENT_NUMBERING_NOT_SET: "Document Number Format is not found. Please setup first.",
			DOC_NUMBER_NOT_SET_TYPE: "{doc_type} Document Number Format is not found. Please setup first.",
			ID_NOT_FOUND: "Record not found",
			MISSING_DOC_TYPE: "Doc Type: {doc_type} not found. Please Contact System Admin.",
			UNIQUE_VALUE: "{code} is not available",
			STATE_CODE_REQUIRED: "State code is not set",
			MARKING_NOT_EXISTS: "Marking not exists",
			DOCUMENT_NO_DUPLICATE: "Document Number is duplicate. Please try again.",
			DATE_FROM_GREATER_THAN_DATE_TO: "{date_to} must be greater than {date_from}",
			AMOUNT_GREATER_THAN: "{name} must be greater than {field}",
			DATA_NOT_FOUND_REQ_CREATE: "{code} unable to found. Please create a new record",

			INVALID_CONTACT_NO_FORMAT: {
				FORMAT_MY: "{name} is not valid, correct format eg: 123456789"
			},
			POSITIVE: "{name} only allow enter positive number",
			RECORD_NO_SELECTED: "No record has been selected",
			INVALID_DATE_FORMAT_EXCEL: "{name} date format not supported",
			ROW_DATA_NOT_FOUND: "Row: {idx} data not found",
			DUPLICATE_RECORD_SET: "Duplicate Set",
			DOCUMENT_FORMAT_LENGHT: "No. Format cannot more than {len} character",
			DOCUMNET_NUMBER_FORMAT_DUPLICATE_SET: "No.Format: {format} duplicate set",
			EXCEL_FILE_NOT_UPLOAD: "Please upload product excel file",
			EXCEL_FILE_TYPE_INVALID: "Excel File Type Invalid",
			EXCEL_IMPORT_DATA_NOT_FOUND: "Uploaded Data not found",
			EXCEL_DATA_INCORRECT: "Please ensure uploaded data is correct",
			CURRENCY_RATE_NOT_SET: "Daily rate is not set",
			CURRENCY_RATE_DEFAULT_NOT_SET: "{currency} conversion setting not set. Please contact Person In Charges",
			DATA_NOT_FOUND: "{data} not found",
			DUPLICATE_RECORD: "{data} having more than 1 record",
			WMS_API_PATH: "{code} Wms Api Path not set. Please contact system admin",
		},

		BTN_DOWNLOAD_ALL_AS_PDF: "Download All As PDF",
		BTN_DOWNLOAD_AS_PDF: "Download As PDF",
		BTN_REJECT: "Reject",
		BTN_VERIFY: "Verify",
		BTN_SEARCH: "Search",
		BTN_CLEAR: "Clear",
		BTN_CANCEL: "Cancel",
		BTN_BACK: "Back",
		BTN_SAVE: "Save",
		BTN_BROWSE: "Browse",
		BTN_EDIT: "Edit",
		BTN_REVERT: "Revert",
		BTN_CREATE: "Create New {btn_name}",
		BTN_SUBMIT: "Submit",
		BTN_APPROVE: "Approve",
		BTN_ADD: "Add",
		BTN_DELETE: "Delete",
		BTN_COPY: "Copy",
		BTN_CLOSE: "Close",
		BTN_CONFIRM: "Confirm",
		BTN_ACKNOWLEDGE: "Acknowledge",
		BTN_PREVIEW: "Preview",
		BTN_SAVE_AS_DRAFT: "Save As Draft",
		BTN_PRINT: "Print",
		BTN_PRINT_ALL: "Print All",
		BTN_CONFIRM_N_APPROVE: "Confirm & Approve",
		BTN_VIEW: "View",
		BTN_RESET: "Reset",
		BTN_DOWNLOAD_ALL: "Download All",
		BTN_DOWNLOAD_SELECTED: "Download Selected",
		BTN_COST_SETUP: "Cost Setup",
		BTN_LEAVE: "Leave Page",
		BTN_STAY: "Stay on the page",
		BTN_REFRESH: "Refresh",
		BTN_OK: "Ok",
		BTN_UPLOAD: "upload",
		BTN_IMPORT: "Import Excel",
		BTN_POST: "Post",
		BTN_REVERSE: "Reverse",
		BTN_SYNC_ALL: "Sync All",
		BTN_SYNC_ALL_WAREHOUSE: "Sync All To Warehouse",
		BTN_SYNC_ALL_AGENT: "Sync All To Agent",
		BTN_PREVIEW_IMAGE: "Preview Image",
		BTN_PREVIEW_DOC: "Preview Document",
		BTN_UPLOAD_IMAGE: "Upload Image",
		BTN_UPLOAD_DOC: "Upload Supported Document",
		BTN_ALL: "All",
		BTN_HISTORY: "History",
		BTN_SYNC_TO_AGENT: "Sync To Agent",
		BTN_SYNC_TO_WAREHOUSE: "Sync To Warehouse",
		BTN_POST_TO_ACCOUNT: "Post To Account",
		BTN_NEW: "New",
		BTN_SKIP: "Skip",
		BTN_ENABLE_EDIT: "Enable Edit",
		BTN_DISABLE_EDIT: "Disable Edit",
		BTN_COPY_EDIT: "Copy/Edit",
		BTN_IGNORE: "Ignore",
		BTN_SELECT: "Select",
		BTN_LINK: "Link",
		BTN_REMOVE: "Remove",
		BTN_DROP_FILE: "Click or Drag {file} file to this area to upload",
		BTN_CLICK_HERE: "Click Here",
		BTN_ADD_FROM_SO_RECEIVED: "Add from S/O Received",
		BTN_YES: "Yes",
		BTN_NO: "No",

		BTN_ENABLED: "Enable Edit {name}",
		BTN_DISABLE: "Disable Edit {name}",
		BTN_EXPORT_EXCEL: "Export To Excel",

		BTN_MANUAL: "Manual",
		BTN_AUTO: "Auto",

		FILE_TYPE: {
			EXCEL: "Excel"
		},

		COL_RECEIVED_AMOUNT: "Rcv. Amt.",
		COL_RECEIVED_DATE: "Rcv. Date",
		COL_NAME: "Name",
		COL_EMAIL: "Email",

		USER_TYPE: {
			INTERNAL_STAFF: "Internal Staff",
			MAIN_SALESMAN: "Main Salesman",
			SALESMAN: "Salesman",
			SYSTEM_ADMIN: "System Admin",
			WAREHOUSE_STAFF: "Warehouse/Office Staff",
			AGENT_STAFF: "Agent Staff"
		},

	},
	MENU: {
		ASIDE: {
			CATEGORY_PARENT: "Product Category (Parent)",
			CATEGORY: "Product Category (Lvl 2)",

			USER: "User",
			ROLE: "Role",
			DATA_FILTER: "Data Filter",
			USER_N_ROLE_MANAGEMENT: "User & Role Management",
			USER_GROUP: "User Group"
		}
	},
	AUTH: {
		BTN_SIGN_OUT: "Sign Out",
		USERNAME_TITLE: "Hi, {username}",
	},
	EPO: {
		FORMAT_CN: "Chinese Format",
		FORMAT_EN: "English Format",
		FORMAT_IN: "Indonesian Format",
		FORMAT_TH: "Thai Format",
		FORMAT_VN: "Vietnamese Format",

		DOWNLOAD_EPO_IMPORT_EXCEL_FORMAT: "Download Import Product Excel Format",
		BTN_IMPORT_PROD: "Import Product",
		UPLOAD_RPODUCT_TITLE: "Import Product Excel File",

		MSG: {
			EXCEL_SHEET_NOT_FOUND: "{name} Sheet not found in excel file",
			EXCEL_FORMAT_NOT_FOUND: "{name} Sheet Column not matching!",
			EXCEL_DATA_EMPTY: "{name} Sheet no data set",
			PARCEL_GUARANTEE_ADD_CHARGES_CUSTOMER: "Additional Parcel Guarantee charges will apply. Please contact to person in charges.",
			PARCEL_GUARANTEE_ADD_CHARGES_ADMIN: "Additional Parcel Guarantee charges will apply. Please confirm with customer",
			PARCEL_GUARANTEE_CONFIRMATION: "Additional Parcel Guarantee charges will apply. Are you sure to continue?",
		},
		BTN_ADD_PROD: "Add Product",
		LBL_HAS_TAX_REBATE: "Tax Rebate"
	},
	CUSTOMER: {
		REQUEST_TYPE: {
			CREATE_TYPE: "Create New",
			UPDATE_TYPE: "Update",
		},
		STATUS: {
			PENDING: "Pending",
			VERIFYING: "Verifying",
			APPROVED: "Approved",
			REJECTED: "Rejected",
		},
		LBL_SAVE_DELIVERY_ADDRESS: "Save As Delivery Address",
	},
	TT: {
		BENE_MODEL: {
			BENE_TITLE: "Beneficiary Details",
			INTERMEDIATE_TITLE: "Intermediate Bank Details",
			INTERMEDIATE_CHECKBOX: "Intermediate Bank?",
			ACC_HOLDER: "Account Holder",
			ACC_NO: "Account Number",
			BENE_CONTACT_PIC: "Bene Contact PIC",
			BENE_CONTACT_CALLING_CODE: "Bene Calling Code",
			BENE_CONTACT_NO: "Bene Contact Number",
			RI_NO: "Ri Number",
			BANK_NAME: "Bank Name",
			BANK_CITY: "Bank City",
			BANK_COUNTRY: "Bank Country",
			BANK_ADDRESS: "Bank Address",
			BENE_ADDRESS: "Bene Address",
			BANK_REMARK: "Bank Remark",
			BANK_CODE: "BIC / Swift Code",
			IBAN: "IBAN",
			INTER_BANK_NAME: "Intermediate Bank Name",
			INTER_BANK_ACC: "Intermediate Account No",
			INTER_SWIFT_CODE: "Intermediate BIC / Swift Code",
			INTER_IBAN_CODE: "Intermediate IBAN Code",
			BENE_DETAIL_NAME: "Beneficiary Detail Name"
		},
		TYPE: {
			DEPOSIT: "Deposit",
			PAYMENT: "Payment",
		}
	},
	GRN_FORMAT: {
		HEADER: {
			DATE: "Date",
			SUPPLIER: "Supplier",
			SUPPLIER_CODE: "Supplier Code",
			REMARK: "Remark",
			MOBILE: "Mobile No.",
			CONTACT_PERSON: "Contact Person",
			DOC_NO: "Doc No.",
			PHONE_NO: "Phone No.",
			PAYMENT_METHOD: "Payment Method",
			RECEIPT: "Receipt",
			SHARED_SO: "Shared S/O No."
		},
		CONTENT: {
			SERIAL_NO: "No.",
			CUSTOMER_MARKING: "Custmer Marking",
			SO_NO: "So No.",
			CTN: "Quantity",
			WEIGHT: "Weight",
			DIMENSION: "Dimension",
			OTHER: "Other",
			PN: "PN",
			TOTAL: "Total",
		},
		FOOTER: {
			DELIVER: "Delivery Person's Signature",
			PERSON_IN_CHARGES: "Person In Charge",
			CONTACT_PERSON: "Contact Person",
			CONTACT_NO: "Contact No."
		}
	},
	PN_FORMAT: {
		HEADER: {
			DATE: "Date",
			SUPPLIER: "Supplier",
			SUPPLIER_CODE: "Supplier Code",
			REMARK: "Remark",
			MOBILE: "Mobile No.",
			CONTACT_PERSON: "Contact Person",
			DOC_NO: "Doc No.",
			PHONE_NO: "Phone No.",
			PAYMENT_METHOD: "Payment Method",
			ACCOUNT_NAME: "Account Name",
			ACCOUNT_NO: "Account No.",
			BANK_ADDRESS: "Bank Branch",
			BANK_NAME: "Bank Name"
		},
		CONTENT: {
			SERIAL_NO: "No.",
			CUSTOMER_MARKING: "Custmer Marking",
			SO_NO: "So No.",
			CTN: "Quantity",
			CURRENCY: "Currency",
			WEIGHT: "Weight",
			DIMENSION: "Dimension",

			TOTAL_AMT: "Order Amount",
			DISCOUNT: "Discount",
			DEPOSIT: "Deposit",
			STICKER_FEE: "Deduct Sticker Fee",
			BALANCE_AMT: "BALANCE AMOUNT",
			TOTAL: "Total"
		},
		FOOTER: {
			DELIVER: "Delivery Person's Signature",
			PERSON_IN_CHARGES: "Person In Charge",
			CONTACT_PERSON: "Contact Person",
			CONTACT_NO: "Contact No."
		}
	},
	REPORT: {
		COMMON: {
			COL_SERIAL_NO: "No.",
			COL_EFFECTIVE_DATE: "Effective Date",
			COL_DEPOSIT_NO: "Deposit No.",
			COL_CUSTOMER_MARKING: "Marking",

			TOTAL: "Total",
			MAIN_SALESMAN: "Main Salesman"
		},
		PACKING_LIST_FORMAT: {
			TITLE: "Packing List Details (C) New",
			SO_NO: "S/O No.",
			SUPPLIER_NAME: "Sup Name",
			T_M3: "T/M3<br />(T/CM3)",
			T_KG: "T/Kg<br />(Vol Weight)",
			T_QTY: "T/Pqty",
			BILL_AMT: "Bill Amt",
			LOADED_AMOUNT: "Loaded Amt",
			CUS_DEPOSIT: "Cus Deposit",
			MY_PAYMENT: "My P'ment",
			FCP_AMT: "FCP Amount",
			SUP_DEPOSIT: "Sup Deposit",
			LOCAL_PAYMENT: "Local P'ment",

			STOCK_DESC: "Stock Short Desc",
			M3: "M3",
			KG: "Kg",
			P_QTY: "P Qty",
			QUANTITY: "Quantity",
			UOM: "Uom",
			UNIT_PRICE: "Unit Price",
			AMOUNT: "Amount",
			RECEIVE_DATE: "Received Date",
			REF_ID: "Ref ID",
			MAIN_SALESMAN: "Main Salesman",
			SALESMAN: "Salesman",
			CUSTOMER_MARKING: "Marking",
			DELIVERY_ID: "Delivery Id",

			REPORT_FILTER: "Report Filter",
			SHOW_M3: "Show M3?",
			SHOW_KG: "Show Kg?",
			SHOW_BILL_AMOUNT: "Show Bill Amount?",
			SHOW_STOCK_DESC1: "Show Stock Desc 1?",
			SHOW_STOCK_DESC2: "Show Stock Desc 2?",
			SHOW_SUPPLIER: "Show Supplier",
			YES: "Yes",
			NO: "No",
			CURRENCY_ID: "Currency ID",
			REMARK: "Remark",
			DEPOSIT_DATE: "D. Date",
			DEPOSIT_NO: "PN #",
			DEPOSIT_RATE: "TR",
			DEPOSIT_AMOUNT: "TS Amt",
			DEPOSIT_CHARGE: "FTC",
			DEPOSIT_CHARGE_MYR: "TC (RM)",
			DEPOSIT_IR: "IR %",
			DEPOSIT_OR: "AOR No.",

			SUB_TTL_T_M3: "T/M3",
			SUB_TTL_T_KG: "T/Kg",
			SUB_TTL_T_PQTY: "T/Pqty",
			SUB_TTL_REF: "Ref ",
			SUB_TTL_VOL_WEIGHT: "Vol Weight",
			SUBTOTAL: "SubTotal",
			CURRENCY: "Currency"
		},
		STOCK_BALANCE_FORMAT: {
			TITLE: "Stock Balance Report with Payment Info",
			DETAIL: "Detail",
			SUMMARY: "Summary",
			SERIAL_NO: "S/N",
			CURRENCY_ID: "Cur ID",
			SO_NO: "S/O No.",
			RECEIVE_DATE: "Rec Date",
			NOTICE_ID: "Notice ID",
			TOTAL_M3: "Total M3",
			TOTAL_PACK: "Total Pack",
			BAL_AMT: "Balance Amount",
			CUS_DEPOSIT: "Cust Deposit",
			MY_PAYMENT: "My Payment",
			SUP_DEPOSIT: "Sup Deposit",
			CASH_PAYMENT: "Cash P'ment",
			CREDIT_PAYMENT: "Credit P'ment",
			FCP_AMOUNT: "FCP Amount",
			PAYABLE_AMT: "Payable Amt",

			PO_DATE: "PO Date",
			STOCK_SHORT: "Stock Short",
			STOCK_DESC: "Stock Desc",
			M3: "M3",
			BAL_PACK: "Bal Pack",
			QUANTITY: "Quantity",
			PURCHASE_PRICE: "Purchase Price",
			AMOUNT: "Amount",
			MAIN_SALESMAN: "Main Salesman",
			SALESMAN: "Salesman",
			CUSTOMER_MARKING: "Marking",
			DELIVERY_ID: "Delivery Id",
			OR_DATE: "O/R Date",
			LOADED_DATE: "Loaded Date",
			// REPORT_FILTER: "All / KCG Only / Exclude KCG",
			FROM: "From",
			TO: "To",
			REPORT_FILTER: "Report Filter",
			REPORT_OPTIONS: "Report Options",
			DELIVERY_ID_TOTAL: "Delivery ID Total",
			MARKING_TOTAL: "Marking Total",
			SALESMAN_TOTAL: "Salesman Total",
			MAIN_SALESMAN_TOTAL: "Main Salesman Total",
			CURRENCY_TOTAL: "Currency Total"
		},
		DO_FORMAT: {
			FORMAT_TITLE: "DELIVERY ORDER",
			TEL_NO: "Tel No.",
			GOODS_RECEIVE_CONDITION: "Goods received in good order & condition",
			SIGNATURE_TITLE: "Authorised Signature & Co Stamp",
			FOOTER_E_DESC: "Computer Generated, no signature required",
			FOOTER_END: "== End ==",

			DONO: "D/Order #",
			REF_ID: "Yr Ref",
			DO_DATE: "Date",
			TRANPORTER: "T-Ref",
			PAGE: "Page",
			MOBILE_NO: "Mobile No.",
			FORWARDER: "Forwarder",
			FAX: "Fax",
			REMARK1: "Remark1",
			REMARK2: "Remark2",
			SERIAL_NO: "S/N",
			SO_NO: "",
			DESCRIPTION: "Description",
			QUANTITY: "Quantity",
			UNIT: "Unit",
			PKG: "Pkg",
			QTY: "Qty",
			TOTAL_M3: "Total M3",
			TOTAL_KG: "Total KG",
			TOTAL_PACKING: "Total Packing",
			SEND_ON_BEHALF: "Send on behalf of"
		}
	},
	MASTER_DATA: {
		DELIVERY_AGENT: {
			MODULE_NAME: "Delivery Agent",
			LISTING_TITLE: "Delivery Agent Listing",
			CREATE_TITLE: "Create New Delivery Agent",
			DETAIL_TITLE: "Delivery Agent Detail",
			EDIT_TITLE: "Edit Delivery Agent",
			CODE: "Agent Code",
			NAME: "Agent Name",
			LABEL: "Label",

			LBL_COURIER: "Courier Service",
			LBL_COURIER_LIST: "Supported Courier",

			MSG_COUNTRY_COURIER_NOT_SET: "{name} courier not set."
		},
	},
	PRODUCT_MANAGEMENT: {

		CATEGORY_PARENT: {
			LISTING_TITLE: "Product Category (Parent) Listing",
			CREATE_TITLE: "Create New Product Category (Parent)",
			DETAIL_TITLE: "Product Category (Parent) Detail",
			EDIT_TITLE: "Edit Product Category (Parent) Detail",

			LBL_CATEGORY_CODE: "Product Category Code",
			LBL_CATEGORY_NAME: "Product Category Name",
			LBL_CATEGORY_PARENT: "Product Category Parent",

			MODULE_NAME: "Product Category",

			COL_CATEGORY_CODE: "Product Category Code",
			COL_CATEGORY_NAME: "Product Category Name",
			COL_CATEGORY_GROUP: "Category Type",
			COL_CATEGORY_PARENT: "Product Category Parent",
			COL_SERVICE_CHARGES: "Service Charges?",
			ROOT_SELECTION: "Parent"
		},
		CATEGORY: {
			LISTING_TITLE: "Product Category (Lvl 2) Listing",
			CREATE_TITLE: "Create New Product Category (Lvl 2)",
			DETAIL_TITLE: "Product Category (Lvl 2) Detail",
			EDIT_TITLE: "Edit Product Category (Lvl 2) Detail",

			LBL_CATEGORY_CODE: "Product Category Code",
			LBL_CATEGORY_NAME: "Product Category Name",
			LBL_CATEGORY_PARENT: "Product Category Parent",
			LBL_INVOICE_DESC: "Invoice Description",
			LBL_CHARGES_FLAT: "Service Charges",

			MODULE_NAME: "Product Category",

			COL_CATEGORY_CODE: "Product Category Code",
			COL_CATEGORY_NAME: "Product Category Name",
			COL_CATEGORY_GROUP: "Category Type",
			COL_CATEGORY_PARENT: "Product Category Parent",
			COL_SERVICE_CHARGES: "Service Charges?",
			ROOT_SELECTION: "Parent",
			MSG_CATEGORY_DUPLICATION: "{code} is not available."
		},
	},
	PN_PAYMENT: {
		MODULE_NAME: "PN Payment",
		LBL_BADGE_NO: "Badge No.",
		LBL_DATE: "Payment Date",

		SO_TITLE: "S/O Detail(s)",
		BENE_TITLE: "Bene/Bank Detail",

		SHOW_PAYABLE_DETAILS: "Show PN Detail",
		SHOW_SO_BENE: "Show S/O Bene & Account Detail",

		LBL_DUE_DAYS: "Due Day(s)",
		LBL_SUPPLIER: "Supplier",

		COL_SUPPLIER_DEPOSIT: "S/O Sup Deposit",
		COL_CUSTOMER_DEPOSIT: "S/O Cus Deposit",
		COL_CASH_PAYMENT: "Cash Payment",
		COL_WAREHOUSE_DISCOUNT: "Sup Wh Disc",
		COL_DISCOUNT: "Disc. Amt",
		COL_MY_PAID: "My Paid",
		COL_STICKER_FEES: "Sticker Fees",
		COL_STICKER_FEES_PAID_AMOUNT: "Sup Pay Amt",
		COL_PAYABLE_AMOUNT: "PN Amt",
		COL_PAID_AMOUNT: "Paid PN Amt",
		COL_PN_CANCEL_AMOUNT: "PN Cancel Amt",
		COL_BALANCE_PN_AMOUNT: "Bal. PN. Amt.",
		COL_PN_PAYMENT_AMOUNT: "PN Payment Amt.",
		COL_BANK_ACC_NAME: "Acc. Holder",
		COL_BANK_ACC: "Acc No.",
		COL_BANK_NAME: "Bank Name",
		COL_BANK_PROVINCE: "Bank Province",
		COL_BANK_DISTRICT: "Bank District",
		COL_BANK_ADDRESS: "Bank Address",
		COL_PN_REF_NO: "PN Ref No.",

		APPROVE_SUMMARY: "PN Payment Summary",
		MODULE_NAME_APPROVE: "Approve PN Payment",
		MODULE_NAME_ACKNOWLEDGE: "Acknowledge PN Payment",

		PN_PAYMENT_DETAIL_TITLE: "PN Payment Detail",

		APPROVE_PN_CONFIRMATION: "You area not allow to edit this Badge No.: {badge_no} after approved. "
	},
	BANK_BENE: {
		LBL_ACCOUNT_NAME: "Bank Account Name",
		LBL_ACCOUNT_NO: "Bank Account No.",
		LBL_BENE_CONTACT_CALLING_CODE: "Bene Contact Calling Code",
		LBL_BENE_CONTACT_NO: "Bene Contact No.",
		LBL_BENE_CONTACT_PIC: "Bene Contact Pic",
		LBL_BENE_ADDRESS: "Bene Address",

		LBL_SAVE_AS_NEW_BANK: "Save As New Bene",

		LBL_BANK_NAME: "Bank Name",
		LBL_BANK_COUNTRY: "Bank Country",

		LBL_BANK_PROVINCE: "Bank Province",
		LBL_BANK_DISTRICT: "Bank District",

		LBL_BANK_ADDRESS: "Bank Address",
		LBL_BANK_RI_NO: "Ri No.",
		LBL_BANK_SWIFT_CODE: "Swift Code",
		LBL_BANK_IBAN: "Iban Code",

		LBL_INTER_BANK_NAME: "Intermediate Bank Name",
		LBL_INTER_BANK_ACCOUNT_NO: "Intermediate Acc No.",
		LBL_INTER_SWIFT_CODE: "Intermediate Swift Code",
		LBL_INTER_IBAN_CODE: "Intermediate IBAN Code",

		LBL_INTER_ENABLE: "Intermediate Bank ?",

		BANK_MODAL_TITLE: "Bank List",
	},
	SO: {
		COL_SUPPLIER_DEPOSIT: "Sup Deposit/DCP",
		COL_CUSTOMER_DEPOSIT: "Cus Deposit",
		COL_DEPOSIT: "DCP Deposit",
		COL_CASH_PAYMENT: "Cash Payment",
		COL_WAREHOUSE_DISCOUNT: "Sup Wh Disc",
		COL_DISCOUNT: "Disc. Amt",
		COL_MY_PAID: "My Paid",
		COL_STICKER_FEES: "Sticker Fees",
		COL_STICKER_FEES_PAID_AMOUNT: "Sup Pay Amt",
		COL_FCP_AMOUNT: "Fcp/CP TT",
		COL_CREDIT_AMOUNT: "Credit Amt",
		COL_SO_PAID_AMOUNT: "S/O Paid Amt",
		COL_CURRENCY_RATE: "Currency Rate",
		COL_GET_CN_AMOUNT: "Get CN Amt",
		COL_BALANCE_AMOUNT: "FCP/PN Amt.",
		COL_BLOCK_TT: "Blocked TT",
		COL_CN_TT: "CN Amt",
		COL_OUTSTANDING_AMOUNT: "OutStanding Amt",
		COL_BLOCK_AMOUNT: "Block Amt",
		COL_CN_AMOUNT: "CN Amt",
		COL_CONVERSION_FACTOR: "Currency Factor",
		COL_CN_LOCAL_AMOUNT: "CN Local Amt",
		COL_DN_LOCAL_AMOUNT: "DN Local Amt",

		COL_DN_AMOUNT: "DN Amt",
		COL_INV_AMOUNT: "Inv Amt",
		COL_SO_REV: "S/O Recv?",
		COL_CLOSE_AMOUNT: "Close Amt"
	},
	INV_FORMAT: {
		SERIAL_NO: "S/N",
		ITEM_DESCRIPTION: "Description",
		QUANTITY: "Quantity",
		UNIT: "Unit",
		PRICE: "Price",
		DISC: "Disc",
		AMOUNT: "Amount",
		INVOICE_TITLE: "Invoice",
		DELIVER_TO: 'Deliver To',
		INVOICE_NO: "Invoice No.",
		YR_REF: "Yr Ref",
		DATE: "Date",
		TERMS: "Terms",
		TTL_PACKING: "Total Packing",
		PAGE: "Page",
		INVOICE_TERMS: "Accounts not paid within terms are subject to a monthly overdue charge until the balance is fully paid.",
		COMPUTER_GENERATOR: "Computer generated, no signature required",
		BANK_ACCOUNT_TITLE: "* Our Bank Account"
	},
	USER_PROFILE: {
		PROFILE_TITLE: "User Profile",
		VERIFY_PASS: "Verify Password",
		VERIFY_DESC: "Your Password Again",
		PASS_NOT_MATCH: "Password Not Match",
		CURR_PASS_ERR: "Current Password Is Wrong",
		CURRENT_PASSWORD: "Current passowrd",
		NEW_PASS: "New password",
		FORGOT_BUTTON: "Forgot Password",
	},
	USER_N_ROLE_MANAGEMENT: {
		USER: {
			LISTING_TITLE: "User Listing",
			CREATE_TITLE: "Create New User",
			DETAIL_TITLE: "User Detail",
			EDIT_TITLE: "Edit User Detail",
			RESET_LOGIN: "RESET LOGIN",
			SENT_EMAIL: "Send Email",
			NEW_PASS: "New password",

			LBL_USER_NAME: "Name",
			LBL_USER_USERNAME: "Username",
			LBL_USER_EMAIL: "Email",
			LBL_USER_PASSWORD: "Password",
			LBL_USER_ROLE: "Roles",
			LBL_USER_DATA_FILTER: "Data Filter (s)",
			LBL_USER_IGNORE_FILTER: "Ignore Filter",
			LBL_DEPARTMENT: "Department",
			LBL_IGNORE_FILTER_ONLY: "Ignore Data Filter Only",
			LBL_USER_TYPE: "User Type",
			LBL_USER_GROUP: "User Group",

			MODULE_NAME: "User",

			COL_USERNAME: "Username",
			COL_NAME: "Name",
			COL_ROLES: "ROLES",
			COL_DATA_FILTER: "Data Filter (s)",
			COL_IGNORE_FILTER: "Ignore Data Filter",
			COL_DEPARTMENT: "Department",
			COL_USER_TYPE: "User Type",
			COL_LAST_LOGIN: "Last Login",
			USER_TYPE: {
				WAREHOUSE_STAFF: "Warehouse/Office Staff",
				AGENT_STAFF: "Agent Staff"
			}
		},
		ROLE: {
			LISTING_TITLE: "Role Listing",
			CREATE_TITLE: "Create New Role",
			DETAIL_TITLE: "Role Detail",
			EDIT_TITLE: "Edit Role Detail",

			LBL_ROLE_NAME: "Role Name",
			LBL_ROLE_DESCRIPTION: "Role Description",
			LBL_ROLE_PERMISSION_PRIVILEGE: "Permission & Privilege",
			LBL_ROLE_PERMISSION: "Role Permission",
			LBL_DEFAULT_SORT: "Default Sort",
			LBL_SORT_ALPHABET: "Sort by Alphabet",
			LBL_SEARCH_PERMISSION: "Search Role Permission",
			LBL_ALL_PERMISSION: "All Permission",
			LBL_APPLY_TO: "Apply For",

			MODULE_NAME: "Role",

			COL_NAME: "Role Name",
			COL_DESCRIPTION: "Role Description",
			COL_APPLY_TO: "Apply For",

			WARNING_DUPLICATE: "Reminder: Data exists in this record - {exist_record} <br /> The system will not allow you to save the same data record twice.",
			BTN_SORT_MENU: "Sort Follow Menu",
			BTN_SORT_ALPHABET: "Sort By Alphabet",
			MSG_BLOCK_EDIT_CREATE_ROLE: "For {staff_type} roles exists."
		},
		DATA_FILTER: {
			LISTING_TITLE: "Data Filter Listing",
			CREATE_TITLE: "Create New Data Filter",
			DETAIL_TITLE: "Data Filter Detail",
			EDIT_TITLE: "Edit Data Filter Detail",

			LBL_DATA_FILTER_NAME: "Name",
			LBL_DATA_FILTER_DESCRIPTION: "Description",
			LBL_DATA_FILTER_COMPANY: "Company",
			LBL_DATA_FILTER_WAREHOUSE: "Warehouse",
			LBL_DATA_FILTER_MSM: "Main Salesman",
			LBL_DATA_FILTER_SM: "Salesman",
			LBL_DATA_FILTER_MARKING: "Marking",
			LBL_DATA_FILTER_PERMISSION_PRIVILEGE: "Permission & Privilege",
			LBL_INCLUDE: "include",
			LBL_EXCLUDE: "exclude",


			MODULE_NAME: "Data Filter",

			COL_NAME: "Data Filter Name",
			COL_DESCRIPTION: "Data Filter Description",

		},
		USER_GROUP: {
			LISTING_TITLE: "User Group Summary",
			CREATE_TITLE: "Create New User Group",
			DETAIL_TITLE: "User Group Detail",
			EDIT_TITLE: "Edit User Group",
			MODULE_NAME: "User Group",

			LBL_GROUP_CODE: "Group Code",
			LBL_GROUP_NAME: "Group Name",
			LBL_SPECIAL_RIGHTS: "Special Rights",
			LBL_SEARCH_SPECIAL_RIGHTS: "Search Special Rights",

		}
	},
}
