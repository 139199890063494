var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"search_form",attrs:{"align-v":"end"}},[_vm._t("search"),(!_vm.hide('customer_id'))?_c('b-col',{attrs:{"sm":"12","md":"4","lg":"3","xl":"3","order":_vm.getFieldOrder('customer_id')}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex flex-nowrap justify-content-between"},[_c('label',{domProps:{"textContent":_vm._s(_vm.$t('COMMON.LBL_CUSTOMER_NAME'))}}),(_vm.allow_search_exists_partner == 1)?_c('b-form-checkbox',{attrs:{"value":"1","unchecked-value":"0","checked":_vm.enable_customer_manual == 1},model:{value:(_vm.enable_customer_manual),callback:function ($$v) {_vm.enable_customer_manual=$$v},expression:"enable_customer_manual"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('COMMON.LBL_ENTER_CUSTOMER_NAME'))}})]):_vm._e()],1)]},proxy:true}],null,false,418973015)},[(_vm.use_marking_selection == 1 && _vm.enable_customer_manual == 0)?_c('selectField',{attrs:{"data":_vm.searchForm.customer_ids,"selection":_vm.customerSelection,"multiple":true,"clearable":true,"show_all_label":true},on:{"update":(v) => {
						_vm.searchForm.customer_ids = v;
						_vm.getMarkingSelection();
					},"focus":function($event){return _vm.getCustomerCompanySelection()}}}):_vm._e(),(_vm.use_marking_selection == 0 || _vm.enable_customer_manual == 1)?_c('b-form-input',{attrs:{"size":"sm","placeholder":_vm.$Common.placeHolderHandle(_vm.$t('COMMON.LBL_CUSTOMER_NAME')),"formatter":(v) => {
						return _vm.$CoreSettingsJs.onFormatterUpperCase(v);
					},"lazy-formatter":""},model:{value:(_vm.searchForm.customer_name),callback:function ($$v) {_vm.$set(_vm.searchForm, "customer_name", $$v)},expression:"searchForm.customer_name"}}):_vm._e()],1)],1):_vm._e(),(!_vm.hide('customer_marking_id'))?_c('b-col',{attrs:{"sm":"12","md":"4","lg":"3","xl":"3","order":_vm.getFieldOrder('customer_marking_id')}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMMON.LBL_CUSTOMER_MARKING')}},[(_vm.use_marking_selection == 1)?_c('selectField',{attrs:{"data":_vm.searchForm.customer_marking_ids,"selection":_vm.markingSelection,"multiple":true,"clearable":true,"loading":_vm.loadingMarking,"enable_search":true,"text_key":`marking`,"value_key":`id`,"field_type":`marking`,"wms":1,"custom_selection":1,"label":_vm.$t('COMMON.TXT_ALL'),"placeholder":_vm.$t('COMMON.PHP_ENTER_SEARCH', { name: _vm.$t('COMMON.LBL_CUSTOMER_MARKING') }),"is_view":!_vm.$CoreSettingsJs.emptyObjectValidation(_vm.searchForm.disabled_marking) && _vm.searchForm.disabled_marking ? 1 : 0},on:{"update":(v) => {
						_vm.searchForm.customer_marking_ids = v;
					},"search":_vm.getMarkingSelection}}):_vm._e(),(_vm.use_marking_selection == 0)?_c('b-form-input',{attrs:{"size":"sm","placeholder":_vm.$Common.placeHolderHandle(_vm.$t('COMMON.LBL_CUSTOMER_MARKING')),"formatter":(v) => {
						return _vm.$CoreSettingsJs.onFormatterUpperCase(v);
					},"lazy-formatter":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}},model:{value:(_vm.searchForm.marking),callback:function ($$v) {_vm.$set(_vm.searchForm, "marking", $$v)},expression:"searchForm.marking"}}):_vm._e()],1)],1):_vm._e(),(!_vm.hide('delivery_address_id'))?_c('b-col',{attrs:{"sm":"12","md":"4","lg":"3","xl":"3","order":_vm.getFieldOrder('delivery_address_id')}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMMON.LBL_DELIVERY_ID')}},[_c('selectField',{attrs:{"data":_vm.searchForm.delivery_address_ids,"selection":_vm.delivery_address_selection,"multiple":true,"clearable":true,"text_key":`address_description`,"field_type":`delivery_address`,"wms":1,"custom_selection":1,"show_all_label":true},on:{"update":(v) => {
						_vm.searchForm.delivery_address_ids = v;
					},"focus":function($event){return _vm.getDeliveryAddressSelection()}}})],1)],1):_vm._e(),(!_vm.hide('msm_id'))?_c('b-col',{attrs:{"sm":"12","md":"3","lg":"3","xl":"3","order":_vm.getFieldOrder('msm_id')}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMMON.LBL_MAIN_SALESMAN')}},[_c('selectField',{attrs:{"data":_vm.searchForm.msm_ids,"selection":_vm.msmSelection,"multiple":true,"clearable":true,"value_key":`msm_id`,"show_all_label":true},on:{"update":(v) => {
						_vm.searchForm.msm_ids = v;
						_vm.searchForm.msm_names = _vm.msmSelection.filter((x) => v.includes(x.msm_id)).map((x) => x.name.split('-')[0].trim());
					},"focus":function($event){return _vm.getMsmSelection()}}})],1)],1):_vm._e(),(!_vm.hide('sm_id'))?_c('b-col',{attrs:{"sm":"12","md":"3","lg":"3","xl":"3","order":_vm.getFieldOrder('sm_id')}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMMON.LBL_SALESMAN')}},[_c('selectField',{attrs:{"data":_vm.searchForm.sm_ids,"selection":_vm.smSelection,"multiple":true,"clearable":true,"show_all_label":true},on:{"update":(v) => {
						_vm.searchForm.sm_ids = v;
						_vm.searchForm.sm_names = _vm.smSelection.filter((x) => v.includes(x.id)).map((x) => x.sm_code);
					},"focus":function($event){return _vm.getSmSelection()}}})],1)],1):_vm._e(),(!_vm.hide('company_id'))?_c('b-col',{attrs:{"sm":"12","md":"3","lg":"3","xl":"3","order":_vm.getFieldOrder('company_id')}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMMON.LBL_COMPANY')}},[_c('selectField',{attrs:{"data":_vm.searchForm.company_ids,"selection":_vm.companySelection,"multiple":true,"clearable":true,"show_all_label":true},on:{"update":(v) => {
						_vm.searchForm.company_ids = v;
						_vm.searchForm.company_names = _vm.companySelection.filter((x) => v.includes(x.id)).map((x) => x.company_code);
					},"focus":function($event){return _vm.getCompanySelection()}}})],1)],1):_vm._e(),(!_vm.hide('company_group_id'))?_c('b-col',{attrs:{"sm":"12","md":"3","lg":"3","xl":"3","order":_vm.getFieldOrder('company_group_id')}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMMON.LBL_COMPANY_GROUP')}},[_c('selectField',{attrs:{"data":_vm.searchForm.company_group_ids,"selection":_vm.companyGroupSelection,"multiple":true,"clearable":true,"show_all_label":true},on:{"update":(v) => {
						_vm.searchForm.company_group_ids = v;
						_vm.searchForm.company_group_names = _vm.companyGroupSelection.filter((x) => v.includes(x.id)).map((x) => x.name);
					},"focus":function($event){return _vm.getCompanyGroup()}}})],1)],1):_vm._e(),(!_vm.hide('status'))?_c('b-col',{attrs:{"sm":"12","md":"2","lg":"2","xl":"1","order":_vm.getFieldOrder('status')}},[_c('b-form-group',{class:_vm.getRequiredFields('status'),attrs:{"label":_vm.$t('COMMON.LBL_STATUS')}},[_c('selectField',{attrs:{"data":_vm.searchForm.status,"selection":_vm.$CoreJs.statusSelection,"value_key":`item`,"text_key":`name`,"show_all_label":true},on:{"update":(v) => {
						_vm.searchForm.status = v;
					}}})],1)],1):_vm._e(),(!_vm.hide('search_button'))?_c('searchButton',{attrs:{"order":_vm.getFieldOrder('search_button')}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }