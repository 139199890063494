import ApiService from "@/core/services/api.service";
import Common from "@/core/services/common";
import i18n from "@/core/plugins/vue-i18n";
import CoreSettingsJs from "@shared/src/core/services/settings.js";
import Swal from "sweetalert2";

const warehouseSelection = async function (marking_id = null, country_id = null, code_only = 1, post_data = {}) {
	if (typeof post_data.locale == "undefined") post_data.locale = i18n.locale;
	if (typeof post_data.marking_id == "undefined") post_data.marking_id = marking_id;
	if (typeof post_data.country_id == "undefined") post_data.country_id = country_id;
	if (typeof post_data.admin_portal == "undefined") post_data.admin_portal = 1;
	if (typeof post_data.code_only == "undefined") post_data.code_only = code_only;
	let response = await ApiService.post("warehouse/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const warehouseCountrySelection = async function (marking_id = null, post_data = {}) {
	if (typeof post_data.locale == "undefined") post_data.locale = i18n.locale;
	if (typeof post_data.marking_id == "undefined") post_data.marking_id = marking_id;
	if (typeof post_data.country_id == "undefined") post_data.country_id = null;
	if (typeof post_data.admin_portal == "undefined") post_data.admin_portal = 1;
	if (typeof post_data.ignore_support_other_country == "undefined") post_data.ignore_support_other_country = 1;

	let response = await ApiService.post("warehouse/country-selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const departmentSelection = async function (post_data = {}) {
	let response = await ApiService.post("department/selection", post_data);

	return response.data.code == "200" ? response.data.result : [];
};

const customerSelection = async function () {
	let response = await ApiService.get(`customer/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const mainSalesmanSelection = async function (post_data = {}) {
	let response = await ApiService.post(`main-salesman/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const salesmanSelection = async function (post_data = {}) {
	let response = await ApiService.post(`salesman/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const fullMarkingSelection = async function (post_data = {}) {
	let response = await ApiService.post(`customer-marking/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const deliveryAddressSelection = async function (post_data = {}) {
	let response = await ApiService.post(`delivery-address/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const companyGroupSelection = async function (post_data = {}) {
	let response = await ApiService.post(`company/group-selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const noticeTypeSelection = async function () {
	let response = await ApiService.get(`notice-type/selection-code`);
	return response.data.code == "200" ? response.data.result : [];
};

const categorySelection = async function (post_data = {}) {
	let response = await ApiService.post(`category/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const categoryLvl2Selection = async function (post_data = {}) {
	let response = await ApiService.post(`category/child-selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const taxSelection = async function (data = {}) {
	let response = await ApiService.post(`tax/selection`, data);
	return response.data.code == "200" ? response.data.result : [];
};

const validitySelection = async function (data) {
	let response = await ApiService.post(`validity/selection`, data);
	return response.data.code == "200" ? response.data.result : [];
};

const getCustomerLoginEmailSelection = async function () {
	let response = await ApiService.get(`customer/login-info/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const taxTypeSelection = async function (post_data = { ignoreStatusFilter: 0, country_id: 127 }) {
	let response = await ApiService.post(`tax-type/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const territorySelection = async function (data) {
	let response = await ApiService.post("territory/selection", data);
	return response.data.code == "200" ? response.data.result : [];
};

const industriesSelection = async function (data) {
	let response = await ApiService.post("industries/selection", data);
	return response.data.code == "200" ? response.data.result : [];
};

const currenySelection = async function (post_data = {}) {
	let response = await ApiService.post("currency/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const ttGrpSelection = async function () {
	let response = await ApiService.get("tt-group/selection");
	return response.data.code == "200" ? response.data.result : [];
};

const uomSelection = async function (data = {}) {
	let response = await ApiService.post("uom/selection", data);
	return response.data.code == "200" ? response.data.result : [];
};

const companySelection = async function (post_data = {}) {
	let response = await ApiService.post(`company/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const userSelection = async function (post_data = {}) {
	let response = await ApiService.post(`user/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const uploadFile = async function (data) {
	let response = await ApiService.uploadfile("upload/image", data, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});

	if (response.status == "200" && response.data.code == "200") {
		if (response.data.result.fileDownloadUri != null || response.data.result.fileDownloadUri != undefined) {
			return response.data.result.fileDownloadUri;
		} else {
			return null;
		}
	} else {
		return null;
	}
};

const ttGroupSelection = async function () {
	let response = await ApiService.get(`tt-group/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const ttDailyRateSelection = async function (post_data = {}) {
	let response = await ApiService.post('daily-rate/selection', post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const glAccSelection = async function (data) {
	let response = await ApiService.post(`gl-acc/selection`, data);
	return response.data.code == "200" ? response.data.result : [];
};

const glControlAccSelection = async function (data) {
	let response = await ApiService.post(`gl-acc/gl-control-acc-selection`, data);
	return response.data.code == "200" ? response.data.result : [];
};

const glSalesAccSelection = async function (data) {
	let response = await ApiService.post(`gl-acc/gl-sales-acc-selection`, data);
	return response.data.code == "200" ? response.data.result : [];
};

const ttGlAccSelection = async function (data = {}) {
	let response = await ApiService.post(`tt-gl-account/selection`, data);
	return response.data.code == "200" ? response.data.result : [];
};

const refIdSelection = async function (data) {
	let response = await ApiService.post(`loaded/selection`, data);
	return response.data.code == "200" ? response.data.result : [];
};

const callingCodeSelection = async function () {
	let response = await ApiService.get("countries/phone-code");
	return response.data.code == "200" ? response.data.result : [];
};

const invFormatSelection = async function () {
	let response = await ApiService.get(`settings/inv-format`);

	let selection = response.data.code == "200" ? response.data.result : [];
	if (!CoreSettingsJs.emptyObjectValidation(selection)) {
		for (let idx in selection) {
			selection[idx].label = i18n.t(`TRANSACTION.INVOICE_MANAGEMENT.${selection[idx].label}`);
		}
		return selection;
	}
};

// [
// 	{ name: i18n.t("TRANSACTION.INVOICE_MANAGEMENT.INVOICE_FORMAT.NORMAL"), id: 0 },
// 	{
// 		name: i18n.t("TRANSACTION.INVOICE_MANAGEMENT.INVOICE_FORMAT.SERVICE_CHARGE_ONLY"),
// 		id: 1
// 	},
// 	{
// 		name: i18n.t("TRANSACTION.INVOICE_MANAGEMENT.INVOICE_FORMAT.DIVIDE_EQUALLY"),
// 		id: 2
// 	}
// ];

const costCalcTypeSelection = async function () {
	let response = await ApiService.get(`cost-calc-type/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const routeSelection = async function (post_data = { ignore_filter: 1, transit: 0 }) {
	let response = await ApiService.post(`route/selection`, post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const invoiceOnHoldSelection = async function () {
	let response = await ApiService.get(`on-hold/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const packingTypeSelection = async function () {
	let response = await ApiService.get(`packing-type/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const costTypeSelection = async function () {
	let response = await ApiService.get(`cost-calc-type/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const consigneeSelection = async function () {
	let response = await ApiService.get(`consignee/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const invRefSelection = async function () {
	let response = await ApiService.get(`inv-ref/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const categoryTypeSelection = async function (data = {}) {
	let response = await ApiService.post(`category-type/selection`, data);
	return response.data.code == "200" ? response.data.result : [];
};

const exceptionalCostTypeSelection = async function () {
	let response = await ApiService.get("exceptional-cost-type/selection");
	return response.data.code == "200" ? response.data.result : [];
};

const exceptionalChargesSelection = async function (post_data = {}) {
	let response = await ApiService.post("exceptional-cost/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const instructionTypeSelection = async function () {
	let response = await ApiService.get("instruction-type/selection");
	return response.data.code == "200" ? response.data.result : [];
};

const shipmentTypeSelection = async function (post_data = {}) {
	post_data.locale = i18n.locale;
	let response = await ApiService.post("shipment-type/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const shipmentModeSelection = async function (post_data = {}) {
	let response = await ApiService.post("shipment-mode/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const epoFormatTypeSelection = [
	{ id: 1, name: "CN" },
	{ id: 2, name: "TH" },
	{ id: 3, name: "IN" },
	{ id: 4, name: "VN" },
	{ id: 5, name: "EN" }
];

const sensitiveTypeSelection = async function (post_data = {}) {
	let response = await ApiService.post("sensitive-type/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const warehouseTypeSelection = async function (post_data = {}) {
	post_data.locale = "en";
	let response = await ApiService.post("warehouse-type/selection", post_data);
	return response.data.code == 200 ? response.data.result : [];
};

const shipmentSelection = async function () {
	let response = await ApiService.get("shipment-mode/shipment-selection");
	return response.data.code == 200 ? response.data.result : [];
};

const getMarkingFromAllAgent = async function () {
	let response = await ApiService.get("customer-marking/selection-from-all-agent");
	return response.data.code == 200 ? response.data.result : [];
};

const statesSelection = async function (cid = null) {
	let response = await ApiService.post("states/selection", { country_id: cid });
	return response.data.code == "200" ? response.data.result : [];
};

const countrySelection = async function () {
	let response = await ApiService.get("countries/active");
	return response.data.code == "200" ? response.data.result : [];
};

const citySelection = async function (cid = null, sid = null) {
	let response = await ApiService.post("cities/active", { cid: cid, sid: sid });
	return response.data.code == "200" ? response.data.result : [];
};

const districtSelection = async function (cid = null, sid = null, city_id = null) {
	let response = await ApiService.post("district/active", { country_id: cid, state_id: sid, city_id: city_id });
	return response.data.code == "200" ? response.data.result : [];
};

const postCodeSelection = async function (country_id, state_id, city_id, keyword, keyword1) {
	let response = await ApiService.post("postcode/active", {
		country_id: country_id,
		state_id: state_id,
		city_id: city_id,
		keyword: keyword,
		keyword1: keyword1
	});
	return response.data.code == "200" ? response.data.result : [];
};

const areaSelection = async function (country_id = null) {
	let response = await ApiService.post("area/selection", { country_id: country_id });
	return response.data.code == "200" ? response.data.result : [];
};

const shipmentTransitionSelection = async function (post_data = {}) {
	let response = await ApiService.post("shipment-transition/selection", post_data);
	return response.data.code == "200" && response.data.result != null && response.data.result.length > 0 ? response.data.result : [];
};

const deliveryZoneSelection = async function (country_id = 127) {
	let response = await ApiService.post("area-group/selection", {
		locale: "en",
		country_id: country_id
	});
	return response.data.code == "200" ? response.data.result : [];
};

const getTaxRemark = function (tax_selections, tax_id) {
	let tax_selection = tax_selections.find((data) => data.id == tax_id);
	return typeof tax_selection != "undefined" ? tax_selection.tax_quotation_desc : "";
};

const portName = function (port_selections, port_id) {
	let port_selection = port_selections.find((data) => data.id == port_id);
	return typeof port_selection != "undefined" ? port_selection.port_desc : "";
};

const portSelection = async function (post_data = {}) {
	let response = await ApiService.post("port/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const chargesTypeSelection = async function (post_data = {}) {
	let response = await ApiService.post("charges-type/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const mathOperatorSelection = [
	{
		id: 0,
		name: "/",
		adjust: "-"
	},
	{
		id: 1,
		name: "*",
		adjust: "+"
	},
	{
		id: 2,
		name: "+",
		adjust: "+"
	},
	{
		id: 3,
		name: "-",
		adjust: "-"
	}
];

const factorSelection = [
	{
		id: 0,
		name: "/",
		adjust: "-"
	},
	{
		id: 1,
		name: "*",
		adjust: "+"
	}
];

const statusSelection = [
	{
		item: null,
		name: i18n.t("COMMON.TXT_ALL")
	},
	{
		item: 1,
		name: i18n.t("COMMON.STATUS.ACTIVE")
	},
	{
		item: 0,
		name: i18n.t("COMMON.STATUS.INACTIVE")
	}
];

const currencyGroupSelection = async function () {
	let response = await ApiService.get("/currency-group/selection");
	return response.data.code == "200" ? response.data.result : [];
};

const exceptionalCostField = {
	m3: 1,
	condition: {
		start: Common.numberFormatCurrency(0),
		end: Common.numberFormatCurrency(0),
		uom_id: 2,
		length: Common.numberFormatInteger(0),
		width: Common.numberFormatInteger(0),
		height: Common.numberFormatInteger(0)
	},
	custom_charges: 0,
	min_charges: Common.numberFormatCurrency(0),
	type: "rate_by_amount",
	charges: {
		value: Common.numberFormatCurrency(0),
		uom_id: 1,
		charges_type: "rate_by_amount"
	},
	additional_charges: {
		value: Common.numberFormatCurrency(0),
		uom_id: 0,
		charges_type: 0
	}
};

const rateTypeSelection = [
	{
		id: "fix_amount",
		name: i18n.t("COMMON.RATE_TYPE_SELECTION.FIX_AMOUNT")
	},
	{
		id: "rate_by_amount",
		name: i18n.t("COMMON.RATE_TYPE_SELECTION.RATE_BY_AMOUNT")
	},
	{
		id: "rate_by_percentage",
		name: i18n.t("COMMON.RATE_TYPE_SELECTION.RATE_BY_PERCENTAGE")
	}
];

const minMarkupTypeSelection = [
	{
		id: 1,
		name: i18n.t("COSTING.MIN_MARKUP_SELECTION.BY_AMOUNT")
	},
	{
		id: 2,
		name: i18n.t("COSTING.MIN_MARKUP_SELECTION.BY_VALUE")
	}
];

const docNumberFormatSelection = async function (post_data = {}) {
	let response = await ApiService.post("doc-num/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const mathOperator = function (id) {
	let data = mathOperatorSelection.find((mdata) => mdata.id == id);
	if (typeof data !== "undefined") {
		return data.name;
	} else {
		return "undefined";
	}
};

const factorPlusMinus = function (id) {
	let data = mathOperatorSelection.find((mdata) => mdata.id == id);
	if (typeof data !== "undefined") {
		return data.adjust;
	} else {
		return "";
	}
};

const numResetSelection = function (include_all = 0) {
	let selection = [];
	if (include_all == 1) {
		selection.push({
			item: null,
			name: i18n.t("COMMON.TXT_ALL")
		});
	}
	return selection.concat([
		{
			id: 0,
			name: i18n.t("MASTER_DATA.DOC_NUM.SELECTION.NO_RESET"),
			format: '',
		},
		{
			id: 1,
			name: i18n.t("MASTER_DATA.DOC_NUM.SELECTION.BY_MONTH"),
			format: Common.UnixTimeToYM(new Date())
		},
		{
			id: 2,
			name: i18n.t("MASTER_DATA.DOC_NUM.SELECTION.BY_YEAR"),
			format: Common.UnixTimeToYear(new Date())
		}
	]);
};

const yesNoSelection = function (include_all = 0) {
	let selection = [];
	if (include_all == 1) {
		selection.push({
			item: null,
			name: i18n.t("COMMON.TXT_ALL")
		});
	}
	return selection.concat([
		{
			item: 1,
			name: i18n.t("COMMON.STATUS.YES")
		},
		{
			item: 0,
			name: i18n.t("COMMON.STATUS.NO")
		}
	]);
};

const postingSideSelection = function (include_all = 0) {
	let selection = [];
	if (include_all == 1) {
		selection.push({
			id: null,
			name: i18n.t("COMMON.TXT_ALL")
		});
	}
	return selection.concat([
		{
			id: 0,
			name: i18n.t("MASTER_DATA.DOC_TYPE.SELECTION.NO_POSTING")
		},
		{
			id: 1,
			name: i18n.t("MASTER_DATA.DOC_TYPE.SELECTION.CREDIT")
		},
		{
			id: 2,
			name: i18n.t("MASTER_DATA.DOC_TYPE.SELECTION.DEBIT")
		}
	]);
};

const markingStatusSelection = [
	{
		id: 0,
		name: i18n.t("COMMON.STATUS.INACTIVE"),
		color: "danger"
	},
	{
		id: 1,
		name: i18n.t("COMMON.STATUS.ACTIVE"),
		color: "success"
	},
	{
		id: 3,
		name: i18n.t("COMMON.STATUS.SUSPEND"),
		color: "warning"
	}
];

const locationSelection = async function (post_data) {
	let response = await ApiService.post("/location/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const deliveryAdressDetail = async function (post_data = {}) {
	let response = await ApiService.post("delivery-address/full-address", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const rangeChargesTransitOption = [
	{
		id: 0,
		name: i18n.t("COSTING.RANGE_CHARGES_TRANSIT_OPTIONS.DIRECT")
	},
	{
		id: 1,
		name: i18n.t("COSTING.RANGE_CHARGES_TRANSIT_OPTIONS.PORT_KLANG")
	}
];

const customerMinGroupCostSelection = async function () {
	let response = await ApiService.get("customer-group-cost/min-cost-selection");
	return response.data.code == "200" ? response.data.result : [];
};

const customerGroupCostSelection = async function () {
	let response = await ApiService.get("customer-group-cost/selection");
	return response.data.code == "200" ? response.data.result : [];
};

const indemnifySelection = async function () {
	let response = await ApiService.get("indemnify/selection");
	return response.data.code == "200" ? response.data.result : [];
};

const frieghtChargesSelection = async function () {
	let response = await ApiService.get("freight-charges/selection");
	return response.data.code == "200" ? response.data.result : [];
};

const productStatusSelection = async function () {
	let response = await ApiService.get("special-prod-status/selection");
	return response.data.code == "200" ? response.data.result : [];
};

const quoteRejectSelection = async function (post_data = {}) {
	let response = await ApiService.post("admin-reject-quote-reason/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const quoteRevertSelection = async function () {
	let response = await ApiService.get("revert-quote-reason/selection");
	return response.data.code == "200" ? response.data.result : [];
};

const quotationSubjectSelection = async function () {
	let response = await ApiService.get("quotation-subject/selection");
	return response.data.code == "200" ? response.data.result : [];
};

const movementTypeSelection = async function (post_data = {}) {
	let response = await ApiService.post("move-type/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const shipmentTermsSelection = async function (post_data = {}) {
	let response = await ApiService.post("shipment-terms/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
};

const getFreightRemark = async function (freight_charges_id, shipment_type_id) {
	let response = await ApiService.post("freight-charges/remark/", {
		freight_charges_id: freight_charges_id,
		shipment_type_id: shipment_type_id
	});
	return response.data.code == "200" ? response.data.result : null;
};

const priceTypeSelection = [
	{
		id: 1,
		name: i18n.t("COMMON.TYPE.NORMAL")
	},
	{
		id: 2,
		name: i18n.t("COMMON.TYPE.SENSITIVE")
	}
];

const roleSelection = async function () {
	let response = await ApiService.post("role/list");
	return response.data.code == "200" ? response.data.result : null;
};

const dataFilterSelection = async function () {
	let response = await ApiService.post("data-filter/list");
	return response.data.code == "200" ? response.data.result : null;
};
const rolesApplyToSelection = [
	{
		id: 1,
		name: i18n.t("COMMON.USER_TYPE.INTERNAL_STAFF")
	},
	{
		id: 2,
		name: i18n.t("COMMON.USER_TYPE.MAIN_SALESMAN")
	},
	{
		id: 3,
		name: i18n.t("COMMON.USER_TYPE.SALESMAN")
	}
];

const userTypeHandle = function (apply_to) {
	let staff = rolesApplyToSelection.find((data) => data.id == apply_to);
	return typeof staff != "undefined" ? staff.name : "";
};

const inRangeChargesSelection = async function (post_data) {
	let response = await ApiService.post("in-range-charges/selection", post_data);
	return response.data.code == "200" ? response.data.result : null;
};

const minChrgTypeSelection = [
	{
		id: 1,
		name: i18n.t("COSTING.BASE_COST.MIN_CHARGES_SELECTION.BY_AMOUNT")
	},
	{
		id: 2,
		name: i18n.t("COSTING.BASE_COST.MIN_CHARGES_SELECTION.BY_VALUE")
	}
];

const specialProductRemarkSelection = async function () {
	let response = await ApiService.get(`special-product-remark/selection`);
	return response.data.code == "200" ? response.data.result : [];
};

const quotationUserPermission = async function () {
	let response = await ApiService.get(`/quote-permission`);
	return response.data.code == "200" ? response.data.result : [];
};

const weightRoundingSelection = async function () {
	let response = await ApiService.get("weight-round/selection");
	return response.data.code == "200" ? response.data.result : [];
};

const creditorTypeSelection = [
	{
		id: 1,
		name: "AP"
	},
	{
		id: 3,
		name: "BK"
	}
];

const forwarderSelection = async function () {
	let response = await ApiService.get("forwarder/list");
	return response.data.code == "200" ? response.data.result : [];
}

const agentSelection = async function () {
	let response = await ApiService.get("agent/selection");
	return response.data.code == "200" ? response.data.result : [];
}

const doChangeAddrStatusSelection = async function (post_data = {}) {
	post_data.locale = i18n.locale;
	let response = await ApiService.post("do-change-add-status/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
}

const doChangeAddrStatusReasonSelection = async function (post_data = {}) {
	post_data.locale = i18n.locale;
	let response = await ApiService.post("do-change-add-status-reason/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
}

const epoStatusSelection = async function () {
	let response = await ApiService.post("epo-status/selection", { locale: i18n.locale });
	return [{ id: 0, name: i18n.t('COMMON.STATUS.DRAFT'), status: 1 }].concat(response.data.code == "200" ? response.data.result : []);
}

const depositStatusSelection = async function (post_data = {}) {
	let response = await ApiService.post("deposit-pn-status/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
}

const depositPnRejectReason = async function (post_data) {
	let response = await ApiService.post("tt-reject-reason/selection", post_data);
	return response.data.code == "200" ? response.data.result : [];
}

const beneTypeSelection = async function (post_data = {}) {
	let response = await ApiService.post("tt-beneficiary-type/selection", post_data);
	return response.data.code == "200" ? response.data.result : []
}

const accountTypeSelection = async function (post_data = {}) {
	if (typeof post_data.locale == "undefined") {
		post_data.locale = i18n.locale
	}
	let response = await ApiService.post("account-type/selection", post_data);
	return response.data.code == "200" ? response.data.result : []
}

const ttCategorySelection = async function (post_data = {}) {
	if (typeof post_data.locale == "undefined") {
		post_data.locale = i18n.locale
	}
	let response = await ApiService.post("tt-category/selection", post_data);
	return response.data.code == "200" ? response.data.result : []
}

const ttCompanySelection = async function (post_data = {}) {
	if (typeof post_data.locale == "undefined") {
		post_data.locale = i18n.locale
	}
	let response = await ApiService.post("tt-company/selection", post_data);
	return response.data.code == "200" ? response.data.result : []
}

const agentCreditorSelection = async function (post_data = {}) {
	if (typeof post_data.locale == "undefined") {
		post_data.locale = i18n.locale
	}
	let response = await ApiService.post("tt-creditor/selection", {
		type_ids: [1, 3]
	});
	return response.data.code == "200" ? response.data.result : []
}

const apAgentSelection = async function (post_data = {}) {
	let response = await ApiService.post("tt-agent/selection", post_data);
	return response.data.code == "200" ? response.data.result : []
}

const apTransTypeSelection = [
	{
		id: 1,
		name: "AP",
		perm_name: "PMS_TT_ASSIGNMENT_AP_TRANSACTION",
		status: 1,
		creditor_type: [1],
		fund_transfer: [1],
	},
	{
		id: 2,
		name: "BK",
		perm_name: "PMS_TT_ASSIGNMENT_AP_TRANSACTION",
		status: 1,
		creditor_type: [3],
		fund_transfer: [3],
	},
	{
		id: 3,
		name: "RF",
		perm_name: "PMS_TT_ASSIGNMENT_AP_TRANSACTION",
		status: 1,
		creditor_type: [3],
		fund_transfer: [3],
	}
]

const courierSelection = async function (post_data = {}) {
	let response = await ApiService.post("courier-company/selection", post_data);
	return response.data.code == "200" ? response.data.result : []
}

const deliveryAgentSelection = async function (post_data = {}) {
	let response = await ApiService.post("delivery-agent/selection", post_data);
	return response.data.code == "200" ? response.data.result : []
}

const applyInChrgSelection = [
	{
		id: 0,
		name: i18n.t('COMMON.INV_CHARGES_DATE.INVOICE_DATE')
	},
	{
		id: 1,
		name: i18n.t('COMMON.INV_CHARGES_DATE.MANIFEST_DATE')
	}
]

const adjustTypeSelection = [
	{
		id: 1,
		name: "+"
	},
	{
		id: 2,
		name: "-"
	}
];


const weightSelection = [
	{
		id: 1,
		name: i18n.t("COSTING.BASE_COST.WEIGHT_SELECTION.ACTUAL_WEIGHT")
	},
	{
		id: 2,
		name: i18n.t("COSTING.BASE_COST.WEIGHT_SELECTION.VOLUME_WEIGHT")
	}
];

const weightConditionSelection = [
	{
		id: 1,
		name: i18n.t("COSTING.BASE_COST.WEIGHT_COND_SELECTION.BETWEEN_ACTUAL_VOLUME_WEIGHT")
	},
	{
		id: 2,
		name: i18n.t("COSTING.BASE_COST.WEIGHT_COND_SELECTION.ACTUAL_WEIGHT")
	},
	{
		id: 3,
		name: i18n.t("COSTING.BASE_COST.WEIGHT_COND_SELECTION.VOLUME_WEIGHT")
	},
	{
		id: 4,
		name: i18n.t("COSTING.BASE_COST.WEIGHT_COND_SELECTION.ACTUAL_WEIGHT_GREATER_VOLUME_WEIGHT")
	},
	{
		id: 5,
		name: i18n.t("COSTING.BASE_COST.WEIGHT_COND_SELECTION.VOLUME_WEIGHT_GREATER_ACTUAL_WEIGHT")
	}
];

const weightMethodSelection = [
	{
		id: 1,
		name: i18n.t("COSTING.BASE_COST.WEIGHT_METHOD_SELECTION.ACTUAL_WEIGHT")
	},
	{
		id: 2,
		name: i18n.t("COSTING.BASE_COST.WEIGHT_METHOD_SELECTION.VOLUME_WEIGHT")
	},
	{
		id: 3,
		name: i18n.t("COSTING.BASE_COST.WEIGHT_METHOD_SELECTION.AVERAGE_WEIGHT")
	},
	{
		id: 4,
		name: i18n.t("COSTING.BASE_COST.WEIGHT_METHOD_SELECTION.HIGHEST_WEIGHT")
	}
];

const noticeTypeGroupSelection = async function () {
	let response = await ApiService.get("notice-type-group/selection");
	return response.data.code == "200" ? response.data.result : []
}

const syncToWms = async function (url, post_data = {}) {
	let success = true;
	let wmsRes = await ApiService.post(url, post_data);
	if (wmsRes.data.code == "503") {
		Swal.fire({
			icon: "warning",
			title: i18n.t("COMMON.MSG.MSG_ERROR_TITLE"),
			text: i18n.t("COMMON.MSG.SERVER_WMS_CONNECTION_ERROR"),
		});
		success = false
	} else if (wmsRes.data.code !== 200) {
		Swal.fire({
			icon: "warning",
			title: i18n.t("COMMON.MSG.MSG_ERROR_TITLE"),
			text: i18n.t("COMMON.MSG.SERVER_WMS_CONNECTION_ERROR"),
		});
		success = false
	}
	return success;
}

const postToAccount = async function (url, post_data = {}) {
	let success = true;
	let wmsRes = await ApiService.post(url, post_data);
	if (wmsRes.data.code == "503") {
		Swal.fire({
			icon: "warning",
			title: i18n.t("COMMON.MSG.MSG_ERROR_TITLE"),
			text: i18n.t("COMMON.MSG.ACCOUNT_API_CONNECTION_REFUSED"),
		});
		success = false
	} else if (wmsRes.data.code !== 200) {
		Swal.fire({
			icon: "warning",
			title: i18n.t("COMMON.MSG..MSG_ERROR_TITLE"),
			text: i18n.t("COMMON.MSG.ACCOUNT_API_CONNECTION_REFUSED"),
		});
		success = false
	}
	return success;
}

const ttCnDnReasonSelection = async function (post_data = {}) {
	let response = await ApiService.post("account/tt-cn-dn-reason-selection", post_data);
	return response.data.code == "200" ? response.data.result : []
}

export default {
	agentSelection,
	areaSelection,
	callingCodeSelection,
	categoryLvl2Selection,
	categorySelection,
	categoryTypeSelection,
	chargesTypeSelection,
	citySelection,
	companyGroupSelection,
	companySelection,
	consigneeSelection,
	costCalcTypeSelection,
	costTypeSelection,
	countrySelection,
	creditorTypeSelection,
	currencyGroupSelection,
	currenySelection,
	customerGroupCostSelection,
	customerMinGroupCostSelection,
	customerSelection,
	dataFilterSelection,
	deliveryAddressSelection,
	deliveryAdressDetail,
	deliveryZoneSelection,
	departmentSelection,
	depositStatusSelection,
	depositPnRejectReason,
	districtSelection,
	doChangeAddrStatusReasonSelection,
	doChangeAddrStatusSelection,
	docNumberFormatSelection,
	epoFormatTypeSelection,
	exceptionalChargesSelection,
	exceptionalCostField,
	exceptionalCostTypeSelection,
	epoStatusSelection,
	factorPlusMinus,
	factorSelection,
	forwarderSelection,
	frieghtChargesSelection,
	fullMarkingSelection,
	getCustomerLoginEmailSelection,
	getFreightRemark,
	getMarkingFromAllAgent,
	getTaxRemark,
	glAccSelection,
	glControlAccSelection,
	glSalesAccSelection,
	indemnifySelection,
	industriesSelection,
	inRangeChargesSelection,
	instructionTypeSelection,
	invFormatSelection,
	invoiceOnHoldSelection,
	invRefSelection,
	locationSelection,
	mainSalesmanSelection,
	markingStatusSelection,
	mathOperator,
	mathOperatorSelection,
	minChrgTypeSelection,
	minMarkupTypeSelection,
	movementTypeSelection,
	noticeTypeSelection,
	numResetSelection,
	packingTypeSelection,
	portName,
	portSelection,
	postCodeSelection,
	postingSideSelection,
	priceTypeSelection,
	productStatusSelection,
	quotationSubjectSelection,
	quotationUserPermission,
	quoteRejectSelection,
	quoteRevertSelection,
	rangeChargesTransitOption,
	rateTypeSelection,
	refIdSelection,
	rolesApplyToSelection,
	roleSelection,
	routeSelection,
	salesmanSelection,
	sensitiveTypeSelection,
	shipmentModeSelection,
	shipmentSelection,
	shipmentTermsSelection,
	shipmentTransitionSelection,
	shipmentTypeSelection,
	specialProductRemarkSelection,
	statesSelection,
	statusSelection,
	taxSelection,
	taxTypeSelection,
	territorySelection,
	ttDailyRateSelection,
	ttGlAccSelection,
	ttGroupSelection,
	ttGrpSelection,
	uomSelection,
	uploadFile,
	userSelection,
	userTypeHandle,
	validitySelection,
	warehouseCountrySelection,
	warehouseSelection,
	warehouseTypeSelection,
	weightRoundingSelection,
	yesNoSelection,
	beneTypeSelection,
	accountTypeSelection,
	ttCategorySelection,
	ttCompanySelection,
	agentCreditorSelection,
	apAgentSelection,
	apTransTypeSelection,
	courierSelection,
	deliveryAgentSelection,
	applyInChrgSelection,
	adjustTypeSelection,
	weightSelection,
	weightConditionSelection,
	weightMethodSelection,
	noticeTypeGroupSelection,
	syncToWms,
	postToAccount,
	ttCnDnReasonSelection
};
